import React, { useEffect, useState } from "react";
import { Button, Card, InputNumber, Spin, Table } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { OperationalService } from "../../services/operational/index.service";
import { useDispatch, useSelector } from "react-redux";
import { CurrencyFormat, YAxisNumberFormat, checkFCAccess } from "../../utils";
import SummaryStoreTable from "./SummaryStoreTable";
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { CustomTooltipForStoreTrackingSales } from "../../utils/Tooltip";
import HourlySalesByStore from "./HourlySalesByStore";

const SalesSectionByStore = (props) => {
  const navigate = useNavigate();
  const { embedded = false } = props;
  const { store = null } = useParams();
  const profile = useSelector((state) => state?.profile) || null;
  const service = new OperationalService();
  const [loading, setLoading] = useState(false);
  const [salesLoading, setSalesLoading] = useState(true);
  const [data, setData] = useState(null);
  const [rankData, setRankData] = useState(null);
  const [visitsData, setVisitsData] = useState(null);
  const [barcharData, setBarChartData] = useState([]);
  const [ritasIdValue, setRitasIdValue] = useState(store || props?.store);
  const [width, setWindowWidth] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const breakPointWidth = 767;
  useEffect(() => {
    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };

  const fetchApi = async (payload) => {
    setLoading(true);
    const res = await service?.fetchSalesSectionByStore(payload);
    setLoading(false);
    setData(res);
  };
  const fetchRankingInfo = async (payload) => {
    setLoading(true);
    const res = await service?.fetchRankingByStore(payload);
    if (res) {
      setRankData(res);
    }
    setLoading(false);
  };
  const fetchStoreVisits = async (payload) => {
    setLoading(true);
    const res = await service?.fetchStoreVisits(payload);
    if (res) {
      setVisitsData(res?.lastVisitsData);
    }
    setLoading(false);
  };
  const fetchSalesHistory = async (payload) => {
    setSalesLoading(true);
    const res = await service?.fetchSalesHistoryByStore(payload);
    setBarChartData(res?.salesData);
    const maxValue = Math.max(...res?.salesData.map((o) => o.Sales));
    setMaxValue(maxValue + 100);
    setSalesLoading(false);
  };
  const handleClear = () => {
    setRitasIdValue(null);
    setData(null);
  };

  useEffect(() => {
    if (ritasIdValue) {
      handleSearch();
    }
  }, []);

  const handleSalesHistoryNavigation = () => {
    // dispatch(updateMenuSelection('financial'))
    navigate(`/${props?.from}/ytd/${ritasIdValue}`);
  };

  const handleSearch = () => {
    let payload = {
      ritasId: ritasIdValue ? ritasIdValue : undefined,
    };
    if (checkFCAccess(profile)) {
      payload = { ...payload, intFSM: profile?.intFSM };
    }
    fetchApi(payload);
    fetchSalesHistory(payload);
    fetchRankingInfo(payload);
    fetchStoreVisits(payload);
  };

  const handleChange = (val) => {
    setRitasIdValue(val);
  };

  const StoreComponent = () => {
    return (
      <div className="report-grid">
        <div className="rank-grid-item">
          <div className="table-div">
            {embedded ? (
              <div className="ant-descriptions-title">{`Store Tracking`}</div>
            ) : (
              <div className="store-tracking-grid-header">
                <InputNumber
                  name="RitasId"
                  type={"number"}
                  style={{ width: "auto" }}
                  id="RitasId"
                  placeholder="Store Number"
                  onChange={handleChange}
                  value={ritasIdValue}
                  autoFocus={true}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      handleSearch();
                    }
                  }}
                />
                <div>
                  <Button
                    style={{ marginRight: "8px" }}
                    onClick={() => handleClear()}
                  >
                    <i className="fas fa-eraser"></i>
                  </Button>
                  <Button onClick={() => handleSearch()}>
                    <i className="fas fa-search"></i>
                  </Button>
                </div>
              </div>
            )}
          </div>
          {!data && !loading ? (
            <div className="no-records-available text-muted">
              {"Please Search with Store Number"}
            </div>
          ) : (
            <>
              <Spin spinning={loading}>
                <div className="my-2 stores-section-summary bg-white">
                  <SummaryStoreTable
                    dataItem={{
                      ...data?.summaryData,
                      ...data?.[`clover-retail`],
                      feedbackCount: data?.feedbackCount,
                    }}
                    rankData={rankData}
                    visitsData={visitsData}
                    embedded={embedded}
                  />
                </div>
              </Spin>
              <Spin spinning={salesLoading}>
                <div className="total-system-sales bg-white">
                  <h6>
                    Sales History{" "}
                    {!embedded && (
                      <span
                        className="btn-link sales-history-link"
                        onClick={() => handleSalesHistoryNavigation()}
                      >
                        Click here
                      </span>
                    )}
                  </h6>
                  <ResponsiveContainer
                    width="100%"
                    aspect={width < breakPointWidth ? 2 : 4}
                  >
                    <BarChart
                      data={barcharData}
                      margin={{ top: 5, right: 20, bottom: 5, left: 20 }}
                    >
                      <XAxis dataKey="Year" />
                      <YAxis
                        label={{
                          // value: "Sales ($)",
                          angle: 360,
                          position: "insideBottomRight",
                          offset: 10,
                        }}
                        // width={85}
                        domain={[
                          0,
                          (dataMax) => parseFloat(maxValue).toFixed(2),
                        ]}
                        tickFormatter={YAxisNumberFormat}
                        allowDataOverflow={true}
                      />
                      <Tooltip
                        content={<CustomTooltipForStoreTrackingSales />}
                        labelStyle={{ color: "darkblue" }}
                      />
                      <Bar
                        dataKey="Sales"
                        fill="#0088FE"
                        barSize={width < breakPointWidth ? 5 : 12}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </Spin>
              <HourlySalesByStore store={ritasIdValue} skeleton={false} />
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      {!embedded ? (
        <Card
          title={
            !embedded ? (
              <>
                <Button
                  type="primary"
                  className="btn-back"
                  onClick={() => navigate(-1)}
                >
                  <i
                    className="fa fa-arrow-left"
                    style={{ fontSize: "16px" }}
                  ></i>
                </Button>
                <span>{`Store Tracking`}</span>
              </>
            ) : (
              <></>
            )
          }
        >
          {StoreComponent()}
        </Card>
      ) : (
        <Card>{StoreComponent()}</Card>
      )}
    </>
  );
};

export default SalesSectionByStore;
