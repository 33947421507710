import { CommonServiceUtils } from "../common-service-utils";

export class CoolnetService extends CommonServiceUtils {
  async fetchYtdSales(req) {
    let response = null;
    const fetchYtdSales = await this.postData(
      `${this.apiUrlCreator(`store-ytd-total-sales`)}`,
      req
    );
    if (fetchYtdSales?.responseCode === 200) {
      response = fetchYtdSales?.result;
    }
    return response;
  }

  async fetchYtdMonthSales(req) {
    let response = null;
    const fetchYtdMonthSales = await this.postData(
      `${this.apiUrlCreator(`store-month-total-sales`)}`,
      req
    );
    if (fetchYtdMonthSales?.responseCode === 200) {
      response = fetchYtdMonthSales?.result;
    }
    return response;
  }
  async fetchStoreSummaryData(req) {
    let response = null;
    const fetchStoreSummaryData = await this.postData(
      `${this.apiUrlCreator(`store-summary`)}`,
      req
    );
    if (fetchStoreSummaryData?.responseCode === 200) {
      response = fetchStoreSummaryData?.result;
    }
    return response;
  }
  async fetchPriorStoreSummaryData(req) {
    let response = null;
    const fetchPriorStoreSummaryData = await this.postData(
      `${this.apiUrlCreator(`store-summary-prior`)}`,
      req
    );
    if (fetchPriorStoreSummaryData?.responseCode === 200) {
      response = fetchPriorStoreSummaryData?.result;
    }
    return response;
  }

  async fetchYtdWeekSales(req) {
    let response = null;
    const fetchYtdWeekSales = await this.postData(
      `${this.apiUrlCreator(`store-week-total-sales`)}`,
      req
    );
    if (fetchYtdWeekSales?.responseCode === 200) {
      response = fetchYtdWeekSales?.result;
    }
    return response;
  }

  async fetchYtdLineOfBusinessSales(req) {
    let response = null;
    const fetchYtdLineOfBusinessSales = await this.postData(
      `${this.apiUrlCreator(`store-ytd-business-sales`)}`,
      req
    );
    if (fetchYtdLineOfBusinessSales?.responseCode === 200) {
      response = fetchYtdLineOfBusinessSales?.result;
    }
    return response;
  }
  async fetchDigitalSalesForStore(req) {
    let response = null;
    const fetchDigitalSalesForStore = await this.postData(
      `${this.apiUrlCreator(`store-digital-sales`)}`,
      req
    );

    if (fetchDigitalSalesForStore?.responseCode === 200) {
      response = fetchDigitalSalesForStore?.result;
    }
    return response;
  }
}
