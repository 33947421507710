import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const monthApi = createApi({
  reducerPath: "monthApi",
  baseQuery: fetchBaseQuery({ baseUrl: "http://localhost:8000/api/" }),
  endpoints: (builder) => ({
    getMonthApi: builder.query({
      query: () => ({
        url: `ytd-monthwise-total-sales`,
        method: "POST",
      }),
    }),
  }),
});

export const { useGetMonthApiQuery } = monthApi;
