import React, { useEffect } from "react";
import { Card, Input, Form,Button } from "antd";
import { beginAuth } from "../../api/oidcApi";
import { useDispatch, useSelector } from "react-redux";
import { generateStateAndNonce } from "../../redux/token/tokenSlice";

const Login = () => {

 const { state, nonce, accessToken } = useSelector((state) => state?.token);
 const dispatch = useDispatch();
 const generateParams = () => {
   dispatch(generateStateAndNonce());
 };
 useEffect(() => {
   if (!accessToken) {
     if (state === null || nonce === null) {
       generateParams();
     } else {
       beginAuth({ state, nonce });
     }
   }
 }, [accessToken,state]);
  const onFinish = (values) => {
    // console.log("Received values of form: ", values);
    if (state === null || nonce === null) {
      generateParams();
      // console.log('generateParams')
    } else {
      // console.log('doing')
      beginAuth({ state, nonce });
    }
  };
  return (
    <div className="site-card-wrapper">
      <div className="login-container">
        {/* <Card className="login-card pb-4" bordered={false}>
          <div className="ant-form-item d-flex justify-content-center"> 
          <img src='/ritas-header-logo.png' alt='Ritas' className='Logo-Login-Img' />
           </div>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please enter Username!",
                },
              ]}
            >
              <Input
                prefix={<i className="fa fa-user"></i>}
                placeholder="Username"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please enter Password!",
                },
              ]}
            >
              <Input
                prefix={<i className="fa fa-lock"></i>}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Log in
              </Button>
            </Form.Item>
          </Form>
        </Card> */}
      </div>
    </div>
  );
};

export default Login;
