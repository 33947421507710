import React, { useEffect, useState } from "react";
import { CoolnetService } from "../../../services/coolnet/index.service";
import { getYearName } from "../../../utils";
import { useSelector } from "react-redux";

const useYearSales = (props) => {
  const { selectedYear } = useSelector(
    (state) => state?.customSelection
  );
  const service = new CoolnetService();
  const [maxValue, setMaxValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [barData, setBarData] = useState([]);
  useEffect(() => {
    fetchApi();
  }, [selectedYear]);

  const fetchApi = async () => {
    setLoading(true);
    const payload = { ritasId: props?.storeId, selectedYear };
    const data = await service?.fetchYtdSales(payload);
    setData(data);
    const rec = data?.TotalSytemSales?.map((i) => {
      const yearVal = getYearName(i?.name, selectedYear);
      const displayName = yearVal;
      return { ...i, displayName: displayName };
    });
    setBarData(rec || []);
    const maxValue = Math.max(...rec.map((o) => o.Amount));
    setMaxValue(maxValue + 100);
    setLoading(false);
  };

  return { loading, maxValue, barData, data };
};

export default useYearSales;
