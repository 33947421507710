import { Button, Result } from "antd";
import React from "react";
const Unauthorized = () => {
  const redirectUrl = process.env.REACT_APP_RITAS_ONE_LOGIN_URL;
  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
      extra={
        <Button type="primary" onClick={() => window.open(redirectUrl,'_self')}>
          Back to One Login
        </Button>
      }
    />
  );
};

export default Unauthorized;
