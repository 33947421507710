import React, { useState } from "react";
import { Button, Card, Segmented } from "antd";
import { useNavigate } from "react-router-dom";
import RankingReport from "./Sales/RankingReport";
import YearPicker from "../YearPicker";
const CummulativeRanking = () => {
  const navigate = useNavigate();
  const [mode, setMode] = useState("Year");
  const [internalMode, setInternalMode] = useState("Sales");
  const handleInternalMode=(e)=>{
    setInternalMode(e)
  }
  return (
    <>
      <Card
        title={
          <>
            <div className="d-flex justify-content-between">
            <div>
              <Button
                type="primary"
                className="btn-back"
                onClick={() => navigate(-1)}
              >
                <i
                  className="fa fa-arrow-left"
                  style={{ fontSize: "16px" }}
                ></i>
              </Button>
              <span>{"Ranking Report"}</span>
            </div>
            <div>
              {mode === "Year"  &&  <YearPicker />}
            </div>
          </div>
          </>
        }
      >
        <>
          <div className="ranking-btn-group pb-2">
            <Segmented
              options={["Year","Quarter","Month","Week","Day"]}
              value={mode}
              onChange={(e) => setMode(e)}
            />
          </div>
          {mode === "Year" && (
            <RankingReport
              type="year"
              skeleton={true}
              internalMode={internalMode}
              handleInternalMode={handleInternalMode}
              title={"Year Total Ranking Report"}
            />
          )}
          {mode === "Quarter" && (
            <RankingReport
              type="quarter"
              skeleton={true}
              handleInternalMode={handleInternalMode}

              internalMode={internalMode}
              title={"Quarter Total Ranking Report"}
            />
          )}
          {mode === "Month" && (
            <RankingReport
              type="month"
              handleInternalMode={handleInternalMode}

              skeleton={true}
              internalMode={internalMode}


              title={"Month Total Ranking Report"}
            />
          )}
          {mode === "Week" && (
            <RankingReport
              type="week"
              skeleton={true}
              handleInternalMode={handleInternalMode}

              internalMode={internalMode}
              title={"Month Total Ranking Report"}
            />
          )}
          {mode === "Day" && (
            <RankingReport
              type="day"
              skeleton={true}
              handleInternalMode={handleInternalMode}

              internalMode={internalMode}

              title={"Day Total Ranking Report"}
            />
          )}
        </>
      </Card>
    </>
  );
};

export default CummulativeRanking;
