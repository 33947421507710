import React from "react";
import { NumberFormat, ShowArrow, getYearNameOnly } from "../../../../utils";
import { PieLegends } from "../../../../utils/legend";
import { SvgTransaction } from "../../../../utils/svgpath";

const LoyaltyLegendTable = (props) => {
  const { selectedView, selectedYear, AllTableData } = props;
  return (
    <>
      <div className="table-container-grid">
        {AllTableData?.map((table) => {
          return (
            <div className="legend-table">
                  <table>
                    <thead>
                      <tr>
                        <th>
                          {selectedView === "Monthly" && "Month"}
                          {selectedView === "Quarterly" && "Quarter"}
                          {selectedView === "Weekly" && "Week"}
                        </th>
                        <th>Visits</th>
                        <th>{getYearNameOnly("currentYear", selectedYear)}</th>
                        <th>{getYearNameOnly("priorYear", selectedYear)}</th>
                        <th>{"Chg%"}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {table?.map((i) => {
                        return (
                          <>
                            <tr>
                              <td rowSpan={2}>{i?.Name}</td>
                              <td>
                                {SvgTransaction(PieLegends["Loyalty Visits"])}
                                {"Loyalty Visits"}
                                {parseFloat(
                                  i["Value"]["chg"]["Loyalty Visits"]
                                ) > 0
                                  ? ShowArrow("up")
                                  : ShowArrow("down")}
                              </td>
                              <td>
                                {NumberFormat(
                                  i["Value"]["currentYear"]["Loyalty Visits"]
                                )}
                              </td>
                              <td>
                                {NumberFormat(
                                  i["Value"]["priorYear"]["Loyalty Visits"]
                                )}
                              </td>
                              <td>{i["Value"]["chg"]["Loyalty Visits"]}</td>
                            </tr>
                            <tr>
                              <td>
                                {SvgTransaction(PieLegends["Guest Count"])}
                                {"Guest Count"}
                                {parseFloat(i["Value"]["chg"]["Guest Count"]) >
                                0
                                  ? ShowArrow("up")
                                  : ShowArrow("down")}
                              </td>
                              <td>
                                {NumberFormat(
                                  i["Value"]["currentYear"]["Guest Count"]
                                )}
                              </td>
                              <td>
                                {NumberFormat(
                                  i["Value"]["priorYear"]["Guest Count"]
                                )}
                              </td>
                              <td>{i["Value"]["chg"]["Guest Count"]}</td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
          );
        })}
      </div>
    </>
  );
};

export default LoyaltyLegendTable;
