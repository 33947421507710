import React, { useEffect, useState } from "react";
import { Button, Card, Segmented, Spin } from "antd";
import { FinancialService } from "../../../services/financial/index.service";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import GuestCount from "./GuestCount";

const CumulativeGuestReport = () => {
  const [type, setType] = useState("year");
  const [title, setTitle] = useState("Yearly Total Guest Count");
  const navigate = useNavigate();
  const [selectedView, setSelectedView] = useState("");
  const [loading, setLoading] = useState(true);
  const [width, setWindowWidth] = useState(0);
  const breakPointWidth = 767;

  useEffect(() => {
    setSelectedView("Year");
    setType("year");
    setTitle("Yearly Total Guest Count");
    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };
  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const handleSegmentSelection = (e) => {
    setLoading(true);
    setType(e);

    if (e === "year") {
      setTitle("Yearly Total Guest Count");
    } else if (e === "month") {
      setTitle("Monthly Total Guest Count");
    } else if (e === "week") {
      setTitle("Weekly Total Guest Count");
    } else if (e === "day") {
      setTitle("Daily Total Guest Count");
    }
    setSelectedView(Capitalize(e));
    setLoading(false);
  };

  return (
    <>
        <Card
          title={
            <>
              <div className="d-flex justify-content-between">
                <div>
                  <Button
                    type="primary"
                    className="btn-back"
                    onClick={() => navigate("/financial")}
                  >
                    <i
                      className="fa fa-arrow-left"
                      style={{ fontSize: "16px" }}
                    ></i>
                  </Button>

                  <span>{title}</span>
                </div>
              </div>
            </>
          }
        >
            <div className="ranking-btn-group pb-2">
            <Segmented
                    options={["Year", "Month", "Week", "Day"]}
                    value={selectedView}
                    onChange={(e) => handleSegmentSelection(e.toLowerCase())}
                  />
          </div>
          <>
            {type === "year" && (
              <GuestCount type="year" title={"Yearly Total Guest Count"} />
            )}
            {type === "month" && (
              <GuestCount type="month" title={"Month Total Guest Count"} />
            )}
            {type === "week" && (
              <GuestCount type="week" title={"Weekly Total Guest Count"} />
            )}
            {type === "day" && (
              <GuestCount type="day" title={"Daily Total Guest Count"} />
            )}
          </>
        </Card>
    </>
  );
};

export default CumulativeGuestReport;
