import { CommonServiceUtils } from "../common-service-utils";

export class MarketingService extends CommonServiceUtils {
  async fetchYtdTrancationsByShop(req) {
    let response = [];
    const fetchYtdTrancationsByShop = await this.postData(
      `${this.apiUrlCreator(`shop-wise-ranking`)}`,
      req
    );

    if (fetchYtdTrancationsByShop?.responseCode === 200) {
      response = fetchYtdTrancationsByShop?.result;
    }
    return response;
  }
  async fetchYtdTrancationsByShopExcel(req) {
    let response = null;
    const fetchYtdTrancationsByShopExcel = await this.postData(
      `${this.apiUrlCreator(`loyaltyexport`)}`,
      req
    );
    if (fetchYtdTrancationsByShopExcel?.responseCode === 200) {
      response = fetchYtdTrancationsByShopExcel?.result;
    }
    return response;
  }
  async fetchLoyaltyReport(req) {
    let response = null;
    const fetchLoyaltyReport = await this.postData(
      `${this.apiUrlCreator(`loyalty-report-ytd`)}`,
      req
    );

    if (fetchLoyaltyReport?.responseCode === 200) {
      response = fetchLoyaltyReport?.result;
    }
    return response;
  }
  async fetchLoyaltyActiveUsers(req) {
    let response = null;
    const fetchLoyaltyActiveUsers = await this.postData(
      `${this.apiUrlCreator(`loyalty-active-users`)}`,
      req
    );

    if (fetchLoyaltyActiveUsers?.responseCode === 200) {
      response = fetchLoyaltyActiveUsers?.result;
    }
    return response;
  }

  async fetchLoyaltyTransactions(req) {
    let response = null;
    const fetchLoyaltyTransactions = await this.postData(
      `${this.apiUrlCreator(`loyalty-transactions-ytd`)}`,
      req
    );

    if (fetchLoyaltyTransactions?.responseCode === 200) {
      response = fetchLoyaltyTransactions?.result;
    }
    return response;
  }
  async fetchRetailTransactions(req) {
    let response = null;
    const fetchRetailTransactions = await this.postData(
      `${this.apiUrlCreator(`retail-transactions-ytd`)}`,
      req
    );

    if (fetchRetailTransactions?.responseCode === 200) {
      response = fetchRetailTransactions?.result;
    }
    return response;
  }
  async fetchFavoriteShopsTransactions(req) {
    let response = null;
    const fetchFavoriteShopsTransactions = await this.postData(
      `${this.apiUrlCreator(`retail-transactions-ytd`)}`,
      req
    );

    if (fetchFavoriteShopsTransactions?.responseCode === 200) {
      response = fetchFavoriteShopsTransactions?.result;
    }
    return response;
  }
  async fetchBirthdayOffersRedeemed(req) {
    let response = null;
    const fetchBirthdayOffersRedeemed = await this.postData(
      `${this.apiUrlCreator(`birthday-redeemed-ytd`)}`,
      req
    );

    if (fetchBirthdayOffersRedeemed?.responseCode === 200) {
      response = fetchBirthdayOffersRedeemed?.result;
    }
    return response;
  }
  async fetchPromotionalDates() {
    let response = null;
    const fetchPromotionalDates = await this.postData(
      `${this.apiUrlCreator(`get-promotional-dates`)}`
    );

    if (fetchPromotionalDates?.responseCode === 200) {
      response = fetchPromotionalDates?.result;
    }
    return response;
  }

  async fetchBirthdayRedeemptionsReport(req) {
    let response = null;
    const fetchBirthdayRedeemptionsReport = await this.postData(
      `${this.apiUrlCreator(`year-birthday`)}`,
      req
    );

    if (fetchBirthdayRedeemptionsReport?.responseCode === 200) {
      response = fetchBirthdayRedeemptionsReport?.result;
    }
    return response;
  }
  async fetchCouponRedeemptionsReport(req) {
    let response = null;
    const fetchCouponRedeemptionsReport = await this.postData(
      `${this.apiUrlCreator(`year-coupon`)}`,
      req
    );

    if (fetchCouponRedeemptionsReport?.responseCode === 200) {
      response = fetchCouponRedeemptionsReport?.result;
    }
    return response;
  }
  async fetchRedeemptionsReport(req) {
    let response = null;
    const fetchRedeemptionsReport = await this.postData(
      `${this.apiUrlCreator(`redeem-report`)}`,
      req
    );

    if (fetchRedeemptionsReport?.responseCode === 200) {
      response = fetchRedeemptionsReport?.result;
    }
    return response;
  }
  async fetchEarnedRedeemptionsReport(req) {
    let response = null;
    const fetchEarnedRedeemptionsReport = await this.postData(
      `${this.apiUrlCreator(`year-earned`)}`,
      req
    );

    if (fetchEarnedRedeemptionsReport?.responseCode === 200) {
      response = fetchEarnedRedeemptionsReport?.result;
    }
    return response;
  }
  async fetchVisitsRedeemptionsReport(req) {
    let response = null;
    const fetchVisitsRedeemptionsReport = await this.postData(
      `${this.apiUrlCreator(`year-visits`)}`,
      req
    );

    if (fetchVisitsRedeemptionsReport?.responseCode === 200) {
      response = fetchVisitsRedeemptionsReport?.result;
    }
    return response;
  }
  async fetchCustomerAppeasementReport(req) {
    let response = null;
    const fetchCustomerAppeasementReport = await this.postData(
      `${this.apiUrlCreator(`year-customer-appeasement`)}`,
      req
    );

    if (fetchCustomerAppeasementReport?.responseCode === 200) {
      response = fetchCustomerAppeasementReport?.result;
    }
    return response;
  }
}
