import React from "react";
import { CurrencyFormat } from "../../../utils";

const SummarySalesTable = (props) => {
  const { selectedType, dataItem, roundOff = false } = props;
  return (
    <>
      <table className="summary-table">
        <thead>
          {selectedType === "week" && (
            <>
              <tr>
                <th>Week</th>
                <th>:</th>
                <th>{dataItem?.Week}</th>
              </tr>
              <tr>
                <th>WE Date</th>
                <th>:</th>
                <th>{dataItem?.WEDate}</th>
              </tr>
              <tr>
                <th>Current Week Average (Delta)</th>
                <th>:</th>
                <th>
                  {CurrencyFormat(dataItem?.weeklyAverageSales, roundOff)}
                </th>
              </tr>
            </>
          )}
          {selectedType === "year" && (
            <>
              <tr>
                <th>Year</th>
                <th>:</th>
                <th>{dataItem?.Year}</th>
              </tr>
            </>
          )}
          {selectedType === "quarter" && (
            <>
              <tr>
                <th>Quarter</th>
                <th>:</th>
                <th>{`${dataItem?.Year} Q${dataItem?.Quarter}`}</th>
              </tr>
              <tr>
                <th>Current Quarter Average (Delta)</th>
                <th>:</th>
                <th>
                  {CurrencyFormat(dataItem?.quarterAverageSales, roundOff)}
                </th>
              </tr>
            </>
          )}
          {selectedType === "month" && (
            <>
              <tr>
                <th>Month</th>
                <th>:</th>
                <th>{dataItem?.Month}</th>
              </tr>
              <tr>
                <th>Current Month Average (Delta)</th>
                <th>:</th>
                <th>{CurrencyFormat(dataItem?.monthAverageSales, roundOff)}</th>
              </tr>
            </>
          )}
          {selectedType === "day" && (
            <>
              <tr>
                <th>Date</th>
                <th>:</th>
                <th>{dataItem?.Date}</th>
              </tr>
              <tr>
                <th>Current Day Average (Delta)</th>
                <th>:</th>
                <th>{CurrencyFormat(dataItem?.dailyAverageSales, roundOff)}</th>
              </tr>
            </>
          )}
          <tr>
            <th>YTD Average</th>
            <th>:</th>
            <th>{CurrencyFormat(dataItem?.YTDTotalShopSales, roundOff)}</th>
          </tr>
        </thead>
      </table>
    </>
  );
};

export default SummarySalesTable;
