import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  BREAKPOINTWIDTH,
  CurrencyFormat,
  getYearName,
  NumberFormat,
  ShowArrow,
  YAxisNumberFormat,
} from "../../../utils";
import { Legends } from "../../../utils/legend";
import { DashboardLoader } from "../../../utils/Loading";
import {
  SvgChg,
  SvgCurrentYear,
  SvgPreviousYear,
} from "../../../utils/svgpath";
import { CustomTooltipWeekView } from "../../../utils/Tooltip";
import YearPicker from "../../YearPicker";
import useWeekSales from "./WeekSales.hook";

const WeekSales = (props) => {
  const {
    maindata,
    maxValue,
    loading,
    selectedYear,
    data,
    roundOff = false,
  } = useWeekSales(props);
  const breakPointWidth = BREAKPOINTWIDTH;
  const { width } = props;
  return (
    <>
      {loading ? (
        <DashboardLoader />
      ) : (
        <>
        <div className="d-flex justify-content-end pb-2"> <YearPicker /></div>
          {data?.length > 0 ? (
            <>
              <div className="total-system-sales">
                <ResponsiveContainer
                  width="100%"
                  aspect={width < breakPointWidth ? 2 : 4}
                >
                  <BarChart
                    data={data}
                    margin={{ top: 5, right: 20, bottom: 5, left: 20 }}
                  >
                    <XAxis dataKey="Week" />
                    <YAxis
                      label={{
                        // value: "Amt ($)",
                        angle: 360,
                        position: "insideBottomRight",
                        offset: 10,
                      }}
                      // width={85}
                      tickFormatter={YAxisNumberFormat}
                      domain={[0, (dataMax) => parseFloat(maxValue).toFixed(2)]}
                      allowDataOverflow={true}
                    />
                    <Tooltip
                      content={
                        <CustomTooltipWeekView
                          roundOff={roundOff}
                          selectedYear={selectedYear}
                        />
                      }
                      labelStyle={{ color: "darkblue" }}
                    />
                    <Bar
                      dataKey="currentYear"
                      fill={Legends["currentYear"]}
                      barSize={4}
                    />
                    <Bar
                      dataKey="priorYear"
                      fill={Legends["priorYear"]}
                      barSize={4}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
              <div className="week-table-container-store">
                {maindata?.WeekWiseSalesChunks?.map((j, index) => {
                  return (
                    <>
                      <div className="table-container d-flex flex-column p-4">
                        <h6>{`Quarter ${index + 1}`}</h6>
                        <table key={width}>
                          <thead>
                            <tr>
                              <th>Week</th>
                              <th>
                                {" "}
                                {SvgCurrentYear()}
                                {getYearName("currentYear", selectedYear)}
                                {ShowArrow(maindata?.ItemData?.currentArrow)}
                              </th>
                              <th>
                                {" "}
                                {SvgPreviousYear()}
                                {getYearName("priorYear", selectedYear)}
                                {ShowArrow(maindata?.ItemData?.priorArrow)}
                              </th>
                              <th>
                                {" "}
                                {SvgChg()}
                                Chg %
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {j?.map((i) => {
                              return (
                                <tr>
                                  <td>{i?.Week}</td>
                                  <td
                                    style={{
                                      color:
                                        i?.currentYear < i?.priorYear
                                          ? `red`
                                          : `inherit`,
                                    }}
                                  >
                                    {CurrencyFormat(i?.currentYear, roundOff)}
                                  </td>
                                  <td
                                    style={{
                                      color:
                                        i?.priorYear < i?.currentYear
                                          ? `red`
                                          : `inherit`,
                                    }}
                                  >
                                    {CurrencyFormat(i?.priorYear, roundOff)}
                                  </td>
                                  <td>{`${i?.["chg"]} %`}</td>
                                </tr>
                              );
                            })}
                            <tr>
                              <td>{"Total"}</td>
                              <td
                                style={{
                                  color:
                                    maindata?.WeekWiseSalesChunksTotal[index]
                                      ?.currentYear <
                                    maindata?.WeekWiseSalesChunksTotal[index]
                                      ?.priorYear
                                      ? `red`
                                      : `inherit`,
                                }}
                              >
                                {CurrencyFormat(
                                  maindata?.WeekWiseSalesChunksTotal[index]
                                    ?.currentYear,
                                  roundOff
                                )}
                              </td>
                              <td
                                style={{
                                  color:
                                    maindata?.WeekWiseSalesChunksTotal[index]
                                      ?.priorYear <
                                    maindata?.WeekWiseSalesChunksTotal[index]
                                      ?.currentYear
                                      ? `red`
                                      : `inherit`,
                                }}
                              >
                                {CurrencyFormat(
                                  maindata?.WeekWiseSalesChunksTotal[index]
                                    ?.priorYear,
                                  roundOff
                                )}
                              </td>
                              <td>{`${maindata?.WeekWiseSalesChunksTotal[index]?.chg} %`}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </>
                  );
                })}
              </div>
            </>
          ) : (
            <>
              <div className="norecords-available">No records available</div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default WeekSales;
