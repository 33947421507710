import React from "react";
import {
  CurrencyFormat,
  getYearName,
  getYearNameWithGuests,
} from "../../../utils";

const SummaryRollupTable = (props) => {
  const { selectedType, dataItem,roundOff=false } = props;
  return (
    <>
      <table className="table-container">
        <thead>
          <>
            <tr>
              <th>Week</th>
              <th>:</th>
              <th>{dataItem?.Week || 0}</th>
            </tr>
            <tr>
              <th>WE Date</th>
              <th>:</th>
              <th>{dataItem?.WEDate || "-"}</th>
            </tr>
            <tr>
              <th>Active Store Count</th>
              <th>:</th>
              <th>{dataItem?.activeStoreCount || 0}</th>
            </tr>
            <tr>
              <th>Total Store Count</th>
              <th>:</th>
              <th>{dataItem?.storeCount || 0}</th>
            </tr>
          </>
          {(selectedType === "sales" || selectedType === "ranking") && (
            <>
              <tr>
                <th>{getYearName("currentYear")}</th>
                <th>:</th>
                <th>{CurrencyFormat(dataItem?.currentSales,roundOff)}</th>
              </tr>
              <tr>
                <th>{getYearName("priorYear")}</th>
                <th>:</th>
                <th>{CurrencyFormat(dataItem?.priorSales,roundOff)}</th>
              </tr>
            </>
          )}
          {(selectedType === "guests" || selectedType === "ranking") && (
            <>
              <tr>
                <th>{getYearNameWithGuests("currentYear")}</th>
                <th>:</th>
                <th>{dataItem?.currentYear || 0}</th>
              </tr>
              <tr>
                <th>{getYearNameWithGuests("priorYear")}</th>
                <th>:</th>
                <th>{dataItem?.priorYear || 0}</th>
              </tr>
            </>
          )}
        </thead>
      </table>
    </>
  );
};

export default SummaryRollupTable;
