import React from "react";
import { CurrencyFormat } from "../../../../utils";
import { useSelector } from "react-redux";
import moment from "moment";

const SummarySalesData = (props) => {
  const { selectedView, dataItem, roundOff = false } = props;
  const {
    selectedYear,
    selectedQuarter,
    selectedMonth,
    selectedWeek,
    selectedDay,
  } = useSelector((state) => state?.customSelection);
  return (
    <>
      <table className="summary-table">
        <thead>
          {selectedView === "Weekly" && (
            <>
              {selectedWeek !== -1 && (
                <>
                  <tr>
                    <th>Week</th>
                    <th>:</th>
                    <th>
                      {selectedWeek === 0
                        ? `${selectedYear} - All Weeks`
                        : `${selectedYear} - Week ${selectedWeek}`}
                    </th>
                  </tr>{" "}
                  {/* <tr>
                    <th>WE Date</th>
                    <th>:</th>
                    <th>{dataItem?.WEDate}</th>
                  </tr> */}
                  <tr>
                    <th>Week Average (Delta)</th>
                    <th>:</th>
                    <th>
                      {CurrencyFormat(dataItem?.weeklyAverageSales, roundOff)}
                    </th>
                  </tr>
                </>
              )}
              {selectedWeek === -1 && (
                <>
                  <tr>
                    <th>Date</th>
                    <th>:</th>
                    <th>{dataItem?.Date}</th>
                  </tr>
                  <tr>
                    <th>Current Day Average (Delta)</th>
                    <th>:</th>
                    <th>
                      {CurrencyFormat(dataItem?.dailyAverageSales, roundOff)}
                    </th>
                  </tr>
                </>
              )}
            </>
          )}
          {selectedView === "Yearly" && (
            <>
              <tr>
                <th>Year</th>
                <th>:</th>
                <th>{dataItem?.Year}</th>
              </tr>
            </>
          )}
          {selectedView === "Quarterly" && (
            <>
              <tr>
                <th>Quarter</th>
                <th>:</th>
                <th>
                  {selectedQuarter === 0
                    ? `${selectedYear} - All Quarters`
                    : `${selectedYear} - Q${selectedQuarter}`}
                </th>
              </tr>
              <tr>
                <th>Quarter Average (Delta)</th>
                <th>:</th>
                <th>
                  {CurrencyFormat(dataItem?.quarterAverageSales, roundOff)}
                </th>
              </tr>
            </>
          )}
          {selectedView === "Monthly" && (
            <>
              <tr>
                <th>Month</th>
                <th>:</th>
                <th>
                  {selectedMonth === 0
                    ? `${selectedYear}- All Months`
                    : `${selectedYear}-${moment(selectedMonth, "M").format(
                        "MMMM"
                      )}`}
                </th>
              </tr>
              <tr>
                <th>Month Average (Delta)</th>
                <th>:</th>
                <th>{CurrencyFormat(dataItem?.monthAverageSales, roundOff)}</th>
              </tr>
            </>
          )}
          {selectedView === "day" && (
            <>
              <tr>
                <th>Date</th>
                <th>:</th>
                <th>{dataItem?.Date}</th>
              </tr>
              <tr>
                <th>Current Day Average (Delta)</th>
                <th>:</th>
                <th>{CurrencyFormat(dataItem?.dailyAverageSales, roundOff)}</th>
              </tr>
            </>
          )}
          <tr>
            <th>YTD Average</th>
            <th>:</th>
            <th>{CurrencyFormat(dataItem?.YTDTotalShopSales, roundOff)}</th>
          </tr>
        </thead>
      </table>
    </>
  );
};

export default SummarySalesData;
