import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  Segmented,
  Space,
  Spin,
  Switch,
  Table,
} from "antd";
import { FinancialService } from "../../../services/financial/index.service";
import {
  CurrencyFormat,
  checkFCAccess,
  generateDownLoadPath,
  getGuestRankLabelName,
  getSalesRankLabelName,
  getYearNameWithVisits,
} from "../../../utils";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import YearPicker from "../../YearPicker";
import { updateRoundOffSelection } from "../../../redux/dashboard/customSelectionSlice";

const RankingReport = (props) => {
  const { type, skeleton = false, child = false, title = "" } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedYear, roundOff } = useSelector(
    (state) => state?.customSelection
  );
  const profile = useSelector((state) => state?.profile) || null;
  const service = new FinancialService();
  const [mode, setMode] = useState(props?.internalMode || "Sales");
  const [loading, setLoading] = useState(true);
  const [excludeLessThanYearStore, setExcludeLessThanYearStore] =
    useState(false);
  const [excludeInactiveStore, setExcludeInactiveStore] = useState(true);
  const [RankData, setRankData] = useState([]);
  const [itemData, setItemData] = useState(null);
  const [loyaltyRankData, setLoyaltyRankData] = useState([]);
  const [excelLoading, setExcelLoading] = useState(false);

  const handleModeChange = (e) => {
    console.log(props);
    console.log(e);
    if (props?.internalMode) {
      props?.handleInternalMode(e);
    } else {
      setMode(e);
    }
  };

  useEffect(() => {
    if (props?.internalMode) {
      if (props?.internalMode === "Loyalty" && type !== "year") {
        setMode("Sales");
      } else {
        setMode(props?.internalMode);
      }
    }
  }, [props?.internalMode]);

  const salesColumns = [
    {
      title: "Rank",
      dataIndex: "Rank",
      key: "rank",
      sorter: (a, b) => a.Rank - b.Rank,
      visibility: true,
    },
    {
      title: "Store #",
      dataIndex: "RitasID",
      key: "RitasID",
      sorter: (a, b) => a.RitasID - b.RitasID,
      visibility: true,
    },
    {
      title: "Store Name",
      dataIndex: "StoreName",
      key: "StoreName",
      visibility: true,
    },
    {
      title: "Region",
      dataIndex: "Region",
      key: "Region",
      width: 200,
      visibility: false,
      sorter: (a, b) => a?.Region?.localeCompare(b?.Region),
    },
    {
      title: "State",
      dataIndex: "State",
      key: "State",
      width: 200,
      visibility: false,
      sorter: (a, b) => a?.State?.localeCompare(b?.State),
    },
    {
      title: getSalesRankLabelName(
        "currentYear",
        type === "year" ? selectedYear : null,
        type
      ),
      dataIndex: "currentSales",
      key: "currentSales",
      sorter: (a, b) => a.currentSales - b.currentSales,
      render: (data) => {
        return CurrencyFormat(data, roundOff);
      },
      visibility: true,
    },
    {
      title: getSalesRankLabelName(
        "priorYear",
        type === "year" ? selectedYear : null,
        type
      ),
      dataIndex: "priorSales",
      key: "priorSales",
      sorter: (a, b) => a.priorSales - b.priorSales,
      render: (data) => {
        return CurrencyFormat(data, roundOff);
      },
      visibility: true,
    },
    {
      title: "Chg %",
      dataIndex: "chg",
      key: "chg",
      sorter: (a, b) => a.chg - b.chg,
      render: (data, props) => {
        let className = "";
        if (data <= 0) {
          className = "red";
        } else if (data > 0) {
          className = "green";
        }
        return <span className={className}>{data}</span>;
      },
      visibility: true,
    },
  ];
  const guestColumns = [
    {
      title: "Rank",
      dataIndex: "Rank",
      key: "Rank",
      sorter: (a, b) => a.Rank - b.Rank,
      visibility: true,
    },
    {
      title: "Store #",
      dataIndex: "RitasID",
      key: "RitasID",
      sorter: (a, b) => a.RitasID - b.RitasID,
      visibility: true,
    },
    {
      title: "Store Name",
      dataIndex: "StoreName",
      key: "StoreName",
      visibility: true,
    },
    {
      title: "Region",
      dataIndex: "Region",
      key: "Region",
      width: 200,
      visibility: false,
      sorter: (a, b) => a?.Region?.localeCompare(b?.Region),
    },
    {
      title: "State",
      dataIndex: "State",
      key: "State",
      width: 200,
      visibility: false,
      sorter: (a, b) => a?.State?.localeCompare(b?.State),
    },
    {
      title: getGuestRankLabelName(
        "currentYear",
        type === "year" ? selectedYear : null,
        type
      ),
      dataIndex: "currentYear",
      key: "currentYear",
      sorter: (a, b) => a.currentYear - b.currentYear,
      visibility: true,
    },
    {
      title: getGuestRankLabelName(
        "priorYear",
        type === "year" ? selectedYear : null,
        type
      ),
      dataIndex: "priorYear",
      key: "priorYear",
      sorter: (a, b) => a.priorYear - b.priorYear,
      visibility: true,
    },
    {
      title: "Chg %",
      dataIndex: "chg",
      key: "chg",
      sorter: (a, b) => a.chg - b.chg,
      render: (data, props) => {
        let className = "";
        if (data <= 0) {
          className = "red";
        } else if (data > 0) {
          className = "green";
        }
        return <span className={className}>{data}</span>;
      },
      visibility: true,
    },
  ];
  const visitsColumns = [
    {
      title: "Rank",
      dataIndex: "Rank",
      key: "Rank",
      sorter: (a, b) => a.Rank - b.Rank,
      visibility: true,
    },
    {
      title: "Store #",
      dataIndex: "Store",
      key: "Store",
      sorter: (a, b) => a.Store - b.Store,
      visibility: true,
    },
    {
      title: "Store Name",
      dataIndex: "StoreName",
      key: "StoreName",
      visibility: true,
    },
    {
      title: "Region",
      dataIndex: "Region",
      key: "Region",
      width: 200,
      visibility: false,
      sorter: (a, b) => a?.Region?.localeCompare(b?.Region),
    },
    {
      title: "State",
      dataIndex: "State",
      key: "State",
      width: 200,
      visibility: false,
      sorter: (a, b) => a?.State?.localeCompare(b?.State),
    },
    {
      title: getYearNameWithVisits(
        "currentYear",
        type === "year" ? selectedYear : null
      ),
      dataIndex: "currentYear",
      key: "currentYear",
      sorter: (a, b) => a.currentYear - b.currentYear,
      visibility: true,
    },
    {
      title: getYearNameWithVisits(
        "priorYear",
        type === "year" ? selectedYear : null
      ),
      dataIndex: "priorYear",
      key: "priorYear",
      sorter: (a, b) => a.priorYear - b.priorYear,
      visibility: true,
    },
    {
      title: "Chg %",
      dataIndex: "chg",
      key: "chg",
      sorter: (a, b) => a.chg - b.chg,
      render: (data, props) => {
        let className = "";
        if (data <= 0) {
          className = "red";
        } else if (data > 0) {
          className = "green";
        }
        return <span className={className}>{data}</span>;
      },
      visibility: true,
    },
  ];

  const [tableSalesColumns, setTableSalesColumns] = useState(salesColumns);
  const [tableGuestColumns, setTableGuestColumns] = useState(guestColumns);
  const [tableLoyaltyColumns, setTableLoyaltyColumns] = useState(visitsColumns);
  useEffect(() => {
    if (mode === "Loyalty") {
      fetchLoyaltyRanking();
    } else {
      fetchApi();
    }
  }, [
    mode,
    type,
    excludeLessThanYearStore,
    selectedYear,
    roundOff,
    excludeInactiveStore,
  ]);

  const handleRoundOffChange = () => {
    dispatch(updateRoundOffSelection(!roundOff));
  };

  const fetchApi = async () => {
    let req = {
      type: type,
      excludeLessThanYearStore,
      roundOff,
      excludeInactiveStore,
    };
    setLoading(true);
    let data;
    let itemData;
    if (type === "year") {
      req = {
        type: type,
        excludeLessThanYearStore,
        selectedYear,
        roundOff,
        excludeInactiveStore,
      };
    }
    if (checkFCAccess(profile)) {
      req = { ...req, intFSM: profile?.intFSM };
    }
    if (mode === "Sales") {
      const res = await service?.fetchSalesRank(req);
      data = res?.salesRank;
      itemData = res?.ItemData;
    } else if (mode === "GC") {
      const res = await service?.fetchGuestRank(req);
      data = res?.guestRank;
      itemData = res?.ItemData;
    }
    setItemData(itemData);
    setRankData(data || []);
    setLoading(false);
  };

  const handleOnChange = (cb) => {
    if (mode === "Sales") {
      const columnsData = [...tableSalesColumns];
      const exTest = columnsData.map((i) => {
        if (i?.dataIndex === cb?.dataIndex) {
          return { ...i, visibility: !i?.visibility };
        } else {
          return { ...i };
        }
      });
      setTableSalesColumns(exTest);
    } else if (mode === "GC") {
      const columnsData = [...tableGuestColumns];
      const exTest = columnsData.map((i) => {
        if (i?.dataIndex === cb?.dataIndex) {
          return { ...i, visibility: !i?.visibility };
        } else {
          return { ...i };
        }
      });
      setTableGuestColumns(exTest);
    } else if (mode === "Loyalty") {
      const columnsData = [...tableLoyaltyColumns];
      const exTest = columnsData.map((i) => {
        if (i?.dataIndex === cb?.dataIndex) {
          return { ...i, visibility: !i?.visibility };
        } else {
          return { ...i };
        }
      });
      setTableLoyaltyColumns(exTest);
    }
  };

  const fetchLoyaltyRanking = async () => {
    let req = {
      type: type,
      excludeLessThanYearStore,
      roundOff,
      excludeInactiveStore,
    };
    setLoading(true);
    let data;
    if (type === "year") {
      req = {
        type: type,
        excludeLessThanYearStore,
        selectedYear,
      };
    }
    if (checkFCAccess(profile)) {
      req = { ...req, intFSM: profile?.intFSM };
    }
    data = await service?.fetchLoyaltyRank(req);
    const result = data?.visitsData.sort((a, b) => a.Rank - b.Rank);
    setLoyaltyRankData(result || []);
    setItemData(data?.ItemData);
    setLoading(false);
  };

  const handleToggle = (val) => {
    setExcludeLessThanYearStore(val);
  };

  const handleInactiveStoreToggle = (val) => {
    setExcludeInactiveStore(val);
  };

  const handleExportExcel = async () => {
    setExcelLoading(true);
    let data = null;
    let payload = { excludeLessThanYearStore, roundOff, excludeInactiveStore };
    if (checkFCAccess(profile)) {
      payload = { ...payload, intFSM: profile?.intFSM };
    }
    switch (type) {
      case "year":
        const req = { ...payload, selectedYear };
        data = await service?.getCurrentYtdExport(req);
        break;
      case "quarter":
        data = await service?.getCurrentQuarterExport(payload);
        break;
      case "month":
        data = await service?.getCurrentMonthExport(payload);
        break;
      case "week":
        data = await service?.getCurrentWeekExport(payload);
        break;
      case "day":
        data = await service?.getCurrentDayExport(payload);
        break;
      default:
        break;
    }
    // const url = `${process.env.REACT_APP_API_URL}/${test?.path}${test?.fileName}`
    const url = generateDownLoadPath(data?.path, data?.fileName);

    setExcelLoading(false);
    window.open(url, "_blank");
  };
  const handleLoyaltyExportExcel = async () => {
    setExcelLoading(true);
    let data = null;
    let payload = { excludeLessThanYearStore, roundOff, excludeInactiveStore };
    if (checkFCAccess(profile)) {
      payload = { ...payload, intFSM: profile?.intFSM };
    }
    const req = { ...payload, selectedYear };
    data = await service?.getCurrentYtdLoyaltyExport(req);
    // const url = `${process.env.REACT_APP_API_URL}/${test?.path}${test?.fileName}`
    const url = generateDownLoadPath(data?.path, data?.fileName);

    setExcelLoading(false);
    window.open(url, "_blank");
  };

  const SkeletonComponent = () => {
    return (
      <>
        <div className="table-div d-flex flex-column align-items-start">
          <div className="total-system-sales-header">
            <div>
              <b className="comp-sales">
                {`Comp Sales (Excluding New Stores & Transfer Stores < 1 year) : `}
                <Switch
                  checked={excludeLessThanYearStore}
                  onChange={handleToggle}
                  disabled={loading}
                />
              </b>
            </div>
            <div className="d-flex flex-column">
              <div className="d-flex justify-self-center align-self-end">
                <>
                  {mode === "Sales" && (
                    <div className="px-1">
                      <label className="px-1">Enable Round Off</label>
                      <Switch
                        title="RoundOff"
                        defaultChecked
                        checked={roundOff}
                        onChange={handleRoundOffChange}
                      />
                    </div>
                  )}
                  {/* {type === "year" && <YearPicker />} */}
                  {mode !== "Loyalty" && (
                    <img
                      src="/DownLoadExcel.svg"
                      alt="DownLoadExcel"
                      className="DownLoadExcel"
                      onClick={() => handleExportExcel()}
                    />
                  )}
                  {mode === "Loyalty" && (
                    <img
                      src="/DownLoadExcel.svg"
                      alt="DownLoadExcel"
                      className="DownLoadExcel"
                      onClick={() => handleLoyaltyExportExcel()}
                    />
                  )}
                </>
              </div>
            </div>
          </div>
          <div
            className="d-flex justify-content-between"
            style={{ width: "100%" }}
          >
            <div>
              <Segmented
                options={
                  type === "year" ? ["Sales", "GC", "Loyalty"] : ["Sales", "GC"]
                }
                value={mode}
                disabled={loading}
                onChange={(e) => handleModeChange(e)}
              />
            </div>
            <div className="align-self-center">
              <Space>
                {columns.map((cb, i) => {
                  if (i == 3 || i == 4) {
                    return (
                      <Checkbox
                        id={i}
                        key={i}
                        defaultValue={cb.title}
                        checked={cb.visibility}
                        onChange={() => handleOnChange(cb)}
                      >
                        {cb.title}
                      </Checkbox>
                    );
                  }
                })}
              </Space>
            </div>
          </div>
        </div>
        {/* <div className="ranking-btn-group pb-2">
          <b className="comp-sales">
            {`Exclude Inactive Stores : `}
            <Switch
              checked={excludeInactiveStore}
              onChange={handleInactiveStoreToggle}
              disabled={loading}
            />
          </b>
        </div> */}
        {mode === "Loyalty" ? (
          <div>
            <Table
              dataSource={loyaltyRankData}
              columns={columns?.filter((i) => i?.visibility)}
              loading={loading}
              pagination={{
                pageSize: "100",
                showQuickJumper: false,
                showSizeChanger: false,
              }}
              bordered
              title={() => (
                <div className="d-flex justify-content-between align-items-center">
                  <h6>{`${mode} Ranking`}</h6>
                  <div className="d-flex flex-column highest-per-summary ">
                    <div>
                      <span className="per-heading">Store No.</span>
                      {` : ${itemData?.RitasID || ""}`}
                    </div>
                    <div>
                      <span className="per-heading">Store Name</span>
                      {` : ${itemData?.StoreName || ""}`}
                    </div>
                    <div>
                      <span className="per-heading">Hightest Percentage</span>
                      {` : ${itemData?.HighestPercentage || 0}%`}
                    </div>
                  </div>
                </div>
              )}
            />
          </div>
        ) : (
          <div className="">
            {/* <div className="rank-grid-item"> */}
            <Table
              dataSource={RankData}
              columns={columns?.filter((i) => i?.visibility)}
              loading={loading}
              bordered
              pagination={{
                pageSize: "100",
                showQuickJumper: false,
                showSizeChanger: false,
              }}
              title={() => (
                <div className="d-flex justify-content-between align-items-center">
                  <h6>{`${mode} Ranking`}</h6>
                  <div className="d-flex flex-column highest-per-summary ">
                    <div>
                      <span className="per-heading">Store No.</span>
                      {` : ${itemData?.RitasID || ""}`}
                    </div>
                    <div>
                      <span className="per-heading">Store Name</span>
                      {` : ${itemData?.StoreName || ""}`}
                    </div>
                    <div>
                      <span className="per-heading">Hightest Percentage</span>
                      {` : ${itemData?.HighestPercentage || 0}%`}
                    </div>
                  </div>
                </div>
              )}
            />
            {/* </div> */}
            {/* <div className="rank-grid-item">
            <Table
              dataSource={bottomRankData}
              columns={columns}
              pagination={false}
              loading={loading}
              bordered
              title={() => `Bottom 10 ${type} ${mode} Rank`}
            />
          </div> */}
          </div>
        )}
      </>
    );
  };

  let columns = [];
  if (mode === "Sales") {
    columns = tableSalesColumns;
  } else if (mode === "GC") {
    columns = tableGuestColumns;
  } else if (mode === "Loyalty") {
    columns = tableLoyaltyColumns;
  }
  if (!skeleton) {
    return (
      <>
        <Spin spinning={excelLoading}>
          <Card
            title={
              <>
                <div className="d-flex justify-content-between">
                  <div>
                    {!child && (
                      <Button
                        type="primary"
                        className="btn-back"
                        onClick={() => navigate("/financial")}
                      >
                        <i
                          className="fa fa-arrow-left"
                          style={{ fontSize: "16px" }}
                        ></i>
                      </Button>
                    )}
                    <span>{title}</span>
                  </div>
                </div>
              </>
            }
          >
            <SkeletonComponent />
          </Card>
        </Spin>
      </>
    );
  } else {
    return (
      <>
        <Spin spinning={excelLoading}>
          <SkeletonComponent />
        </Spin>
      </>
    );
  }
};

export default RankingReport;
