import { Segmented } from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import YearPicker from "../../YearPicker";
import StoreDigitalSales from "../DigitalSales/StoreDigitalSales.view";
import LineOfBusinessSales from "../LineOfBusinessSales/LineOfBusinessSales.view";
import MonthSales from "../MonthSales/MonthSales.view";
import StoreSummary from "../StoreSummary/StoreSummary.view";
import WeekSales from "../WeekSales/WeekSales.view";
import YearSales from "../YearSales/YearSales.view";
import useStoreDashboard from "./StoreDashboard.hook";

const StoreDashboard = () => {
  const { storeId = null } = useParams();

  const { selectedView, handleSegmentSelection, width, tabList } =
    useStoreDashboard();

  const ShowComponent = () => {
    let component = <></>;
    switch (selectedView) {
      case "Summary":
        component = <StoreSummary storeId={storeId} width={width} handleSegmentSelection={handleSegmentSelection} />;
        break;
      case "Year":
        component = <YearSales storeId={storeId} width={width} />;
        break;
      case "Month":
        component = <MonthSales storeId={storeId} width={width} />;
        break;
      case "Week":
        component = <WeekSales storeId={storeId} width={width} />;
        break;
      case "Line of Business":
        component = <LineOfBusinessSales storeId={storeId} width={width} />;
        break;
      case "Digital":
        component = <StoreDigitalSales storeId={storeId} width={width} />;
        break;
      default:
        break;
    }
    return component;
  };
  return (
    <div>
      <div className="table-div d-flex flex-column align-items-start">
        <div className="tab-view">
          <Segmented
            options={tabList}
            value={selectedView}
            onChange={(e) => handleSegmentSelection(e)}
          />
        </div>
      </div>
      <div className="p-4">{ShowComponent()}</div>
    </div>
  );
};

export default StoreDashboard;
