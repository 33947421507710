import React, { useEffect, useState } from "react";
import { Button, Card, Select, Spin } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { OperationalService } from "../../services/operational/index.service";
import { useSelector } from "react-redux";
import { Cell, Pie, PieChart, Sector, Tooltip } from "recharts";
import { PieLegends } from "../../utils/legend";
import { checkFCAccess } from "../../utils";

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = (props, mode = null, platform) => {
  const {
    cx,
    cy,
    midAngle,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    percent,
    value,
    name,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 20) * cos;
  const my = cy + (outerRadius + 20) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${name}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {mode === null || mode === "In Percentage"
          ? `(${(percent * 100).toFixed(2)}%)`
          : `(${value} ${mode === "percentage" ? "%" :mode === "products" ? "products":"stores"})`}
      </text>
    </g>
  );
};
const FCTracking = () => {
  const navigate = useNavigate();
  const { fcId = null } = useParams();
  const profile = useSelector((state) => state?.profile) || null;
  const service = new OperationalService();
  const [digitalLoading, setDigitalLoading] = useState(true);
  const [cloverLoading, setCloverLoading] = useState(true);
  const [leaseLoading, setLeaseLoading] = useState(true);
  const [digitalData, setDigitalData] = useState(null);
  const [cloverRetailData, setCloverRetailData] = useState(null);
  const [fCLeaseData, setFCLeaseData] = useState(null);
  const [selectedFC, setSelectedFC] = useState(null);
  const [options, setOptions] = useState([]);
  const [width, setWindowWidth] = useState(0);
  const breakPointWidth = 767;

  const onChange = (value) => {
    setSelectedFC(value);
  };

  useEffect(() => {
    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };

  const fetchFCDigital = async (payload) => {
    setDigitalLoading(true);
    const fcdigital = await service.fetchFCDigital(payload);
    setDigitalData(fcdigital);
    setDigitalLoading(false);
  };
  const fetchFCCloverRetailPie = async (payload) => {
    setCloverLoading(true);
    const fcCloverRetail = await service.fetchFCCloverRetailPie(payload);
    setCloverRetailData(fcCloverRetail);
    setCloverLoading(false);
  };
  const fetchFCLeaseCountPie = async (payload) => {
    setLeaseLoading(true);
    const fcLeaseExport = await service.fetchFCLeaseCountPie(payload);
    setFCLeaseData(fcLeaseExport);
    setLeaseLoading(false);
  };

  const onSearch = (value) => {
    // console.log("search:", value);
  };

  useEffect(() => {
    if (selectedFC) {
      handleSearch(selectedFC);
    }
  }, [selectedFC]);

  const handleSearch = (id) => {
    let payload = {
      intFSM: id,
    };
    fetchFCCloverRetailPie(payload);
    fetchFCDigital(payload);
    fetchFCLeaseCountPie(payload);
  };

  useEffect(() => {
    fetchFranchises();
  }, []);

  const fetchFranchises = async () => {
    const res = await service?.fetchCloverFC();
    setOptions(res?.FCNames || []);
    setSelectedFC(checkFCAccess(profile) ? profile?.intFSM : fcId ? fcId : "");
  };

  const DigitalCount = () => {
    return (
      <PieChart
        width={width < breakPointWidth ? 350 : 480}
        height={width < breakPointWidth ? 200 : 350}
      >
        <Pie
          data={digitalData?.count}
          cx="50%"
          cy="50%"
          minAngle={width < breakPointWidth ? 40 : 30}
          outerRadius={width < breakPointWidth ? 40 : 80}
          labelLine={false}
          label={(props) => renderCustomizedLabel(props, "count")}
        >
          {digitalData?.count?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={PieLegends[entry?.name]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    );
  };
  const DigitalPercentage = () => {
    return (
      <PieChart
        width={width < breakPointWidth ? 350 : 480}
        height={width < breakPointWidth ? 200 : 350}
      >
        <Pie
          data={digitalData?.percentage}
          cx="50%"
          cy="50%"
          minAngle={width < breakPointWidth ? 40 : 30}
          outerRadius={width < breakPointWidth ? 40 : 80}
          labelLine={false}
          label={(props) => renderCustomizedLabel(props, "percentage")}
        >
          {digitalData?.percentage?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={PieLegends[entry?.name]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    );
  };
  const CloverRetailCount = () => {
    if (cloverRetailData?.count?.length > 0) {
      return (
        <PieChart
          width={width < breakPointWidth ? 350 : 480}
          height={width < breakPointWidth ? 200 : 350}
        >
          <Pie
            data={cloverRetailData?.count}
            cx="50%"
            cy="50%"
            minAngle={width < breakPointWidth ? 40 : 30}
            outerRadius={width < breakPointWidth ? 40 : 80}
            labelLine={false}
            label={(props) => renderCustomizedLabel(props, "products")}
          >
            {cloverRetailData?.count?.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={PieLegends[entry?.name]} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      );
    } else {
      return (
        <div
          style={{
            height: width < breakPointWidth ? "200px" : "350px",
            width: width < breakPointWidth ? "350px" : "480px",
            background: "#FAFAFA",
          }}
          className="d-flex justify-content-center align-items-center text-muted"
        >
          {"No Data to display"}
        </div>
      );
    }
  };
  const CloverRetailPercentage = () => {
    if (cloverRetailData?.percentage?.length > 0) {
      return (
        <PieChart
          width={width < breakPointWidth ? 350 : 480}
          height={width < breakPointWidth ? 200 : 350}
        >
          <Pie
            data={cloverRetailData?.percentage}
            cx="50%"
            cy="50%"
            minAngle={width < breakPointWidth ? 40 : 30}
            outerRadius={width < breakPointWidth ? 40 : 80}
            labelLine={false}
            label={(props) => renderCustomizedLabel(props, "percentage")}
          >
            {cloverRetailData?.percentage?.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={PieLegends[entry?.name]} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      );
    } else {
      return (
        <div
          style={{

            height: width < breakPointWidth ? "200px" : "350px",
            width: width < breakPointWidth ? "350px" : "480px",
            background: "#FAFAFA",
          }}
          className="d-flex justify-content-center align-items-center text-muted"
        >
          {"No Data to display"}
        </div>
      );
    }
  };
  const LeaseInfoCount = () => {
    return (
      <PieChart
        width={width < breakPointWidth ? 350 : 480}
        height={width < breakPointWidth ? 200 : 350}
      >
        <Pie
          data={fCLeaseData?.count}
          cx="50%"
          cy="50%"
          minAngle={width < breakPointWidth ? 40 : 30}
          outerRadius={width < breakPointWidth ? 40 : 80}
          labelLine={false}
          label={(props) => renderCustomizedLabel(props, "count")}
        >
          {fCLeaseData?.count?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={PieLegends[entry?.name]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    );
  };
  const LeaseInfoPercentage = () => {
    return (
      <PieChart
        width={width < breakPointWidth ? 350 : 480}
        height={width < breakPointWidth ? 200 : 350}
      >
        <Pie
          data={fCLeaseData?.percentage}
          cx="50%"
          cy="50%"
          minAngle={width < breakPointWidth ? 40 : 30}
          outerRadius={width < breakPointWidth ? 40 : 80}
          labelLine={false}
          label={(props) => renderCustomizedLabel(props, "percentage")}
        >
          {fCLeaseData?.percentage?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={PieLegends[entry?.name]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    );
  };

  return (
    <>
      <Card
        title={
          <>
            <Button
              type="primary"
              className="btn-back"
              onClick={() =>
                checkFCAccess(profile) ? navigate("/operational") : navigate(-1)
              }
            >
              <i className="fa fa-arrow-left" style={{ fontSize: "16px" }}></i>
            </Button>
            <span>{`FC Tracking`}</span>
          </>
        }
      >
        <div className="report-grid">
          <div
            className="rank-grid-item"
          >
            <div className="table-div">
              <div className="ranking-btn-group"></div>
              <div className="clover-retail-search">
                <div className="clover-search-sub">
                  <Select
                    showSearch
                    placeholder="Select a FC"
                    style={{ width: "auto" }}
                    optionFilterProp="children"
                    value={
                      selectedFC || { selectedFC: 0, label: "Please Select FC" }
                    }
                    disabled={checkFCAccess(profile)}
                    onChange={onChange}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={options}
                  />
                </div>
              </div>
            </div>
            {selectedFC ? (
              <>
                <div className="my-2 fc-section-summary bg-white">
                  <div className="fc-items">
                    <Spin spinning={digitalLoading}>
                      <div className="count-item">
                        <h6 className="d-flex justify-content-center">{`Not utilised third party by stores(Count) `}</h6>
                        <DigitalCount />
                      </div>
                    </Spin>
                    <Spin spinning={digitalLoading}>
                      <div className="percentage-item">
                        <h6 className="d-flex justify-content-center">{`Not utilised third party by stores(Percentage) `}</h6>
                        <DigitalPercentage />
                      </div>
                    </Spin>
                  </div>
                  <hr />
                  <div className="fc-items">
                    <Spin spinning={cloverLoading}>
                      <div className="count-item">
                        <h6 className="d-flex justify-content-center">{`Clover vs Retail(Count) `}</h6>
                        <CloverRetailCount />
                      </div>
                    </Spin>
                    <Spin spinning={cloverLoading}>
                      <div className="percentage-item">
                        <h6 className="d-flex justify-content-center">{`Clover vs Retail (Percentage) `}</h6>
                        <CloverRetailPercentage />
                      </div>
                    </Spin>
                  </div>
                  <hr />
                  <div className="fc-items">
                    <Spin spinning={leaseLoading}>
                      <div className="count-item">
                        <h6 className="d-flex justify-content-center">{`Lease Expiration(Count) `}</h6>
                        <LeaseInfoCount />
                      </div>
                    </Spin>
                    <Spin spinning={leaseLoading}>
                      <div className="percentage-item">
                        <h6 className="d-flex justify-content-center">{`Lease Expiration (Percentage) `}</h6>
                        <LeaseInfoPercentage />
                      </div>
                    </Spin>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="no-records-available text-muted">
                  {"Please Select FC"}
                </div>
              </>
            )}
          </div>
        </div>
      </Card>
    </>
  );
};

export default FCTracking;
