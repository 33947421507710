import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MarketingService } from "../../../../services/marketing/index.service";
import { checkFCAccess, chunkArray, getXaxisName } from "../../../../utils";
import moment from "moment";

const useRedeemption = (props) => {
  const service = new MarketingService();
  const { selectedView } = props;
  const {
    selectedYear,
    selectedQuarter,
    selectedMonth,
    selectedWeek,
    selectedDay,
  } = useSelector((state) => state?.customSelection);
  const customSelection = useSelector((state) => state?.customSelection);
  const profile = useSelector((state) => state?.profile) || null;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [AllTableData, setAllTableData] = useState([]);
  const [maxValue, setMaxValue] = useState(0);
  const [maindata, setMainData] = useState(null);
  const [tableData, setTableData] = useState(null);
  useEffect(() => {
    fetchApi();
  }, [
    selectedYear,
    selectedView,
    selectedMonth,
    selectedQuarter,
    selectedWeek,
    selectedDay,
  ]);

  const fetchApi = async () => {
    setLoading(true);
    let payload = {};
    if (checkFCAccess(profile)) {
      payload = { ...payload, intFSM: profile?.intFSM };
    }
    let AllValue = false;
    let count=0
    switch (selectedView) {
      case "Yearly":
        payload = { ...payload, selectedYear, type: selectedView };
        break;
      case "Quarterly":
        payload = {
          ...payload,
          selectedYear,
          type: selectedView,
          selectedQuarter,
        };
        if (selectedQuarter === 0) {
          AllValue = true;
          count=2
        }
        break;
      case "Monthly":
        payload = {
          ...payload,
          selectedYear,
          type: selectedView,
          selectedMonth,
        };
        if (selectedMonth === 0) {
          AllValue = true;
          count=2
        }
        break;
      case "Weekly":
        if (selectedWeek === -1) {
          payload = {
            ...payload,
            selectedYear,
            type: "Daily",
            selectedDay: selectedDay,
          };
        } else {
          payload = {
            ...payload,
            selectedYear,
            type: selectedView,
            selectedWeek,
          };
          if (selectedWeek === 0) {
            AllValue = true;
            count=4
          }
        }
        break;
      case "Daily":
        payload = { ...payload, selectedYear, type: selectedView, selectedDay };
        break;
      default:
        break;
    }
    const res = await service?.fetchRedeemptionsReport(payload);
    if (AllValue) {
      const currentTotalMax = Math.max(
        ...res?.TransactionsData.map(
          (o) =>
            parseInt(o.CurrentBirthday) +
            parseInt(o.CurrentCoupon) +
            parseInt(o.CurrentEarned) +
            parseInt(o.CurrentCustomer)
        )
      );
      const priorTotalMax = Math.max(
        ...res?.TransactionsData.map(
          (o) =>
            parseInt(o.PriorBirthday) +
            parseInt(o.PriorCoupon) +
            parseInt(o.PriorEarned) +
            parseInt(o.PriorCustomer)
        )
      );
      setMaxValue(Math.max(currentTotalMax, priorTotalMax) + 100);
      setMainData(res);
      setData(res?.TransactionsData || []);
      const chunksData = chunkArray(res?.tableData, count);
      setAllTableData(chunksData)
    } else {
      setMainData(res);
      setTableData(res?.tableData)
    }
    setLoading(false);
  };

  return {
    loading,
    maxValue,
    maindata,
    selectedYear,
    data,
    customSelection,
    selectedMonth,
    selectedQuarter,
    selectedWeek,
    tableData,
    AllTableData
  };
};

export default useRedeemption;
