import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  BREAKPOINTWIDTH,
  CurrencyFormat,
  getYearName,
  NumberFormat,
  ShowArrow,
  YAxisNumberFormat,
} from "../../../utils";
import { Legends } from "../../../utils/legend";
import { DashboardLoader } from "../../../utils/Loading";
import {
  SvgChg,
  SvgCurrentYear,
  SvgPreviousYear,
} from "../../../utils/svgpath";
import { CustomTooltipWithoutChg } from "../../../utils/Tooltip";
import YearPicker from "../../YearPicker";
import useMonthSales from "./MonthSales.hook";

const MonthSales = (props) => {
  const {
    loading,
    data,
    maxValue,
    selectedYear,
    roundOff = false,
  } = useMonthSales(props);
  const breakPointWidth = BREAKPOINTWIDTH;
  const { width } = props;
  return (
    <>
      {loading ? (
        <DashboardLoader />
      ) : (
        <>
        <div className="d-flex justify-content-end pb-2"> <YearPicker /></div>

          {data?.length > 0 &&
          data.filter((i) => i?.month !== "Total")?.length > 0 ? (
            <>
              <div className="total-system-sales">
                <ResponsiveContainer
                  width="100%"
                  aspect={width < breakPointWidth ? 2 : 4}
                >
                  <BarChart
                    data={data.filter((i) => i?.month !== "Total")}
                    margin={{ top: 5, right: 20, bottom: 5, left: 20 }}
                  >
                    <XAxis dataKey="month" />
                    <YAxis
                      label={{
                        // value: "Amt ($)",
                        angle: 360,
                        position: "insideBottomRight",
                        offset: 10,
                      }}
                      // width={120}
                      allowDataOverflow={true}
                      tickFormatter={YAxisNumberFormat}
                      domain={[0, (dataMax) => parseFloat(maxValue).toFixed(2)]}
                    />
                    <Tooltip
                      content={
                        <CustomTooltipWithoutChg
                          roundOff={roundOff}
                          selectedYear={selectedYear}
                        />
                      }
                      labelStyle={{ color: "darkblue" }}
                    />
                    <Bar
                      dataKey="currentYear"
                      fill={Legends["currentYear"]}
                      barSize={width < breakPointWidth ? 10 : 25}
                    />
                    <Bar
                      dataKey="priorYear"
                      fill={Legends["priorYear"]}
                      barSize={width < breakPointWidth ? 10 : 25}
                    />
                  </BarChart>
                </ResponsiveContainer>
                {width < 991 ? (
                  <>
                    <div className="table-container legend-table">
                      <table key={width}>
                        <thead>
                          <tr>
                            <th>Month</th>
                            <th>
                              {" "}
                              {SvgCurrentYear()}
                              {getYearName("currentYear", selectedYear)}
                              {ShowArrow(props?.ItemData?.currentArrow)}
                            </th>
                            <th>
                              {" "}
                              {SvgPreviousYear()}
                              {getYearName("priorYear", selectedYear)}
                              {ShowArrow(props?.ItemData?.priorArrow)}
                            </th>
                            <th>
                              {" "}
                              {SvgChg()}
                              Chg %
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.map((i) => {
                            return (
                              <tr>
                                <td>{i?.month}</td>
                                <td
                                  style={{
                                    color:
                                      i?.currentYear < i?.priorYear
                                        ? `red`
                                        : `inherit`,
                                  }}
                                >
                                  {CurrencyFormat(i?.currentYear, roundOff)}
                                </td>
                                <td
                                  style={{
                                    color:
                                      i?.priorYear < i?.currentYear
                                        ? `red`
                                        : `inherit`,
                                  }}
                                >
                                  {CurrencyFormat(i?.priorYear, roundOff)}
                                </td>
                                <td>{`${i?.["chg"]} %`}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="table-container legend-table">
                      <table>
                        <thead>
                          <tr>
                            <th></th>
                            {data?.map((i) => {
                              return (
                                <>
                                  <td>{i?.month}</td>
                                </>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {SvgCurrentYear()}
                              {getYearName("currentYear", selectedYear)}
                              {ShowArrow(props?.ItemData?.currentArrow)}
                            </td>
                            {data?.map((i) => {
                              return (
                                <>
                                  <td
                                    style={{
                                      color:
                                        i?.currentYear < i?.priorYear
                                          ? `red`
                                          : `inherit`,
                                    }}
                                  >
                                    {CurrencyFormat(i?.currentYear, roundOff)}
                                  </td>
                                </>
                              );
                            })}
                          </tr>
                          <tr>
                            <td>
                              {SvgPreviousYear()}
                              {getYearName("priorYear", selectedYear)}
                              {ShowArrow(props?.ItemData?.priorArrow)}
                            </td>
                            {data?.map((i) => {
                              return (
                                <>
                                  <td
                                    style={{
                                      color:
                                        i?.priorYear < i?.currentYear
                                          ? `red`
                                          : `inherit`,
                                    }}
                                  >
                                    {CurrencyFormat(i?.priorYear, roundOff)}
                                  </td>
                                </>
                              );
                            })}
                          </tr>
                          <tr>
                            <td>
                              {SvgChg()}
                              Chg %
                            </td>
                            {data.map((i) => {
                              return (
                                <>
                                  <td>{`${i?.["chg"]} %`}</td>
                                </>
                              );
                            })}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="norecords-available">No records available</div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default MonthSales;
