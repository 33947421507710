import React, { useRef, useEffect, useState } from "react";
import {
  Button,
  Card,
  Segmented,
  Spin,
  Switch,
  Table,
  Input,
  Space,
  Checkbox,
  InputNumber,
} from "antd";
import { FinancialService } from "../../../services/financial/index.service";
// import { CEOService } from "../../../services/ceo/index.service";
import {
  CurrencyFormat,
  generateDownLoadPath,
  getYearName,
  getYearNameWithGuests,
} from "../../../utils";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import YearPicker from "../../YearPicker";
import { updateRoundOffSelection } from "../../../redux/dashboard/customSelectionSlice";

const YTDRanking = ({ type, skeleton = false, child = false, title = "" }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedYear, roundOff } = useSelector(
    (state) => state?.customSelection
  );
  const service = new FinancialService();
  const [mode, setMode] = useState("Sales");
  const [loading, setLoading] = useState(true);
  const [excludeLessThanYearStore, setExcludeLessThanYearStore] =
    useState(false);
  const [RankData, setRankData] = useState([]);
  const [excelLoading, setExcelLoading] = useState(false);
  const [RankDataTemp, setRankDataTemp] = useState([]);
  const [searchTextLocal, setSearchTextLocal] = useState(undefined);
  const [searchTextState, setSearchTextState] = useState("");

  const salesColumns = [
    {
      title: "Rank",
      dataIndex: "Rank",
      key: "rank",
      visibility: true,
      sorter: (a, b) => a.Rank - b.Rank,
    },
    {
      title: "Store #",
      dataIndex: "RitasID",
      key: "RitasID",
      visibility: true,
      sorter: (a, b) => a.RitasID - b.RitasID,
    },
    {
      title: "Store Name",
      dataIndex: "StoreName",
      key: "StoreName",
      visibility: true,
      sorter: (a, b) => a?.StoreName?.localeCompare(b?.StoreName),
    },
    {
      title: "Region",
      dataIndex: "Region",
      key: "Region",
      width: 200,
      visibility: false,
      sorter: (a, b) => a?.Region?.localeCompare(b?.Region),
    },
    {
      title: "State",
      dataIndex: "State",
      key: "State",
      width: 200,
      visibility: false,
      sorter: (a, b) => a?.State?.localeCompare(b?.State),
    },
    {
      title: getYearName("currentYear", type === "year" ? selectedYear : null),
      dataIndex: "currentSales",
      key: "currentSales",
      visibility: true,
      render: (data) => {
        return CurrencyFormat(data, roundOff);
      },
      sorter: (a, b) => a.currentSales - b.currentSales,
    },
    {
      title: getYearName("priorYear", type === "year" ? selectedYear : null),
      dataIndex: "priorSales",
      key: "priorSales",
      visibility: true,
      render: (data) => {
        return CurrencyFormat(data, roundOff);
      },
      sorter: (c, d) => c.priorSales - d.priorSales,
    },
  ];
  const guestColumns = [
    {
      title: "Rank",
      dataIndex: "Rank",
      key: "Rank",
      visibility: true,
      sorter: (a, b) => a.Rank - b.Rank,
    },
    {
      title: "Store #",
      dataIndex: "RitasID",
      key: "RitasID",
      visibility: true,
      sorter: (a, b) => a.RitasID - b.RitasID,
    },
    {
      title: "Store Name",
      dataIndex: "StoreName",
      key: "StoreName",
      visibility: true,
      sorter: (a, b) => a?.StoreName?.localeCompare(b?.StoreName),
    },
    {
      title: "Region",
      dataIndex: "Region",
      key: "Region",
      width: 200,
      visibility: false,
      sorter: (a, b) => a?.Region?.localeCompare(b?.Region),
    },
    {
      title: "State",
      dataIndex: "State",
      key: "State",
      width: 200,
      visibility: false,
      sorter: (a, b) => a?.State?.localeCompare(b?.State),
    },
    {
      title: getYearNameWithGuests(
        "currentYear",
        type === "year" ? selectedYear : null
      ),
      dataIndex: "currentYear",
      key: "currentYear",
      visibility: true,
      sorter: (a, b) => a.currentYear - b.currentYear,
    },
    {
      title: getYearNameWithGuests(
        "priorYear",
        type === "year" ? selectedYear : null
      ),
      dataIndex: "priorYear",
      key: "priorYear",
      visibility: true,
      sorter: (c, d) => c.priorYear - d.priorYear,
    },
  ];
  const [tableSalesColumns, setTableSalesColumns] = useState(salesColumns);
  const [tableGuestColumns, setTableGuestColumns] = useState(guestColumns);
  useEffect(() => {
    fetchApi();
  }, [mode, type, excludeLessThanYearStore, selectedYear, roundOff]);

  const fetchApi = async () => {
    let req = {
      type: type,
      excludeLessThanYearStore,
      roundOff,
      showAll: true,
    };
    setLoading(true);
    let data;
    if (type === "year") {
      req = {
        type: type,
        excludeLessThanYearStore,
        selectedYear,
        roundOff,
        showAll: true,
      };
    }
    if (mode === "Sales") {
      data = await service?.fetchSalesRank(req);
      //   data = await service?.fetchYTDRankings(req);
    } else if (mode === "GC") {
      data = await service?.fetchGuestRank(req);
    }
    if (mode === "Sales") {
      const res= await service?.fetchSalesRank(req);
      data = res?.salesRank
    } else if (mode === "GC") {
      const res = await service?.fetchGuestRank(req);
      data = res?.guestRank
    }
    setRankData(data || []);
    setRankDataTemp(data || []);
    // setBottomRankData(data?.bottom || []);
    setLoading(false);
  };

  const handleRoundOffChange = () => {
    dispatch(updateRoundOffSelection(!roundOff));
  };

  const handleToggle = (val) => {
    setExcludeLessThanYearStore(val);
  };

  const handleClear = () => {
    setSearchTextLocal("");
    setSearchTextState("");
    setRankData(RankDataTemp);
  };
  const handleSearchText = (val) => {
    setSearchTextLocal(val);
  };
  const handleFilterWithState = (e) => {
    setSearchTextState(e);
  };
  const handleSearch = (check = true) => {
    let data = RankDataTemp;
    if (searchTextLocal && searchTextLocal !== "") {
      data = data.filter((item) => item.RitasID == searchTextLocal);
    }
    if (check && searchTextState && searchTextState !== "") {
      data = data.filter((i) =>
        i?.State.toLowerCase().includes(searchTextState.toLowerCase())
      );
    }
    if (!searchTextState && !searchTextLocal) {
      data = RankDataTemp;
    }
    setRankData(data || []);
  };

  const handleOnChange = (cb) => {
    if (mode === "Sales") {
      const columnsData = [...tableSalesColumns];
      const exTest = columnsData.map((i) => {
        if (i?.dataIndex === cb?.dataIndex) {
          if (cb?.key === "State" && i?.visibility) {
            setSearchTextState("");
            handleSearch(false);
          }
          return { ...i, visibility: !i?.visibility };
        } else {
          return { ...i };
        }
      });
      setTableSalesColumns(exTest);
    } else if (mode === "GC") {
      const columnsData = [...tableGuestColumns];
      const exTest = columnsData.map((i) => {
        if (i?.dataIndex === cb?.dataIndex) {
          if (cb?.key === "State" && i?.visibility) {
            setSearchTextState("");
            handleSearch(false);
          }
          return { ...i, visibility: !i?.visibility };
        } else {
          return { ...i };
        }
      });
      setTableGuestColumns(exTest);
    }
  };
  const handleExportExcel = async () => {
    setExcelLoading(true);
    let data = null;
    const payload = { excludeLessThanYearStore, roundOff };
    switch (type) {
      case "year":
        const req = { ...payload, selectedYear };
        data = await service?.getCurrentYtdExport(req);
        break;
      case "quarter":
        data = await service?.getCurrentQuarterExport(payload);
        break;
      case "month":
        data = await service?.getCurrentMonthExport(payload);
        break;
      case "week":
        // data = await service?.getCurrentWeekExport(payload);
        data = await service?.getCurrentWeekCEOExport(payload);
        break;
      case "day":
        // data = await service?.getCurrentDayExport(payload);
        data = await service?.getCurrentDayCEOExport(payload);
        break;
      default:
        break;
    }
    // const url = `${process.env.REACT_APP_API_URL}/${test?.path}${test?.fileName}`
    const url = generateDownLoadPath(data?.path, data?.fileName);

    setExcelLoading(false);
    window.open(url, "_blank");
  };

  const handleChangeMode = (e) => {
    setMode(e);
    handleClear();
  };

  let columns = [];
  let visible = false;
  if (mode === "Sales") {
    columns = tableSalesColumns;
  } else if (mode === "GC") {
    columns = tableGuestColumns;
  }
  visible = columns.find((i) => i?.key === "State")?.visibility;
  return (
    <>
      <Spin spinning={excelLoading}>
        <Card
          title={
            <>
              <div className="d-flex justify-content-between">
                <div>
                  {!child && (
                    <Button
                      type="primary"
                      className="btn-back"
                      onClick={() => navigate("/ceo")}
                    >
                      <i
                        className="fa fa-arrow-left"
                        style={{ fontSize: "16px" }}
                      ></i>
                    </Button>
                  )}
                  <span>{title}</span>
                </div>
              </div>
            </>
          }
        >
          <div className="table-div d-flex flex-column align-items-start">
            <div className="total-system-sales-header">
              <div>
                <b className="comp-sales">
                  {`Comp Sales (Excluding New Stores & Transfer Stores < 1 year) : `}
                  <Switch
                    checked={excludeLessThanYearStore}
                    onChange={handleToggle}
                    disabled={loading}
                  />
                </b>
              </div>
              <div className="d-flex justify-self-end align-items-center">
                <div className="px-1">
                  <label className="px-1">Enable Round Off</label>
                  <Switch
                    title="RoundOff"
                    defaultChecked
                    checked={roundOff}
                    onChange={handleRoundOffChange}
                    />
                </div>
                {type === "year" && <YearPicker />}
                <img
                  src="/DownLoadExcel.svg"
                  alt="DownLoadExcel"
                  className="DownLoadExcel"
                  onClick={() => handleExportExcel()}
                />
              </div>
            </div>
            <div className="show-monthly-selection pb-2">
              <Segmented
                options={["Sales", "GC"]}
                value={mode}
                disabled={loading}
                onChange={(e) => handleChangeMode(e)}
              />
            </div>
            <div className="ceo-ranking-report-header">
              <div>
                <Space>
                  {columns.map((cb, i) => {
                    if (i == 3 || i == 4) {
                      return (
                        <Checkbox
                          id={i}
                          key={i}
                          defaultValue={cb.title}
                          checked={cb.visibility}
                          onChange={() => handleOnChange(cb)}
                        >
                          {cb.title}
                        </Checkbox>
                      );
                    }
                  })}
                </Space>
              </div>
              <Input
                type="number"
                name="RitasId"
                autoComplete="off"
                style={{ width: "auto" }}
                title="Search with store #"
                placeholder="Store Number"
                onChange={(e) => handleSearchText(e.target.value)}
                value={searchTextLocal}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    handleSearch();
                  }
                }}
                // autoFocus={true}
              />
              {visible && (
                <Input
                  name="StateName"
                  autoComplete="off"
                  style={{ width: "auto" }}
                  placeholder="State Name"
                  onChange={(e) => handleFilterWithState(e.target.value)}
                  value={searchTextState}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      handleSearch();
                    }
                  }}
                />
              )}
              <div>
                {/* <Space>
                  <Button type="default" onClick={() => handleClear()}>
                    Clear
                  </Button>
                  <Button type="primary" onClick={() => handleSearch()}>
                    Search
                  </Button>
                </Space> */}
                <Button
                    style={{ marginRight: "8px" }}
                    onClick={() => handleClear()}
                  >
                    <i className="fas fa-eraser"></i>
                  </Button>
                  <Button onClick={() => handleSearch()}>
                    <i className="fas fa-search"></i>
                  </Button>
              </div>
            </div>
          </div>
          <div className="report-grid">
            <div className="rank-grid-item">
              <Table
                dataSource={RankData}
                columns={columns?.filter((i) => i.visibility)}
                pagination={{
                  pageSize: "100",
                  showQuickJumper: false,
                  showSizeChanger: false,
                }}
                loading={loading}
                bordered
              />
            </div>
          </div>
        </Card>
      </Spin>
    </>
  );
};

export default YTDRanking;
