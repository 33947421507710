import StoreDashboard from "../components/EmbeddedComponents/StoreDashboard/StoreDashboard.view";
import YTDSales from "../components/FinancialDashboard/Sales/YTDSales";
import SalesSectionByStore from "../components/OperationalDashboard/SalesSectionByStore";

export const MainEmbeddedRoutes = [
  {
    path: "/store-track-report/:store",
    name: "SalesSectionByStore",
    key: "SalesSectionByStore",
    component: <SalesSectionByStore embedded={true} />,
  },
  {
    path: "/ytd/:storeId",
    name: "YTDSales",
    key: "YTDSales",
    component: <YTDSales embedded={true}  />,
  },
  {
    path: "/store-dashboard/:storeId",
    name: "StoreDashboard",
    key: "StoreDashboard",
    component: <StoreDashboard  />,
  },
];
