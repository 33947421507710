import { Button, Card } from "antd";
import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { FinancialService } from "../../../../services/financial/index.service";
import { getYearNameOnly, ShowArrow } from "../../../../utils";
import { Legends } from "../../../../utils/legend";
import { DashboardLoader } from "../../../../utils/Loading";
import { SvgCurrentYear, SvgPreviousYear } from "../../../../utils/svgpath";
import { GuestTooltipWithChg } from "../../../../utils/Tooltip";

const MonthlyWiseGuestsForRegion = (props) => {
  const service = new FinancialService();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [maxValue, setMaxValue] = useState(0);
  const breakPointWidth = 767;
  useEffect(() => {
    fetchApi();
  }, []);

  const fetchApi = async () => {
    setLoading(true);
    //need to change api
    const req = props?.showMonthly;

    const res = await service?.fetchMonthWiseRegionGuestCount(req);

    const result = res?.map((i) => {
      const displayCurrentYearName = getYearNameOnly("currentYear");
      const displayPriorYearName = getYearNameOnly("priorYear");
      return {
        ...i,
        [displayCurrentYearName]: i?.currentYear,
        [displayPriorYearName]: i?.priorYear,
      };
    });
    setData(result || []);
    const currentYearMax = Math.max(...result.map((o) => o.currentYear || 0));
    const priorYearMax = Math.max(...result.map((o) => o.priorYear || 0));
    setMaxValue(Math.max(currentYearMax, priorYearMax) + 100);
    setLoading(false);
  };
  return (
    <>
      <Card
        title={
          <>
            <Button
              type="primary"
              className="btn-back"
              onClick={() => props?.handleMonthlyView("")}
            >
              <i className="fa fa-arrow-left" style={{ fontSize: "16px" }}></i>
            </Button>
            <span>{`Month View Total GC For Region (${props?.showMonthly})`}</span>
          </>
        }
      >
        {loading ? (
          <DashboardLoader />
        ) : (
          <>
            {data?.length > 0 ? (
              <>
                <div className="total-system-sales">
                  <>
                    <ResponsiveContainer
                      width="100%"
                      aspect={props?.width < breakPointWidth ? 2 : 4}
                    >
                      <BarChart
                        data={data}
                        margin={{
                          top: 5,
                          right: props?.width < breakPointWidth ? 5 : 20,
                          bottom: 5,
                          left: props?.width < breakPointWidth ? 5 : 20,
                        }}
                      >
                        <XAxis dataKey="month" />
                        <YAxis
                          label={{
                            value: "Guest(s)",
                            angle: 360,
                            position: "insideBottomRight",
                            offset: 10,
                          }}
                          // width={120}
                          allowDataOverflow={true}
                          domain={[
                            0,
                            (dataMax) => parseInt(maxValue).toFixed(2),
                          ]}
                        />
                        <Tooltip
                          content={<GuestTooltipWithChg />}
                          labelStyle={{ color: "darkblue" }}
                        />
                        <Bar
                          dataKey="currentYear"
                          fill={Legends["currentYear"]}
                          barSize={props?.width < breakPointWidth ? 15 : 30}
                        />
                        <Bar
                          dataKey="priorYear"
                          fill={Legends["priorYear"]}
                          barSize={props?.width < breakPointWidth ? 15 : 30}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                    {props?.width < breakPointWidth ? (
                      <>
                        {" "}
                        <div className="table-container">
                          <table>
                            <thead>
                              <tr>
                                <th>Month</th>
                                <th>
                                  {" "}
                                  {SvgCurrentYear()}
                                  {getYearNameOnly("currentYear")}
                                  {ShowArrow(props?.ItemData?.currentArrow)}
                                </th>
                                <th>
                                  {" "}
                                  {SvgPreviousYear()}
                                  {getYearNameOnly("priorYear")}
                                  {ShowArrow(props?.ItemData?.priorArrow)}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.map((i) => {
                                return (
                                  <tr>
                                    <td>{i?.month}</td>
                                    <td>{i?.currentYear}</td>
                                    <td>{i?.priorYear}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="table-container">
                          <table>
                            <thead>
                              <tr>
                                <th></th>
                                {data.map((i) => {
                                  return (
                                    <>
                                      <td>{i?.month}</td>
                                    </>
                                  );
                                })}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  {SvgCurrentYear()}
                                  {getYearNameOnly("currentYear")}
                                  {ShowArrow(props?.ItemData?.currentArrow)}
                                </td>
                                {data.map((i) => {
                                  return (
                                    <>
                                      <td>{i?.currentYear}</td>
                                    </>
                                  );
                                })}
                              </tr>
                              <tr>
                                <td>
                                  {SvgPreviousYear()}
                                  {getYearNameOnly("priorYear")}
                                  {ShowArrow(props?.ItemData?.priorArrow)}
                                </td>
                                {data.map((i) => {
                                  return (
                                    <>
                                      <td>{i?.priorYear}</td>
                                    </>
                                  );
                                })}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </>
                    )}
                  </>
                </div>
              </>
            ) : (
              <>
                <div className="norecords-available">No records available</div>
              </>
            )}
          </>
        )}
      </Card>
    </>
  );
};

export default MonthlyWiseGuestsForRegion;
