import { DashboardLoader } from "../../../utils/Loading";
import useYearSales from "./YearSales.hook";
import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { CurrencyFormat, NumberFormat, ShowArrow, YAxisNumberFormat } from "../../../utils";
import { CustomTooltipForSales } from "../../../utils/Tooltip";
import { Legends } from "../../../utils/legend";
import { SvgCurrentYear, SvgPreviousYear } from "../../../utils/svgpath";
import SummarySalesTable from "../../FinancialDashboard/Sales/SummarySalesTable";
import YearPicker from "../../YearPicker";

const YearSales = (props) => {
  const { loading, barData, data, maxValue } = useYearSales(props);
  const { width, breakPointWidth } = props;
  const DefaultComponent = (propData) => {
    return (
      <div className="total-system-sales">
        <div className="show-monthly-selection pb-3">
          <div className="summary-table-container">
            <SummarySalesTable
              selectedType={`year`}
              dataItem={data?.ItemData}
            />
          </div>
        </div>
        <ResponsiveContainer
          width="100%"
          aspect={width < breakPointWidth ? 2 : 4}
        >
          <BarChart
            data={barData}
            margin={{ top: 5, right: 20, bottom: 5, left: 20 }}
          >
            <XAxis dataKey="displayName" />
            <YAxis
              dataKey={"Amount"}
              label={{
                // value: "Amt ($)",
                angle: 360,
                position: "insideBottomRight",
                offset: 10,
              }}
              tickFormatter={YAxisNumberFormat}
              domain={[0, (dataMax) => parseFloat(maxValue).toFixed(2)]}
              allowDataOverflow={true}
            />
            <Tooltip
              content={<CustomTooltipForSales />}
              labelStyle={{ color: "darkblue" }}
            />
            <Bar
              dataKey="Amount"
              fill="#0088FE"
              barSize={width < breakPointWidth ? 20 : 30}
            >
              {barData.map((entry, index) => {
                const color = Legends[entry?.name];
                return <Cell key={`cell-${index}`} fill={color} />;
              })}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        {DefaultSummaryComponent()}
      </div>
    );
  };
  const DefaultSummaryComponent = () => {
    return (
      <>
        {width < breakPointWidth ? (
          <>
            <div className="table-container legend-table">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>{"Amount"}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {SvgCurrentYear()}
                      {barData?.[0]?.["displayName"]}
                      {ShowArrow(data?.ItemData?.currentArrow)}
                    </td>
                    <td>{CurrencyFormat(barData?.[0]?.["Amount"])}</td>
                  </tr>
                  <tr>
                    <td>
                      {SvgPreviousYear()}
                      {barData?.[1]?.["displayName"]}
                      {ShowArrow(data?.ItemData?.priorArrow)}
                    </td>
                    <td>{CurrencyFormat(barData?.[1]?.["Amount"])}</td>
                  </tr>
                  <tr>
                    <td>{"% Chg"}</td>
                    <td>{`${data?.chg} %`}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <>
            <div className="table-container legend-table">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      {SvgCurrentYear()}
                      {barData?.[0]?.["displayName"]}
                      {ShowArrow(data?.ItemData?.currentArrow)}
                    </th>
                    <th>
                      {SvgPreviousYear()}
                      {barData?.[1]?.["displayName"]}
                      {ShowArrow(data?.ItemData?.priorArrow)}
                    </th>
                    <th>% Chg</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Amount</td>
                    <td>{CurrencyFormat(barData?.[0]?.["Amount"])}</td>
                    <td>{CurrencyFormat(barData?.[1]?.["Amount"])}</td>
                    <td>{`${data?.chg} %`}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}
      </>
    );
  };
  return (
    <div>
      {loading ? (
        <DashboardLoader />
      ) : (
        <>
      <div className="d-flex justify-content-end pb-2"> <YearPicker /></div>

          {barData?.length > 0 ? (
            <>
              <DefaultComponent />
              {/* <DefaultSummaryComponent /> */}
            </>
          ) : (
            <>
              <div className="norecords-available">No records available</div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default YearSales;
