import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateMonthSelection } from "../redux/dashboard/customSelectionSlice";
import moment from "moment";
import { useEffect, useState } from "react";

const MonthPicker = (props) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state);
  const [months, setMonths] = useState([]);

  const handleChange = (value) => {
    dispatch(updateMonthSelection(value));
  };
  useEffect(() => {
    generateMonths();
  }, [data?.customSelection?.selectedYear]);

  const generateMonths = () => {

    let items = [
      {
      value: 0,
      label: `All Months`,
      key:`All Months`,
    },
    {
      value: moment().month()+1,
      label: `Current Month`,
      key:`Current Month`,

    },
  ];

    for (let i = 1; i <= 12; i++) {
      const obj = {
        value: i,
        label: `${data?.customSelection?.selectedYear}-${moment(i, "M").format(
          "MMMM"
        )}`,
        key: `${data?.customSelection?.selectedYear}-${moment(i, "M").format(
          "MMMM"
        )}`,
      };
      items.push(obj);
    }
    console.log(items)
    setMonths(items);
  };
  const onSearch = (value) => {
    console.log('search:', value);
  };
  console.log(months)
  return (
    <div className="year-picker">
      <Select
        showSearch
        value={data?.customSelection?.selectedMonth}
        style={{
          width: 150,
        }}
        onSearch={onSearch}
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        onChange={handleChange}
        options={months}
        disabled={props?.disabled}
      />
    </div>
  );
};

export default MonthPicker;
