import moment from "moment";

export const CurrencyFormat = (number, roundOff = false) => {
  let formatter;
  if (roundOff) {
    formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      trailingZeroDisplay: "stripIfInteger",
    });
  } else {
    formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
  }
  return formatter.format(number || 0);
};
export const NumberFormat = (number) => {
  const formatter = new Intl.NumberFormat("en-US", {
    trailingZeroDisplay: "stripIfInteger",
  });
  return formatter.format(number || 0);
};

export const chunkArray = (arr, n) => {
  let chunkLength = Math.max(arr.length / n, 1);
  let chunks = [];
  for (let i = 0; i < n; i++) {
    if (chunkLength * (i + 1) <= arr.length)
      chunks.push(arr.slice(chunkLength * i, chunkLength * (i + 1)));
  }
  return chunks;
};

export const YAxisNumberFormat=number=>{
 let  num = number.toString().replace(/[^0-9.]/g, '');
    if (num < 1000) {
        return "$"+num;
    }
    let si = [
      {v: 1E3, s: "K"},
      {v: 1E6, s: "M"},
      {v: 1E9, s: "B"},
      {v: 1E12, s: "T"},
      {v: 1E15, s: "P"},
      {v: 1E18, s: "E"}
      ];
    let index;
    for (index = si.length - 1; index > 0; index--) {
        if (num >= si[index].v) {
            break;
        }
    }
    return "$"+(num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[index].s;
}

export const getYearName = (type, selectedYear = null) => {
  if (!selectedYear) {
    if (type === "currentYear") {
      return `${moment().year()} Sales`;
    } else {
      return `${moment().year() - 1} Sales`;
    }
  } else {
    if (type === "currentYear") {
      return `${selectedYear} Sales`;
    } else {
      return `${selectedYear - 1} Sales`;
    }
  }
};
export const getSalesRankLabelName = (type, selectedYear = null,selectedTab="") => {
  let labelValue=""
  switch (selectedTab) {
    case "quarter":
      labelValue = "Current Quarter"
      break;
    case "month":
      labelValue = "Current Month"
      break;
    case "week":
      labelValue = "Current Week"
      break;
    case "day":
      labelValue = "Current Day"
      break;
    default:
      break;
  }
  if (!selectedYear) {
    if (type === "currentYear") {
      return `${moment().year()} ${labelValue} Sales`;
    } else {
      return `${moment().year() - 1} ${labelValue} Sales`;
    }
  } else {
    if (type === "currentYear") {
      return `${selectedYear} ${labelValue} Sales`;
    } else {
      return `${selectedYear - 1} ${labelValue} Sales`;
    }
  }
};
export const getGuestRankLabelName = (type, selectedYear = null,selectedTab="") => {
  let labelValue=""
  switch (selectedTab) {
    case "quarter":
      labelValue = "Current Quarter"
      break;
    case "month":
      labelValue = "Current Month"
      break;
    case "week":
      labelValue = "Current Week"
      break;
    case "day":
      labelValue = "Current Day"
      break;
    default:
      break;
  }
  if (!selectedYear) {
    if (type === "currentYear") {
      return `${moment().year()} ${labelValue} Guests`;
    } else {
      return `${moment().year() - 1} ${labelValue} Guests`;
    }
  } else {
    if (type === "currentYear") {
      return `${selectedYear} ${labelValue} Guests`;
    } else {
      return `${selectedYear - 1} ${labelValue} Guests`;
    }
  }
};

export const getYearNameOnly = (type, selectedYear = null) => {
  if (!selectedYear) {
    if (type === "currentYear") {
      return `${moment().year()}`;
    } else {
      return `${moment().year() - 1}`;
    }
  } else {
    if (type === "currentYear") {
      return `${selectedYear}`;
    } else {
      return `${selectedYear - 1}`;
    }
  }
};
export const getXaxisName = (type, selectedView, data) => {
  const {
    selectedYear,
    selectedQuarter,
    selectedMonth,
    selectedWeek,
    selectedDay,
  } = data;
  switch (selectedView) {
    case "Yearly":
      if (type === "currentYear") {
        return `${selectedYear}`;
      } else {
        return `${selectedYear - 1}`;
      }
    case "Monthly":
      if (type === "currentYear") {
        return `${selectedYear}-${moment(selectedMonth, "M").format("MMMM")}`;
      } else {
        return `${selectedYear - 1}-${moment(selectedMonth, "M").format(
          "MMMM"
        )}`;
      }
    case "Weekly":
      if (type === "currentYear") {
        return `${selectedYear} - Week ${selectedWeek}`
      } else {
        return `${selectedYear-1} - Week ${selectedWeek}`
      }
    case "Quarterly":
      if (type === "currentYear") {
        return `${selectedYear} - Q${selectedQuarter}`;
      } else {
        return `${selectedYear - 1} - Q${selectedQuarter}`;
      }
    case "Daily":
      if (type === "currentYear") {
        return `${selectedDay}`;
      } else {
        return `${moment(selectedDay).subtract(1, 'year').format('L')}`;
      }

    default:
      break;
  }
};
export const getYearNameWithGuests = (type, selectedYear = null) => {
  if (!selectedYear) {
    if (type === "currentYear") {
      return `${moment().year()} Guests`;
    } else {
      return `${moment().year() - 1} Guests`;
    }
  } else {
    if (type === "currentYear") {
      return `${selectedYear} Guests`;
    } else {
      return `${selectedYear - 1} Guests`;
    }
  }
};
export const getYearNameWithVisits = (type, selectedYear = null) => {
  if (!selectedYear) {
    if (type === "currentYear") {
      return `${moment().year()} Visits`;
    } else {
      return `${moment().year() - 1} Visits`;
    }
  } else {
    if (type === "currentYear") {
      return `${selectedYear} Visits`;
    } else {
      return `${selectedYear - 1} Visits`;
    }
  }
};

export const ShowArrow = (type) => {
  const arrowType = type;
  if (arrowType === "up") {
    return (
      <>
        <i
          className="fa fa-arrow-up"
          style={{ color: "green", paddingLeft: "5px" }}
        ></i>
      </>
    );
  } else if (arrowType === "down") {
    return (
      <>
        <i
          className="fa fa-arrow-down"
          style={{ color: "red", paddingLeft: "5px" }}
        ></i>
      </>
    );
  } else {
    return <></>;
  }
};

export const generateDownLoadPath = (path, filename) => {
  const url = `${process.env.REACT_APP_API_URL}/${path}${filename}`;

  return url;
};

export const UserRoles = ["Corporate", "Administrators"];

export const checkUserAccess = (obj) => {
  const currentRole = obj?.vchGroupName;
  const check = UserRoles?.indexOf(currentRole) > -1;
  return check;
};
export const checkMarketingAccess = (obj) => {
  const marketingRole = "ManageMarketingFTP";
  const check = obj?.roles?.indexOf(marketingRole) > -1;
  return check;
};
export const checkFinancialAccess = (obj) => {
  const FinancialRole = "Accounting";
  const check = obj?.roles?.indexOf(FinancialRole) > -1;
  return check;
};
export const checkOperationAccess = (obj) => {
  const operationRole = "DirOps";
  const check = obj?.roles?.indexOf(operationRole) > -1;
  return check;
};
export const checkFCAccess = (obj) => {
  const fcRole = "FSM";
  const check = obj?.roles?.indexOf(fcRole) > -1;
  return check;
};
export const checkIsAdmin = (obj) => {
  return obj?.vchGroupName === "Administrators";
};
export const BREAKPOINTWIDTH = 767;

export const generateRedeemptionFullName=(name,selectedYear,key)=>{
  switch (name) {
    case "Current Redeemptions":
        return key==="Customer Appeasement" ?`${selectedYear} ${key}`:`${selectedYear} ${key} Redeemptions`;
    case "Prior Redeemptions":
        return key==="Customer Appeasement" ?`${selectedYear-1} ${key}`:`${selectedYear-1} ${key} Redeemptions`;
    case "Current Visits":
        return `${selectedYear} Loyalty Visits`;
    case "Prior Visits":
        return `${selectedYear-1} Loyalty Visits`;
    case "Current GC":
        return `${selectedYear} GC`;
    case "Prior GC":
        return `${selectedYear-1} GC`;
    default:
      break;
  }
}
export const generateRedeemFullName=(name,selectedYear,key)=>{
  switch (name) {
    case "CurrentBirthday":
        return `${selectedYear} Birthday Redeemptions`;
    case "PriorBirthday":
        return `${selectedYear-1} Birthday Redeemptions`;
    case "CurrentCoupon":
        return `${selectedYear} Coupon Redeemptions`;
    case "PriorCoupon":
        return `${selectedYear-1} Coupon Redeemptions`;
    case "CurrentEarned":
        return `${selectedYear} Earned Redeemptions`;
    case "PriorEarned":
        return `${selectedYear-1} Earned Redeemptions`;
    case "CurrentCustomer":
        return `${selectedYear} Customer Appeasement`;
    case "PriorCustomer":
        return `${selectedYear-1} Customer Appeasement`;
    default:
      break;
  }
}