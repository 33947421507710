import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  InputNumber,
  Select,
  Spin,
  Table,
  Checkbox,
  Input,
} from "antd";
import { useNavigate } from "react-router-dom";
import { checkFCAccess, generateDownLoadPath } from "../../utils";
import { OperationalService } from "../../services/operational/index.service";
import { useSelector } from "react-redux";
import moment from "moment";

const FCLeaseDetails = () => {
  const navigate = useNavigate();
  const profile = useSelector((state) => state?.profile) || null;
  const service = new OperationalService();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [value, setValue] = useState("");
  const [ritasIdValue, setRitasIdValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [excelLoading, setExcelLoading] = useState(false);
  const [searchTextState, setSearchTextState] = useState("");
  const [showLeasedCheck, setShowLeasedCheck] = useState(true);
  const onChange = (value) => {
    setValue(value);
    setRitasIdValue(null);
    setSearchTextState("");
    handleSearch(value);
  };
  const [page, setPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(15);
  const onSearch = (value) => {};
  const columns = [
    {
      title: "Sr.No",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => (page - 1) * paginationSize + index + 1,
    },
    {
      title: "Store #",
      dataIndex: "RitasID",
      key: "RitasID",
      sorter: (a, b) => a.RitasID - b.RitasID,
      render: (data, props) => {
        return (
          <td
            className="store-link"
            onClick={() =>
              navigate(`/operational/store-track-report/${props?.RitasID}`)
            }
          >
            {props?.RitasID}
          </td>
        );
      },
    },
    {
      title: "Store Name",
      dataIndex: "StoreName",
      key: "StoreName",
    },
    {
      title: "FC Name",
      dataIndex: "FSMName",
      key: "FSMName",
    },
    {
      title: "State",
      dataIndex: "State",
      key: "State",
      sorter: (a, b) => a?.State?.localeCompare(b?.State),
    },
    {
      title: "Agreement Start Date",
      dataIndex: "AgreementStartDate",
      key: "AgreementStartDate",

      sorter: (a, b) =>
        moment(a.AgreementStartDate).unix() -
        moment(b.AgreementStartDate).unix(),
    },
    {
      title: "Agreement Expiration",
      dataIndex: "AgreementExpirationDate",
      key: "AgreementExpirationDate",
      sorter: (c, d) =>
        moment(c.AgreementExpirationDate).unix() -
        moment(d.AgreementExpirationDate).unix(),
    },
    {
      title: "Lease Expiration",
      dataIndex: "LeaseExpirationDate",
      key: "LeaseExpirationDate",
      // defaultSortOrder: "ascend",
      render: (data, props) => {
        const date = moment().format("YYYY-MM-DD");
        let className = "";
        if (
          moment().format("YYYY-MM-DD") >
          moment(props?.LeaseExpirationDate).format("YYYY-MM-DD")
        ) {
          className = "red";
        } else if (
          moment(props?.LeaseExpirationDate).diff(moment(date), "months") < 18
        ) {
          className = "orange";
        }
        return <span className={className}>{props?.LeaseExpirationDate}</span>;
      },
      sorter: (e, f) =>
        moment(e.LeaseExpirationDate).unix() -
        moment(f.LeaseExpirationDate).unix(),
    },

    {
      title: "Rent",
      dataIndex: "Rent",
      key: "Rent",
      sorter: (a, b) => a?.Rent?.localeCompare(b?.Rent),
    },
  ];

  useEffect(() => {
    if (checkFCAccess(profile)) {
      handleSearch();
    } else {
      fetchFranchises();
      const payload = { showLeased: showLeasedCheck };
      fetchApi(payload);
    }
  }, [showLeasedCheck]);

  const fetchFranchises = async () => {
    const res = await service?.fetchCloverFC();
    setOptions(res?.FCNames || []);
  };

  const handleExportExcel = async () => {
    setExcelLoading(true);
    let payload = {
      intRitasStoreID: ritasIdValue ? ritasIdValue : undefined,
      intFSM: value ? value : undefined,
    };
    if (checkFCAccess(profile)) {
      payload = { ...payload, intFSM: profile?.intFSM };
    }
    if (showLeasedCheck) {
      payload = { ...payload, showLeased: showLeasedCheck };
    }
    const data = await service?.fetchFCAgreementDatesExport(payload);
    const url = generateDownLoadPath(data?.path, data?.fileName);
    setExcelLoading(false);
    window.open(url, "_blank");
  };

  const fetchApi = async (payload, searchState = false) => {
    setLoading(true);
    const res = await service?.fetchFCLeaseDetails(payload);
    let leaseData = res?.LeaseDetails;
    if (searchState) {
      leaseData = leaseData.filter((i) =>
        i?.State.toLowerCase().includes(searchTextState.toLowerCase())
      );
    }
    setData(leaseData);
    setLoading(false);
  };

  const handleClear = () => {
    setRitasIdValue(null);
    setValue("");
    setSearchTextState("");
    let payload = {};
    if (checkFCAccess(profile)) {
      payload = { ...payload, intFSM: profile?.intFSM };
    }
    if (showLeasedCheck) {
      payload = { ...payload, showLeased: showLeasedCheck };
    }
    fetchApi(payload);
  };

  const handleSearch = (intFSMValue = null) => {
    if (intFSMValue) {
      let payload = {
        intFSM: intFSMValue,
      };
      if (showLeasedCheck) {
        payload = { ...payload, showLeased: showLeasedCheck };
      }
      fetchApi(payload);
    } else {
      let payload = {
        intRitasStoreID: ritasIdValue ? ritasIdValue : undefined,
        intFSM: value ? value : undefined,
      };
      if (checkFCAccess(profile)) {
        payload = { ...payload, intFSM: profile?.intFSM };
      }
      if (showLeasedCheck) {
        payload = { ...payload, showLeased: showLeasedCheck };
      }
      if (searchTextState && searchTextState !== "") {
        if (ritasIdValue || value) {
          fetchApi(payload, true);
        } else {
          const filteredData = data.filter((i) =>
            i?.State.toLowerCase().includes(searchTextState.toLowerCase())
          );
          setData(filteredData);
        }
      } else {
        fetchApi(payload);
      }
    }
  };

  const handleChange = (val) => {
    setRitasIdValue(val);
  };

  const handleFilterWithState = (e) => {
    setSearchTextState(e);
  };
  return (
    <>
      <Spin spinning={excelLoading}>
        <Card
          title={
            <>
              <Button
                type="primary"
                className="btn-back"
                onClick={() => navigate(-1)}
              >
                <i
                  className="fa fa-arrow-left"
                  style={{ fontSize: "16px" }}
                ></i>
              </Button>
              <span>{`FC Lease Details`}</span>
            </>
          }
        >
          <div className="report-grid">
            <div className="rank-grid-item">
              <div className="table-div">
                <div className="fc-lease-grid-header">
                  <InputNumber
                    name="RitasId"
                    type={"number"}
                    style={{ width: "180px" }}
                    id="RitasId"
                    placeholder="Store Number"
                    onChange={handleChange}
                    value={ritasIdValue}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        handleSearch();
                      }
                    }}
                  />
                  <Input
                    name="StateName"
                    autoComplete="off"
                    style={{ width: "180px" }}
                    placeholder="State Name"
                    onChange={(e) => handleFilterWithState(e.target.value)}
                    value={searchTextState}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        handleSearch();
                      }
                    }}
                  />
                  {profile?.isAdmin && (
                    <>
                      <Select
                        showSearch
                        placeholder="Select a FC"
                        style={{ width: "180px" }}
                        optionFilterProp="children"
                        value={value || { value: 0, label: "Please Select FC" }}
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={options}
                      />
                    </>
                  )}
                  <div>
                    <Checkbox
                      name="showLeasedCheck"
                      checked={showLeasedCheck}
                      onChange={(e) => setShowLeasedCheck(e.target.checked)}
                      style={{ marginRight: "2px" }}
                    />
                    Show Leased stores
                  </div>
                  <div>
                    <Button
                      onClick={() => handleClear()}
                      style={{ marginRight: "8px" }}
                    >
                      <i className="fas fa-eraser"></i>
                    </Button>
                    <Button onClick={() => handleSearch()}>
                      <i className="fas fa-search"></i>
                    </Button>
                  </div>
                </div>
              </div>
              {data?.length > 0 && (
                <div className="d-flex justify-content-end py-2">
                  <img
                    src="/DownLoadExcel.svg"
                    alt="DownLoadExcel"
                    className="DownLoadExcel"
                    onClick={() => handleExportExcel()}
                  />
                </div>
              )}
              <Table
                dataSource={data}
                pagination={{
                  pageSize: "10",
                  current: page,
                  showQuickJumper: false,
                  showSizeChanger: false,
                  onChange(page, pageSize) {
                    setPage(page);
                    // setPaginationSize(pageSize);
                  },
                }}
                columns={columns}
                loading={loading}
                bordered
                scrollToFirstRowOnChange={true}
                size="middle"
              />
            </div>
          </div>
        </Card>
      </Spin>
    </>
  );
};

export default FCLeaseDetails;
