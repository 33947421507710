import { Card, Spin } from "antd";
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import useLoyaltyVisits from "./LoyaltyVisits.hook";
import {
  BREAKPOINTWIDTH,
  NumberFormat,
  ShowArrow,
  getXaxisName,
} from "../../../../utils";
import {
  AllBarDataLegends,
  BarLegends,
  PieLegends,
} from "../../../../utils/legend";
import { CustomTooltipLoyaltyReport } from "../../../../utils/Tooltip";
import { SvgTransaction } from "../../../../utils/svgpath";
import { DashboardLoader } from "../../../../utils/Loading";
import SummaryLoyaltyReport from "../SummaryLoyaltyReport";
import QuarterPicker from "../../../QuarterPicker";
import MonthPicker from "../../../MonthPicker";
import WeekPicker from "../../../WeekPicker";
import DayPicker from "../../../DayPicker";
import moment from "moment";
import LoyaltyPieChart from "./LoyaltyPieChart";
import { getYearNameOnly } from "../../../../utils";
import LoyaltyLegendTable from "./LoyaltyLegendTable";
const LoyaltyVisits = (props) => {
  const breakPointWidth = BREAKPOINTWIDTH;
  const { width, selectedView } = props;
  const {
    loading,
    data,
    maxValue,
    selectedYear,
    maindata,
    customSelection,
    selectedMonth,
    selectedQuarter,
    selectedWeek,
    tableData,
    AllTableData,
    activeUsersData,
  } = useLoyaltyVisits(props);
  let selctedViewValue = selectedView;
  let selectedDayValue = customSelection?.selectedDay;
  if (selectedView === "Weekly" && selectedWeek === -1) {
    selctedViewValue = "Daily";
    selectedDayValue = `${moment()
      .add(-1, "days")
      .format("MM/DD")}/${selectedYear}`;
  }
  let barSize = 35;
  if (selectedView === "Quarterly") {
    barSize = 30;
  } else if (selectedView === "Monthly") {
    barSize = 17;
  } else if (selectedView === "Weekly") {
    barSize = 5;
  }
  return (
    <Card>
      <div className="total-system-sales">
        <div className={selectedView === "Yearly" ? "" : "tab"}>
          {selectedView === "Quarterly" && <QuarterPicker disabled={loading} />}
          {selectedView === "Monthly" && <MonthPicker disabled={loading} />}
          {selectedView === "Weekly" && <WeekPicker disabled={loading} />}
          {selectedView === "Daily" && <DayPicker disabled={loading} />}
        </div>
        {loading ? (
          <DashboardLoader />
        ) : (
          <>
            <div className="show-monthly-selection pb-3">
              <div className="summary-table-container">
                <SummaryLoyaltyReport
                  dataItem={maindata?.ItemData}
                  selectedView={selectedView}
                  activeUsersData={activeUsersData}
                />
              </div>
            </div>
            {(selectedView === "Monthly" && selectedMonth === 0) ||
            (selectedView === "Quarterly" && selectedQuarter === 0) ||
            (selectedView === "Weekly" && selectedWeek === 0) ? (
              <>
                {" "}
                {data?.length > 0 ? (
                  <>
                    {" "}
                    <ResponsiveContainer
                      width="100%"
                      aspect={width < breakPointWidth ? 2 : 4}
                    >
                      <BarChart
                        data={data}
                        margin={{ top: 5, right: 20, bottom: 5, left: 25 }}
                      >
                        <XAxis dataKey="name" />
                        <YAxis
                          label={{
                            value: "Transactions",
                            angle: 360,
                            position: "insideBottomRight",
                            offset: 10,
                          }}
                          domain={[
                            0,
                            (dataMax) => parseInt(maxValue).toFixed(2),
                          ]}
                          allowDataOverflow={true}
                          tickFormatter={NumberFormat}
                        />
                        <Tooltip
                          content={
                            <CustomTooltipLoyaltyReport
                              selectedYear={selectedYear}
                              all={true}
                              selectedView={selectedView}
                            />
                          }
                          shared={false}
                          labelStyle={{ color: "darkblue" }}
                        />
                        <Bar
                          dataKey="Current Visits"
                          stackId="a"
                          fill={PieLegends["Loyalty Visits"]}
                          barSize={barSize}
                          chartType="current"
                        />
                        <Bar
                          dataKey="Prior Visits"
                          stackId="b"
                          fill={PieLegends["Loyalty Visits"]}
                          barSize={barSize}
                          chartType="prior"
                        />
                        <Bar
                          dataKey="Current GC"
                          stackId="a"
                          fill={PieLegends["Guest Count"]}
                          barSize={barSize}
                          chartType="current"
                        />
                        <Bar
                          dataKey="Prior GC"
                          stackId="b"
                          fill={PieLegends["Guest Count"]}
                          barSize={barSize}
                          chartType="prior"
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </>
                ) : (
                  <>
                    <div className="norecords-available">
                      No records available
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div className="fc-items">
                  <Spin spinning={loading}>
                    <div className="count-item">
                      <LoyaltyPieChart
                        width={width}
                        data={maindata?.currentYear}
                      />
                      <h6 className="d-flex justify-content-center">
                        {" "}
                        {getXaxisName("currentYear", selctedViewValue, {
                          ...customSelection,
                          selectedDay: selectedDayValue,
                        })}
                      </h6>
                    </div>
                  </Spin>
                  <Spin spinning={loading}>
                    <div className="percentage-item">
                      <LoyaltyPieChart
                        width={width}
                        data={maindata?.priorYear}
                      />
                      <h6 className="d-flex justify-content-center">
                        {" "}
                        {getXaxisName("priorYear", selctedViewValue, {
                          ...customSelection,
                          selectedDay: selectedDayValue,
                        })}
                      </h6>
                    </div>
                  </Spin>
                </div>
              </>
            )}
            {(selectedView === "Monthly" && selectedMonth === 0) ||
            (selectedView === "Quarterly" && selectedQuarter === 0) ||
            (selectedView === "Weekly" && selectedWeek === 0) ? (
              <>
               <LoyaltyLegendTable selectedView={selectedView} selectedYear={selectedYear} AllTableData={AllTableData} />
              </>
            ) : (
              <>
                {" "}
                <div className="table-container legend-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Visits</th>
                        <th>{getYearNameOnly("currentYear", selectedYear)}</th>
                        <th>{getYearNameOnly("priorYear", selectedYear)}</th>
                        <th>{"Chg%"}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {SvgTransaction(PieLegends["Loyalty Visits"])}
                          {"Loyalty Visits"}
                          {parseFloat(tableData["chg"]["Loyalty Visits"]) > 0
                            ? ShowArrow("up")
                            : ShowArrow("down")}
                        </td>
                        <td>
                          {NumberFormat(
                            tableData["currentYear"]["Loyalty Visits"]
                          )}
                        </td>
                        <td>
                          {NumberFormat(
                            tableData["priorYear"]["Loyalty Visits"]
                          )}
                        </td>
                        <td>{tableData["chg"]["Loyalty Visits"]}</td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          {SvgTransaction(PieLegends["Guest Count"])}
                          {"Guest Count"}
                          {parseFloat(tableData["chg"]["Guest Count"]) > 0
                            ? ShowArrow("up")
                            : ShowArrow("down")}
                        </td>
                        <td>
                          {NumberFormat(
                            tableData["currentYear"]["Guest Count"]
                          )}
                        </td>
                        <td>
                          {NumberFormat(tableData["priorYear"]["Guest Count"])}
                        </td>
                        <td>{tableData["chg"]["Guest Count"]}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </Card>
  );
};

export default LoyaltyVisits;
