import React, { useEffect, useState } from "react";
import { Card, Spin } from "antd";
import { OperationalService } from "../../services/operational/index.service";
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { CustomTooltipForStoreTrackingHourlySales } from "../../utils/Tooltip";
import { YAxisNumberFormat } from "../../utils";

const HourlySalesByStore = (props) => {
  const service = new OperationalService();
  const [hourlySalesLoading, setHourlySalesLoading] = useState(true);
  const [hourlybarcharData, setHourlyBarChartData] = useState([]);
  const [hourlyData, setHourlyData] = useState(null);
  const [ritasIdValue, setRitasIdValue] = useState(props?.store);
  const [width, setWindowWidth] = useState(0);
  const [maxHourlyValue, setMaxHourlyValue] = useState(0);
  const breakPointWidth = 767;
  useEffect(() => {
    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };

  const fetchHourlySales = async (payload) => {
    setHourlySalesLoading(true);
    const res = await service?.fetchHourlySalesByStore(payload);
    setHourlyData(res);
    setHourlyBarChartData(res?.HourlySales);
    const maxValue = Math.max(...res?.HourlySales.map((o) => o.sales));
    setMaxHourlyValue(maxValue + 100);
    setHourlySalesLoading(false);
  };

  useEffect(() => {
    fetchHourlySales({
      ritasId: ritasIdValue ? ritasIdValue : undefined,
    });
  }, []);

  const HourlyChart = () => {
    return (
      <Spin spinning={hourlySalesLoading}>
        <div className="total-system-sales bg-white">
          {!props?.skeleton && <h6>
            {hourlyData?.ItemData?.displayDate
              ? `Hourly Sales - ${hourlyData?.ItemData?.displayDate}`
              : `Hourly Sales`}
          </h6>}
          <ResponsiveContainer
            width="100%"
            aspect={width < breakPointWidth ? 2 : 4}
          >
            <BarChart
              data={hourlybarcharData}
              margin={{ top: 5, right: 20, bottom: 5, left: 20 }}
            >
              <XAxis dataKey="time" />
              <YAxis
                label={{
                  // value: "Sales ($)",
                  angle: 360,
                  position: "insideBottomRight",
                  offset: 10,
                }}
                tickFormatter={YAxisNumberFormat}
                // width={85}
                domain={[0, (dataMax) => parseFloat(maxHourlyValue).toFixed(2)]}
                allowDataOverflow={true}
              />
              <Tooltip
                content={<CustomTooltipForStoreTrackingHourlySales />}
                labelStyle={{ color: "darkblue" }}
              />
              <Bar
                dataKey="sales"
                fill="#0088FE"
                barSize={width < breakPointWidth ? 5 : 12}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </Spin>
    );
  };

  if(props?.skeleton){

      return (
        <Card>
          <div className="report-grid">
            <div className="rank-grid-item">
              <div className="table-div d-flex flex-column align-items-start">
                <div className="total-system-sales-header">
                  <span className="card-heading-overview-item">
                    {hourlyData?.ItemData?.displayDate
                      ? `Hourly Sales - ${hourlyData?.ItemData?.displayDate}`
                      : `Hourly Sales`}
                  </span>
                </div>
              </div>
              {HourlyChart()}
            </div>
          </div>
        </Card>
      );
  }else{
    return <HourlyChart />
  }
};

export default HourlySalesByStore;
