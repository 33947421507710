import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  InputNumber,
  Select,
  Spin,
  Switch,
  Table,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import {
  CurrencyFormat,
  checkFCAccess,
  generateDownLoadPath,
} from "../../utils";
import { OperationalService } from "../../services/operational/index.service";
import SummaryCloverTable from "./SummaryCloverTable";
import { useDispatch, useSelector } from "react-redux";
import { updateRoundOffSelection } from "../../redux/dashboard/customSelectionSlice";

const CloverRetailPrices = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { store = null } = useParams();
  const { roundOff } = useSelector((state) => state?.customSelection);
  const profile = useSelector((state) => state?.profile) || null;
  const service = new OperationalService();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [summaryData, setSummaryData] = useState(null);
  const [value, setValue] = useState("");
  const [ritasIdValue, setRitasIdValue] = useState(store);
  const [options, setOptions] = useState([]);
  const [redflagCheck, setRedflagCheck] = useState(true);
  const [excelLoading, setExcelLoading] = useState(false);

  const onChange = (value) => {
    setValue(value);
    setRitasIdValue(null);
    handleSearch(value);
  };

  const onSearch = (value) => {
    // console.log("search:", value);
  };
  const columns = [
    {
      title: "Store #",
      dataIndex: "intRitasStoreID",
      key: "intRitasStoreID",
      render: (data, props) => {
        return (
          <td
            className="store-link"
            onClick={() =>
              navigate(
                `/operational/store-track-report/${props?.intRitasStoreID}`
              )
            }
          >
            {props?.intRitasStoreID}
          </td>
        );
      },
    },
    {
      title: "Store Name",
      dataIndex: "vchStoreName",
      key: "vchStoreName",
    },
    {
      title: "FC Name",
      dataIndex: "vchFBCName",
      key: "vchFBCName",
    },
    {
      title: "Product Name",
      dataIndex: "vchCloverProductName",
      key: "vchCloverProductName",
    },
    {
      title: "Price Type",
      dataIndex: "vchCloverPriceType",
      key: "vchCloverPriceType",
    },
    {
      title: "Retail Price",
      dataIndex: "mnyPrice",
      key: "mnyPrice",
      render: (data) => {
        return CurrencyFormat(data, roundOff);
      },
    },
    {
      title: "Clover Price",
      dataIndex: "mnyCloverProductPrice",
      key: "mnyCloverProductPrice",
      render: (data, props) => {
        let className = "";
        if (props?.difference > 0) {
          className = "red";
        } else if (props?.difference < 0) {
          className = "orange";
        }
        return (
          <span className={className}>{CurrencyFormat(data, roundOff)}</span>
        );
      },
    },
  ];

  useEffect(() => {
    if (checkFCAccess(profile)) {
      handleSearch();
    } else {
      fetchFranchises();
    }
  }, []);

  const fetchFranchises = async () => {
    const res = await service?.fetchCloverFC();
    setOptions(res?.FCNames || []);
  };

  const handleExportExcel = async () => {
    setExcelLoading(true);
    let payload = {
      intRitasStoreID: ritasIdValue ? ritasIdValue : undefined,
      intFSM: value ? value : undefined,
      redFlag: redflagCheck,
    };
    if (checkFCAccess(profile)) {
      payload = { ...payload, intFSM: profile?.intFSM };
    }
    const data = await service?.fetchCloverRetailExport(payload);
    const url = generateDownLoadPath(data?.path, data?.fileName);
    setExcelLoading(false);
    window.open(url, "_blank");
  };

  const fetchApi = async (payload) => {
    setLoading(true);
    const res = await service?.fetchCloverRetailPrices(payload);
    setData(res?.CloverVsRetail);
    setSummaryData(res?.Summary || null);
    setLoading(false);
  };

  const handleClear = () => {
    setRitasIdValue(null);
    setRedflagCheck(true);
    setValue("");
    setData([]);
    setSummaryData(null);
  };

  useEffect(() => {
    if (ritasIdValue) {
      handleSearch();
    }
  }, [redflagCheck]);

  const handleSearch = (intFSMValue = null,redFlag=redflagCheck) => {
    if (intFSMValue) {
      let payload = {
        intFSM: intFSMValue,
        redFlag: redFlag,
      };
      fetchApi(payload);
    } else {
      let payload = {
        intRitasStoreID: ritasIdValue ? ritasIdValue : undefined,
        intFSM: value ? value : undefined,
        redFlag: redFlag,
      };
      if (checkFCAccess(profile)) {
        payload = { ...payload, intFSM: profile?.intFSM };
      }
      fetchApi(payload);
    }
  };

  const handleRedFlag=(val)=>{
    setRedflagCheck(val)
    handleSearch(value,val)
  }

  const handleChange = (val) => {
    setRitasIdValue(val);
  };
  const handleRoundOffChange = () => {
    dispatch(updateRoundOffSelection(!roundOff));
  };

  const SkeletonComponent = () => {
    return (
      <>
        <div className="report-grid">
          <div className="rank-grid-item">
            <div className="table-div">
              <div className="clover-retail-grid-header">
                <div className="px-1">
                  <label className="px-1">Enable Round Off</label>
                  <Switch
                    title="RoundOff"
                    defaultChecked
                    checked={roundOff}
                    onChange={handleRoundOffChange}
                  />
                </div>
                <InputNumber
                  name="RitasId"
                  style={{ width: "180px" }}
                  id="RitasId"
                  placeholder="Store Number"
                  onChange={handleChange}
                  value={ritasIdValue}
                  autoFocus={true}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      handleSearch();
                    }
                  }}
                />
                {profile?.isAdmin && (
                  <>
                    <Select
                      showSearch
                      placeholder="Select a FC"
                      style={{ width: "180px" }}
                      optionFilterProp="children"
                      value={value || { value: 0, label: "Please Select FC" }}
                      onChange={onChange}
                      onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={options}
                    />
                  </>
                )}
                <div>
                  <Checkbox
                    name="redflagCheck"
                    checked={redflagCheck}
                    onChange={(e) => handleRedFlag(e.target.checked)}
                  />
                  Show Anomalous Only
                </div>
                <div>
                  <Button
                    style={{ marginRight: "8px" }}
                    onClick={() => handleClear()}
                  >
                    <i className="fas fa-eraser"></i>
                  </Button>
                  <Button onClick={() => handleSearch()}>
                    <i className="fas fa-search"></i>
                  </Button>
                </div>
              </div>
            </div>
            {data?.length > 0 && (
              <div className="d-flex justify-content-end pt-2">
                <img
                  src="/DownLoadExcel.svg"
                  alt="DownLoadExcel"
                  className="DownLoadExcel"
                  onClick={() => handleExportExcel()}
                />
              </div>
            )}
            {summaryData && (
              <div className="show-monthly-selection pb-3">
                <div className="summary-table-container">
                  <SummaryCloverTable dataItem={summaryData} />
                </div>
              </div>
            )}

            <Table
              dataSource={data}
              columns={columns}
              pagination={{
                pageSize: "10",
                showQuickJumper: false,
                showSizeChanger: false,
              }}
              loading={loading}
              bordered
              scrollToFirstRowOnChange={true}
              size="middle"
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Spin spinning={excelLoading}>
        <Card
          title={
            <>
              <Button
                type="primary"
                className="btn-back"
                onClick={() => navigate(-1)} //"/operational"
              >
                <i
                  className="fa fa-arrow-left"
                  style={{ fontSize: "16px" }}
                ></i>
              </Button>
              <span>{`Clover vs Retail Prices`}</span>
            </>
          }
        >
          <SkeletonComponent />
        </Card>
      </Spin>
    </>
  );
};

export default CloverRetailPrices;
