import React, { useEffect, useState } from "react";
import { Button, Card, Segmented } from "antd";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { useNavigate } from "react-router-dom";
import { FinancialService } from "../../../../services/financial/index.service";
import {
  CustomTooltipForDigitalSales,
  CustomTooltipForYearGuest,
} from "../../../../utils/Tooltip";
import { Legends } from "../../../../utils/legend";
import { DashboardLoader } from "../../../../utils/Loading";
import { useSelector } from "react-redux";
import YearPicker from "../../../YearPicker";
import { checkFCAccess } from "../../../../utils";

const DigitalSalesStoreWise = (props) => {
  const navigate = useNavigate();
  const service = new FinancialService();
  const [selectedView, setSelectedView] = useState(
    "3PD Active Participation by Platform"
  );
  const { selectedYear } = useSelector((state) => state?.dashboard);
  const profile = useSelector((state) => state?.profile) || null;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [barData, setBarData] = useState([]);
  const [maxValue, setMaxValue] = useState(0);
  const [width, setWindowWidth] = useState(0);
  const breakPointWidth = 767;
  useEffect(() => {
    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };
  useEffect(() => {
    fetchApi();
  }, [selectedView]);

  const handleSegmentSelection = (e) => {
    setSelectedView(e);
  };

  const fetchApi = async () => {
    setLoading(true);
    let data = [];
    let payload = { };
    if(checkFCAccess(profile)){
      payload={...payload,intFSM:profile?.intFSM}
    }
    if(props?.storeId){
      payload = { ...payload,ritasId:props?.storeId };
    }
    switch (selectedView) {
      case "3PD Active Participation by Platform":
        data = await service?.fetch3PDActiveParticipationByPlatform(payload);
        break;
      case "3PD Multiple Platform Participation":
        data = await service?.fetch3PDMultiplePlatformParticipation(payload);
        break;
      default:
        break;
    }
    const resultData = data?.Count;
    setData(resultData);
    // const rec = data?.TotalGuestCount?.map((i) => {
    //   const displayName = getYearNameOnly(i?.name);
    //   return { ...i, displayName: displayName };
    // });
    setBarData(resultData || []);
    const removeTotal = resultData?.filter((i) => i?.name !== "Total");
    const maxValue = Math.max(...removeTotal.map((o) => o.value || 0));
    setMaxValue(maxValue + 10);
    setLoading(false);
  };

  const DefaultSummaryComponent = () => {
    return (
      <>
        {" "}
        {width < breakPointWidth || props?.child ? (
          <>
            <div className="table-container legend-table">
              <table>
                <tbody>
                  <tr>
                    <td>
                      {selectedView === "3PD Multiple Platform Participation"
                        ? ""
                        : "Platform Name"}
                    </td>
                    <td>{"Stores Count"}</td>
                  </tr>
                  {barData?.map((i) => {
                    return (
                      <>
                        <tr>
                          <td>{i?.name}</td>
                          <td>{i?.value}</td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <>
            {" "}
            <div className="table-container legend-table">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    {barData?.map((i) => {
                      return (
                        <>
                          <th>{i?.name}</th>
                        </>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Stores Count</td>
                    {barData?.map((i) => {
                      return (
                        <>
                          <td>{i?.value}</td>
                        </>
                      );
                    })}
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}
      </>
    );
  };

  const DefaultComponent = () => {
    return (
      <>
        <div className="total-system-sales">
          <ResponsiveContainer
            width="100%"
            aspect={width < breakPointWidth ? 2 : 4}
          >
            <BarChart
              data={barData?.filter((i) => i?.name !== "Total")}
              margin={{ top: 5, right: 20, bottom: 5, left: 20 }}
            >
              <XAxis dataKey="name" />
              <YAxis
                dataKey={"value"}
                label={{
                  value: "Stores",
                  angle: 360,
                  position: "insideBottomRight",
                  offset: 10,
                }}
                // width={120}
                domain={[0, (dataMax) => parseFloat(maxValue).toFixed(2)]}
                allowDataOverflow={true}
              />
              <Tooltip
                content={<CustomTooltipForDigitalSales />}
                labelStyle={{ color: "darkblue" }}
              />
              <Bar
                dataKey="value"
                fill="#0088FE"
                barSize={width < breakPointWidth ? 20 : 30}
              >
                {barData?.map((entry, index) => {
                  return <Cell key={`cell-${index}`} fill={"#0088FE"} />;
                })}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
          {DefaultSummaryComponent()}
        </div>
      </>
    );
  };

  return (
    <>
      <Card
        title={
          <>
            <div className="d-flex justify-content-between">
              <div>
                {!props?.child && (
                  <Button
                    type="primary"
                    className="btn-back"
                    onClick={() => navigate("/financial")}
                  >
                    <i
                      className="fa fa-arrow-left"
                      style={{ fontSize: "16px" }}
                    ></i>
                  </Button>
                )}
                <span className="card-heading-overview-item">{"Digital Sales - 3PD"}</span>
                {props?.child && !props?.storeId && (
                  <i
                    className="fa fa-external-link"
                    style={{ cursor: "pointer", paddingLeft: "0.5rem",fontSize:'14px' }}
                    aria-hidden="true"
                    onClick={() => props?.handleFullView()}
                  ></i>
                )}
              </div>
            </div>
          </>
        }
      >
        <div className="table-div d-flex flex-column align-items-start">
          <div className="total-system-sales-header mb-4" style={{justifyContent:'center'}}>
              <Segmented
              className="digital-tabs"
                options={[
                  "3PD Active Participation by Platform",
                  "3PD Multiple Platform Participation",
                ]}
                value={selectedView}
                disabled={loading}
                onChange={(e) => handleSegmentSelection(e)}
              />

          </div>
        </div>
        {loading ? (
          <DashboardLoader />
        ) : (
          <>
            {barData?.length > 0 ? (
              <>
                <DefaultComponent />
                {/* <DefaultSummaryComponent /> */}
              </>
            ) : (
              <>
                <div className="norecords-available">No records available</div>
              </>
            )}
          </>
        )}
      </Card>
    </>
  );
};

export default DigitalSalesStoreWise;
