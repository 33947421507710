import React from 'react'
import { useNavigate } from 'react-router';
import { Button } from 'antd';
import SalesByLineofBusiness from '../SalesByLineofBusiness';
import SalesByRegion from '../SalesByRegion';
import TotalSytemSales from '../TotalSytemSales';

const Day = () => {
    const navigate = useNavigate();
    const handleClick = (name) => {
        navigate(`/${name}`)
    }
    return (
        <>
             <Button   type="primary" ghost className='m-2' onClick={() => navigate('/financial')}>Go to Dashboard</Button>
            <div className='grid-container-overview'>
                <div className="one" onClick={() => handleClick('financial/day-sales')}>
                    <TotalSytemSales
                        type="day"
                        title={"Current Day Total System Sales"}
                        child={true}
                    />
                </div>
                <div className="two" onClick={() => handleClick('financial/day-sales-line-of-business')}>
                    <SalesByLineofBusiness type='day' title={'Current Day Total System Sales By Line of Business'} child={true}/>
                </div>
                <div className="three" onClick={() => handleClick('financial/day-sales-by-region')}>
                    <SalesByRegion type='day' title={'Current Day Total System Sales By Region'} child={true}/>
                </div >
            </div>
        </>
    )
}

export default Day