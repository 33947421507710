import React, { useEffect, useState } from "react";
import { Button, Card, Spin, Switch } from "antd";
import {
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Line,
  ComposedChart,
  ReferenceLine,
} from "recharts";
import { FinancialService } from "../../../services/financial/index.service";
import {
  checkFCAccess,
  CurrencyFormat,
  generateDownLoadPath,
  getYearName,
  ShowArrow,
  YAxisNumberFormat,
} from "../../../utils";
import MonthlyWiseSalesForRegion from "./YTDSales/MonthlyWiseSalesForRegion";
import { CustomTooltipWithChg } from "../../../utils/Tooltip";
import { Legends } from "../../../utils/legend";
import {
  SvgChg,
  SvgCurrentYear,
  SvgPreviousYear,
} from "../../../utils/svgpath";
import SummarySalesTable from "./SummarySalesTable";
import { useNavigate } from "react-router-dom";
import { DashboardLoader } from "../../../utils/Loading";
import { useDispatch, useSelector } from "react-redux";
import YearPicker from "../../YearPicker";
import { updateRoundOffSelection } from "../../../redux/dashboard/customSelectionSlice";

const SalesByRegion = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { type, title } = props;
  const service = new FinancialService();
  const { selectedYear, roundOff } = useSelector(
    (state) => state?.customSelection
  );
  const profile = useSelector((state) => state?.profile) || null;
  const [showMonthly, setshowMonthly] = useState("");
  const [loading, setLoading] = useState(true);
  const [maxValue, setMaxValue] = useState(0);
  const [maxChgValue, setMaxChgValue] = useState(0);
  const [minChgValue, setMinChgValue] = useState(0);
  const [data, setData] = useState([]);
  const [excludeLessThanYearStore, setExcludeLessThanYearStore] =
    useState(false);
  const [excludeInactiveStore, setExcludeInactiveStore] = useState(true);
  const [mainData, setMainData] = useState(null);
  const [width, setWindowWidth] = useState(0);
  const [excelLoading, setExcelLoading] = useState(false);

  const breakPointWidth = 767;
  useEffect(() => {
    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };
  const handleMonthlyView = (name) => {
    setshowMonthly(name);
  };

  const handleRoundOffChange = () => {
    dispatch(updateRoundOffSelection(!roundOff));
  };


  useEffect(() => {
    fetchApi();
  }, [excludeLessThanYearStore, selectedYear, roundOff, excludeInactiveStore]);

  const fetchApi = async () => {
    setLoading(true);
    let payload = { excludeLessThanYearStore, roundOff, excludeInactiveStore };
    let data = [];
    if (checkFCAccess(profile)) {
      payload = { ...payload, intFSM: profile?.intFSM };
    }
    if (props?.storeId) {
      payload = { ...payload, ritasId: props?.storeId };
    }
    switch (type) {
      case "year":
        const req = { ...payload, selectedYear };
        data = await service?.fetchYtdTotalRegionSales(req);
        break;
      case "month":
        data = await service?.fetchMonthTotalRegionSales(payload);
        break;
      case "quarter":
        data = await service?.fetchQuarterTotalRegionSales(payload);
        break;
      case "week":
        data = await service?.fetchWeekTotalRegionSales(payload);
        break;
      case "day":
        data = await service?.fetchDayTotalRegionSales(payload);
        break;
      default:
        break;
    }
    setMainData(data);
    const result = data?.SalesByRegion?.map((i) => {
      const displayCurrentYearName = getYearName(
        "currentYear",
        type === "year" ? selectedYear : null
      );
      const displayPriorYearName = getYearName(
        "priorYear",
        type === "year" ? selectedYear : null
      );
      return {
        ...i,
        [displayCurrentYearName]: i?.currentYear,
        [displayPriorYearName]: i?.priorYear,
      };
    });
    setData(result || []);
    const removeTotal = result?.filter((i) => i?.Region !== "Total");
    const currentYearMax = Math.max(
      ...removeTotal.map((o) => o.currentYear || 0)
    );
    const priorYearMax = Math.max(...removeTotal.map((o) => o.priorYear || 0));
    setMaxValue(Math.max(currentYearMax, priorYearMax) + 100);
    setMaxChgValue(Math.max(...removeTotal.map((o) => o.chg || 0)));
    setMinChgValue(Math.min(...removeTotal.map((o) => o.chg || 0)));
    setLoading(false);
  };

  const handleExportExcel = async () => {
    setExcelLoading(true);
    let data = null;
    let payload = { excludeLessThanYearStore, roundOff, excludeInactiveStore };
    if (checkFCAccess(profile)) {
      payload = { ...payload, intFSM: profile?.intFSM };
    }
    switch (type) {
      case "year":
        const req = { ...payload, selectedYear };
        data = await service?.getCurrentYtdExport(req);
        break;
      case "quarter":
        data = await service?.getCurrentQuarterExport(payload);
        break;
      case "month":
        data = await service?.getCurrentMonthExport(payload);
        break;
      case "week":
        data = await service?.getCurrentWeekExport(payload);
        break;
      case "day":
        data = await service?.getCurrentDayExport(payload);
        break;
      default:
        break;
    }
    // const url = `${process.env.REACT_APP_API_URL}/${test?.path}${test?.fileName}`
    const url = generateDownLoadPath(data?.path, data?.fileName);

    setExcelLoading(false);
    window.open(url, "_blank");
  };

  const handleToggle = (val) => {
    setExcludeLessThanYearStore(val);
  };

  const handleInactiveStoreToggle = (val) => {
    setExcludeInactiveStore(val);
  };

  const SkeletonComponent = () => {
    return (
      <>
        <div className="table-div d-flex flex-column align-items-start">
          <div className="total-system-sales-header">
            <div>
              <b className="comp-sales">
                {`Comp Sales (Excluding New Stores & Transfer Stores < 1 year) : `}
                <Switch
                  checked={excludeLessThanYearStore}
                  onChange={handleToggle}
                  disabled={loading}
                />
              </b>
            </div>
            {/* <div className="ranking-btn-group pb-2">
                      <b className="comp-sales">
                        {`Exclude Inactive Stores : `}
                        <Switch
                          checked={excludeInactiveStore}
                          onChange={handleInactiveStoreToggle}
                          disabled={loading}
                        />
                      </b>
                    </div> */}
            <div className="d-flex justify-self-end  align-items-center">
              {!props?.child && (
                <>
                <div className="px-1">
                    <label className="px-1">Enable Round Off</label>
                    <Switch
                      title="RoundOff"
                      defaultChecked
                      checked={roundOff}
                      onChange={handleRoundOffChange}
                    />
                  </div>
                  {type === "year" && <YearPicker />}
                  <img
                    src="/DownLoadExcel.svg"
                    alt="DownLoadExcel"
                    className="DownLoadExcel"
                    onClick={() => handleExportExcel()}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        {loading ? (
          <>
            <DashboardLoader />
          </>
        ) : (
          <>
            {data?.length > 0 &&
            data.filter((i) => i?.Region !== "Total")?.length > 0 ? (
              <>
                <div className="total-system-sales">
                  {!props?.child && (
                    <>
                      <div className="show-monthly-selection pb-3">
                        <div className="summary-table-container">
                          <SummarySalesTable
                            selectedType={type}
                            dataItem={mainData?.ItemData}
                            roundOff={roundOff}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  <ResponsiveContainer
                    width="100%"
                    aspect={width < breakPointWidth ? 2 : 4}
                  >
                    <ComposedChart
                      data={data.filter((i) => i?.Region !== "Total")}
                      width={400}
                      margin={{
                        top: 25,
                        right: 20,
                        left: 20,
                        bottom: 0,
                      }}
                      height={300}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="Region" />
                      <YAxis
                        label={{
                          // value: "Amt ($)",
                          angle: 360,
                          position: "insideBottomRight",
                          offset: 10,
                        }}
                        // width={100}
                        tickFormatter={YAxisNumberFormat}
                        domain={[0, (dataMax) => parseFloat(maxValue).toFixed(2)]}
                        allowDataOverflow={true}
                      />
                      <Tooltip
                        content={
                          <CustomTooltipWithChg
                            roundOff={roundOff}
                            selectedYear={type === "year" ? selectedYear : null}
                          />
                        }
                        labelStyle={{ color: "darkblue" }}
                      />
                      <Bar
                        dataKey="currentYear"
                        fill={Legends["currentYear"]}
                        barSize={width < breakPointWidth ? 20 : 30}
                      />
                      <Bar
                        dataKey="priorYear"
                        fill={Legends["priorYear"]}
                        barSize={width < breakPointWidth ? 20 : 30}
                      />
                      <Line
                        dot={true}
                        strokeWidth={2}
                        dataKey="chg"
                        stroke="#FFC000"
                        yAxisId="right"
                        name="chg"
                      />
                      {minChgValue < 0 && (
                        <ReferenceLine
                          y={0}
                          stroke="green"
                          strokeDasharray="6 6"
                          yAxisId="right"
                          label={"0%"}
                        />
                      )}
                      <YAxis
                        type={"number"}
                        domain={[
                          (dataMin) =>
                            (minChgValue > 0 ? 0 : minChgValue).toFixed(2),
                          (dataMax) => (maxChgValue + 10).toFixed(2),
                        ]}
                        allowDataOverflow={true}
                        tickLine={false}
                        yAxisId="right"
                        interval={"preserveStartEnd"}
                        orientation="right"
                        axisLine={{ stroke: "#f5f5f5" }}
                        label={{
                          value: "Chg (%)",
                          angle: 360,
                          position: "insideBottomRight",
                          offset: 10,
                        }}
                      />
                    </ComposedChart>
                  </ResponsiveContainer>
                  {width < breakPointWidth ? (
                    <>
                      <div className="table-container">
                        <table>
                          <thead>
                            <tr>
                              <th>{"Region"}</th>
                              <th>
                                {" "}
                                {SvgCurrentYear()}
                                {getYearName(
                                  "currentYear",
                                  type === "year" ? selectedYear : null
                                )}
                                {ShowArrow(mainData?.ItemData?.currentArrow)}
                              </th>
                              <th>
                                {" "}
                                {SvgPreviousYear()}
                                {getYearName(
                                  "priorYear",
                                  type === "year" ? selectedYear : null
                                )}
                                {ShowArrow(mainData?.ItemData?.priorArrow)}
                              </th>
                              <th>
                                {" "}
                                {SvgChg()}
                                Chg %
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((i) => {
                              return (
                                <>
                                  <tr>
                                    <td>
                                      {/* {!props?.child && type === "year" &&
                                    i?.Region !== "Total" ? (
                                      <>
                                        <span
                                          onClick={() =>
                                            handleMonthlyView(i?.Region)
                                          }
                                          className={"table-td btn btn-link"}
                                        >
                                          {i?.Region}{" "}
                                          <i
                                            className="fa fa-external-link"
                                            aria-hidden="true"
                                          ></i>
                                        </span>
                                      </>
                                    ) : (
                                      <>{i?.Region}</>
                                    )} */}
                                      {i?.Region}
                                    </td>
                                    <td>
                                      {CurrencyFormat(i?.currentYear, roundOff)}
                                    </td>
                                    <td>
                                      {CurrencyFormat(i?.priorYear, roundOff)}
                                    </td>
                                    <td>{`${i?.["chg"]} %`}</td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) : (
                    <>
                      {" "}
                      <div className="table-container">
                        <table>
                          <thead>
                            <tr>
                              <th></th>
                              {data.map((i) => {
                                return (
                                  <>
                                    <td>
                                      {/* {!props?.child && type === "year" && i?.Region !== "Total" ? (
                                      <>
                                        <span
                                          onClick={() =>
                                            handleMonthlyView(i?.Region)
                                          }
                                          className={"table-td btn btn-link"}
                                        >
                                          {i?.Region}{" "}
                                          <i
                                            className="fa fa-external-link"
                                            aria-hidden="true"
                                          ></i>
                                        </span>
                                      </>
                                    ) : (
                                      <>{i?.Region}</>
                                    )} */}
                                      {i?.Region}
                                    </td>
                                  </>
                                );
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {SvgCurrentYear()}
                                {getYearName(
                                  "currentYear",
                                  type === "year" ? selectedYear : null
                                )}
                                {ShowArrow(mainData?.ItemData?.currentArrow)}
                              </td>
                              {data.map((i) => {
                                return (
                                  <>
                                    <td>
                                      {CurrencyFormat(i?.currentYear, roundOff)}
                                    </td>
                                  </>
                                );
                              })}
                            </tr>
                            <tr>
                              <td>
                                {SvgPreviousYear()}
                                {getYearName(
                                  "priorYear",
                                  type === "year" ? selectedYear : null
                                )}
                                {ShowArrow(mainData?.ItemData?.priorArrow)}
                              </td>
                              {data.map((i) => {
                                return (
                                  <>
                                    <td>
                                      {CurrencyFormat(i?.priorYear, roundOff)}
                                    </td>
                                  </>
                                );
                              })}
                            </tr>
                            <tr>
                              <td>
                                {SvgChg()}
                                Chg %
                              </td>
                              {data.map((i) => {
                                return (
                                  <>
                                    <td>{`${i?.["chg"] || 0} %`}</td>
                                  </>
                                );
                              })}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="norecords-available">No records available</div>
              </>
            )}
          </>
        )}
      </>
    );
  };

  if (showMonthly !== "") {
    return (
      <>
        <MonthlyWiseSalesForRegion
          showMonthly={showMonthly}
          title={title}
          width={width}
          handleMonthlyView={handleMonthlyView}
        />
      </>
    );
  }
  if (!props?.skeleton) {
    return (
      <>
        <Spin spinning={excelLoading}>
          <Card
            title={
              <>
                <div className="d-flex justify-content-between">
                  <div>
                    {!props?.child && (
                      <Button
                        type="primary"
                        className="btn-back"
                        onClick={() => navigate("/financial")}
                      >
                        <i
                          className="fa fa-arrow-left"
                          style={{ fontSize: "16px" }}
                        ></i>
                      </Button>
                    )}
                    <span>{`${title}`}</span>
                    {props?.child && !props?.storeId && (
                      <i
                        className="fa fa-external-link"
                        style={{ cursor: "pointer", paddingLeft: "0.5rem",fontSize:'14px' }}
                        aria-hidden="true"
                        onClick={() => props?.handleFullView()}
                      ></i>
                    )}
                  </div>
                  <div className="d-flex justify-content-end align-items-center px-2"></div>
                </div>
              </>
            }
          >
            <SkeletonComponent />
          </Card>
        </Spin>
      </>
    );
  } else {
    return (
      <>
        <Spin spinning={excelLoading}>
          <span style={{ float: "right" }}>
            <Button onClick={() => handleExportExcel()}>Excel Export</Button>
          </span>
          <SkeletonComponent />
        </Spin>
      </>
    );
  }
};

export default SalesByRegion;
