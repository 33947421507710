import React from "react";
import { useNavigate } from "react-router";
import { Button } from "antd";
import SalesByLineofBusiness from "../SalesByLineofBusiness";
import SalesByRegion from "../SalesByRegion";
import TotalSytemSales from "../TotalSytemSales";
const Month = () => {
  const navigate = useNavigate();
  const handleClick = (name) => {
    navigate(`/${name}`);
  };
  return (
    <>
      <Button
        type="primary"
        ghost
        className="m-2"
        onClick={() => navigate("/financial")}
      >
        Go to Dashboard
      </Button>
      <div className="grid-container-overview">
        <div
          className="one"
          onClick={() => handleClick("financial/month-sales")}
        >
          <TotalSytemSales
            type="month"
            title={"Current Month Total System Sales"}
            child={true}
          />
        </div>
        <div
          className="two"
          onClick={() => handleClick("financial/month-sales-line-of-business")}
        >
          <SalesByLineofBusiness
            type="month"
            title={"Current Month Total System Sales By Line of Business"}
            child={true}
          />
        </div>
        <div
          className="three"
          onClick={() => handleClick("financial/month-sales-by-region")}
        >
          <SalesByRegion
            type="month"
            title={"Current Month Total System Sales By Region"}
            child={true}
          />
        </div>
      </div>
    </>
  );
};

export default Month;
