import React from "react";

const SummaryGuestTable = (props) => {
  const { selectedType, dataItem } = props;
  return (
    <>
      <table>
        <thead>
          {selectedType === "week" && (
            <>
              <tr>
                <th>Week</th>
                <th>:</th>
                <th>{dataItem?.Week}</th>
              </tr>
              {/* <tr>
                <th>WE Date</th>
                <th>:</th>
                <th>{dataItem?.WEDate}</th>
              </tr> */}
              <tr>
                <th>Current Week Average GC</th>
                <th>:</th>
                <th>{dataItem?.weeklyAverageSales}</th>
              </tr>
            </>
          )}
          {selectedType === "year" && (
            <>
              <tr>
                <th>Year</th>
                <th>:</th>
                <th>{dataItem?.Year}</th>
              </tr>
            </>
          )}
          {selectedType === "quarter" && (
            <>
              <tr>
                <th>Quarter</th>
                <th>:</th>
                <th>{`${dataItem?.Year} Q${dataItem?.Quarter}`}</th>
              </tr>
              <tr>
                <th>Current Quarter Average (Delta)</th>
                <th>:</th>
                <th>{dataItem?.quarterAverageSales}</th>
              </tr>
            </>
          )}
          {selectedType === "month" && (
            <>
              <tr>
                <th>Month</th>
                <th>:</th>
                <th>{dataItem?.Month}</th>
              </tr>
              <tr>
                <th>Current Month Average GC</th>
                <th>:</th>
                <th>{dataItem?.monthAverageSales}</th>
              </tr>
            </>
          )}
          {selectedType === "day" && (
            <>
              <tr>
                <th>Date</th>
                <th>:</th>
                <th>{dataItem?.Date}</th>
              </tr>
              <tr>
                <th>Current Day Average GC</th>
                <th>:</th>
                <th>{dataItem?.dailyAverageSales}</th>
              </tr>
            </>
          )}
          <tr>
            <th>YTD Average GC</th>
            <th>:</th>
            <th>{dataItem?.YTDTotalShopSales}</th>
          </tr>
        </thead>
      </table>
    </>
  );
};

export default SummaryGuestTable;
