export const Legends = {
  currentYear: "#0088FE",
  priorYear: "#D9D9D9",
};

export const PieLegends = {
  DoorDash: "#4473C5",
  UberEats: "#ED7D31",
  GrubHub: "#A5A5A5",
  RitasOnlineOrder: "#007BF7",
  Others: "#D9D9D9",
  "Loyalty Visits": "#00DF96",
  "Guest Count": "#0094FE",
  "Birthday Redeemptions": "#FFBB28",
  "Coupon Redeemptions": "#0088FE",
  "Earned Redeemptions": "#00C49F",
  "Customer Appeasement": "#FF8042",
  "0 Platform": "#D9D9D9",
  "3 Platforms": "#A5A5A5",
  "1 Platform": "#4473C5",
  "2 Platforms": "#ED7D31",
  "casesMixValue":"#107C41",
  "yieldCasesMixValue":"#007BF7",

  "Red Flag": "#4473C5",
  "Orange Flag": "#ED7D31",
  "Normal Flag": "#A5A5A5",
  "No Issues": "#A5A5A5",
  "Near to Lease Expire Stores": "#4473C5",
  "Lease Expired Stores": "#ED7D31",
  "Far from Lease Expire Stores": "#A5A5A5",
};
export const BarLegends = {
  "Retail Transactions": "#4473C5",
  "Loyalty Visits": "#107C41",
  "Birthday Redeemptions": "#007BF7",
  "Total Transactions": "#D9D9D9",
  "Redeemptions":"#4473C5",
  "Guest Count":"#ED7D31",
};
export const AllBarDataLegends={
  "Current Redeemptions":"#ED7D31",
  "Prior Redeemptions":"#007BF7",
  "Current Visits":"#007BF7",
  "Prior Visits":"#007BF7",
  "Current GC":"#CA472F",
  "Prior GC":"#CA472F",
  // "Current Redeemptions":"#5D9C59",
  // "Prior Redeemptions":"#C7E8CA",
  // "Current Visits":"#19A7CE",
  // "Prior Visits":"#AFD3E2",
  // "Current GC":"#F97B22",
  // "Prior GC":"#FFA559",
  "CurrentBirthday":"#FFBB28",
  "PriorBirthday":"#FFBB28",
  "CurrentCoupon":"#0088FE",
  "PriorCoupon":"#0088FE",
  "CurrentEarned":"#00C49F",
  "PriorEarned":"#00C49F",
  "CurrentCustomer":"#FF8042",
  "PriorCustomer":"#FF8042",

}