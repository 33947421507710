import LegalDashboard from "../components/LegalDashboard"
import FCLeaseDetails from "../components/OperationalDashboard/FCLeaseDetails"

const LegalRoutes = [
    {
        path: "legal",
        name: "LegalDashboard",
        key: "LegalDashboard",
        component: <LegalDashboard />,
      },
      {
        path: "/legal/fc-lease-details",
        name: "FCLeaseDetails",
        key: "FCLeaseDetails",
        component: <FCLeaseDetails />,
      },
]

export default LegalRoutes