import React, { useEffect, useState } from "react";
import { Button, Card, Segmented, Spin, Switch, Table } from "antd";
import { FinancialService } from "../../../services/financial/index.service";
import {
  CurrencyFormat,
  generateDownLoadPath,
  getYearName,
  getYearNameWithGuests,
} from "../../../utils";
import SummaryRollupTable from "./SummaryRollupTable";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateRoundOffSelection } from "../../../redux/dashboard/customSelectionSlice";

const FCRankingReport = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { roundOff } = useSelector((state) => state?.customSelection);
  const service = new FinancialService();
  const [loading, setLoading] = useState(true);
  const [gcRankData, setGcRankData] = useState([]);
  const [mainData, setMainData] = useState(null);
  const [salesRankData, setSalesRankData] = useState([]);
  const [selectedView, setSelectedView] = useState("By Total");
  const [excelLoading, setExcelLoading] = useState(false);
  const handleRoundOffChange = () => {
    dispatch(updateRoundOffSelection(!roundOff));
  };

  const handleExportExcel = async () => {
    setExcelLoading(true);
    const payload = { roundOff };
    const data = await service?.getFCSalesExport(payload);
    // const url = `${process.env.REACT_APP_API_URL}/${test?.path}${test?.fileName}`
    const url = generateDownLoadPath(data?.path, data?.fileName);

    setExcelLoading(false);
    window.open(url, "_blank");
  };
  const salesColumns = [
    {
      title: "Rank",
      dataIndex: "Rank",
      key: "rank",
      visibility: true,
    },
    {
      title: "FC Name",
      dataIndex: "FC",
      key: "FC",
      visibility: true,
    },
    {
      title: getYearName("currentYear"),
      dataIndex: "currentSales",
      key: "currentSales",
      render: (data) => {
        return CurrencyFormat(data, roundOff);
      },
      visibility: true,
    },
    {
      title: getYearName("priorYear"),
      dataIndex: "priorSales",
      key: "priorSales",
      render: (data) => {
        return CurrencyFormat(data, roundOff);
      },
      visibility: true,
    },
    {
      title: "Chg %",
      dataIndex: "chg",
      key: "chg",
      render: (data) => {
        let colorClassName = "";
        if (data >= 0) {
          colorClassName = "green-td";
        } else {
          colorClassName = "red-td";
        }
        return <span className={colorClassName}>{data}</span>;
      },
      visibility: selectedView === "By Total" ? false : true,
    },
  ];
  const guestColumns = [
    {
      title: "Rank",
      dataIndex: "Rank",
      key: "rank",
      visibility: true,
    },
    {
      title: "FC Name",
      dataIndex: "FC",
      key: "FC",
      visibility: true,
    },
    {
      title: getYearNameWithGuests("currentYear"),
      dataIndex: "currentYear",
      key: "currentYear",
      visibility: true,
    },
    {
      title: getYearNameWithGuests("priorYear"),
      dataIndex: "priorYear",
      key: "priorYear",
      visibility: true,
    },
    {
      title: "Chg %",
      dataIndex: "chg",
      key: "chg",
      render: (data) => {
        let colorClassName = "";
        if (data >= 0) {
          colorClassName = "green-td";
        } else {
          colorClassName = "red-td";
        }
        return <span className={colorClassName}>{data}</span>;
      },
      visibility: selectedView === "By Total" ? false : true,
    },
  ];
  useEffect(() => {
    fetchApi();
  }, [selectedView, roundOff]);
  const handleSegmentSelection = (e) => {
    setSelectedView(e);
  };
  const fetchApi = async () => {
    setLoading(true);
    const payload = { roundOff };
    let result = null;
    switch (selectedView) {
      case "By Chg %":
        result = await service?.fcRankingByChange(payload);
        break;
      case "By Total":
        result = await await service?.fcRankingByTotal(payload);
        break;
      default:
        break;
    }
    setMainData(result);
    setSalesRankData(result?.salesRank || []);
    setGcRankData(result?.gcranks || []);
    setLoading(false);
  };

  return (
    <>
      <Spin spinning={excelLoading}>
        <Card
          title={
            <>
              <Button
                type="primary"
                className="btn-back"
                onClick={() => navigate(-1)}
              >
                <i
                  className="fa fa-arrow-left"
                  style={{ fontSize: "16px" }}
                ></i>
              </Button>
              <span>{"FC Ranking"}</span>
            </>
          }
        >
          <>
            <div className="table-div d-flex flex-column align-items-start">
              <div
                className="d-flex justify-content-between align-items-center py-2 "
                style={{ width: "100%" }}
              >
                <div>
                  <Segmented
                    options={["By Total", "By Chg %"]}
                    value={selectedView}
                    disabled={loading}
                    onChange={(e) => handleSegmentSelection(e)}
                  />
                </div>
                <div className="d-flex align-items-center flex-wrap">
                  <div className="px-1">
                    <label className="px-1">Enable Round Off</label>
                    <Switch
                      title="RoundOff"
                      defaultChecked
                      checked={roundOff}
                      onChange={handleRoundOffChange}
                    />
                  </div>
                  <img
                    src="/DownLoadExcel.svg"
                    alt="DownLoadExcel"
                    className="DownLoadExcel"
                    onClick={() => handleExportExcel()}
                  />
                </div>
              </div>
            </div>
            <>
              <div className="show-monthly-selection py-2">
                <div className="summary-table-container">
                  <SummaryRollupTable
                    dataItem={mainData?.ItemData}
                    selectedType={"ranking"}
                    roundOff={roundOff}
                  />
                </div>
              </div>
              <div className="ranking-report-grid">
                <div className="rank-grid-item">
                  <Table
                    dataSource={salesRankData}
                    columns={salesColumns?.filter((i) => i.visibility)}
                    pagination={false}
                    loading={loading}
                    bordered
                    title={() => `FC Sales Rank`}
                  />
                </div>
                <div className="rank-grid-item">
                  <Table
                    dataSource={gcRankData}
                    columns={guestColumns?.filter((i) => i.visibility)}
                    pagination={false}
                    loading={loading}
                    bordered
                    title={() => `FC GC Rank`}
                  />
                </div>
              </div>
            </>
          </>
        </Card>
      </Spin>
    </>
  );
};

export default FCRankingReport;
