import React, { useEffect, useState } from "react";
import { Button, Card, Radio, Switch, Table } from "antd";
import { FinancialService } from "../../../services/financial/index.service";
import { checkFCAccess, getYearNameWithGuests } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const GuestsRank = (props) => {
  const navigate=useNavigate()
  const service = new FinancialService();
  const profile = useSelector((state) => state?.profile) || null;
  const [type, setType] = useState("year");
  const [loading, setLoading] = useState(true);
  const [excludeLessThanYearStore, setExcludeLessThanYearStore] =
    useState(false);
  const [bottomRankData, setBottomRankData] = useState([]);
  const [topRankData, setTopRankData] = useState([]);

  const columns = [
    {
      title: "Rank",
      dataIndex: "Rank",
      key: "rank",
    },
    {
      title: "Store #",
      dataIndex: "RitasID",
      key: "RitasID",
    },
    {
      title: "Store Name",
      dataIndex: "StoreName",
      key: "StoreName",
    },
    {
      title: getYearNameWithGuests("currentYear"),
      dataIndex: "currentYear",
      key: "currentYear",
    },
    {
      title: getYearNameWithGuests("priorYear"),
      dataIndex: "priorYear",
      key: "priorYear",
    },
  ];
  useEffect(() => {
    fetchApi();
  }, [type,excludeLessThanYearStore]);

  const fetchApi = async () => {
    let req = {
      type,
      excludeLessThanYearStore
    };
    if(checkFCAccess(profile)){
      req={...req,intFSM:profile?.intFSM}
    }
    setLoading(true);
    const data = await service?.fetchGuestRank(req);
    setLoading(false);
    setTopRankData(data?.top || []);
    setBottomRankData(data?.bottom || []);
  };
  const handleToggle = (val) => {
    setExcludeLessThanYearStore(val);
  };

  return (
    <>
      <Card
        title={
          <>
            {!props?.child && (
                <Button
                type="primary"
                className="btn-back"
                onClick={() => navigate("/financial")}
              >
                <i
                  className="fa fa-arrow-left"
                  style={{ fontSize: "16px" }}
                ></i>
              </Button>
            )}
            <span>{"Guests Rank Report"}</span>
          </>
        }
      >
        <>
          <div className="ranking-btn-group pb-2">
            <b>
            {`Exclude new stores & transfer stores (less than 1 year) : `}
              <Switch
                checked={excludeLessThanYearStore}
                onChange={handleToggle}
                disabled={loading}
              />
            </b>
          </div>
          <div className="ranking-btn-group">
            <Radio.Group
              value={type}
              onChange={(e) => setType(e.target.value)}
              className="pb-2"
            >
              <Radio.Button value="year">Year</Radio.Button>
              <Radio.Button value="month">Month</Radio.Button>
              <Radio.Button value="week">Week</Radio.Button>
              <Radio.Button value="day">Day</Radio.Button>
            </Radio.Group>
          </div>
          <>
            <div className="ranking-report-grid">
              <div className="rank-grid-item">
                <Table
                  dataSource={topRankData}
                  columns={columns}
                  pagination={false}
                  loading={loading}
                  bordered
                  title={() => `Top 10 ${type} Guest(s) Rank`}
                />
              </div>
              <div className="rank-grid-item">
                <Table
                  dataSource={bottomRankData}
                  columns={columns}
                  pagination={false}
                  loading={loading}
                  bordered
                  title={() => `Bottom 10 ${type} Guest(s) Rank`}
                />
              </div>
            </div>
          </>
        </>
      </Card>
    </>
  );
};

export default GuestsRank;
