import React, { useEffect, useState } from "react";
import { Button, Card, Segmented, Spin } from "antd";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { FinancialService } from "../../../services/financial/index.service";
import {
  checkFCAccess,
  generateDownLoadPath,
  getYearNameOnly,
  ShowArrow,
} from "../../../utils";
import { CustomTooltipForYearGuest } from "../../../utils/Tooltip";
import { Legends } from "../../../utils/legend";
import { SvgCurrentYear, SvgPreviousYear } from "../../../utils/svgpath";
import MonthlyWiseGuestCount from "./Yearly/MonthlyWiseGuestCount";
import { useNavigate } from "react-router-dom";
import { DashboardLoader } from "../../../utils/Loading";
import SummaryGuestTable from "./SummaryGuestTable";
import { useSelector } from "react-redux";
import YearPicker from "../../YearPicker";

const GuestCount = ({ type, title, child = false }) => {
  const navigate = useNavigate();
  const { selectedYear, roundOff } = useSelector(
    (state) => state?.customSelection
  );
  const profile = useSelector((state) => state?.profile);
  const service = new FinancialService();
  const [isChildComponent, setIsChildComponent] = useState(false);
  const [selectedView, setSelectedView] = useState("Year");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [barData, setBarData] = useState([]);
  const [maxValue, setMaxValue] = useState(0);
  const [width, setWindowWidth] = useState(0);
  const [excelLoading, setExcelLoading] = useState(false);
  const breakPointWidth = 767;
  useEffect(() => {
    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };
  useEffect(() => {
    fetchApi();
  }, [selectedYear]);

  const handleSegmentSelection = (e) => {
    if (e === "Year") {
      setIsChildComponent(false);
    } else {
      setIsChildComponent(true);
    }
    setSelectedView(e);
  };

  const fetchApi = async () => {
    setLoading(true);
    let data = [];
    let payload = {};
    if (checkFCAccess(profile)) {
      payload = { ...payload, intFSM: profile?.intFSM };
    }
    switch (type) {
      case "year":
        payload = { ...payload, selectedYear: selectedYear };
        data = await service?.fetchYtdTotalGuestCount(payload);
        break;
      case "month":
        data = await service?.fetchMonthTotalGuestCount(payload);
        break;
      case "week":
        data = await service?.fetchWeekTotalGuestCount(payload);
        break;
      case "day":
        data = await service?.fetchDayTotalGuestCount(payload);
        break;
      default:
        break;
    }
    if (data) {
      setData(data);
      const rec = data?.TotalGuestCount?.map((i) => {
        let yearVal = getYearNameOnly(i?.name);
        if (type === "year") {
          yearVal = getYearNameOnly(i?.name, selectedYear);
        }
        const displayName = yearVal;
        return { ...i, displayName: displayName };
      });
      setBarData(rec || []);
      const maxValue = Math.max(...rec.map((o) => o.Guests || 0));
      setMaxValue(maxValue + 100);
    } else {
      setData(null);
      setBarData([]);
    }

    setLoading(false);
  };

  const handleExportExcel = async () => {
    setExcelLoading(true);
    let data = null;
    let payload = { excludeLessThanYearStore: false };
    if (checkFCAccess(profile)) {
      payload = { ...payload, intFSM: profile?.intFSM };
    }
    switch (type) {
      case "year":
        const req = { ...payload, selectedYear: selectedYear };
        data = await service?.getCurrentYtdExport(req);
        break;
      case "quarter":
        data = await service?.getCurrentQuarterExport(payload);
        break;
      case "month":
        data = await service?.getCurrentMonthExport(payload);
        break;
      case "week":
        data = await service?.getCurrentWeekExport(payload);
        break;
      case "day":
        data = await service?.getCurrentDayExport(payload);
        break;
      default:
        break;
    }
    // const url = `${process.env.REACT_APP_API_URL}/${test?.path}${test?.fileName}`
    const url = generateDownLoadPath(data?.path, data?.fileName);

    setExcelLoading(false);
    window.open(url, "_blank");
  };

  const DefaultSummaryComponent = () => {
    return (
      <>
        {" "}
        {width < breakPointWidth ? (
          <>
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>{"Guests"}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {SvgCurrentYear()}
                      {barData?.[0]?.["displayName"]}
                      {ShowArrow(data?.ItemData?.currentArrow)}
                    </td>
                    <td>{barData?.[0]?.Guests || 0}</td>
                  </tr>
                  <tr>
                    <td>
                      {SvgPreviousYear()}
                      {barData?.[1]?.["displayName"]}
                      {ShowArrow(data?.ItemData?.priorArrow)}
                    </td>
                    <td>{barData?.[1]?.Guests || 0}</td>
                  </tr>
                  <tr>
                    <td>{"% Chg"}</td>
                    <td>{`${data?.chg || 0} %`}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <>
            {" "}
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      {SvgCurrentYear()}
                      {barData?.[0]?.displayName}
                      {ShowArrow(data?.ItemData?.currentArrow)}
                    </th>
                    <th>
                      {SvgPreviousYear()}
                      {barData?.[1]?.displayName}
                      {ShowArrow(data?.ItemData?.priorArrow)}
                    </th>
                    <th>% Chg</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Guest Count</td>
                    <td>{barData?.[0]?.Guests || 0}</td>
                    <td>{barData?.[1]?.Guests || 0}</td>
                    <td>{`${data?.chg || 0} %`}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}
      </>
    );
  };

  const DefaultComponent = () => {
    return (
      <>
        <div className="total-system-sales">
          <div className="show-monthly-selection pb-3">
            <div className="summary-table-container">
              <SummaryGuestTable
                selectedType={type}
                dataItem={data?.ItemData}
              />
            </div>
          </div>
          <ResponsiveContainer
            width="100%"
            aspect={width < breakPointWidth ? 2 : 4}
          >
            <BarChart
              data={barData}
              margin={{ top: 5, right: 20, bottom: 5, left: 20 }}
            >
              <XAxis dataKey="displayName" />
              <YAxis
                dataKey={"Guests"}
                label={{
                  value: "Guest(s)",
                  angle: 360,
                  position: "insideBottomRight",
                  offset: 10,
                }}
                // width={120}
                domain={[0, (dataMax) => parseFloat(maxValue).toFixed(2)]}
                allowDataOverflow={true}
              />
              <Tooltip
                content={<CustomTooltipForYearGuest />}
                labelStyle={{ color: "darkblue" }}
              />
              <Bar
                dataKey="Guests"
                fill="#0088FE"
                barSize={width < breakPointWidth ? 20 : 30}
              >
                {barData?.map((entry, index) => {
                  const color = Legends[entry?.name];
                  return <Cell key={`cell-${index}`} fill={color} />;
                })}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </>
    );
  };

  return (
    <>
      <Spin spinning={excelLoading}>
        <Card
        // title={
        //   <>
        //     <div className="d-flex justify-content-between">
        //       <div>
        //         {!child && (
        //           <Button
        //             type="primary"
        //             className="btn-back"
        //             onClick={() => navigate("/financial")}
        //           >
        //             <i
        //               className="fa fa-arrow-left"
        //               style={{ fontSize: "16px" }}
        //             ></i>
        //           </Button>
        //         )}
        //         <span>{title}</span>
        //       </div>
        //     </div>
        //   </>
        // }
        >
          {!child && type === "year" && (
            <>
              <div className="table-div">
                <div
                  className="d-flex justify-content-between align-items-center py-2 "
                  style={{ width: "100%" }}
                >
                  <div>
                    <Segmented
                      options={["Year", "Month View"]}
                      value={selectedView}
                      disabled={loading}
                      onChange={(e) => handleSegmentSelection(e)}
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    {type === "year" && <YearPicker />}

                    <img
                      src="/DownLoadExcel.svg"
                      alt="DownLoadExcel"
                      className="DownLoadExcel"
                      onClick={() => handleExportExcel()}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          {isChildComponent ? (
            <>
              {selectedView === "Month View" && (
                <MonthlyWiseGuestCount
                  title={title}
                  width={width}
                  ItemData={data?.ItemData}
                />
              )}
            </>
          ) : (
            <>
              {loading ? (
                <DashboardLoader />
              ) : (
                <>
                  {barData?.length > 0 ? (
                    <>
                      <DefaultComponent />
                      <DefaultSummaryComponent />
                    </>
                  ) : (
                    <>
                      <div className="norecords-available">
                        No records available
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Card>
      </Spin>
    </>
  );
};

export default GuestCount;
