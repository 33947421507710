import React from "react";
import { useNavigate } from "react-router";
import { Button } from "antd";
import SalesByLineofBusiness from "../SalesByLineofBusiness";
import SalesByRegion from "../SalesByRegion";
import TotalSytemSales from "../TotalSytemSales";
const Quarter = () => {
  const navigate = useNavigate();
  const handleClick = (name) => {
    navigate(`/${name}`);
  };
  return (
    <>
      <Button
        type="primary"
        ghost
        className="m-2"
        onClick={() => navigate("/financial")}
      >
        Go to Dashboard
      </Button>
      <div className="grid-container-overview">
        <div className="one">
          <TotalSytemSales
            type="quarter"
            title={"Current Quarter Total System Sales"}
            handleFullView={() => handleClick("financial/quarter-sales")}
            child={true}
          />
        </div>
        <div className="two">
          <SalesByLineofBusiness
            type="quarter"
            title={"Current Quarter Total System Sales By Line of Business"}
            child={true}
            handleFullView={() =>
              handleClick("financial/quarter-sales-line-of-business")
            }
          />
        </div>
        <div className="three">
          <SalesByRegion
            type="quarter"
            title={"Current Quarter Total System Sales By Region"}
            child={true}
            handleFullView={() =>
              handleClick("financial/quarter-sales-by-region")
            }
          />
        </div>
      </div>
    </>
  );
};

export default Quarter;
