import React from "react";
import { Button, Card, Row } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "antd";
import { updateMenuSelection } from "../../redux/dashboard/dashboardSlice";
const { Title } = Typography;

const OperationalDashboard = () => {
  const profileData = useSelector((state) => state?.profile);
  const dispatch = useDispatch();
  return (
    <Card
      title={
        <>
          <Button
            type="primary"
            className="btn-back"
            onClick={() => dispatch(updateMenuSelection("home"))}
          >
            <i className="fa fa-arrow-left" style={{ fontSize: "16px" }}></i>
          </Button>
          <span>{"Operational Dashboard"}</span>
        </>
      }
    >
      <div className="parent-flex-container pt-3">
        <Card
          className="report_Card"
          headStyle={{ width: "100%" }}
          title={
            <Title
              style={{ color: "#044A2A !important", fontSize: "16px" }}
              level={5}
            >
              Operational Report
            </Title>
          }
          hoverable={true}
          bordered={true}
        >
          <Row type="flex" style={{ color: "#aaaaaa" }}>
            <Link to="/operational/clover-retail-prices">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>Clover vs Retail Prices
                  </div>
                </div>
              </div>
            </Link>
          </Row>
          <Row>
            <Link to="/operational/fc-lease-details">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>FC Lease Details
                  </div>
                </div>
              </div>
            </Link>
          </Row>
          <Row>
            <Link to="/operational/stores-list-report">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>Store Tracking Report
                  </div>
                </div>
              </div>
            </Link>
          </Row>
          <Row>
            <Link to="/operational/fc-list-report">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>FC Tracking Report
                  </div>
                </div>
              </div>
            </Link>
          </Row>
          <Row>
            <Link to="/operational/fc-discrepancy-list">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>FC Discrepancy Report
                  </div>
                </div>
              </div>
            </Link>
          </Row>
          <Row type="flex" style={{ color: "#aaaaaa" }}>
            <Link to="/operational/rank-report">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>Ranking Report
                  </div>
                </div>
              </div>
            </Link>
          </Row>
          <Row type="flex" style={{ color: "#aaaaaa" }}>
                <Link to="/operational/fc-rollup">
                  <div className="report-content-text">
                    <div className="report-text-wrap">
                      <div className="report-sub-heading">
                        <span className="fas fa-poll"></span>FC Rollup
                      </div>
                    </div>
                  </div>
                </Link>
              </Row>
              <Row type="flex" style={{ color: "#aaaaaa" }}>
                <Link to="/operational/fc-ranking">
                  <div className="report-content-text">
                    <div className="report-text-wrap">
                      <div className="report-sub-heading">
                        <span className="fas fa-poll"></span>FC Ranking
                      </div>
                    </div>
                  </div>
                </Link>
              </Row>
              <Row type="flex" style={{ color: "#aaaaaa" }}>
                <Link to="/operational/region-rollup">
                  <div className="report-content-text">
                    <div className="report-text-wrap">
                      <div className="report-sub-heading">
                        <span className="fas fa-poll"></span>Region Rollup
                      </div>
                    </div>
                  </div>
                </Link>
              </Row>
        </Card>
      </div>
    </Card>
  );
};

export default OperationalDashboard;
