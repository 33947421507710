import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { resetCustomSelection } from "../../../redux/dashboard/customSelectionSlice";

const useMainLoyaltyReport = () => {
  const dispatch= useDispatch()
  const [width, setWindowWidth] = useState(0);
  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions)
      dispatch(resetCustomSelection())
    };
  }, []);


  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };

  const [selectedView, setSelectedView] = useState("Yearly");
  const handleSegmentSelection = (e) => {
    setSelectedView(e);
  };
  return { selectedView, handleSegmentSelection, width };
};

export default useMainLoyaltyReport;
