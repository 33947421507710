import React from "react";
import { NumberFormat, ShowArrow, getYearNameOnly } from "../../../../utils";
import { PieLegends } from "../../../../utils/legend";
import { SvgTransaction } from "../../../../utils/svgpath";

const RedeemptionTable = (props) => {
  const { selectedView, selectedYear, AllTableData } = props;
  return (
    <>
      <div className="table-container-grid">
        {AllTableData?.map((table) => {
          return (
            <div className="legend-table">
              <table>
                <thead>
                  <tr>
                    <th>
                      {selectedView === "Monthly" && "Month"}
                      {selectedView === "Quarterly" && "Quarter"}
                      {selectedView === "Weekly" && "Week"}
                    </th>
                    <th>Rewards</th>
                    <th>{getYearNameOnly("currentYear", selectedYear)}</th>
                    <th>{getYearNameOnly("priorYear", selectedYear)}</th>
                    <th>{"Chg%"}</th>
                  </tr>
                </thead>
                <tbody>
                  {table?.map((i) => {
                    return (
                      <>
                        <tr>
                          <td rowSpan={4}>{i?.Name}</td>
                          <td>
                            {SvgTransaction(
                              PieLegends["Birthday Redeemptions"]
                            )}
                            {"Birthday Redeemptions"}
                            {parseFloat(
                              i["Value"]["chg"]["Birthday Redeemptions"]
                            ) > 0
                              ? ShowArrow("up")
                              : ShowArrow("down")}
                          </td>
                          <td>
                            {NumberFormat(
                              i["Value"]["currentYear"]["Birthday Redeemptions"]
                            )}
                          </td>
                          <td>
                            {NumberFormat(
                              i["Value"]["priorYear"]["Birthday Redeemptions"]
                            )}
                          </td>
                          <td>{i["Value"]["chg"]["Birthday Redeemptions"]}</td>
                        </tr>
                        <tr>
                          <td>
                            {SvgTransaction(PieLegends["Coupon Redeemptions"])}
                            {"Coupon Redeemptions"}
                            {parseFloat(
                              i["Value"]["chg"]["Coupon Redeemptions"]
                            ) > 0
                              ? ShowArrow("up")
                              : ShowArrow("down")}
                          </td>
                          <td>
                            {NumberFormat(
                              i["Value"]["currentYear"]["Coupon Redeemptions"]
                            )}
                          </td>
                          <td>
                            {NumberFormat(
                              i["Value"]["priorYear"]["Coupon Redeemptions"]
                            )}
                          </td>
                          <td>{i["Value"]["chg"]["Coupon Redeemptions"]}</td>
                        </tr>
                        <tr>
                          <td>
                            {SvgTransaction(PieLegends["Earned Redeemptions"])}
                            {"Earned Redeemptions"}
                            {parseFloat(
                              i["Value"]["chg"]["Earned Redeemptions"]
                            ) > 0
                              ? ShowArrow("up")
                              : ShowArrow("down")}
                          </td>
                          <td>
                            {NumberFormat(
                              i["Value"]["currentYear"]["Earned Redeemptions"]
                            )}
                          </td>
                          <td>
                            {NumberFormat(
                              i["Value"]["priorYear"]["Earned Redeemptions"]
                            )}
                          </td>
                          <td>{i["Value"]["chg"]["Earned Redeemptions"]}</td>
                        </tr>
                        <tr>
                          <td>
                            {SvgTransaction(PieLegends["Customer Appeasement"])}
                            {"Customer Appeasement"}
                            {parseFloat(
                              i["Value"]["chg"]["Customer Appeasement"]
                            ) > 0
                              ? ShowArrow("up")
                              : ShowArrow("down")}
                          </td>
                          <td>
                            {NumberFormat(
                              i["Value"]["currentYear"]["Customer Appeasement"]
                            )}
                          </td>
                          <td>
                            {NumberFormat(
                              i["Value"]["priorYear"]["Customer Appeasement"]
                            )}
                          </td>
                          <td>{i["Value"]["chg"]["Customer Appeasement"]}</td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default RedeemptionTable;
