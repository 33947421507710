import React from "react";

const SummaryCloverTable = (props) => {
  const { dataItem } = props;
  return (
    <>
      <table>
        <thead>
          <tr>
            <th>Total</th>
            <th>:</th>
            <th>{dataItem?.total}</th>
          </tr>
          <tr>
            <th>Red Flag Total</th>
            <th>:</th>
            <th>{dataItem?.positive}</th>
          </tr>
          <tr>
            <th>Orange Flag Total</th>
            <th>:</th>
            <th>{dataItem?.negative}</th>
          </tr>
        </thead>
      </table>
    </>
  );
};

export default SummaryCloverTable;
