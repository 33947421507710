import React, { useEffect, useState } from "react";
import { CoolnetService } from "../../../services/coolnet/index.service";
import { getYearName } from "../../../utils";
import { useSelector } from "react-redux";

const useLineOfBusinessSales = (props) => {
  const { selectedYear } = useSelector(
    (state) => state?.customSelection
  );
  const service = new CoolnetService();
  const [loading, setLoading] = useState(true);
  const [maxValue, setMaxValue] = useState(0);
  const [maxChgValue, setMaxChgValue] = useState(0);
  const [minChgValue, setMinChgValue] = useState(0);
  const [data, setData] = useState([]);
  const [mainData, setMainData] = useState(null);


  useEffect(() => {
    fetchApi();
  }, [selectedYear]);

  const fetchApi = async () => {
    setLoading(true);
    const req = { ritasId: props?.storeId, selectedYear };
    const data = await service?.fetchYtdLineOfBusinessSales(req);
    setMainData(data);

    const result = data?.SalesByLineofBusiness?.map((i) => {
      const displayCurrentYearName = getYearName("currentYear", selectedYear);
      const displayPriorYearName = getYearName("priorYear", selectedYear);
      return {
        ...i,
        currentYear: parseFloat(i?.currentYear),
        priorYear: parseFloat(i?.priorYear),
        [displayCurrentYearName]: parseFloat(i?.currentYear),
        [displayPriorYearName]: parseFloat(i?.priorYear),
      };
    });
    setData(result || []);
    const removeTotal = result?.filter((i) => i?.name !== "Total");
    const currentYearMax = Math.max(
      ...removeTotal.map((o) => o?.currentYear || 0)
    );
    const priorYearMax = Math.max(...removeTotal.map((o) => o?.priorYear || 0));
    setMaxValue(Math.max(currentYearMax, priorYearMax) + 100);
    setMaxChgValue(Math.max(...removeTotal.map((o) => o?.chg || 0)));
    setMinChgValue(Math.min(...removeTotal.map((o) => o?.chg || 0)));
    setLoading(false);
  };
  return {
    loading,
    mainData,
    maxChgValue,
    maxValue,
    selectedYear,
    data,
    minChgValue,
  };
};

export default useLineOfBusinessSales;
