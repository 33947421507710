import { CommonServiceUtils } from "../common-service-utils";

export class OperationalService extends CommonServiceUtils {
  async fetchCloverRetailPrices(req) {
    let response = null;
    const CloverRetailPrices = await this.postData(
      `${this.apiUrlCreator(`clover-retail`)}`,
      req
    );
    if (CloverRetailPrices?.responseCode === 200) {
      response = CloverRetailPrices?.result;
    }
    return response;
  }
  async fetchCloverStores() {
    let response = null;
    const getCloverStores = await this.postData(
      `${this.apiUrlCreator(`clover-stores`)}`
    );
    if (getCloverStores?.responseCode === 200) {
      response = getCloverStores?.result;
    }
    return response;
  }
  async fetchCloverFC() {
    let response = null;
    const getCloverFC = await this.postData(
      `${this.apiUrlCreator(`clover-fc`)}`
    );
    if (getCloverFC?.responseCode === 200) {
      response = getCloverFC?.result;
    }
    return response;
  }
  async fetchYtdCaseMixes(req) {
    let response = null;
    const getYtdCaseMixes = await this.postData(
      `${this.apiUrlCreator(`ytd-case-mixes`)}`,
      req
    );
    if (getYtdCaseMixes?.responseCode === 200) {
      response = getYtdCaseMixes?.result;
    }
    return response;
  }
  async fetchCaseMixesByMonth(req) {
    let response = null;
    const getCaseMixesByMonth = await this.postData(
      `${this.apiUrlCreator(`ytd-case-mixes`)}`,
      req
    );
    if (getCaseMixesByMonth?.responseCode === 200) {
      response = getCaseMixesByMonth?.result;
    }
    return response;
  }

  async fetchMonthCaseMixes(req) {
    let response = null;
    const getMonthCaseMixes = await this.postData(
      `${this.apiUrlCreator(`month-case-mixes`)}`,
      req
    );
    if (getMonthCaseMixes?.responseCode === 200) {
      response = getMonthCaseMixes?.result;
    }
    return response;
  }
  async fetchMixesByCasesPurchased(req) {
    let response = null;
    const getMixesByCasesPurchased = await this.postData(
      `${this.apiUrlCreator(`cases-purchased`)}`,
      req
    );
    if (getMixesByCasesPurchased?.responseCode === 200) {
      response = getMixesByCasesPurchased?.result;
    }
    return response;
  }
  async fetchMixesByYieldOfCasesPurchased(req) {
    let response = null;
    const getMixesByYieldOfCasesPurchased = await this.postData(
      `${this.apiUrlCreator(`mix-of-total`)}`,
      req
    );
    if (getMixesByYieldOfCasesPurchased?.responseCode === 200) {
      response = getMixesByYieldOfCasesPurchased?.result;
    }
    return response;
  }

  async fetchCloverRetailExport(req) {
    let response = null;
    const fetchCloverRetailExport = await this.postData(
      `${this.apiUrlCreator(`clover-retail-excel`)}`,
      req
    );
    if (fetchCloverRetailExport?.responseCode === 200) {
      response = fetchCloverRetailExport?.result;
    }
    return response;
  }
  async fetchCasePurchasedExport(req) {
    let response = null;
    const fetchCasePurchasedExport = await this.postData(
      `${this.apiUrlCreator(`case-purchased-export`)}`,
      req
    );
    if (fetchCasePurchasedExport?.responseCode === 200) {
      response = fetchCasePurchasedExport?.result;
    }
    return response;
  }
  async fetchCaseMixExport(req) {
    let response = null;
    const fetchCaseMixExport = await this.postData(
      `${this.apiUrlCreator(`case-mix-export`)}`,
      req
    );
    if (fetchCaseMixExport?.responseCode === 200) {
      response = fetchCaseMixExport?.result;
    }
    return response;
  }
  async fetchYTDCaseMixesExcel(req) {
    let response = null;
    const fetchYTDCaseMixesExcel = await this.postData(
      `${this.apiUrlCreator(`ytd-case-mixes-excel`)}`,
      req
    );
    if (fetchYTDCaseMixesExcel?.responseCode === 200) {
      response = fetchYTDCaseMixesExcel?.result;
    }
    return response;
  }
  async fetchMonthCaseMixesExcel(req) {
    let response = null;
    const fetchMonthCaseMixesExcel = await this.postData(
      `${this.apiUrlCreator(`month-case-mixes-excel`)}`,
      req
    );
    if (fetchMonthCaseMixesExcel?.responseCode === 200) {
      response = fetchMonthCaseMixesExcel?.result;
    }
    return response;
  }
  async fetchFCLeaseDetails(req) {
    let response = null;
    const getFCLeaseDetails = await this.postData(
      `${this.apiUrlCreator(`fc-lease-details`)}`,
      req
    );
    if (getFCLeaseDetails?.responseCode === 200) {
      response = getFCLeaseDetails?.result;
    }
    return response;
  }
  async fetchFCAgreementDatesExport(req) {
    let response = null;
    const fetchFCAgreementDatesExport = await this.postData(
      `${this.apiUrlCreator(`fc-lease-export`)}`,
      req
    );
    if (fetchFCAgreementDatesExport?.responseCode === 200) {
      response = fetchFCAgreementDatesExport?.result;
    }
    return response;
  }
  async fetchSalesSectionByStore(req) {
    let response = null;
    const fetchSalesSectionByStore = await this.postData(
      `${this.apiUrlCreator(`sales-section-by-store`)}`,
      req
    );
    if (fetchSalesSectionByStore?.responseCode === 200) {
      response = fetchSalesSectionByStore?.result;
    }
    return response;
  }
  async fetchSalesHistoryByStore(req) {
    let response = null;
    const fetchSalesSHistoryByStore = await this.postData(
      `${this.apiUrlCreator(`sales-history`)}`,
      req
    );
    if (fetchSalesSHistoryByStore?.responseCode === 200) {
      response = fetchSalesSHistoryByStore?.result;
    }
    return response;
  }
  async fetchHourlySalesByStore(req) {
    let response = null;
    const fetchHourlySalesByStore = await this.postData(
      `${this.apiUrlCreator(`store-hourly-sales`)}`,
      req
    );
    if (fetchHourlySalesByStore?.responseCode === 200) {
      response = fetchHourlySalesByStore?.result;
    }
    return response;
  }
  async fetchRankingByStore(req) {
    let response = null;
    const fetchRankingByStore = await this.postData(
      `${this.apiUrlCreator(`store-ranking`)}`,
      req
    );
    if (fetchRankingByStore?.responseCode === 200) {
      response = fetchRankingByStore?.result;
    }
    return response;
  }
  async fetchStoreVisits(req) {
    let response = null;
    const fetchStoreVisits = await this.postData(
      `${this.apiUrlCreator(`store-visits`)}`,
      req
    );
    if (fetchStoreVisits?.responseCode === 200) {
      response = fetchStoreVisits?.result;
    }
    return response;
  }
  async fetchStoreFeedbacks(req) {
    let response = null;
    const fetchStoreFeedbacks = await this.postData(
      `${this.apiUrlCreator(`store-feedbacks`)}`,
      req
    );
    if (fetchStoreFeedbacks?.responseCode === 200) {
      response = fetchStoreFeedbacks?.result;
    }
    return response;
  }
  async fetchFCDiscrepancy(req) {
    let response = null;
    const fetchFCDiscrepancy = await this.postData(
      `${this.apiUrlCreator(`fc-discrepancy`)}`,
      req
    );
    if (fetchFCDiscrepancy?.responseCode === 200) {
      response = fetchFCDiscrepancy?.result;
    }
    return response;
  }

  async fetchStoresList(req) {
    let response = null;
    const getStoresList = await this.postData(
      `${this.apiUrlCreator(`store-list`)}`,
      req
    );
    if (getStoresList?.responseCode === 200) {
      response = getStoresList?.result;
    }
    return response;
  }
  async fetchFCList() {
    let response = null;
    const fetchFCList = await this.postData(
      `${this.apiUrlCreator(`fc-list`)}`);
    if (fetchFCList?.responseCode === 200) {
      response = fetchFCList?.result;
    }
    return response;
  }
  async fetchDispencaryFCList() {
    let response = null;
    const getDIspencaryFCList = await this.postData(
      `${this.apiUrlCreator(`fc-discrepancy-rank`)}`);
    if (getDIspencaryFCList?.responseCode === 200) {
      response = getDIspencaryFCList?.result;
    }
    return response;
  }

  async fetchFCDigital(req) {
    let response = null;
    const getFCDigital = await this.postData(
      `${this.apiUrlCreator(`fc-digital-pie`)}`,
      req
    );
    if (getFCDigital?.responseCode === 200) {
      response = getFCDigital?.result;
    }
    return response;
  }
  async fetchFCCloverRetailPie(req) {
    let response = null;
    const getFCCloverRetailPie = await this.postData(
      `${this.apiUrlCreator(`fc-clover-retail-pie`)}`,
      req
    );
    if (getFCCloverRetailPie?.responseCode === 200) {
      response = getFCCloverRetailPie?.result;
    }
    return response;
  }
  async fetchFCLeaseCountPie(req) {
    let response = null;
    const getFCLeaseCountPie = await this.postData(
      `${this.apiUrlCreator(`fc-lease-count-pie`)}`,
      req
    );
    if (getFCLeaseCountPie?.responseCode === 200) {
      response = getFCLeaseCountPie?.result;
    }
    return response;
  }
}
