import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { monthApi } from "./services/monthApi";
import profileReducer from "./profile/profileSlice";
import tokenReducer from "./token/tokenSlice";
import dashboardReducer from "./dashboard/dashboardSlice";
import customSelectionSlice from "./dashboard/customSelectionSlice";
export const store = configureStore({
  reducer: {
    // userData: UserReducer,
    profile: profileReducer,
    token: tokenReducer,
    dashboard: dashboardReducer,
    customSelection: customSelectionSlice
    // [monthApi.reducerPath]: monthApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(monthApi.middleware),
});

setupListeners(store.dispatch);
