import { Button, Card, Spin, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { FinancialService } from "../../../../services/financial/index.service";
import {
  checkFCAccess,
  CurrencyFormat,
  generateDownLoadPath,
  getYearName,
  NumberFormat,
  ShowArrow,
  YAxisNumberFormat,
} from "../../../../utils";
import { Legends } from "../../../../utils/legend";
import { DashboardLoader } from "../../../../utils/Loading";
import {
  SvgChg,
  SvgCurrentYear,
  SvgPreviousYear,
} from "../../../../utils/svgpath";
import { CustomTooltipWithoutChg } from "../../../../utils/Tooltip";
import SummarySalesTable from "../SummarySalesTable";

const MonthlyWiseSales = (props) => {
  const service = new FinancialService();
   const { selectedYear, roundOff } = useSelector((state) => state?.customSelection);
  const profile = useSelector((state) => state?.profile) || null;

  const [excelLoading, setExcelLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [maxValue, setMaxValue] = useState(0);
  const [itemData,setItemData]=useState(null)
  const [data, setData] = useState([]);

  const breakPointWidth = 767;

  useEffect(() => {
    fetchApi();
  }, [
    props?.excludeLessThanYearStore,
    selectedYear,
    roundOff,
    props?.excludeInactiveStore,
  ]);

  const fetchApi = async () => {
    let payload = {
      excludeLessThanYearStore: props?.excludeLessThanYearStore,
      excludeInactiveStore: props?.excludeInactiveStore,
      selectedYear,
      roundOff,
    };
    if (checkFCAccess(profile)) {
      payload = { ...payload, intFSM: profile?.intFSM };
    }
    setLoading(true);
    const res = await service?.fetchYtdMonthlyWiseTotalSales(payload);
    const result = res?.result?.map((i) => {
      const displayCurrentYearName = getYearName("currentYear", selectedYear);
      const displayPriorYearName = getYearName("priorYear", selectedYear);
      return {
        ...i,
        [displayCurrentYearName]: i?.currentYear,
        [displayPriorYearName]: i?.priorYear,
      };
    });
    setData(result || []);
    setItemData(res?.summaryData)
    const removeTotal = result?.filter((i) => i?.month !== "Total");
    const currentYearMax = Math.max(...removeTotal.map((o) => o.currentYear));
    const priorYearMax = Math.max(...removeTotal.map((o) => o.priorYear));
    setMaxValue(Math.max(currentYearMax, priorYearMax) + 100);
    setLoading(false);
  };
  return (
    <>
      {loading ? (
        <DashboardLoader />
      ) : (
        <>
          <Spin spinning={excelLoading}>
            {data?.length > 0 &&
            data.filter((i) => i?.month !== "Total")?.length > 0 ? (
              <>
              <div className="total-system-sales">
                {/* <div className="d-flex justify-content-end p-2">
                    <img
                       src="/DownLoadExcel.svg"
                       alt="DownLoadExcel"
                       className="DownLoadExcel"
                       onClick={() => handleExportExcel()}
                     />
                </div> */}
                <div className="show-monthly-selection pb-3">
                  <div className="summary-table-container">
                    <SummarySalesTable
                      selectedType={"year"}
                      dataItem={itemData}
                      roundOff={roundOff}
                    />
                  </div>
                </div>
                  <ResponsiveContainer
                    width="100%"
                    aspect={props?.width < breakPointWidth ? 2 : 4}
                  >
                    <BarChart
                      data={data.filter((i) => i?.month !== "Total")}
                      margin={{ top: 5, right: 20, bottom: 5, left: 25 }}
                    >
                      <XAxis dataKey="month" />
                      <YAxis
                        label={{
                          value: "Amt ($)",
                          angle: 360,
                          position: "insideBottomRight",
                          offset: 10,
                        }}
                        // width={120}
                        allowDataOverflow={true}
                        tickFormatter={YAxisNumberFormat}
                        domain={[0, (dataMax) => parseFloat(maxValue).toFixed(2)]}
                      />
                      <Tooltip
                        content={
                          <CustomTooltipWithoutChg
                            roundOff={roundOff}
                            selectedYear={selectedYear}
                          />
                        }
                        labelStyle={{ color: "darkblue" }}
                      />
                      <Bar
                        dataKey="currentYear"
                        fill={Legends["currentYear"]}
                        barSize={props?.width < breakPointWidth ? 10 : 25}
                      />
                      <Bar
                        dataKey="priorYear"
                        fill={Legends["priorYear"]}
                        barSize={props?.width < breakPointWidth ? 10 : 25}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                  {props?.width < 991 ? (
                    <>
                      <div className="table-container legend-table">
                        <table key={props?.width}>
                          <thead>
                            <tr>
                              <th>Month</th>
                              <th>
                                {" "}
                                {SvgCurrentYear()}
                                {getYearName("currentYear", selectedYear)}
                                {ShowArrow(props?.ItemData?.currentArrow)}
                              </th>
                              <th>
                                {" "}
                                {SvgPreviousYear()}
                                {getYearName("priorYear", selectedYear)}
                                {ShowArrow(props?.ItemData?.priorArrow)}
                              </th>
                              <th>
                                {" "}
                                {SvgChg()}
                                Chg %
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.map((i) => {
                              return (
                                <tr>
                                  <td>{i?.month}</td>
                                  <td style={{color: i?.currentYear < i?.priorYear ?`red`:`inherit`}}>
                                    {CurrencyFormat(i?.currentYear, roundOff)}
                                  </td>
                                  <td style={{color: i?.priorYear < i?.currentYear ?`red`:`inherit`}}>
                                    {CurrencyFormat(i?.priorYear, roundOff)}
                                  </td>
                                  <td>{`${i?.["chg"]} %`}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="table-container legend-table">
                        <table>
                          <thead>
                            <tr>
                              <th></th>
                              {data?.map((i) => {
                                return (
                                  <>
                                    <th>{i?.month}</th>
                                  </>
                                );
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {SvgCurrentYear()}
                                {getYearName("currentYear", selectedYear)}
                                {ShowArrow(props?.ItemData?.currentArrow)}
                              </td>
                              {data?.map((i) => {
                                return (
                                  <>
                                    <td style={{color: i?.currentYear < i?.priorYear ?`red`:`inherit`}}>
                                    {CurrencyFormat(i?.currentYear, roundOff)}
                                  </td>
                                  </>
                                );
                              })}
                            </tr>
                            <tr>
                              <td>
                                {SvgPreviousYear()}
                                {getYearName("priorYear", selectedYear)}
                                {ShowArrow(props?.ItemData?.priorArrow)}
                              </td>
                              {data?.map((i) => {
                                return (
                                  <>
                                  <td style={{color: i?.priorYear < i?.currentYear ?`red`:`inherit`}}>
                                    {CurrencyFormat(i?.priorYear, roundOff)}
                                  </td>
                                  </>
                                );
                              })}
                            </tr>
                            <tr>
                              <td>
                                {SvgChg()}
                                Chg %
                              </td>
                              {data.map((i) => {
                                return (
                                  <>
                                    <td>{`${i?.["chg"]} %`}</td>
                                  </>
                                );
                              })}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="norecords-available">No records available</div>
              </>
            )}
          </Spin>
        </>
      )}
    </>
  );
};

export default MonthlyWiseSales;
