import HRDashboard from "../components/HRDashboard";

const HrRoutes = [
  {
    path: "hr",
    name: "HRDashboard",
    key: "HRDashboard",
    component: <HRDashboard />,
  },
];

export default HrRoutes;
