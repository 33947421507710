import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import { Route, Routes } from "react-router-dom";
import './EmbeddedLayout.css'
import { MainEmbeddedRoutes } from "../routes/MainEmbeddedRoutes";
const EmbeddedLayout = () => {
    console.log(`EmbeddedLayout`)
  return (
    <Layout className="embedded-layout">
      <Content
      >
        <Routes>
          {MainEmbeddedRoutes.map((route) => {
            return route.component ? (
              <Route
                path={route?.path}
                element={route?.component}
                key={route?.key}
              />
            ) : (
              <></>
            );
          })}
        </Routes>
      </Content>
    </Layout>
  );
};

export default EmbeddedLayout;
