import React from "react";
import { Button, Card, Row, Typography } from "antd";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateMenuSelection } from "../../redux/dashboard/dashboardSlice";
const { Title } = Typography;

const MarketingDashboard = () => {
  const dispatch = useDispatch();
  return (
    <Card
      title={
        <>
          <Button
            type="primary"
            className="btn-back"
            onClick={() => dispatch(updateMenuSelection("home"))}
          >
            <i className="fa fa-arrow-left" style={{ fontSize: "16px" }}></i>
          </Button>
          <span>{"Marketing Dashboard"}</span>
        </>
      }
    >
      <div className="parent-flex-container pt-3">
        <Card
          className="report_Card"
          headStyle={{ width: "100%" }}
          title={
            <Title
              style={{ color: "#044A2A !important", fontSize: "16px" }}
              level={5}
            >
              Loyalty
            </Title>
          }
          hoverable={true}
          bordered={true}
        >
          <Row type="flex" style={{ color: "#aaaaaa" }}>
            <Link to="/marketing/loyalty-incentive-promotions">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>Loyalty Incentive
                    Promotions
                  </div>
                </div>
              </div>
            </Link>
          </Row>
          <Row>
            <Link to="/marketing/stores-list-report">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>Store Tracking Report
                  </div>
                </div>
              </div>
            </Link>
          </Row>
          <Row>
            <Link to="/marketing/loyalty-report">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>Loyalty & Guest Count
                    Correlation
                  </div>
                </div>
              </div>
            </Link>
          </Row>
          <Row>
            <Link to="/marketing/redeem-rewards-report">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>Redeem Rewards Report
                  </div>
                </div>
              </div>
            </Link>
          </Row>
          <Row type="flex" style={{ color: "#aaaaaa" }}>
            <Link to="/marketing/rank-report">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>Ranking Report
                  </div>
                </div>
              </div>
            </Link>
          </Row>
        </Card>
      </div>
    </Card>
  );
};

export default MarketingDashboard;
