import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { OperationalService } from '../../services/operational/index.service';
import { checkFCAccess } from '../../utils';

const useFCDiscrepancy = () => {
  const { fcId = null } = useParams();
  const service = new OperationalService();
  const [selectedFC, setSelectedFC] = useState(null);
  const [storeData, setStoreData] = useState([]);
  const [loading,setLoading] =useState(true)
  const [selectedStore,setSelectedStore] =useState(null)
  const profile = useSelector((state) => state?.profile) || null;

  const [options, setOptions] = useState([]);
  const onChange = (value) => {
    setSelectedFC(value);
  };

  useEffect(() => {
    if (selectedFC) {
      handleSearch(selectedFC);
    }
  }, [selectedFC]);

  const handleStoreTracking=(store=null)=>{
    setSelectedStore(store)
  }

  const handleSearch = (id) => {
    let payload = {
      intFSM: id,
    };
    //api Data to Call
    fetchApi(payload)

  };

  useEffect(() => {
    fetchFranchises();
  }, []);

  const fetchApi = async (payload) => {
    setLoading(true);
    const res = await service?.fetchFCDiscrepancy(payload);
    let result = res?.FCDiscrepency;
    result.sort((a, b) => b.weightedScale - a.weightedScale);
    setStoreData(result);
    setLoading(false);
  };


  const fetchFranchises = async () => {
    const res = await service?.fetchCloverFC();
    setOptions(res?.FCNames || []);
    setSelectedFC(checkFCAccess(profile) ? profile?.intFSM : fcId ? fcId : "");
  };


  return {options,onChange,selectedFC,storeData,loading,handleStoreTracking,selectedStore}
}

export default useFCDiscrepancy 