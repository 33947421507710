import React, { useState } from "react";
import { Button, Card, Radio, Segmented } from "antd";
import TotalSytemSales from "../TotalSytemSales";
import { useNavigate } from "react-router-dom";
const TotalDayMonthSystemSales = () => {
  const navigate = useNavigate();

  const [mode, setMode] = useState("Month");
  return (
    <>
      <Card
        title={
          <>
            <Button
              type="primary"
              className="btn-back"
              onClick={() => navigate("/financial")}
            >
              <i className="fa fa-arrow-left" style={{ fontSize: "16px" }}></i>
            </Button>
            <span>{"Total System Sales"}</span>
          </>
        }
      >
        <>
          <div className="ranking-btn-group pb-2">
            <Segmented
              options={["Month", "Day"]}
              value={mode}
              onChange={(e) => setMode(e)}
            />
          </div>
          {mode === "Month" && (
            <TotalSytemSales
              type="month"
              skeleton={true}
              title={"Current Month Total System Sales"}
            />
          )}
          {mode === "Day" && (
            <TotalSytemSales
              type="day"
              skeleton={true}
              title={"Current Day Total System Sales"}
            />
          )}
        </>
      </Card>
    </>
  );
};

export default TotalDayMonthSystemSales;
