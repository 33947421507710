import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedMenu:'home'
};

export const dashboardSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    updateMenuSelection: (stateVal, action) => {
      stateVal.selectedMenu = action.payload;
      return stateVal;
    },
  },
});

export const { updateMenuSelection } =
  dashboardSlice.actions;
export default dashboardSlice.reducer;
