import { Button, Card, DatePicker, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { OperationalService } from "../../services/operational/index.service";
import { generateDownLoadPath } from "../../utils";
import { PieLegends } from "../../utils/legend";
import { DashboardLoader } from "../../utils/Loading";
import { CustomTooltipForMixes } from "../../utils/Tooltip";

const MixesByCases = (props) => {
  const navigate = useNavigate();

  const service = new OperationalService();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [datePickerVal, setDatePickerVal] = useState(moment());
  const [width, setWindowWidth] = useState(0);
  const [excelLoading, setExcelLoading] = useState(false);

  const breakPointWidth = 767;
  useEffect(() => {
    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };
  useEffect(() => {
    fetchApi();
  }, [datePickerVal]);

  const handleExportExcel = async () => {
    setExcelLoading(true);
    // let data;
    const date = datePickerVal ? moment(datePickerVal) : datePickerVal;
    const payload = {
      year: date.year(),
      month: date.month() + 1,
    };
    // //excel api
    const data = await service?.fetchCasePurchasedExport(payload);
    const url = generateDownLoadPath(data?.path, data?.fileName);
    setExcelLoading(false);
    window.open(url, "_blank");
  };

  const fetchApi = async () => {
    const date = datePickerVal ? moment(datePickerVal) : datePickerVal;
    if (datePickerVal) {
      const payload = {
        year: date.year(),
        month: date.month() + 1,
      };
      setLoading(true);
      const res = await service?.fetchMixesByCasesPurchased(payload);
      setData(res?.caseMixes || []);
      setLoading(false);
    } else {
      setData([]);
    }
  };

  const onChange = (dateval, dateString) => {
    // console.log(dateval, dateString);
    const date = dateval ? moment(dateval) : dateval;
    setDatePickerVal(date);
  };

  const DefaultSummaryComponent = () => {
    return (
      <>
        {width < breakPointWidth ? (
          <>
            {" "}
            <div className="table-container legend-table">
              <table>
                <thead>
                  <tr>
                    <th>{"Item Name"}</th>
                    <th>{"% Cases Purchased"}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((i) => {
                    return (
                      <>
                        <tr>
                          <td>{i?.ItemName}</td>
                          <td>{i?.YTDMix} %</td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <>
            <div className="table-container legend-table">
              <table>
                <thead>
                  <tr>
                    <th>{"Item"}</th>
                    {data.map((i) => {
                      return (
                        <>
                          <th>{i?.ItemName}</th>
                        </>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{"% Cases Purchased"}</td>
                    {data.map((i) => {
                      return (
                        <>
                          <td>{i?.YTDMix} %</td>
                        </>
                      );
                    })}
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}
      </>
    );
  };

  const CustomizedLabel = (props) => {
    let shortname = props;
    if (shortname?.length > 10) {
      shortname = props.substring(0, 10) + " ...";
    }
    return shortname;
  };

  return (
    <>
      <Spin spinning={excelLoading}>
        <Card
          title={
            <>
              <Button
                type="primary"
                className="btn-back"
                onClick={() => navigate("/supply-chain")}
              >
                <i
                  className="fa fa-arrow-left"
                  style={{ fontSize: "16px" }}
                ></i>
              </Button>
              <span>{`Top 10 Mixes by Cases Purchased`}</span>
            </>
          }
        >
          {loading ? (
            <DashboardLoader />
          ) : (
            <>
              <div className="d-flex justify-content-end mb-2">
                <DatePicker
                  onChange={onChange}
                  picker="month"
                  value={datePickerVal}
                  defaultValue={moment()}
                />
              {data?.length > 0 && (
                <>
                  <div className="d-flex justify-content-end">
                    <img
                      src="/DownLoadExcel.svg"
                      alt="DownLoadExcel"
                      className="DownLoadExcel"
                      onClick={() => handleExportExcel()}
                    />
                  </div>
                </>
              )}
              </div>
              {data?.length > 0 ? (
                <>
                  <div className="total-system-sales">
                    <ResponsiveContainer
                      width="100%"
                      aspect={width < breakPointWidth ? 2 : 4}
                    >
                      <BarChart
                        data={data}
                        margin={{ top: 5, right: 20, bottom: 50, left: 20 }}
                      >
                        {/* <XAxis dataKey="ItemName" tickFormatter={CustomizedLabel}  interval={0}       allowDataOverflow={false}/> */}
                        <XAxis
                          dataKey="ItemName"
                          interval={0}
                          angle={-30}
                          allowDataOverflow={false}
                        />
                        {/* <XAxis dataKey={"ItemName"} label={<CustomizedLabel />} /> */}
                        <YAxis
                          label={{
                            value: "% of Cases",
                            angle: 360,
                            position: "insideBottomRight",
                            offset: 10,
                          }}
                          unit={"%"}
                          allowDataOverflow={true}
                        />
                        <Tooltip
                          content={<CustomTooltipForMixes />}
                          labelStyle={{ color: "darkblue" }}
                        />
                        <Bar
                          dataKey="YTDMix"
                          fill={PieLegends["casesMixValue"]}
                          barSize={width < breakPointWidth ? 10 : 20}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  <DefaultSummaryComponent />
                  </div>
                </>
              ) : (
                <>
                  <div className="norecords-available">
                    No records available
                  </div>
                </>
              )}
            </>
          )}
        </Card>
      </Spin>
    </>
  );
};

export default MixesByCases;
