import React, { useEffect, useState } from "react";
import { Button, Card, InputNumber, Select, Table, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { OperationalService } from "../../services/operational/index.service";
import { useSelector } from "react-redux";
import { checkFCAccess } from "../../utils";

const FCList = (props) => {
  const navigate = useNavigate();
  const profile = useSelector((state) => state?.profile) || null;
  const service = new OperationalService();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(15);

  const columns = [
    {
      title: "Sr.No",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => (page - 1) * paginationSize + index + 1,
    },
    {
      title: "FC Name",
      dataIndex: "FSMName",
      key: "FSMName",
      sorter: (a, b) => a?.FSMName?.localeCompare(b?.FSMName),
    },
    {
      title: "Store Count",
      dataIndex: "storeCount",
      key: "storeCount",
      sorter: (a, b) => a.storeCount - b.storeCount,
    },
    {
      title: "Franchisee Count",
      dataIndex: "franchiseeCount",
      key: "franchiseeCount",
      sorter: (a, b) => a.franchiseeCount - b.franchiseeCount,
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      render: (data, props) => {
        return (
          <td
            className="store-link"
            onClick={() =>
              navigate(`/operational/fc-track-report/${props?.intFSM}`)
            }
          >
            {"View Details"}
          </td>
        );
      },
    },
  ];

  useEffect(() => {
    if (checkFCAccess(profile)) {
      navigate(`/operational/fc-track-report/${profile?.intFSM}`);
    } else {
      fetchApi();
    }
  }, []);

  const fetchApi = async () => {
    setLoading(true);
    const result = await service?.fetchFCList();
    setData(result?.FCNames);
    setLoading(false);
  };

  return (
    <>
      <Card
        title={
          <>
            <Button
              type="primary"
              className="btn-back"
              onClick={() => navigate(`/${props?.from}`)}
            >
              <i className="fa fa-arrow-left" style={{ fontSize: "16px" }}></i>
            </Button>
            <span>{`FC List`}</span>
          </>
        }
      >
        <div className="report-grid">
          <div className="rank-grid-item">
            <Table
              dataSource={data}
              pagination={{
                pageSize: "15",
                current: page,
                showQuickJumper: false,
                showSizeChanger: false,
                onChange(page, pageSize) {
                  setPage(page);
                },
              }}
              columns={columns}
              loading={loading}
              bordered
              scrollToFirstRowOnChange={true}
              size="middle"
            />
          </div>
        </div>
      </Card>
    </>
  );
};

export default FCList;
