import React, { useEffect, useState } from "react";
import { Card, Spin } from "antd";
import { Cell, Pie, PieChart, Sector, Tooltip } from "recharts";
import moment from "moment";
import { BREAKPOINTWIDTH, CurrencyFormat } from "../../../../utils";
import { FinancialService } from "../../../../services/financial/index.service";
import { PieLegends } from "../../../../utils/legend";
import { useSelector } from "react-redux";
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = (props, mode = null, platform) => {
  const {
    cx,
    cy,
    midAngle,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    percent,
    value,
    name,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 20) * cos;
  const my = cy + (outerRadius + 20) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${name}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`${CurrencyFormat(value)}`}
      </text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={35}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};
const YTDStoreDigitalSales = (props) => {
  const breakPointWidth = BREAKPOINTWIDTH;
  const service = new FinancialService();
  const { width } = props;
  const [digitalLoading, setDigitalLoading] = useState(true);
  const [digitalData, setDigitalData] = useState([]);
  const { selectedYear } = useSelector(
    (state) => state?.customSelection
  );

  useEffect(() => {
    fetchStoreDigitalSales();
  }, [selectedYear]);

  const fetchStoreDigitalSales = async () => {
    const payload = { ritasId: props?.storeId, selectedYear };
    setDigitalLoading(true);
    const digitalSalesData = await service.fetchYTDDigitalSalesForStore(
      payload
    );
    let res=[]
    if(digitalSalesData){
      res = digitalSalesData?.DigitalSales?.map((i) => {
        return { ...i, value: parseFloat(i?.value) };
      })?.filter((i) => i?.value > 0);
    }
    setDigitalData(res);
    setDigitalLoading(false);
  };

  const DigitalPercentage = () => {
    return (
      <PieChart
        width={width < breakPointWidth ? 380 : 510}
        height={width < breakPointWidth ? 230 : 380}
      >
        <Pie
          data={digitalData}
          cx="50%"
          cy="50%"
          minAngle={width < breakPointWidth ? 40 : 30}
          outerRadius={width < breakPointWidth ? 40 : 80}
          labelLine={false}
          label={(props) => renderCustomizedLabel(props, "percentage")}
        >
          {digitalData?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={PieLegends[entry?.name]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    );
  };

  return (
    <>
      <Card>
        <div className="report-grid">
          <div className="rank-grid-item">
          <div className="table-div d-flex flex-column align-items-start">
          <div className="total-system-sales-header">
            <span className="card-heading-overview-item">{props?.title}</span>
            </div>
          </div>
              <Spin spinning={digitalLoading}>
           
            {digitalData?.length > 0 ? <div className="d-flex justify-content-center bg-white"><div className="percentage-item">
                  <DigitalPercentage />
                </div> </div>:<div className="norecords-available">
                      No records available
                    </div>}
                
              </Spin>
          </div>
        </div>
      </Card>
    </>
  );
};

export default YTDStoreDigitalSales;
