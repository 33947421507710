import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CoolnetService } from "../../../services/coolnet/index.service";

const useStoreDigitalSales = (props) => {
  const { selectedYear } = useSelector(
    (state) => state?.customSelection
  );
  const service = new CoolnetService();
  const [digitalLoading, setDigitalLoading] = useState(true);
  const [digitalData, setDigitalData] = useState([]);
  useEffect(() => {
    fetchStoreDigitalSales();
  }, [selectedYear]);

  const fetchStoreDigitalSales = async () => {
    const payload = { ritasId: props?.storeId, selectedYear };
    setDigitalLoading(true);
    const digitalSalesData = await service.fetchDigitalSalesForStore(payload);
    const res=digitalSalesData?.DigitalSales?.map(i=> {return {...i,value:parseFloat(i?.value)}})?.filter(i=>i?.value > 0)
    console.log(res)
    setDigitalData(res);
    setDigitalLoading(false);
  };

  return { digitalLoading, digitalData };
};

export default useStoreDigitalSales;
