import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  Input,
  InputNumber,
  Select,
  Spin,
  Table,
} from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { MarketingService } from "../../services/marketing/index.service";
import { OperationalService } from "../../services/operational/index.service";
import moment from "moment";
import { checkFCAccess, generateDownLoadPath } from "../../utils";

const YTDTransactionsByShop = () => {
  const navigate = useNavigate();
  const profile = useSelector((state) => state?.profile) || null;
  const service = new MarketingService();
  const operationalService = new OperationalService();
  const [loading, setLoading] = useState(true);
  const [searchTextState, setSearchTextState] = useState("");
  const [data, setData] = useState([]);
  const [value, setValue] = useState("");
  const [ritasIdValue, setRitasIdValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [excelLoading, setExcelLoading] = useState(false);
  const [promotionalDates, setPromotionalDates] = useState([]);
  const [selecteDateValue, setSelecteDateValue] = useState("");
  const selectedYear = moment().year();
  const onChange = (value) => {
    setValue(value);
    setRitasIdValue(null);
    setSearchTextState("");
    handleSearch(value);
  };
  const onChangePromotionalDate = (value) => {
    setSelecteDateValue(value);
  };

  const onSearch = (value) => {
    // console.log("search:", value);
  };

  const columns = [
    {
      title: "Rank",
      dataIndex: "Rank",
      key: "Rank",
      sorter: (a, b) => a.Rank - b.Rank,
    },
    {
      title: "Store #",
      dataIndex: "RitasID",
      key: "RitasID",
      sorter: (a, b) => a.RitasID - b.RitasID,
    },
    {
      title: "Store Name",
      dataIndex: "storeName",
      key: "storeName",
    },
    {
      title: "FC Name",
      dataIndex: "FSMName",
      key: "FSMName",
    },
    {
      title: "State",
      dataIndex: "State",
      key: "State",
      sorter: (a, b) => a?.State?.localeCompare(b?.State),
    },
    {
      title: "Status",
      dataIndex: "StoreStatus",
      key: "StoreStatus",
      sorter: (a, b) => a?.StoreStatus?.localeCompare(b?.StoreStatus),
    },
    {
      title: "Loyalty Transactions",
      dataIndex: "loyaltyTransactions",
      key: "loyaltyTransactions",
      sorter: (a, b) => a.loyaltyTransactions - b.loyaltyTransactions,
    },
    {
      title: "Retail Transactions",
      dataIndex: "retailTransactions",
      key: "retailTransactions",
      sorter: (a, b) => a.retailTransactions - b.retailTransactions,
    },
    {
      title: "Incentive Score",
      dataIndex: "IncentiveScore",
      key: "IncentiveScore",
      render: (data, props) => {
        return <td>{props?.IncentiveScore?.toFixed(2)}</td>;
      },
      sorter: (a, b) => a.IncentiveScore - b.IncentiveScore,
    },
  ];

  useEffect(() => {
    fetchPromotionalDates();
    if (profile?.isAdmin) {
      fetchFranchises();
    }
  }, []);
  useEffect(() => {
    if(selecteDateValue){
      handleSearch();
    }
  }, [selecteDateValue]);

  const fetchPromotionalDates = async () => {
    const res = await service?.fetchPromotionalDates();
    const data = res?.PromotionDates.map((i) => {
      return {
        ...i,
        value: i?.intPromotionId,
        label: `${moment(i?.displayStartDate).format("MMMM DD")} - ${moment(
          i?.displayEndDate
        ).format("MMMM DD")}`,
      };
    });
    const selectedPromotionDate = data?.find(
      (i) => i?.isCurrentPromotion === true
    );
    setSelecteDateValue(selectedPromotionDate?.intPromotionId);
    setPromotionalDates(data || []);
  };
  const handleExportExcel = async () => {
    setExcelLoading(true);
    const selectedPromiotionalDate=promotionalDates.find(i=>i?.intPromotionId===selecteDateValue)
    let payload = {
      selectedYear: selectedYear,
      intRitasStoreID: ritasIdValue ? ritasIdValue : undefined,
      intFSM: value ? value : undefined,
      startDate:selectedPromiotionalDate?.displayStartDate,
      endDate:selectedPromiotionalDate?.displayEndDate
    };
    if (checkFCAccess(profile)) {
      payload = { ...payload, intFSM: profile?.intFSM };
    }

    const data = await service?.fetchYtdTrancationsByShopExcel(payload);
    const url = generateDownLoadPath(data?.path, data?.fileName);
    setExcelLoading(false);
    window.open(url, "_blank");
  };

  const fetchFranchises = async () => {
    const res = await operationalService?.fetchCloverFC();
    setOptions(res?.FCNames || []);
  };

  const fetchApi = async (payload, searchState = false) => {
    setLoading(true);
    const selectedPromiotionalDate=promotionalDates.find(i=>i?.intPromotionId===selecteDateValue)
    const req={
      ...payload,startDate:selectedPromiotionalDate?.displayStartDate,endDate:selectedPromiotionalDate?.displayEndDate
    }
    const res = await service?.fetchYtdTrancationsByShop(req);
    let result = res?.transactionsByShop;
    if (searchState) {
      result = result.filter((i) =>
        i?.State.toLowerCase().includes(searchTextState.toLowerCase())
      );
    }
    setData(result || []);
    setLoading(false);
  };

  const handleClear = () => {
    setRitasIdValue(null);
    setValue("");
    setSearchTextState("");
    let payload = { selectedYear };
    if (checkFCAccess(profile)) {
      payload = { ...payload, intFSM: profile?.intFSM };
    }
    fetchApi(payload);
  };
  const handleFilterWithState = (e) => {
    setSearchTextState(e);
  };
  const handleSearch = (intFSMValue = null) => {
    if (intFSMValue) {
      let payload = {
        intFSM: intFSMValue,
        selectedYear,
      };
      fetchApi(payload);
    } else {
      let payload = {
        ritasId: ritasIdValue ? ritasIdValue : undefined,
        intFSM: value ? value : undefined,
        selectedYear,
      };
      if (checkFCAccess(profile)) {
        payload = { ...payload, intFSM: profile?.intFSM, selectedYear };
      }
      if (searchTextState && searchTextState !== "") {
        if (ritasIdValue || value) {
          fetchApi(payload, true);
        } else {
          const filteredData = data.filter((i) =>
            i?.State.toLowerCase().includes(searchTextState.toLowerCase())
          );
          setData(filteredData);
        }
      } else {
        fetchApi(payload);
      }
    }
  };
  const handleStateSearch = () => {
    if (searchTextState && searchTextState !== "") {
      const filteredData = data.filter((i) =>
        i?.State.toLowerCase().includes(searchTextState.toLowerCase())
      );
      setData(filteredData);
    } else {
      handleSearch();
    }
  };

  const handleChange = (val) => {
    setRitasIdValue(val);
  };

  return (
    <>
      <Spin spinning={excelLoading}>
        <Card
          title={
            <>
              <Button
                type="primary"
                className="btn-back"
                onClick={() => navigate("/marketing")}
              >
                <i
                  className="fa fa-arrow-left"
                  style={{ fontSize: "16px" }}
                ></i>
              </Button>
              <span>{`Loyalty Incentive Promotions`}</span>
            </>
          }
        >
          <div style={{ marginTop: "20px" }}></div>
          <div className="report-grid ">
            <div className="rank-grid-item">
              <div className="table-div">
                <div className="ranking-btn-group"></div>
                <div className="clover-retail-search">
                  <div className="clover-search-sub">
                    <Select
                      style={{ width: "auto" }}
                      optionFilterProp="children"
                      value={selecteDateValue || { value: 0, label: "Please Select Promotional Date" }}
                      onChange={onChangePromotionalDate}
                      options={promotionalDates}
                    />
                    <InputNumber
                      name="RitasId"
                      style={{ width: "auto" }}
                      id="RitasId"
                      placeholder="Store Number"
                      onChange={handleChange}
                      value={ritasIdValue}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          handleSearch();
                        }
                      }}
                    />
                    <Input
                      name="StateName"
                      autoComplete="off"
                      style={{ width: "auto" }}
                      placeholder="State Name"
                      onChange={(e) => handleFilterWithState(e.target.value)}
                      value={searchTextState}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          handleStateSearch();
                        }
                      }}
                    />
                    {profile?.isAdmin && (
                      <Select
                        showSearch
                        placeholder="Select a FC"
                        style={{ width: "auto" }}
                        optionFilterProp="children"
                        value={value || { value: 0, label: "Please Select FC" }}
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={options}
                      />
                    )}
                  </div>
                  <div className="clover-search-sub">
                    <div>
                      <Button
                        style={{ marginRight: "8px" }}
                        onClick={() => handleClear()}
                      >
                        <i className="fas fa-eraser"></i>
                      </Button>
                      <Button onClick={() => handleSearch()}>
                        <i className="fas fa-search"></i>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              {data?.length > 0 && (
                <div className="d-flex justify-content-end py-2">
                  <img
                    src="/DownLoadExcel.svg"
                    alt="DownLoadExcel"
                    className="DownLoadExcel"
                    onClick={() => handleExportExcel()}
                  />
                </div>
              )}
              <Table
                dataSource={data}
                columns={columns}
                pagination={{
                  pageSize: "10",
                  showQuickJumper: false,
                  showSizeChanger: false,
                }}
                loading={loading}
                bordered={false}
                scrollToFirstRowOnChange={true}
                size="middle"
              />
            </div>
          </div>
        </Card>
      </Spin>
    </>
  );
};

export default YTDTransactionsByShop;
