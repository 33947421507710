import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { FinancialService } from "../../../../services/financial/index.service";
import { getYearNameOnly } from "../../../../utils";
import { Legends } from "../../../../utils/legend";
import { DashboardLoader } from "../../../../utils/Loading";
import { SvgCurrentYear, SvgPreviousYear } from "../../../../utils/svgpath";
import { GuestTooltipWithChg } from "../../../../utils/Tooltip";

const MonthlyWiseGuestCount = (props) => {
  const service = new FinancialService();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [maxValue, setMaxValue] = useState(0);
  const { selectedYear } = useSelector((state) => state?.dashboard);
  useEffect(() => {
    fetchApi();
  }, [selectedYear]);
  const breakPointWidth = 767;

  const fetchApi = async () => {
    setLoading(true);
    const payload={selectedYear}
    const res = await service?.fetchYtdTotalGuestCountMonthWise(payload);
    const result = res?.map((i) => {
      const displayCurrentYearName = getYearNameOnly("currentYear", selectedYear);
      const displayPriorYearName = getYearNameOnly("priorYear", selectedYear);
      return {
        ...i,
        [displayCurrentYearName]: i?.currentYear,
        [displayPriorYearName]: i?.priorYear,
      };
    });
    setData(result || []);
    const removeTotal=result?.filter((i) => i?.month !== "Total")
    const currentYearMax = Math.max(...removeTotal.map((o) => o.currentYear || 0));
    const priorYearMax = Math.max(...removeTotal.map((o) => o.priorYear || 0));
    setMaxValue(Math.max(currentYearMax, priorYearMax) + 100);
    setLoading(false);
  };
  return (
    <>
      {loading ? (
        <DashboardLoader />
      ) : (
        <>
          {data?.length > 0 && data?.filter((i) => i?.month !== "Total")?.length > 0 ? (
            <>
              <div className="total-system-sales">
                  <ResponsiveContainer
                    width="100%"
                    aspect={props?.width < breakPointWidth ? 2 : 4}
                  >
                    <BarChart
                      data={data?.filter((i) => i?.month !== "Total")}
                      margin={{
                        top: 5,
                        right: props?.width < breakPointWidth ? 5 : 20,
                        bottom: 5,
                        left: props?.width < breakPointWidth ? 5 : 20,
                      }}
                    >
                      <XAxis dataKey="month" />
                      <YAxis
                        label={{
                          value: "Guest(s)",
                          angle: 360,
                          position: "insideBottomRight",
                          offset: 10,
                        }}
                        // width={120}
                        allowDataOverflow={true}
                        domain={[0, (dataMax) => parseInt(maxValue).toFixed(2)]}
                      />
                      <Tooltip
                        content={<GuestTooltipWithChg selectedYear={selectedYear} />}
                        labelStyle={{ color: "darkblue" }}
                      />
                      <Bar
                        dataKey="currentYear"
                        fill={Legends["currentYear"]}
                        barSize={props?.width < breakPointWidth ? 10 : 25}
                      />
                      <Bar
                        dataKey="priorYear"
                        fill={Legends["priorYear"]}
                        barSize={props?.width < breakPointWidth ? 10 : 25}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                  {props?.width < breakPointWidth ? (
                    <>
                      <div className="table-container">
                        <table>
                          <thead>
                            <tr>
                              <th>Month</th>
                              <th>
                                {SvgCurrentYear()}
                                {getYearNameOnly("currentYear",selectedYear)}
                              </th>
                              <th>
                                {SvgPreviousYear()}
                                {getYearNameOnly("priorYear",selectedYear)}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((i) => {
                              return (
                                <tr>
                                  <td>{i?.month}</td>
                                  <td>{i?.currentYear}</td>
                                  <td>{i?.priorYear}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="table-container">
                        <table>
                          <thead>
                            <tr>
                              <th></th>
                              {data.map((i) => {
                                return (
                                  <>
                                    <td>{i?.month}</td>
                                  </>
                                );
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {SvgCurrentYear()}
                                {getYearNameOnly("currentYear",selectedYear)}
                              </td>
                              {data.map((i) => {
                                return (
                                  <>
                                    <td>{i?.currentYear}</td>
                                  </>
                                );
                              })}
                            </tr>
                            <tr>
                              <td>
                                {SvgPreviousYear()}
                                {getYearNameOnly("priorYear",selectedYear)}
                              </td>
                              {data.map((i) => {
                                return (
                                  <>
                                    <td>{i?.priorYear}</td>
                                  </>
                                );
                              })}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
              </div>
            </>
          ) : (
            <>
              <div className="norecords-available">No records available</div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default MonthlyWiseGuestCount;
