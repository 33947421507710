import React, { useState } from "react";
import { Button, Card, Segmented, Spin, Switch } from "antd";
import FCSales from "./FCSales";
import FCGuests from "./FCGuests";
import { useNavigate } from "react-router-dom";
import { FinancialService } from "../../../services/financial/index.service";
import { generateDownLoadPath } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { updateRoundOffSelection } from "../../../redux/dashboard/customSelectionSlice";

const FCRollup = () => {
  const service = new FinancialService();
  const [mode, setMode] = useState("Sales");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [excelLoading, setExcelLoading] = useState(false);
  const { roundOff } = useSelector((state) => state?.customSelection);

  const handleExportExcel = async () => {
    setExcelLoading(true);
    const payload = { roundOff };
    const data = await service?.getFCSalesExport(payload);
    // const url = `${process.env.REACT_APP_API_URL}/${test?.path}${test?.fileName}`
    const url = generateDownLoadPath(data?.path, data?.fileName);

    setExcelLoading(false);
    window.open(url, "_blank");
  };

  const handleRoundOffChange = () => {
    dispatch(updateRoundOffSelection(!roundOff));
  };

  return (
    <>
      <Spin spinning={excelLoading}>
        <Card
          title={
            <>
              <Button
                type="primary"
                className="btn-back"
                onClick={() => navigate(-1)}
              >
                <i
                  className="fa fa-arrow-left"
                  style={{ fontSize: "16px" }}
                ></i>
              </Button>
              <span>{"FC Rollup"}</span>
            </>
          }
        >
          <>
            <div className="table-div">
              <div
                className="d-flex justify-content-between align-items-center py-2 "
                style={{ width: "100%" }}
              >
                <div>
                  <Segmented
                    options={["Sales", "GC"]}
                    value={mode}
                    onChange={(e) => setMode(e)}
                  />
                </div>
                <div className="d-flex align-items-center">
                  <div className="px-1">
                    <label className="px-1">Enable Round Off</label>
                    <Switch
                      title="RoundOff"
                      defaultChecked
                      checked={roundOff}
                      onChange={handleRoundOffChange}
                    />
                  </div>
                  <img
                    src="/DownLoadExcel.svg"
                    alt="DownLoadExcel"
                    className="DownLoadExcel"
                    onClick={() => handleExportExcel()}
                  />
                </div>
              </div>
            </div>
            {mode === "Sales" && <FCSales />}
            {mode === "GC" && <FCGuests />}
          </>
        </Card>
      </Spin>
    </>
  );
};

export default FCRollup;
