import { Button, Card, Table } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import useFeedBackDetails from "./FeedBackDetails.hook";

const FeedBackDetails = () => {
  const { data, loading, store, handlePage, page, columns } =
    useFeedBackDetails();
  const navigate = useNavigate();

  return (
    <>
      <Card
        title={
          <>
            <Button
              type="primary"
              className="btn-back"
              onClick={() =>
                navigate(-1)
                // navigate(`/operational/store-track-report/${store}`)
              }
            >
              <i className="fa fa-arrow-left" style={{ fontSize: "16px" }}></i>
            </Button>
            <span>{`Store FeedBacks`}</span>
          </>
        }
      >
        <div className="report-grid">
          <div className="rank-grid-item">
            <div className="table-div">{`Store - ${store}`}</div>
            <Table
              dataSource={data}
              pagination={{
                pageSize: "10",
                current: page,
                showQuickJumper: false,
                showSizeChanger: false,
                onChange(page, pageSize) {
                  handlePage(page);
                },
              }}
              columns={columns}
              loading={loading}
              bordered
              scrollToFirstRowOnChange={true}
              size="middle"
            />
          </div>
        </div>
      </Card>
    </>
  );
};

export default FeedBackDetails;
