import React from "react";
import { Button, Card, Row } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "antd";
import { updateMenuSelection } from "../../redux/dashboard/dashboardSlice";
const { Title } = Typography;

const SupplyChainDashboard = () => {
  const profileData = useSelector((state) => state?.profile);
  const dispatch = useDispatch();
  return (
    <Card
      title={
        <>
          <Button
            type="primary"
            className="btn-back"
            onClick={() => dispatch(updateMenuSelection("home"))}
          >
            <i className="fa fa-arrow-left" style={{ fontSize: "16px" }}></i>
          </Button>
          <span>{"Supply Chain & Research And Development"}</span>
        </>
      }
    >
      <div className="parent-flex-container pt-3">
        <Card
          className="report_Card"
          headStyle={{ width: "100%" }}
          title={
            <Title
              style={{ color: "#044A2A !important", fontSize: "16px" }}
              level={5}
            >
              Supply Chain & Research And Development
            </Title>
          }
          hoverable={true}
          bordered={true}
        >
          {profileData?.isAdmin && (
            <>
              <Row>
                <Link to="/supply-chain/mixes-cases">
                  <div className="report-content-text">
                    <div className="report-text-wrap">
                      <div className="report-sub-heading">
                        <span className="fas fa-poll"></span>Mixes by Cases
                        Purchased
                      </div>
                    </div>
                  </div>
                </Link>
              </Row>
              <Row>
                <Link to="/supply-chain/mixes-yield-cases">
                  <div className="report-content-text">
                    <div className="report-text-wrap">
                      <div className="report-sub-heading">
                        <span className="fas fa-poll"></span>Mixes by Yield of
                        Cases Purchased
                      </div>
                    </div>
                  </div>
                </Link>
              </Row>
              <Row>
                <Link to="/supply-chain/ytd-mixes-sales">
                  <div className="report-content-text">
                    <div className="report-text-wrap">
                      <div className="report-sub-heading">
                        <span className="fas fa-poll"></span>YTD Mixes Sales
                      </div>
                    </div>
                  </div>
                </Link>
              </Row>
              <Row>
                <Link to="/supply-chain/monthview-mixes-sales">
                  <div className="report-content-text">
                    <div className="report-text-wrap">
                      <div className="report-sub-heading">
                        <span className="fas fa-poll"></span>Month View Mixes
                        Sales
                      </div>
                    </div>
                  </div>
                </Link>
              </Row>
            </>
          )}
        </Card>
      </div>
    </Card>
  );
};

export default SupplyChainDashboard;
