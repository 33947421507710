import { Card, Spin, Switch } from "antd";
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";
import useConsolidatedSystemSales from "./ConsolidatedSystemSales.hook";

import moment from "moment";
import {
  BREAKPOINTWIDTH,
  CurrencyFormat,
  NumberFormat,
  ShowArrow,
  YAxisNumberFormat,
} from "../../../../utils";
import QuarterPicker from "../../../QuarterPicker";
import MonthPicker from "../../../MonthPicker";
import WeekPicker from "../../../WeekPicker";
import DayPicker from "../../../DayPicker";
import { DashboardLoader } from "../../../../utils/Loading";
import { CustomTooltipForSales } from "../../../../utils/Tooltip";
import { Legends } from "../../../../utils/legend";
import SummarySalesData from "./SummarySalesData";
import { SvgCurrentYear, SvgPreviousYear } from "../../../../utils/svgpath";
import MonthlyWiseSales from "../../Sales/YTDSales/MonthlyWiseSales";
import WeekWiseSales from "../../Sales/YTDSales/WeekWiseSales";
import QuartelyWiseSales from "../../Sales/YTDSales/QuartelyWiseSales";
const ConsolidatedSystemSales = (props) => {
  const breakPointWidth = BREAKPOINTWIDTH;
  const { width, selectedView } = props;
  const {
    loading,
    data,
    maxValue,
    barData,
    selectedYear,
    roundOff,
    customSelection,
    selectedMonth,
    selectedQuarter,
    selectedWeek,
    excludeLessThanYearStore,
    handleExportExcel,
    excelLoading,
    handleToggle,
    handleRoundOffChange,
    handleCompletedYearToggle,
    excludeInactiveStore,
    selectedCompleteYear,
  } = useConsolidatedSystemSales(props);
  let selctedViewValue = selectedView;
  let selectedDayValue = customSelection?.selectedDay;
  if (selectedView === "Weekly" && selectedWeek === -1) {
    selctedViewValue = "Daily";
    selectedDayValue = `${moment().format("MM/DD")}/${selectedYear}`;
  }
  let barSize = 35;
  if (selectedView === "Quarterly") {
    barSize = 30;
  } else if (selectedView === "Monthly") {
    barSize = 17;
  } else if (selectedView === "Weekly") {
    barSize = 5;
  }
  const DefaultSummaryComponent = () => {
    return (
      <>
        {width < breakPointWidth ? (
          <>
            <div className="table-container legend-table">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>{"Amount"}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {SvgCurrentYear()}
                      {barData?.[0]?.["displayName"]}
                      {ShowArrow(data?.ItemData?.currentArrow)}
                    </td>
                    <td>
                      {CurrencyFormat(barData?.[0]?.["Amount"], roundOff)}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {SvgPreviousYear()}
                      {barData?.[1]?.["displayName"]}
                      {ShowArrow(data?.ItemData?.priorArrow)}
                    </td>
                    <td>
                      {CurrencyFormat(barData?.[1]?.["Amount"], roundOff)}
                    </td>
                  </tr>
                  <tr>
                    <td>{"% Chg"}</td>
                    <td>{`${data?.chg} %`}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <>
            <div className="table-container legend-table">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      {SvgCurrentYear()}
                      {barData?.[0]?.["displayName"]}
                      {ShowArrow(data?.ItemData?.currentArrow)}
                    </th>
                    <th>
                      {SvgPreviousYear()}
                      {barData?.[1]?.["displayName"]}
                      {ShowArrow(data?.ItemData?.priorArrow)}
                    </th>
                    <th>% Chg</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Amount</td>
                    <td>
                      {CurrencyFormat(barData?.[0]?.["Amount"], roundOff)}
                    </td>
                    <td>
                      {CurrencyFormat(barData?.[1]?.["Amount"], roundOff)}
                    </td>
                    <td>{`${data?.chg} %`}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}
      </>
    );
  };
  return (
    <Spin spinning={excelLoading}>

      <Card>
      <div className="d-flex justify-content-end align-items-center py-1">
              <img
                src="/DownLoadExcel.svg"
                alt="DownLoadExcel"
                className="DownLoadExcel"
                onClick={() => handleExportExcel()}
              />
            </div>
        <div className="total-system-sales p-1">
          <div className="total-system-sales-header">
            <div>
              <b className="comp-sales">
                {`Comp Sales (Excluding New Stores & Transfer Stores < 1 year) : `}
                <Switch
                  checked={excludeLessThanYearStore}
                  onChange={handleToggle}
                  disabled={loading}
                />
              </b>
            </div>
            <div className="d-flex flex-column justify-self-end align-items-end">
              <div className="py-1">
                <label className="px-1">Enable Round Off</label>
                <Switch
                  title="RoundOff"
                  defaultChecked
                  checked={roundOff}
                  onChange={handleRoundOffChange}
                />
              </div>
              {selectedView === "Yearly" && selectedYear=== moment().year() && (
                <div className="py-1">
                  <label className="px-1">
                    Enable Complete Year Comparison
                  </label>
                  <Switch
                    title="Enable Complete Year Comparison"
                    defaultChecked
                    checked={selectedCompleteYear}
                    onChange={handleCompletedYearToggle}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={selectedView === "Yearly" ? "" : "tab"}>
            {selectedView === "Quarterly" && (
              <QuarterPicker disabled={loading} />
            )}
            {selectedView === "Monthly" && <MonthPicker disabled={loading} />}
            {selectedView === "Weekly" && <WeekPicker disabled={loading} />}
            {selectedView === "Daily" && <DayPicker disabled={loading} />}
          </div>
          {loading ? (
            <DashboardLoader />
          ) : (
            <>
              
              <div className="show-monthly-selection pb-3">
                <div className="summary-table-container">
                  {(selectedView === "Monthly" && selectedMonth === 0) ||
                  (selectedView === "Quarterly" && selectedQuarter === 0) ||
                  (selectedView === "Weekly" && selectedWeek === 0) ? (
                    <></>
                  ) : (
                    <>
                      <SummarySalesData
                        selectedView={selectedView}
                        dataItem={data?.ItemData}
                        roundOff={roundOff}
                      />
                    </>
                  )}
                </div>
              </div>
              {(selectedView === "Monthly" && selectedMonth === 0) ||
              (selectedView === "Quarterly" && selectedQuarter === 0) ||
              (selectedView === "Weekly" && selectedWeek === 0) ? (
                <>
                  {selectedView === "Quarterly" && (
                    <QuartelyWiseSales
                      width={width}
                      excludeLessThanYearStore={excludeLessThanYearStore}
                      ItemData={data?.ItemData}
                      excludeInactiveStore={excludeInactiveStore}
                    />
                  )}
                  {selectedView === "Monthly" && (
                    <MonthlyWiseSales
                      width={width}
                      excludeLessThanYearStore={excludeLessThanYearStore}
                      ItemData={data?.ItemData}
                      excludeInactiveStore={excludeInactiveStore}
                    />
                  )}
                  {selectedView === "Weekly" && (
                    <WeekWiseSales
                      width={width}
                      excludeLessThanYearStore={excludeLessThanYearStore}
                      excludeInactiveStore={excludeInactiveStore}
                    />
                  )}
                </>
              ) : (
                <>
                  <ResponsiveContainer
                    width="100%"
                    aspect={width < breakPointWidth ? 2 : 4}
                  >
                    <BarChart
                      data={barData}
                      margin={{ top: 5, right: 20, bottom: 5, left: 25 }}
                    >
                      <XAxis dataKey="displayName" />
                      <YAxis
                        dataKey={"Amount"}
                        label={{
                          // value: "Amt ($)",
                          angle: 360,
                          position: "insideBottomRight",
                          offset: 10,
                        }}
                        domain={[
                          0,
                          (dataMax) => parseFloat(maxValue).toFixed(2),
                        ]}
                        tickFormatter={YAxisNumberFormat}
                        allowDataOverflow={true}
                      />
                      <Tooltip
                        content={<CustomTooltipForSales />}
                        labelStyle={{ color: "darkblue" }}
                      />
                      <Bar
                        dataKey="Amount"
                        fill="#0088FE"
                        barSize={width < breakPointWidth ? 20 : 30}
                      >
                        {barData.map((entry, index) => {
                          const color = Legends[entry?.name];
                          return <Cell key={`cell-${index}`} fill={color} />;
                        })}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                  {DefaultSummaryComponent()}
                </>
              )}
            </>
          )}
        </div>
      </Card>
    </Spin>
  );
};

export default ConsolidatedSystemSales;
