import OperationalDashboard from "../components/OperationalDashboard";
import CloverRetailPrices from "../components/OperationalDashboard/CloverRetailPrices";
import FCLeaseDetails from "../components/OperationalDashboard/FCLeaseDetails";
import SalesSectionByStore from "../components/OperationalDashboard/SalesSectionByStore";
import StoresList from "../components/OperationalDashboard/StoresList";
import FCTracking from "../components/OperationalDashboard/FCTracking";
import FCList from "../components/OperationalDashboard/FCList";
import FCDiscrepancy from "../components/FCDispencaryReport/FCDispencary.view";
import FCDiscrepancyList from "../components/OperationalDashboard/FCDiscrepancyList";
import FeedBackDetails from "../components/OperationalDashboard/FeedBackDetails.view";
import YTDSales from "../components/FinancialDashboard/Sales/YTDSales";
import CummulativeRanking from "../components/FinancialDashboard/CummulativeRanking";
import FCRankingReport from "../components/FinancialDashboard/Reconciliation/FCRankingReport";
import FCRollup from "../components/FinancialDashboard/Reconciliation/FCRollup";
import RegionRollup from "../components/FinancialDashboard/Reconciliation/RegionRollup";
const OperationalRoutes = [
  {
    path: "/operational",
    name: "OperationalDashboard",
    key: "OperationalDashboard",
    component: <OperationalDashboard />,
  },
  {
    path: "/operational/ytd/:storeId",
    name: "YTDSales",
    key: "YTDSales",
    component: <YTDSales from={"operational"} />,
  },
  {
    path: "/operational/store-track-report/:store",
    name: "SalesSectionByStore",
    key: "SalesSectionByStore",
    component: <SalesSectionByStore from={`operational`} />,
  },
  {
    path: "/operational/store-track-report",
    name: "SalesSectionByStore",
    key: "SalesSectionByStore",
    component: <SalesSectionByStore from={`operational`} />,
  },
  {
    path: "/operational/stores-list-report",
    name: "StoresList",
    key: "StoresList",
    component: <StoresList from={`operational`} />,
  },
  {
    path: "/operational/fc-track-report/:fcId",
    name: "FCTrackingByID",
    key: "FCTrackingByID",
    component: <FCTracking />,
  },
  {
    path: "/operational/fc-track-report",
    name: "FCTracking",
    key: "FCTracking",
    component: <FCTracking />,
  },
  {
    path: "/operational/fc-list-report",
    name: "FCList",
    key: "FCList",
    component: <FCList from={`operational`} />,
  },

  {
    path: "/operational/clover-retail-prices",
    name: "CloverRetailPrices",
    key: "CloverRetailPrices",
    component: <CloverRetailPrices />,
  },
  {
    path: "/operational/clover-retail-prices/:store",
    name: "CloverRetailPrices",
    key: "CloverRetailPrices",
    component: <CloverRetailPrices />,
  },
  {
    path: "/operational/feedback-details/:store",
    name: "FeedBackDetails",
    key: "FeedBackDetails",
    component: <FeedBackDetails />,
  },
  {
    path: "/operational/fc-lease-details",
    name: "FCLeaseDetails",
    key: "FCLeaseDetails",
    component: <FCLeaseDetails />,
  },

  {
    path: "/operational/fc-discrepancy",
    name: "FCDiscrepancy",
    key: "FCDiscrepancy",
    component: <FCDiscrepancy />,
  },
  {
    path: "/operational/fc-discrepancy/:fcId",
    name: "FCDiscrepancyByID",
    key: "FCDiscrepancyByID",
    component: <FCDiscrepancy />,
  },
  {
    path: "/operational/fc-discrepancy-list",
    name: "FCDiscrepancyList",
    key: "FCDiscrepancyList",
    component: <FCDiscrepancyList />,
  },
  {
    path: "/operational/rank-report",
    name: "OperationalCummulativeRanking",
    key: "OperationalCummulativeRanking",
    component: <CummulativeRanking />,
  },
  {
    path: "/operational/fc-ranking",
    name: "FCRanking",
    key: "FCRanking",
    component: <FCRankingReport />,
  },
  {
    path: "/operational/fc-rollup",
    name: "FCRollup",
    key: "FCRollup",
    component: <FCRollup />,
  },
  {
    path: "/operational/region-rollup",
    name: "RegionRollup",
    key: "RegionRollup",
    component: <RegionRollup />,
  },
];

export default OperationalRoutes;
