import React, { useEffect, useState } from "react";
import {
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Line,
  ComposedChart,
} from "recharts";
import { FinancialService } from "../../../services/financial/index.service";
import { CurrencyFormat, getYearName, ShowArrow, YAxisNumberFormat } from "../../../utils";
import { CustomTooltipWithRegionRollupSales } from "../../../utils/Tooltip";
import { Legends } from "../../../utils/legend";
import {
  SvgChg,
  SvgCurrentYear,
  SvgPreviousYear,
} from "../../../utils/svgpath";
import SummaryRollupTable from "./SummaryRollupTable";
import { DashboardLoader } from "../../../utils/Loading";
import { useSelector } from "react-redux";

const RegionSales = () => {
  const service = new FinancialService();
  const { roundOff } = useSelector((state) => state?.customSelection);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [maxValue, setMaxValue] = useState(0);
  const [maxChgValue, setMaxChgValue] = useState(0);
  const [minChgValue, setMinChgValue] = useState(0);
  const [mainData, setMainData] = useState([]);
  const [width, setWindowWidth] = useState(0);
  const breakPointWidth = 767;
  useEffect(() => {
    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };
  useEffect(() => {
    fetchApi();
  }, [roundOff]);

  const fetchApi = async () => {
    setLoading(true);
    const payload = { roundOff };
    const res = await service?.regionRollupSales(payload);
    setMainData(res);
    setData(res?.SalesByRegion);
    const removeTotal = res?.SalesByRegion?.filter(
      (i) => i?.Region !== "Total"
    );
    const currentYearMax = Math.max(
      ...removeTotal.map((o) => o.currentYear || 0)
    );
    const priorYearMax = Math.max(...removeTotal.map((o) => o.priorYear || 0));
    setMaxValue(Math.max(currentYearMax, priorYearMax) + 100);
    setMaxChgValue(Math.max(...removeTotal.map((o) => o?.stores || 0)));
    setMinChgValue(Math.min(...removeTotal.map((o) => o?.stores || 0)));
    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <DashboardLoader />
      ) : (
        <>
          {data?.length > 0 ? (
            <>
              <div className="total-system-sales">
                <>
                  <div className="show-monthly-selection pb-3">
                    <div className="summary-table-container">
                      <SummaryRollupTable
                        dataItem={mainData?.ItemData}
                        selectedType={"sales"}
                        roundOff={roundOff}
                      />
                    </div>
                  </div>
                </>
                <ResponsiveContainer
                  width="100%"
                  aspect={width < breakPointWidth ? 2 : 4}
                >
                  <ComposedChart
                    data={data.filter((i) => i?.Region !== "Total")}
                    width={400}
                    margin={{
                      top: 25,
                      right: width < breakPointWidth ? 5 : 20,
                      left: width < breakPointWidth ? 5 : 20,
                      bottom: 0,
                    }}
                    height={300}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="Region" />
                    <YAxis
                      label={{
                        // value: "Amt ($)",
                        angle: 360,
                        position: "insideBottomRight",
                        offset: 10,
                      }}
                      // width={100}
                      tickFormatter={YAxisNumberFormat}
                      domain={[0, (dataMax) => parseFloat(maxValue).toFixed(2)]}
                      allowDataOverflow={true}
                    />
                    <Tooltip
                      content={<CustomTooltipWithRegionRollupSales />}
                      labelStyle={{ color: "darkblue" }}
                    />
                    <Bar
                      dataKey="currentYear"
                      fill={Legends["currentYear"]}
                      barSize={width < breakPointWidth ? 20 : 30}
                    />
                    <Bar
                      dataKey="priorYear"
                      fill={Legends["priorYear"]}
                      barSize={width < breakPointWidth ? 20 : 30}
                    />
                    <Line
                      dot={true}
                      strokeWidth={2}
                      dataKey="stores"
                      stroke="#FFC000"
                      yAxisId="right"
                      name="stores"
                    />
                    <YAxis
                      type={"number"}
                      domain={[
                        (dataMin) => minChgValue.toFixed(2),
                        (dataMax) => (maxChgValue + 10).toFixed(2),
                      ]}
                      allowDataOverflow={true}
                      tickLine={false}
                      yAxisId="right"
                      interval={"preserveStartEnd"}
                      orientation="right"
                      axisLine={{ stroke: "#f5f5f5" }}
                      label={{
                        value: "Stores",
                        angle: 360,
                        position: "insideBottomRight",
                        offset: 10,
                      }}
                    />
                  </ComposedChart>
                </ResponsiveContainer>
                {width < breakPointWidth ? (
                  <>
                    <div className="table-container legend-table">
                      <table>
                        <thead>
                          <tr>
                            <th>Region</th>
                            <th>
                              {SvgCurrentYear()}
                              {getYearName("currentYear")}
                              {ShowArrow(mainData?.ItemData?.currentArrow)}
                            </th>
                            <th>
                              {SvgPreviousYear()}
                              {getYearName("priorYear")}
                              {ShowArrow(mainData?.ItemData?.priorArrow)}
                            </th>
                            <th>
                              {SvgChg()}
                              Stores
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.map((i) => {
                            return (
                              <tr>
                                <td>{i?.Region}</td>
                                <td>{CurrencyFormat(i?.currentYear,roundOff)}</td>
                                <td>{CurrencyFormat(i?.priorYear,roundOff)}</td>
                                <td>{i?.["stores"] || 0}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="table-container legend-table">
                      <table>
                        <thead>
                          <tr>
                            <th></th>
                            {data.map((i) => {
                              return (
                                <>
                                  <th>{i?.Region}</th>
                                </>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {SvgCurrentYear()}
                              {getYearName("currentYear")}
                              {ShowArrow(mainData?.ItemData?.currentArrow)}
                            </td>
                            {data.map((i) => {
                              return (
                                <>
                                  <td>{CurrencyFormat(i?.currentYear,roundOff)}</td>
                                </>
                              );
                            })}
                          </tr>
                          <tr>
                            <td>
                              {SvgPreviousYear()}
                              {getYearName("priorYear")}
                              {ShowArrow(mainData?.ItemData?.priorArrow)}
                            </td>
                            {data.map((i) => {
                              return (
                                <>
                                  <td>{CurrencyFormat(i?.priorYear,roundOff)}</td>
                                </>
                              );
                            })}
                          </tr>
                          <tr>
                            <td>
                              {SvgChg()}
                              Stores
                            </td>
                            {data.map((i) => {
                              return (
                                <>
                                  <td>{i?.["stores"] || 0}</td>
                                </>
                              );
                            })}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="norecords-available">No records available</div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default RegionSales;
