import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateWeekSelection } from "../redux/dashboard/customSelectionSlice";
import { useEffect, useState } from "react";
import moment from "moment";

const WeekPicker = (props) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state);
  const [weeks, setWeeks] = useState([]);

  const handleChange = (value) => {
    dispatch(updateWeekSelection(value));
  };
  useEffect(() => {
    generateWeeks();
  }, [data?.customSelection?.selectedYear]);

  const generateWeeks = () => {
    let items = [
      {
        value: 0,
        label: `All Weeks`,
        key: `All Weeks`,
      },
      {
        value: moment().week()-1,
        label: `Current Week`,
        key: `Current Week`,
      },
      {
        value: -1,
        label: `Current Day`,
        key: `Current Day`,
      },
    ];
    for (let i = 1; i <= 52; i++) {
      let obj = {
        value: i,
        label: `${data?.customSelection?.selectedYear} - Week ${i}`,
        key: `${data?.customSelection?.selectedYear} - Week ${i}`,
      };
      items.push(obj);
    }
    setWeeks(items);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };
  return (
    <div className="year-picker">
      <Select
        showSearch
        value={data?.customSelection?.selectedWeek}
        disabled={props?.disabled}
        style={{
          width: 150,
        }}
        onSearch={onSearch}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        onChange={handleChange}
        options={weeks}
      />
    </div>
  );
};

export default WeekPicker;
