import Auth from "../components/Auth/Auth";
import Dashboard from "../components/Dashboard";
import Login from "../components/Login/Login";
import Unauthorized from "../utils/Unauthorized";
import CeoRoutes from "./CeoRoutes";
import DevelopmentRoutes from "./DevelopmentRoutes";
import FinancialRoutes from "./FinancialRoutes";
import FranchiseSalesRoutes from "./FranchiseSalesRoutes";
import HrRoutes from "./HrRoutes";
import LegalRoutes from "./LegalRoutes";
import MarketingRoutes from "./MarketingRoutes";
import OperationalRoutes from "./OperationalRoutes";
import SupplyChainRoutes from "./SupplyChainRoutes";

const routes = [
  {
    path: "/auth",
    name: "Auth",
    key: "Auth",
    component: <Auth />,
  },
  {
    path: "/403",
    name: "Unauthorized",
    key: "Unauthorized",
    component: <Unauthorized />,
  },
  {
    path: "/",
    name: "MainDashboard",
    key: "MainDashboard",
    component: <Dashboard />,
  },
  {
    path: "/home",
    name: "MainDashboard",
    key: "MainDashboard",
    component: <Dashboard />,
  },
  {
    path: "/login",
    name: "login",
    key: "login",
    component: <Login />,
  },
];

export const MainRoutes=[...routes,...FinancialRoutes,...OperationalRoutes,...MarketingRoutes,...CeoRoutes,...HrRoutes,...LegalRoutes,...FranchiseSalesRoutes,...DevelopmentRoutes,...SupplyChainRoutes]

