import DevelopmentDashboard from "../components/DevelopmentDashboard"
import FCLeaseDetails from "../components/OperationalDashboard/FCLeaseDetails"

const DevelopmentRoutes = [
    {
        path: "development",
        name: "DevelopmentDashboard",
        key: "DevelopmentDashboard",
        component: <DevelopmentDashboard />,
      },
      {
        path: "/development/fc-lease-details",
        name: "FCLeaseDetails",
        key: "FCLeaseDetails",
        component: <FCLeaseDetails />,
      },
]

export default DevelopmentRoutes