import { CommonServiceUtils } from "../common-service-utils";

export class FinancialService extends CommonServiceUtils {
  /* YTD Sales */
  async fetchYtdTotalSales(req) {
    let response = null;
    const ytdTotalSalesData = await this.postData(
      `${this.apiUrlCreator(`ytd-total-sales`)}`,
      req
    );
    if (ytdTotalSalesData?.responseCode === 200) {
      response = ytdTotalSalesData?.result;
    }
    return response;
  }

  async fetchYtdTotalBusinessSales(req) {
    let response = null;
    const ytdTotalBusinessSalesData = await this.postData(
      `${this.apiUrlCreator(`ytd-total-business-sales`)}`,
      req
    );
    if (ytdTotalBusinessSalesData?.responseCode === 200) {
      response = ytdTotalBusinessSalesData?.result;
    }
    return response;
  }
  async fetchYtdMonthlyWiseBusinessSales(business, req) {
    let response = null;
    const ytdMonthlyWiseBusinessSalesData = await this.postData(
      `${this.apiUrlCreator(`ytd-monthwise-business-sales/${business}`)}`,
      req
    );
    if (ytdMonthlyWiseBusinessSalesData?.responseCode === 200) {
      response = ytdMonthlyWiseBusinessSalesData?.result;
    }
    return response;
  }
  async fetchYtdTotalRegionSales(req) {
    let response = null;
    const ytdTotalRegionSalesData = await this.postData(
      `${this.apiUrlCreator(`ytd-total-region-sales`)}`,
      req
    );
    if (ytdTotalRegionSalesData?.responseCode === 200) {
      response = ytdTotalRegionSalesData?.result;
    }
    return response;
  }

  async fetchYtdMonthlyWiseRegionSales(region, req) {
    let response = null;
    const ytdMonthlyWiseRegionSalesData = await this.postData(
      `${this.apiUrlCreator(`ytd-monthwise-region-sales/${region}`)}`,
      req
    );
    if (ytdMonthlyWiseRegionSalesData?.responseCode === 200) {
      response = ytdMonthlyWiseRegionSalesData?.result;
    }
    return response;
  }
  async fetchYtdQuartelyWiseTotalSales(req) {
    let response = null;
    const fetchYtdQuartelyWiseTotalSales = await this.postData(
      `${this.apiUrlCreator(`ytd-quarterwise-total-sales`)}`,
      req
    );
    if (fetchYtdQuartelyWiseTotalSales?.responseCode === 200) {
      response = fetchYtdQuartelyWiseTotalSales;
    }
    return response;
  }
  async fetchYtdMonthlyWiseTotalSales(req) {
    let response = null;
    const ytdMonthlyWiseTotalSalesData = await this.postData(
      `${this.apiUrlCreator(`ytd-monthwise-total-sales`)}`,
      req
    );
    if (ytdMonthlyWiseTotalSalesData?.responseCode === 200) {
      response = ytdMonthlyWiseTotalSalesData;
    }
    return response;
  }
  async fetchYtdWeeklyWiseTotalSales(req) {
    let response = null;
    const ytdWeeklyWiseTotalSalesData = await this.postData(
      `${this.apiUrlCreator(`ytd-weekwise-total-sales`)}`,
      req
    );
    if (ytdWeeklyWiseTotalSalesData?.responseCode === 200) {
      response = ytdWeeklyWiseTotalSalesData?.result;
    }
    return response;
  }

  /* Guest Count */
  async fetchYtdTotalGuestCount(req) {
    let response = null;
    const ytdTotalGuestCount = await this.postData(
      `${this.apiUrlCreator(`ytd-total-guest-count`)}`,req
    );
    if (ytdTotalGuestCount?.responseCode === 200) {
      response = ytdTotalGuestCount?.result;
    }
    return response;
  }
  async fetchYtdTotalGuestCountRegion(req) {
    let response = null;
    const ytdTotalGuestCountRegion = await this.postData(
      `${this.apiUrlCreator(`ytd-total-guest-count-region`)}`,req
    );
    if (ytdTotalGuestCountRegion?.responseCode === 200) {
      response = ytdTotalGuestCountRegion?.result;
    }
    return response;
  }
  async fetchYtdTotalGuestCountMonthWise(req) {
    let response = null;
    const ytdTotalGuestCountMonthWise = await this.postData(
      `${this.apiUrlCreator(`ytd-total-guest-count-monthwise`)}`,req
    );
    if (ytdTotalGuestCountMonthWise?.responseCode === 200) {
      response = ytdTotalGuestCountMonthWise?.result;
    }
    return response;
  }
  async fetchMonthWiseRegionGuestCount(region) {
    let response = null;
    const monthWiseRegionGuestCount = await this.postData(
      `${this.apiUrlCreator(`monthwise-region-guest-count/${region}`)}`
    );
    if (monthWiseRegionGuestCount?.responseCode === 200) {
      response = monthWiseRegionGuestCount?.result;
    }
    return response;
  }
  async fetchMonthTotalGuestCount(req) {
    let response = null;
    const monthTotalGuestCount = await this.postData(
      `${this.apiUrlCreator(`month-total-guest-count`)}`,req
    );
    if (monthTotalGuestCount?.responseCode === 200) {
      response = monthTotalGuestCount?.result;
    }
    return response;
  }
  async fetchMonthTotalGuestCountRegion(req) {
    let response = null;
    const monthTotalGuestCountRegion = await this.postData(
      `${this.apiUrlCreator(`month-total-guest-count-region`)}`,req
    );
    if (monthTotalGuestCountRegion?.responseCode === 200) {
      response = monthTotalGuestCountRegion?.result;
    }
    return response;
  }
  async fetchWeekTotalGuestCount(req) {
    let response = null;
    const weekTotalGuestCount = await this.postData(
      `${this.apiUrlCreator(`week-total-guest-count`)}`,req
    );
    if (weekTotalGuestCount?.responseCode === 200) {
      response = weekTotalGuestCount?.result;
    }
    return response;
  }
  async fetchWeekTotalGuestCountRegion(req) {
    let response = null;
    const weekTotalGuestCountRegion = await this.postData(
      `${this.apiUrlCreator(`week-total-guest-count-region`)}`,req
    );
    if (weekTotalGuestCountRegion?.responseCode === 200) {
      response = weekTotalGuestCountRegion?.result;
    }
    return response;
  }
  async fetchDayTotalGuestCount(req) {
    let response = null;
    const dayTotalGuestCount = await this.postData(
      `${this.apiUrlCreator(`day-total-guest-count`)}`,req
    );
    if (dayTotalGuestCount?.responseCode === 200) {
      response = dayTotalGuestCount?.result;
    }
    return response;
  }
  async fetchDayTotalGuestCountRegion(req) {
    let response = null;
    const dayTotalGuestCountRegion = await this.postData(
      `${this.apiUrlCreator(`day-total-guest-count-region`)}`,req
    );
    if (dayTotalGuestCountRegion?.responseCode === 200) {
      response = dayTotalGuestCountRegion?.result;
    }
    return response;
  }

  /* Current Week Sales */

  async fetchWeekTotalSales(req) {
    let response = null;
    const weekTotalSalesData = await this.postData(
      `${this.apiUrlCreator(`week-total-sales`)}`,
      req
    );
    if (weekTotalSalesData?.responseCode === 200) {
      response = weekTotalSalesData?.result;
    }
    return response;
  }

  async fetchWeekTotalBusinessSales(req) {
    let response = null;
    const weekTotalBusinessSalesData = await this.postData(
      `${this.apiUrlCreator(`week-total-business-sales`)}`,
      req
    );
    if (weekTotalBusinessSalesData?.responseCode === 200) {
      response = weekTotalBusinessSalesData?.result;
    }
    return response;
  }
  async fetchWeekTotalRegionSales(req) {
    let response = null;
    const weekTotalRegionSalesData = await this.postData(
      `${this.apiUrlCreator(`week-total-region-sales`)}`,
      req
    );
    if (weekTotalRegionSalesData?.responseCode === 200) {
      response = weekTotalRegionSalesData?.result;
    }
    return response;
  }
  /* Current Month Sales */

  async fetchMonthTotalSales(req) {
    let response = null;
    const monthTotalSalesData = await this.postData(
      `${this.apiUrlCreator(`month-total-sales`)}`,
      req
    );
    if (monthTotalSalesData?.responseCode === 200) {
      response = monthTotalSalesData?.result;
    }
    return response;
  }

  async fetchMonthTotalBusinessSales(req) {
    let response = null;
    const monthTotalBusinessSalesData = await this.postData(
      `${this.apiUrlCreator(`month-total-business-sales`)}`,
      req
    );
    if (monthTotalBusinessSalesData?.responseCode === 200) {
      response = monthTotalBusinessSalesData?.result;
    }
    return response;
  }
  async fetchMonthTotalRegionSales(req) {
    let response = null;
    const monthTotalRegionSalesData = await this.postData(
      `${this.apiUrlCreator(`month-total-region-sales`)}`,
      req
    );
    if (monthTotalRegionSalesData?.responseCode === 200) {
      response = monthTotalRegionSalesData?.result;
    }
    return response;
  }

  /* Current Day Sales */

  async fetchDayTotalSales(req) {
    let response = null;
    const dayTotalSalesData = await this.postData(
      `${this.apiUrlCreator(`day-total-sales`)}`,
      req
    );
    if (dayTotalSalesData?.responseCode === 200) {
      response = dayTotalSalesData?.result;
    }
    return response;
  }

  async fetchDayTotalBusinessSales(req) {
    let response = null;
    const dayTotalBusinessSalesData = await this.postData(
      `${this.apiUrlCreator(`day-total-business-sales`)}`,
      req
    );
    if (dayTotalBusinessSalesData?.responseCode === 200) {
      response = dayTotalBusinessSalesData?.result;
    }
    return response;
  }
  async fetchDayTotalRegionSales(req) {
    let response = null;
    const dayTotalRegionSalesData = await this.postData(
      `${this.apiUrlCreator(`day-total-region-sales`)}`,
      req
    );
    if (dayTotalRegionSalesData?.responseCode === 200) {
      response = dayTotalRegionSalesData?.result;
    }
    return response;
  }

  /* Ranking Sales */
  async fetchSalesRank(req) {
    let response = [];
    const fetchSalesRank = await this.postData(
      `${this.apiUrlCreator(`sales-rank`)}`,
      req
    );
    if (fetchSalesRank?.responseCode === 200) {
      // response = fetchSalesRank?.result?.salesRank;
      response = fetchSalesRank?.result;
    }
    return response;
  }
  async fetchLoyaltyRank(req) {
    let response = [];
    const fetchLoyaltyRank = await this.postData(
      `${this.apiUrlCreator(`ytd-visits-ranking`)}`,
      req
    );
    if (fetchLoyaltyRank?.responseCode === 200) {
      response = fetchLoyaltyRank?.result;
    }
    return response;
  }
  async fetchGuestRank(req) {
    let response = [];
    const fetchGuestRank = await this.postData(
      `${this.apiUrlCreator(`guest-rank`)}`,
      req
    );
    if (fetchGuestRank?.responseCode === 200) {
      // response = fetchGuestRank?.result?.guestRank;
      response = fetchGuestRank?.result;
    }
    return response;
  }

  async fetchDigitalSales(req) {
    let response = null;
    const digitalSales = await this.postData(
      `${this.apiUrlCreator(`digital-sales`)}`,req
    );
    if (digitalSales?.responseCode === 200) {
      response = digitalSales?.result;
    }
    return response;
  }

  async fetchDigitalSalesPlatformWise(req) {
    let response = null;
    const digitalSalesPlatformWise = await this.postData(
      `${this.apiUrlCreator(`digital-sales-platformwise`)}`,req
    );
    if (digitalSalesPlatformWise?.responseCode === 200) {
      response = digitalSalesPlatformWise?.result;
    }
    return response;
  }
  async fetchDigitalSalesCount(req) {
    let response = null;
    const digitalSalesCount = await this.postData(
      `${this.apiUrlCreator(`digital-count`)}`,req
    );
    if (digitalSalesCount?.responseCode === 200) {
      response = digitalSalesCount?.result;
    }
    return response;
  }
  async fetchDigitalSalesCountPlatformWise(req) {
    let response = null;
    const digitalSalesCountPlatformWise = await this.postData(
      `${this.apiUrlCreator(`digital-platform-count`)}`,req
    );
    if (digitalSalesCountPlatformWise?.responseCode === 200) {
      response = digitalSalesCountPlatformWise?.result;
    }
    return response;
  }

  async fcRollupSales(req) {
    let response = null;
    const fcRollupSales = await this.postData(
      `${this.apiUrlCreator(`fc-rollup-sales`)}`,req
    );
    if (fcRollupSales?.responseCode === 200) {
      response = fcRollupSales?.result;
    }
    return response;
  }
  async fcRollupGc(req) {
    let response = null;
    const fcRollupGc = await this.postData(
      `${this.apiUrlCreator(`fc-rollup-gc`)}`,req
    );
    if (fcRollupGc?.responseCode === 200) {
      response = fcRollupGc?.result;
    }
    return response;
  }
  async fcRankingByChange(req) {
    let response = null;
    const fcRankingByChange = await this.postData(
      `${this.apiUrlCreator(`fc-ranking`)}`,req
    );
    if (fcRankingByChange?.responseCode === 200) {
      response = fcRankingByChange?.result;
    }
    return response;
  }
  async fcRankingByTotal(req) {
    let response = null;
    const fcRankingByTotal = await this.postData(
      `${this.apiUrlCreator(`fc-ranking-total`)}`,req
    );
    if (fcRankingByTotal?.responseCode === 200) {
      response = fcRankingByTotal?.result;
    }
    return response;
  }

  async regionRollupSales(req) {
    let response = null;
    const regionRollupSales = await this.postData(
      `${this.apiUrlCreator(`fc-region-sales`)}`,req
    );
    if (regionRollupSales?.responseCode === 200) {
      response = regionRollupSales?.result;
    }
    return response;
  }
  async regionRollupGc(req) {
    let response = null;
    const regionRollupGc = await this.postData(
      `${this.apiUrlCreator(`fc-region-gc`)}`,req
    );
    if (regionRollupGc?.responseCode === 200) {
      response = regionRollupGc?.result;
    }
    return response;
  }

  async fetchQuarterTotalSales(req) {
    let response = null;
    const quarterTotalSalesData = await this.postData(
      `${this.apiUrlCreator(`quarter-total-sales`)}`,
      req
    );
    if (quarterTotalSalesData?.responseCode === 200) {
      response = quarterTotalSalesData?.result;
    }
    return response;
  }

  async fetchQuarterTotalBusinessSales(req) {
    let response = null;
    const quarterTotalBusinessSalesData = await this.postData(
      `${this.apiUrlCreator(`quarter-sales-business`)}`,
      req
    );
    if (quarterTotalBusinessSalesData?.responseCode === 200) {
      response = quarterTotalBusinessSalesData?.result;
    }
    return response;
  }
  async fetchQuarterTotalRegionSales(req) {
    let response = null;
    const quarterTotalRegionSales = await this.postData(
      `${this.apiUrlCreator(`quarter-sales-region`)}`,
      req
    );
    if (quarterTotalRegionSales?.responseCode === 200) {
      response = quarterTotalRegionSales?.result;
    }
    return response;
  }

  async fetch3PDActiveParticipationByPlatform(req) {
    let response = null;
    const digitalSales = await this.postData(
      `${this.apiUrlCreator(`digital-sales-left`)}`,req
    );
    if (digitalSales?.responseCode === 200) {
      response = digitalSales?.result;
    }
    return response;
  }

  async fetch3PDMultiplePlatformParticipation(req) {
    let response = null;
    const digitalSales = await this.postData(
      `${this.apiUrlCreator(`digital-sales-right`)}`,req
    );
    if (digitalSales?.responseCode === 200) {
      response = digitalSales?.result;
    }
    return response;
  }
  async getCurrentMonthExport(req) {
    let response = null;
    const getExcelData = await this.postData(
      `${this.apiUrlCreator(`currentmonthexport`)}`,req
    );
    if (getExcelData?.responseCode === 200) {
      response = getExcelData?.result;
    }
    return response;
  }
  async getCurrentWeekExport(req) {
    let response = null;
    const getExcelData = await this.postData(
      `${this.apiUrlCreator(`currentweekexport`)}`,req
    );
    if (getExcelData?.responseCode === 200) {
      response = getExcelData?.result;
    }
    return response;
  }
  async getCurrentWeekCEOExport(req) {
    let response = null;
    const getExcelData = await this.postData(
      `${this.apiUrlCreator(`ceo-currentweekexport`)}`,req
    );
    if (getExcelData?.responseCode === 200) {
      response = getExcelData?.result;
    }
    return response;
  }
  async getCurrentQuarterExport(req) {
    let response = null;
    const getExcelData = await this.postData(
      `${this.apiUrlCreator(`currentquarterexport`)}`,req
    );
    if (getExcelData?.responseCode === 200) {
      response = getExcelData?.result;
    }
    return response;
  }
  async getCurrentYtdExport(req) {
    let response = null;
    const getExcelData = await this.postData(
      `${this.apiUrlCreator(`currentytdexport`)}`,req
    );
    if (getExcelData?.responseCode === 200) {
      response = getExcelData?.result;
    }
    return response;
  }
  async getCurrentYtdLoyaltyExport(req) {
    let response = null;
    const getExcelData = await this.postData(
      `${this.apiUrlCreator(`loyaltyvisitsexport`)}`,req
    );
    if (getExcelData?.responseCode === 200) {
      response = getExcelData?.result;
    }
    return response;
  }
  
  async getCurrentDayExport(req) {
    let response = null;
    const getExcelData = await this.postData(
      `${this.apiUrlCreator(`currentdayexport`)}`,req
    );
    if (getExcelData?.responseCode === 200) {
      response = getExcelData?.result;
    }
    return response;
  }
  async getCurrentDayCEOExport(req) {
    let response = null;
    const getExcelData = await this.postData(
      `${this.apiUrlCreator(`ceo-currentdayexport`)}`,req
    );
    if (getExcelData?.responseCode === 200) {
      response = getExcelData?.result;
    }
    return response;
  }
  async getFCSalesExport(req) {
    let response = null;
    const getExcelData = await this.postData(
      `${this.apiUrlCreator(`fcsalesexport`)}`,req
    );
    if (getExcelData?.responseCode === 200) {
      response = getExcelData?.result;
    }
    return response;
  }

  async getCurrentYtdWeekViewExport(req) {
    let response = null;
    const getExcelData = await this.postData(
      `${this.apiUrlCreator(`weekwiseexport`)}`,req
    );
    if (getExcelData?.responseCode === 200) {
      response = getExcelData?.result;
    }
    return response;
  }

  async getCurrentYtdMonthViewExport(req) {
    let response = null;
    const getExcelData = await this.postData(
      `${this.apiUrlCreator(`monthwiseexport`)}`,req
    );
    if (getExcelData?.responseCode === 200) {
      response = getExcelData?.result;
    }
    return response;
  }
  async getCurrentYtdQuarterViewExport(req) {
    let response = null;
    const getExcelData = await this.postData(
      `${this.apiUrlCreator(`quarterwiseexport`)}`,req
    );
    if (getExcelData?.responseCode === 200) {
      response = getExcelData?.result;
    }
    return response;
  }

  async fetchYTDDigitalSalesForStore(req) {
    let response = null;
    const fetchYTDDigitalSalesForStore = await this.postData(
      `${this.apiUrlCreator(`digital-sales-store`)}`,
      req
    );

    if (fetchYTDDigitalSalesForStore?.responseCode === 200) {
      response = fetchYTDDigitalSalesForStore?.result;
    }
    return response;
  }
}
