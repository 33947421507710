import { Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateYearSelection } from "../redux/dashboard/customSelectionSlice";

const YearPicker = (props) => {
  const dispatch = useDispatch();
  const [years, setYears] = useState([]);
  const data = useSelector((state) => state);
  useEffect(() => {
    generateYears();
  }, []);

  const generateYears = () => {
    const currentYear = moment().year();
    let items = [];
    for (let i = 0; i < 2; i++) {
      const year = currentYear - i;
      let obj = {
        value: year,
        label: `${year} - ${year - 1}`,
      };
      items.push(obj);
    }
    setYears(items);
  };
  const handleChange = (value) => {
    dispatch(updateYearSelection(value));
  };
  return (
    <div className="year-picker">
      <Select
        value={data?.customSelection?.selectedYear}
        style={{
          width: 120,
        }}
        onChange={handleChange}
        options={years}
      />
    </div>
  );
};

export default YearPicker;
