import { Spin } from "antd";

export const DashboardLoader = () => {
    return (
      <div className="total-system-sales">
        <Spin tip={""} size={'large'}>
          <div className="norecords-available"></div>
        </Spin>
      </div>
    );
};
