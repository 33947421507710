import SupplyChainDashboard from "../components/SupplyChainDashboard";
import MixesByCases from "../components/SupplyChainDashboard/MixesByCases";
import MixesByYieldCases from "../components/SupplyChainDashboard/MixesByYieldCases";
import YTDMixesSales from "../components/SupplyChainDashboard/YTDMixesSales";
import CurrentMonthMixesSales from "../components/SupplyChainDashboard/CurrentMonthMixesSales";
import MonthViewMixesSales from "../components/SupplyChainDashboard/MonthViewMixesSales";

const SupplyChainRoutes = [
  {
    path: "/supply-chain",
    name: "SupplyChainDashboard",
    key: "SupplyChainDashboard",
    component: <SupplyChainDashboard />,
  },
  {
    path: "/supply-chain/mixes-cases",
    name: "MixesCases",
    key: "MixesCases",
    component: <MixesByCases />,
  },
  {
    path: "/supply-chain/mixes-yield-cases",
    name: "MixesByYieldCases",
    key: "MixesByYieldCases",
    component: <MixesByYieldCases />,
  },
  {
    path: "/supply-chain/ytd-mixes-sales",
    name: "YTDMixesSales",
    key: "YTDMixesSales",
    component: <YTDMixesSales />,
  },
  {
    path: "/supply-chain/monthview-mixes-sales",
    name: "MonthViewMixesSales",
    key: "MonthViewMixesSales",
    component: <MonthViewMixesSales />,
  },
  {
    path: "/supply-chain/current-month-mixes-sales",
    name: "CurrentMonthMixesSales",
    key: "CurrentMonthMixesSales",
    component: <CurrentMonthMixesSales />,
  },
];

export default SupplyChainRoutes;
