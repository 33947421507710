import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Row } from "antd";
import { Link } from "react-router-dom";
import { Typography } from "antd";
import { updateMenuSelection } from "../../redux/dashboard/dashboardSlice";
const { Title } = Typography;

const CEODashboard = () => {
  const dispatch = useDispatch();

  return (
    <Card
      className="dashboard-antd-card"
      title={
        <>
          <Button
            type="primary"
            className="btn-back"
            onClick={() => dispatch(updateMenuSelection('home'))}
          >
            <i className="fa fa-arrow-left" style={{ fontSize: "16px" }}></i>
          </Button>
          <span>{"CEO Dashboard"}</span>
        </>
      }
    >
      <div className="parent-flex-container mt-5">
        {/* <List
          className="card_layout"
          header={<div className="cart_title">Ranking Report</div>}
          bordered
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={<span className="fas fa-poll" />}
                title={<Link to="/ceo/ytd-rankings">{item.title}</Link>}
              />
            </List.Item>
          )}
        /> */}
        <Card
          className="report_Card"
          headStyle={{
            color: "#044A2A !important",
            width: "100%",
            fontSize: "14px !important",
            // Font: "Archivo",
            // Weight: "500",
            // Size: "16px",
            // lineHeight: "17.41px",
          }}
          title={
            <Title
              style={{ color: "#044A2A !important", fontSize: "16px" }}
              level={5}
            >
            Reports
            </Title>
          }
          hoverable={true}
          bordered={true}
        >
          {/* <Row type="flex" style={{ color: "#aaaaaa" }}>
            <Link to="/ceo/ytd-rankings">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>YTD Ranking Report
                  </div>
                </div>
              </div>
            </Link>
          </Row>
          <Row type="flex" style={{ color: "#aaaaaa" }}>
            <Link to="/ceo/weekly-rankings">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>Weekly Ranking Report
                  </div>
                </div>
              </div>
            </Link>
          </Row>
          <Row type="flex" style={{ color: "#aaaaaa" }}>
            <Link to="/ceo/daily-rankings">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>Daily Ranking Report
                  </div>
                </div>
              </div>
            </Link>
          </Row> */}
          <Row type="flex" style={{ color: "#aaaaaa" }}>
            <Link to="/ceo/rank-report">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>Ranking Report
                  </div>
                </div>
              </div>
            </Link>
          </Row>
        </Card>
      </div>
      <div></div>
    </Card>
  );
};

export default CEODashboard;
