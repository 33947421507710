import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const useStoreDashboard = () => {
  const [selectedView, setSelectedView] = useState("Summary");
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const isMobile = query.get("isMobile")
  // console.log(isMobile);
  let tabs = [
    "Summary",
    "Year",
    "Month",
    "Week",
    "Line of Business",
    "Digital",
    // "Loyalty (Coming Soon)",
  ];
  if(isMobile==="true"){
    tabs = [
      "Summary",
      "Year",
      "Month",
      "Week",
    ];
  }
  
  const [tabList,setTabsList]=useState(tabs)
  const handleSegmentSelection = (e) => {
    setSelectedView(e);
  };
  const [width, setWindowWidth] = useState(0);
  const breakPointWidth = 767;
  useEffect(() => {
    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };

  return { handleSegmentSelection, selectedView, width, breakPointWidth,tabList };
};

export default useStoreDashboard;
