import { Button, Card, Segmented } from "antd";
import React from "react";
import useRedeemRewardsReport from "./RedeemRewardsReport.hook";
import { useNavigate } from "react-router-dom";
import YearPicker from "../../YearPicker";
import BirthdayRedeemptions from "./BirthdayRedeemptions/BirthdayRedeemptions.view";
import CouponRedeemptions from "./CouponRedeemptions/CouponRedeemptions.view";
import EarnedRedeemptions from "./EarnedRedeemptions/EarnedRedeemptions.view";
import VisitsRedeemptions from "./VisitsRedeemptions/VisitsRedeemptions.view";
import CustomerAppeasement from "./CustomerAppeasement/CustomerAppeasement.view";
import WeekPicker from "../../WeekPicker";
import QuarterPicker from "../../QuarterPicker";
import MonthPicker from "../../MonthPicker";
import DayPicker from "../../DayPicker";
import { useSelector } from "react-redux";
import Redeemption from "./Redeemption/Redeemption.view";

const RedeemRewardsReport = () => {
  const navigate = useNavigate();
  const { selectedView, handleSegmentSelection, width } = useRedeemRewardsReport();
  const {
    selectedQuarter,
    selectedMonth,
    selectedWeek,
  } = useSelector((state) => state?.customSelection);
  let classNameValue=`redeem-grid-container single-grid-item`
    if((selectedView === "Monthly" && selectedMonth === 0) ||
    (selectedView === "Quarterly" && selectedQuarter === 0) ||
    (selectedView === "Weekly" && selectedWeek === 0)){
      classNameValue=`redeem-grid-container single-grid-item`
    }
  return (
    <Card
      title={
        <>
          <div className="d-flex justify-content-between">
            <div>
              <Button
                type="primary"
                className="btn-back"
                onClick={() => navigate("/marketing")}
              >
                <i
                  className="fa fa-arrow-left"
                  style={{ fontSize: "16px" }}
                ></i>
              </Button>
              <span>{"Redeem Rewards Report"}</span>
            </div>
            <div>
            {selectedView !=="Daily" &&  <YearPicker />}
            </div>
          </div>
        </>
      }
    >
      {" "}
      <>
        <div className="show-monthly-selection pb-2">
          <Segmented
            options={["Yearly", "Quarterly", "Monthly", "Weekly"]}
            value={selectedView}
            onChange={(e) => handleSegmentSelection(e)}
          />
        </div>
        <div className="">
          {/* <div style={{paddingLeft:'20px'}}>
          {selectedView === "Quarterly" && <QuarterPicker disabled={false}/>}
          {selectedView === "Monthly" && <MonthPicker disabled={false} />}
          {selectedView === "Weekly" && <WeekPicker disabled={false} />}
          </div> */}
          <div className={classNameValue}>
            <Redeemption width={width} selectedView={selectedView} />
              {/* <BirthdayRedeemptions width={width} selectedView={selectedView} />
              <CouponRedeemptions width={width} selectedView={selectedView} />
              <EarnedRedeemptions width={width} selectedView={selectedView} />
              <CustomerAppeasement width={width} selectedView={selectedView} /> */}
             {/* <VisitsRedeemptions width={width} selectedView={selectedView} /> */}
          </div>
        </div>
      </>
    </Card>
  );
};

export default RedeemRewardsReport;
