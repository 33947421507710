import { Badge, Button, Card, Select, Spin, Table, Tooltip } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CurrencyFormat, checkFCAccess } from "../../utils";
import useFCDiscrepancy from "./FCDispencary.hook ";
import SalesSectionByStore from "../OperationalDashboard/SalesSectionByStore";

const FCDiscrepancy = () => {
  const discrepancyData = useFCDiscrepancy();
  const profile = useSelector((state) => state?.profile) || null;
  const navigate = useNavigate();

  const columns = [
    {
      title: "Rank",
      dataIndex: "Rank",
      key: "Rank",
      className:'Rank-Column',
      sorter: (a, b) => a.Rank - b.Rank,
      render: (data, i) => {
        let borderClassName = ``;
        if (i?.weightedScale > 75) {
          borderClassName = `red`;
        } else if (i?.weightedScale > 50) {
          borderClassName = `orange`;
        } else if (i?.weightedScale > 25) {
          borderClassName = `yellow`;
        } else if (i?.weightedScale <= 25) {
          borderClassName = `green`;
        }
        return (
            <div className="d-flex flex-column">
              <div className={`show-color ${borderClassName}`}></div>
              <div>{i?.Rank}</div>
            </div>
        );
      },
    },
    {
      title: "Store #",
      dataIndex: "RitasID",
      key: "RitasID",
      sorter: (a, b) => a.RitasID - b.RitasID,
      render: (data, props) => {
        return (
          <td
            className="store-link"
            // onClick={() => discrepancyData.handleStoreTracking(props?.RitasID)}
            onClick={()=> navigate(`/operational/store-track-report/${props.RitasID}`)}
          >
            {props?.RitasID}
          </td>
        );
      },
    },
    {
      title: "Store Name",
      dataIndex: "storeName",
      key: "storeName",
    },
    {
      title: "Action",
      dataIndex: "storeName",
      key: "storeName",
      width:'50%',
      className:"fc-discripency-action-row",
      render: (data, i) => {
        let expiration = 0;
        if (i?.AgreementExpirationFlag > 0) {
          expiration += 1;
        }
        if (i?.LeaseExpirationFlag > 0) {
          expiration += 1;
        }
        let umbrella = 0;
        if (i?.bitInsuranceExpirationFlag > 0) {
          umbrella += 1;
        }
        if (i?.bitWorkersCompExpirationFlag > 0) {
          umbrella += 1;
        }
        if (i?.bitUmbrellaExpirationFlag > 0) {
          umbrella += 1;
        }
        return (
          <div className="fc-discripency-action-column">
            {i?.isRedFlag > 0 && (
              <div className="isRedFlag">
                <Tooltip
                  title={`Red Flag:${i?.redFlagCount}`}
                  color={`#108ee9`}
                >
                  <Badge count={i?.redFlagCount} title="" color={"#D41042"}>
                    <img
                      onClick={() =>
                        navigate(
                          `/operational/clover-retail-prices/${i.RitasID}`
                        )
                      }
                      className="cursor-pointer"
                      src={"/images/fc-report/red-flag.svg"}
                      alt=""
                    />
                  </Badge>
                </Tooltip>
              </div>
            )}
            {i?.digitalpdFlag > 0 && (
              <div className="digitalpdFlag">
                <Tooltip
                  title={() => (
                    <div className="d-flex flex-column">
                      <span>{`DoorDash:${
                        i?.bitDoorDash > 0 ? "Yes" : "No"
                      }`}</span>
                      <span>{`Grubhub:${
                        i?.bitGrubHub > 0 ? "Yes" : "No"
                      }`}</span>
                      <span>{`UberEats:${
                        i?.bitUberEats > 0 ? "Yes" : "No"
                      }`}</span>
                    </div>
                  )}
                  color={`#108ee9`}
                >
                  <img src={"/images/fc-report/digital.svg"} alt="" />
                </Tooltip>
              </div>
            )}
            {i?.isSalesFlag > 0 && (
              <div className="isSalesFlag">
                <Tooltip
                  title={() => (
                    <div className="d-flex flex-column">
                      <span>{`Current Year:${CurrencyFormat(
                        i?.currentYrSales
                      )}`}</span>
                      <span>{`Previous Year:${CurrencyFormat(i?.priorYrSales)}
                    `}</span>
                    </div>
                  )}
                  color={`#108ee9`}
                >
                  <img
                    src={"/images/fc-report/sales.svg"}
                    alt=""
                  />
                </Tooltip>
              </div>
            )}
            {expiration > 0 && (
              <div>
                <Tooltip
                  title={() => (
                    <div className="d-flex flex-column">
                      {i?.AgreementExpirationFlag > 0 && (
                        <div>{`Agreement Expiration : ${
                          i?.AgreementExpirationDate
                            ? i?.AgreementExpirationDate
                            : `N/A`
                        }`}</div>
                      )}
                      {i?.LeaseExpirationFlag > 0 && (
                        <div>{`Lease Expiration : ${
                          i?.LeaseExpirationDate
                            ? i?.LeaseExpirationDate
                            : `N/A`
                        }`}</div>
                      )}
                    </div>
                  )}
                  color={`#108ee9`}
                >
                  <Badge count={expiration} title="" color={"#D41042"}>
                    <img src={"/images/fc-report/expiration.svg"} alt="" />
                  </Badge>
                </Tooltip>
              </div>
            )}
            {umbrella > 0 && (
              <div>
                <Tooltip
                  title={() => (
                    <div className="d-flex flex-column">
                      {i?.bitWorkersCompExpirationFlag > 0 && (
                        <div>{`Workers Compensation Expiration : ${
                          i?.WorkersCompExpirationDate
                            ? i?.WorkersCompExpirationDate
                            : `N/A`
                        }`}</div>
                      )}
                      {i?.bitUmbrellaExpirationFlag > 0 && (
                        <div>{`Umbrella Expiration : ${
                          i?.UmbrellaExpirationDate
                            ? i?.UmbrellaExpirationDate
                            : `N/A`
                        }`}</div>
                      )}
                      {i?.bitInsuranceExpirationFlag > 0 && (
                        <div>{`Insurance Expiration : ${
                          i?.InsuranceExpirationDate
                            ? i?.InsuranceExpirationDate
                            : `N/A`
                        }`}</div>
                      )}
                    </div>
                  )}
                  color={`#108ee9`}
                >
                  <Badge count={umbrella} title="" color={"#D41042"}>
                    <img src={"/images/fc-report/umbrella.svg"} alt="" />
                  </Badge>
                </Tooltip>
              </div>
            )}

            {i?.bitClientFeedbackFlag > 0 && (
              <div>
                <Tooltip
                  title={`Feedback Count:${i?.intclientFeedbackCount}`}
                  color={`#108ee9`}
                >
                  <Badge
                    count={i?.intclientFeedbackCount}
                    title=""
                    color={"#D41042"}
                  >
                    <img
                      src={"/images/fc-report/feedback.svg"}
                      className="cursor-pointer"
                      alt=""
                      onClick={() =>
                        navigate(`/operational/feedback-details/${i.RitasID}`)
                      }
                    />
                  </Badge>
                </Tooltip>
              </div>
            )}
            {i?.bitnewStoreFlag > 0 && (
              <div className="store-new-one">
                <Tooltip
                  title={`Shop Open Date:${
                    i?.shopOpenDate ? i?.shopOpenDate : "N/A"
                  }`}
                  color={`#108ee9`}
                  placement={"left"}
                >
                  <img
                    className="new-store-image"
                    src={"/images/fc-report/new.svg"}
                    alt=""
                  />
                </Tooltip>
              </div>
            )}
          </div>
        );
      },
    },
  ];
  if (discrepancyData.selectedStore) {
    return (
      <SalesSectionByStore
        from={`operational`}
        navigation={true}
        store={discrepancyData.selectedStore}
        handleClose={() => discrepancyData.handleStoreTracking()}
      />
    );
  }
  return (
    <Card
      title={
        <div className="fc-discrepancy-report-card-title p-2">
          <div>
            <Button
              type="primary"
              className="btn-back"
              // onClick={() => navigate("/operational/fc-discrepancy-list")}
              onClick={() => navigate(-1)}
            >
              <i className="fa fa-arrow-left" style={{ fontSize: "16px" }}></i>
            </Button>
            <span>{`FC Discrepancy Report`}</span>
          </div>
          <div className="fc-discrepancy-select-fc">
            <Select
              showSearch
              placeholder="Select a FC"
              style={{ width: "180px" }}
              optionFilterProp="children"
              value={
                discrepancyData?.selectedFC || {
                  selectedFC: 0,
                  label: "Please Select FC",
                }
              }
              disabled={checkFCAccess(profile)}
              onChange={discrepancyData?.onChange}
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
              }
              options={discrepancyData?.options}
            />
          </div>
        </div>
      }
    >
      <Table
        dataSource={discrepancyData?.storeData}
        columns={columns}
        loading={discrepancyData?.loading}
        className="discripancy-table"
        pagination={false}
        title={""}
        // pagination={tr}
        // scroll={{
        //   y: 'auto',
        // }}
      />
    </Card>
  );
};

export default FCDiscrepancy;
