import FranchiseSalesDashboard from "../components/FranchiseSalesDashboard"
import FCLeaseDetails from "../components/OperationalDashboard/FCLeaseDetails"

const FranchiseSalesRoutes = [
    {
        path: "franchise-sales",
        name: "FranchiseSalesDashboard",
        key: "FranchiseSalesDashboard",
        component: <FranchiseSalesDashboard />,
      },
      {
        path: "/franchise-sales/fc-lease-details",
        name: "FCLeaseDetails",
        key: "FCLeaseDetails",
        component: <FCLeaseDetails />,
      },
]

export default FranchiseSalesRoutes