import { Descriptions } from "antd";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { CurrencyFormat } from "../../utils";

const SummaryStoreTable = (props) => {
  const navigate = useNavigate();

  const { dataItem, rankData = null, visitsData = null } = props;
  const date = moment().format("YYYY-MM-DD");
  let LeaseExpirationDateClassName = "";
  if (
    moment().format("YYYY-MM-DD") >
    moment(dataItem?.LeaseExpirationDate).format("YYYY-MM-DD")
  ) {
    LeaseExpirationDateClassName = "text-red-600";
  } else if (
    moment(dataItem?.LeaseExpirationDate).diff(moment(date), "months") < 18
  ) {
    LeaseExpirationDateClassName = "text-orange-600";
  }
  let InsuranceExpirationDateClassName = "";
  if (
    moment(dataItem?.InsuranceExpirationDate).diff(moment(date), "days") <=7
  ) {
    InsuranceExpirationDateClassName = "text-red-600";
  } else if (
    moment(dataItem?.InsuranceExpirationDate).diff(moment(date), "days") <=15
  ) {
    InsuranceExpirationDateClassName = "text-orange-600";
  } else if (
    moment(dataItem?.InsuranceExpirationDate).diff(moment(date), "days") <=30
  ) {
    InsuranceExpirationDateClassName = "text-yellow-600";
  }
  let cloverclassName = "";
  if (dataItem?.Red > 0) {
    cloverclassName = "text-red-600";
  }

  return (
    <>
      <div>
        <div>
          <Descriptions title="Shop Information">
            <Descriptions.Item label="Shop No.">
              {dataItem?.StoreNo}
            </Descriptions.Item>
            <Descriptions.Item label="Shop Name">
              {dataItem?.StoreName}
            </Descriptions.Item>
            <Descriptions.Item label="City">{dataItem?.city}</Descriptions.Item>
            <Descriptions.Item label="State">
              {dataItem?.state}
            </Descriptions.Item>
            <Descriptions.Item label="Franchisee">
              {dataItem?.Franchisee}
            </Descriptions.Item>
            <Descriptions.Item label="FC Name">
              {dataItem?.FC}
            </Descriptions.Item>
          </Descriptions>
        </div>
        {rankData && (
          <div>
            <Descriptions title={`Current Week Info (Week:${rankData?.weekNo})`}>
              <Descriptions.Item label="Sales">
                {CurrencyFormat(rankData?.currentSales)}
              </Descriptions.Item>
              <Descriptions.Item label="Guests">
                {rankData?.guestCount}
              </Descriptions.Item>
              <Descriptions.Item label="Sales Rank">
                {rankData?.salesRank}
              </Descriptions.Item>
              <Descriptions.Item label="Guest Rank">
                {rankData?.guestRank}
              </Descriptions.Item>
            </Descriptions>
          </div>
        )}
        <div>
          <Descriptions title="Classifications">
            <Descriptions.Item label="Shop Status">
              {dataItem?.StoreStatus}
            </Descriptions.Item>
            <Descriptions.Item label="Seasonality">
              {dataItem?.Seasonality}
            </Descriptions.Item>
            <Descriptions.Item label="Special Venue">
              {dataItem?.SpecialVenue}
            </Descriptions.Item>
            <Descriptions.Item label="UberEats">
              {dataItem?.UberEats === "0" ? (
                <i className="fa fa-close" style={{ color: "red" }}></i>
              ) : (
                <i
                  className="fa fa-check"
                  style={{ color: "green" }}
                  aria-hidden="true"
                ></i>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="DoorDash">
              {dataItem?.DoorDash === "0" ? (
                <i className="fa fa-close" style={{ color: "red" }}></i>
              ) : (
                <i
                  className="fa fa-check"
                  style={{ color: "green" }}
                  aria-hidden="true"
                ></i>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="GrubHub">
              {dataItem?.GrubHub === "0" ? (
                <i className="fa fa-close" style={{ color: "red" }}></i>
              ) : (
                <i
                  className="fa fa-check"
                  style={{ color: "green" }}
                  aria-hidden="true"
                ></i>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Others">
              {dataItem?.Others === "0" ? (
                <i className="fa fa-close" style={{ color: "red" }}></i>
              ) : (
                <i
                  className="fa fa-check"
                  style={{ color: "green" }}
                  aria-hidden="true"
                ></i>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Current Register System">
              {dataItem?.CurrentRegisterSystem}
            </Descriptions.Item>
            <Descriptions.Item label="Nut Free">
              {dataItem?.NutFree}
            </Descriptions.Item>
            <Descriptions.Item label="Kosher">
              {dataItem?.Kosher}
            </Descriptions.Item>
            <Descriptions.Item label="Distributor">
              {dataItem?.Distributor}
            </Descriptions.Item>
            <Descriptions.Item label="CO-OP">
              {dataItem?.CoOpName}
            </Descriptions.Item>
            <Descriptions.Item label="MSA">
              {dataItem?.MSAName}
            </Descriptions.Item>
            <Descriptions.Item label="Red Flag">
              {dataItem?.Red > 0 && !props?.embedded ? (
                <span
                  className={`store-link ${cloverclassName}`}
                  onClick={() =>
                    navigate(
                      `/operational/clover-retail-prices/${dataItem?.StoreNo}`
                    )
                  }
                >
                  {dataItem?.Red}
                </span>
              ) : (
                <span>{dataItem?.Red}</span>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Feedbacks">
              {parseInt(dataItem?.feedbackCount) > 0 && !props?.embedded ? (
                <span
                  className={`store-link ${cloverclassName}`}
                  onClick={() =>
                    navigate(
                      `/operational/feedback-details/${dataItem?.StoreNo}`
                    )
                  }
                >
                  {dataItem?.feedbackCount}
                </span>
              ) : (
                <span>{dataItem?.feedbackCount}</span>
              )}
            </Descriptions.Item>
          </Descriptions>
        </div>
        <div>
          <Descriptions title="Important Dates">
            <Descriptions.Item label="Shop Open Date">
              {dataItem?.ShopOpenDate ? dataItem?.ShopOpenDate : "N/A"}
            </Descriptions.Item>
            <Descriptions.Item label="Transfer Date">
              {dataItem?.TransferDate ? dataItem?.TransferDate : "N/A"}
            </Descriptions.Item>
            <Descriptions.Item label="Agreement Start Date">
              {dataItem?.TransferDateAgreementStartDate
                ? dataItem?.TransferDate
                : "N/A"}
            </Descriptions.Item>
            <Descriptions.Item label="Agreement Expiration Date">
              {dataItem?.AgreementExpirationDate
                ? dataItem?.AgreementExpirationDate
                : "N/A"}
            </Descriptions.Item>
            <Descriptions.Item label="Lease Expiration Date">
              <span className={LeaseExpirationDateClassName}>
              {dataItem?.LeaseExpirationDate
                ? dataItem?.LeaseExpirationDate
                : "N/A"}
              </span>
            </Descriptions.Item>
            <Descriptions.Item label="Seasonal Open Date">
              {dataItem?.SeasonalOpenDate ? dataItem?.SeasonalOpenDate : "N/A"}
            </Descriptions.Item>
            <Descriptions.Item label="Seasonal Close Date">
              {dataItem?.SeasonalCloseDate
                ? dataItem?.SeasonalCloseDate
                : "N/A"}
            </Descriptions.Item>
            <Descriptions.Item label="General Liability Expiration">
            <span className={InsuranceExpirationDateClassName}>
              {dataItem?.InsuranceExpirationDate
                ? dataItem?.InsuranceExpirationDate
                : "N/A"}
            </span>
            </Descriptions.Item>
            <Descriptions.Item label="Umbrella Expiration">
              {dataItem?.UmbrellaExpirationDate
                ? dataItem?.UmbrellaExpirationDate
                : "N/A"}
            </Descriptions.Item>
            <Descriptions.Item label="Workers Compensation Expiration">
              {dataItem?.WorkersCompensationExpirationDate
                ? dataItem?.WorkersCompensationExpirationDate
                : "N/A"}
            </Descriptions.Item>
          </Descriptions>
        </div>
        <div>
          {visitsData && (
            <div>
              <Descriptions title="FC Visits">
                {visitsData?.map((i) => {
                  return (
                    <>
                      <Descriptions.Item label="Visit Date">
                        {`${i?.lastVisitDate} (Score:${
                          i?.score ? i?.score : "N/A"
                        })`}
                      </Descriptions.Item>
                    </>
                  );
                })}
              </Descriptions>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SummaryStoreTable;
