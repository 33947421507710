import { createSlice, nanoid } from "@reduxjs/toolkit";

const initialState = {
  accessToken: null,
  idToken: null,
  state: null,
  nonce: null,
};

export const tokenSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    clearAll: (stateVal) => initialState,
    receiveAccessToken: (stateVal, action) => {
        stateVal.accessToken=action.payload
        return stateVal
    },
    receiveIdToken: (stateVal, action) => {
        stateVal.idToken=action.payload
        return stateVal
    },
    generateStateAndNonce: (stateVal) => {
        stateVal.state=stateVal.state || nanoid(32)
        stateVal.nonce=stateVal.nonce || nanoid(32)
        return stateVal
    }
  },
});

export const { clearAll, receiveAccessToken,receiveIdToken,generateStateAndNonce } = tokenSlice.actions;
export default tokenSlice.reducer;
