import React, { useState, useEffect } from "react";
import { parse } from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { beginAuth } from "../api/oidcApi";
import { generateStateAndNonce } from "../redux/token/tokenSlice";
import { MainRoutes } from "../routes/routes";
import { Layout, Menu } from "antd";
import { authLogout } from "../api/oidcApi";
import { updateMenuSelection } from "../redux/dashboard/dashboardSlice";
import {
  checkFCAccess,
  checkFinancialAccess,
  checkMarketingAccess,
  checkOperationAccess,
} from "../utils";

const { Header, Sider, Content } = Layout;

const MenuSidebar = () => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(true);
  const [width, setWindowWidth] = useState(0);
  const breakPointWidth = 767;
  useEffect(() => {
    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };
  const data = useSelector((state) => state);
  const profileData = useSelector((state) => state?.profile);
  const handleMenuSelection = (name) => {
    if (data?.dashboard?.selectedMenu === name) {
      navigate(`/${name}`);
    } else {
      dispatch(updateMenuSelection(name));
      setCollapsed(true);
    }
  };

  useEffect(() => {
    if (data?.dashboard?.selectedMenu) {
      const name = data?.dashboard?.selectedMenu;
      if (name === "home") {
        navigate("/");
      } else if (name === "logout") {
        authLogout({
          id_token_hint: data?.token?.accessToken,
          state: data?.token?.state,
        });
      } else if (name != "") {
        navigate(`/${name}`);
      }
    }
  }, [data?.dashboard?.selectedMenu]);

  const location = useLocation();
  // console.log(location?.pathname)

  const { state, nonce, accessToken } = useSelector((state) => state?.token);
  const dispatch = useDispatch();
  const generateParams = () => {
    dispatch(generateStateAndNonce());
  };
  useEffect(() => {
    const path = location?.pathname;
    if (
      !accessToken &&
      path !== "/403" &&
      process.env.REACT_APP_ENABLE_ONE_LOGIN
    ) {
      if (state === null || nonce === null) {
        generateParams();
      } else {
        const hash = location.hash;
        const response = parse(hash);
        if (!response.error) {
          localStorage.setItem("path", path);
          beginAuth({ state, nonce });
        }
      }
    }
  }, [accessToken, state]);

  const menuItems = [
    {
      key: "home",
      icon: (
        <img
          src={`${process.env.REACT_APP_URL}/images/home.svg`}
          width="22px"
          height="22px"
          alt=""
        />
      ),
      label: "Home",
      visible: true,
    },
    {
      key: "financial",
      icon: (
        <img
          src={`${process.env.REACT_APP_URL}/images/dashboard/finance.svg`}
          width="22px"
          height="22px"
          alt=""
        />
      ),
      label: "Financial",
      visible:
        profileData?.isAdmin ||
        checkFinancialAccess(profileData) ||
        checkFCAccess(profileData),
    },
    {
      key: "marketing",
      icon: (
        <img
          src={`${process.env.REACT_APP_URL}/images/dashboard/marketing.svg`}
          width="22px"
          height="22px"
          alt=""
        />
      ),
      label: "Marketing",
      visible:
        profileData?.isAdmin ||
        checkMarketingAccess(profileData) ||
        checkFCAccess(profileData),
    },
    {
      key: "operational",
      icon: (
        <img
          src={`${process.env.REACT_APP_URL}/images/dashboard/operations.svg`}
          width="22px"
          height="22px"
          alt=""
        />
      ),
      label: "Operations",
      visible:
        profileData?.isAdmin ||
        checkOperationAccess(profileData) ||
        checkFCAccess(profileData),
    },
    {
      key: "franchise-sales",
      icon: (
        <img
          src={`${process.env.REACT_APP_URL}/images/dashboard/franchiseSale.svg`}
          width="22px"
          height="22px"
          alt=""
        />
      ),
      label: `Franchise Sales`,
      visible: profileData?.isAdmin,
    },
    {
      key: "development",
      icon: (
        <img
          src={`${process.env.REACT_APP_URL}/images/dashboard/developmentConstruction.svg`}
          width="22px"
          height="22px"
          alt=""
        />
      ),
      label: `Development &                                                               
      Construction`,
      visible: profileData?.isAdmin,
    },
    {
      key: "supply-chain",
      icon: (
        <img
          src={`${process.env.REACT_APP_URL}/images/dashboard/supplyChain.svg`}
          width="22px"
          height="22px"
          alt=""
        />
      ),
      label: "Supply Chain or R & D",
      visible: profileData?.isAdmin,
    },
    {
      key: "legal",
      icon: (
        <img
          src={`${process.env.REACT_APP_URL}/images/dashboard/Legal.svg`}
          width="22px"
          height="22px"
          alt=""
        />
      ),
      label: "Legal",
      visible: profileData?.isAdmin,
    },
    {
      key: "hr",
      icon: (
        <img
          src={`${process.env.REACT_APP_URL}/images/dashboard/humanResource.svg`}
          width="22px"
          height="22px"
          alt=""
        />
      ),
      label: "Human Resource",
      visible: profileData?.isAdmin,
    },
    {
      key: "ceo",
      icon: (
        <img
          src={`${process.env.REACT_APP_URL}/images/dashboard/ceo_image.png`}
          width="22px"
          height="22px"
          alt=""
        />
      ),
      label: "CEO",
      visible: profileData?.isAdmin,
    },
  ];

  const logoutMenus = [
    {
      key: "logout",
      icon: (
        <img
          src={`${process.env.REACT_APP_URL}/images/logout.svg`}
          width="22px"
          height="22px"
          alt=""
        />
      ),
      label: "Logout",
    },
  ];
  if (width < breakPointWidth) {
    return (
      <Layout>
        {!collapsed && (
          <Sider
            trigger={null}
            collapsible
            width={"100%"}
            collapsed={collapsed}
          >
            <div className="collapse-toggle d-flex justify-content-end">
              <span
                className="fa fa-close fa-lg px-4 pt-4"
                style={{ color: "red", fontSize: "28px" }}
                onClick={() => setCollapsed(!collapsed)}
              ></span>
            </div>
            <Menu
              className="menu_custom"
              onClick={(e) => handleMenuSelection(e.key)}
              // theme="dark"
              mode="inline"
              items={menuItems}
              selectedKeys={[`${data?.dashboard?.selectedMenu}`]}
            />
            <Menu
              className="menu_custom logout_menu"
              onClick={(e) => handleMenuSelection(e.key)}
              mode="inline"
              items={logoutMenus}
            />
          </Sider>
        )}
        <Header
          style={{
            background: "#FFF",
            height: "70px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="logo-img">
            <img src="/ritas-logo.svg" alt="" />
          </div>
          <div className="collapse-toggle">
            <img
              src="/toggle.svg"
              onClick={() => setCollapsed(!collapsed)}
              alt=""
            />
          </div>
        </Header>
        <Content
          style={{
            margin: "24px 60px",
            padding: 24,
            minHeight: 280,
          }}
        >
          <Routes>
            {MainRoutes.map((route) => {
              return route.component ? (
                <Route
                  path={route?.path}
                  element={route?.component}
                  key={route?.key}
                />
              ) : (
                <></>
              );
            })}
          </Routes>
        </Content>
      </Layout>
    );
  }
  return (
    <Layout className="Main-Layout">
      {collapsed ? (
        <>
          <Sider
            className="ant-layout-sider"
            trigger={null}
            collapsible
            collapsed={collapsed}
          >
            <div className="d-flex">
              <div className="d-flex justify-content-center m-2 w-100">
                <img
                  src={`${process.env.REACT_APP_URL}/images/logo.svg`}
                  width="40px"
                  height="40px"
                  alt=""
                />
              </div>
            </div>

            <Menu
              className="menu_custom"
              onClick={(e) => handleMenuSelection(e.key)}
              // theme="dark"
              mode="inline"
              // defaultSelectedKeys={["1"]}
              items={menuItems?.filter((i) => i?.visible)}
              selectedKeys={[`${data?.dashboard?.selectedMenu}`]}
            />
            <Menu
              className="menu_custom logout_menu"
              onClick={(e) => handleMenuSelection(e.key)}
              mode="inline"
              defaultSelectedKeys={["3"]}
              items={logoutMenus}
            />
            {/* </div> */}
            <div className="trigger">
              <img
                src="/rightArrowCollapse.svg"
                onClick={() => setCollapsed(!collapsed)}
                alt=""
              />
            </div>
          </Sider>
        </>
      ) : (
        <>
          <Sider
            className="ant-layout-sider-expanded"
            trigger={null}
            collapsible
            collapsed={collapsed}
          >
            <div className="logo-item">
              <div className="">
                <img
                  src={`${process.env.REACT_APP_URL}/images/logo.svg`}
                  width="35px"
                  height="35px"
                  alt=""
                />
              </div>
              <div className="logo-header">{"Rita's Dashboard"}</div>
            </div>
            <Menu
              className="menu_custom"
              onClick={(e) => handleMenuSelection(e.key)}
              // theme="dark"
              mode="inline"
              // defaultSelectedKeys={["1"]}
              items={menuItems?.filter((i) => i?.visible)}
              selectedKeys={[`${data?.dashboard?.selectedMenu}`]}
            />
            <Menu
              className="menu_custom logout_menu"
              onClick={(e) => handleMenuSelection(e.key)}
              mode="inline"
              defaultSelectedKeys={["3"]}
              items={logoutMenus}
            />
            {/* </div> */}
            <div className="trigger">
              <img
                src="/leftArrowCollapse.svg"
                onClick={() => setCollapsed(!collapsed)}
                alt=""
              />
            </div>
          </Sider>
        </>
      )}
      <Layout className="site-layout">
        <Content
          style={{
            margin: "24px 60px",
            padding: 24,
            minHeight: 280,
            // background: "#EEEFFF",
          }}
        >
          <Routes>
            {MainRoutes.map((route) => {
              return route.component ? (
                <Route
                  path={route?.path}
                  element={route?.component}
                  key={route?.key}
                />
              ) : (
                <></>
              );
            })}
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};
export default MenuSidebar;
