import CEODashboard from "../components/CEODashboard";
import YTDRanking from "../components/CEODashboard/Ranking/YTDRanking";
import CummulativeRanking from "../components/FinancialDashboard/CummulativeRanking";
const CeoRoutes = [
  {
    path: "ceo",
    name: "CEODashboard",
    key: "CEODashboard",
    component: <CEODashboard />,
  },
  {
    path: "ceo/ytd-rankings",
    name: "CEOYTDRanking",
    key: "CEOYTDRanking",
    component: <YTDRanking type="year" title={"YTD Ranking Report"} />,
  },

  {
    path: "ceo/weekly-rankings",
    name: "CEOWeeklyRanking",
    key: "CEOMonthlyRanking",
    component: <YTDRanking type="week" title={"Weekly Ranking Report"} />,
  },
  {
    path: "ceo/daily-rankings",
    name: "CEODailyRanking",
    key: "CEODailyRanking",
    component: <YTDRanking type="day" title={"Daily Ranking Report"} />,
  },
  {
    path: "/ceo/rank-report",
    name: "ceoCummulativeRanking",
    key: "ceoCummulativeRanking",
    component: <CummulativeRanking />,
  },
];

export default CeoRoutes;
