import { Button, Card, Segmented } from "antd";
import React from "react";
import useMainLoyaltyReport from "./MainLoyaltyReport.hook";
import { useNavigate } from "react-router-dom";
import YearPicker from "../../YearPicker";
import LoyaltyVisits from "./LoyaltyVisitsReport/LoyaltyVisits.view";

const MainLoyaltyReport = () => {
  const navigate = useNavigate();
  const { selectedView, handleSegmentSelection, width } = useMainLoyaltyReport();
  return (
    <Card
      title={
        <>
          <div className="d-flex justify-content-between">
            <div>
              <Button
                type="primary"
                className="btn-back"
                onClick={() => navigate("/marketing")}
              >
                <i
                  className="fa fa-arrow-left"
                  style={{ fontSize: "16px" }}
                ></i>
              </Button>
              <span>{"Loyalty & Guest Count Correlation "}</span>
            </div>
            <div>
              {selectedView !=="Daily" &&  <YearPicker />}
            </div>
          </div>
        </>
      }
    >
      {" "}
      <>
        <div className="show-monthly-selection pb-2">
          <Segmented
            options={["Yearly", "Quarterly", "Monthly", "Weekly"]}
            value={selectedView}
            onChange={(e) => handleSegmentSelection(e)}
          />
        </div>
        <div className="">
        <LoyaltyVisits selectedView={selectedView} width={width} />
        </div>
      </>
    </Card>
  );
};

export default MainLoyaltyReport;
