import React from "react";
import { useNavigate, useParams } from "react-router";
import { Button } from "antd";
import SalesByLineofBusiness from "../SalesByLineofBusiness";
import TotalSytemSales from "../TotalSytemSales";
import DigitalSalesStoreWise from "./DigitalSalesStoreWise";
import YearPicker from "../../../YearPicker";
import YTDStoreDigitalSales from "./YTDStoreDigitalSales";
import HourlySalesByStore from "../../../OperationalDashboard/HourlySalesByStore";
const YTDSales = (props) => {
  const { storeId = null } = useParams();
  const navigate = useNavigate();
  const handleClick = (name) => {
    navigate(`/${name}`);
  };
  const handleGoBack = (Id) => {
    // if (Id) {
    //   navigate(`/${props?.from}/store-track-report/${Id}`);
    // } else {
    //   navigate("/financial");
    // }
    navigate(-1)
  };
  return (
    <>
      <div className="d-flex justify-content-between align-items-center p-2">
        <Button
          type="primary"
          ghost
          className="m-2 btn-back"
          onClick={() => handleGoBack(storeId)}
        >
          {/* {storeId ? `Go to Store Tracking` : `Go to Dashboard`} */}
          <i
                            className="fa fa-arrow-left"
                            style={{ fontSize: "16px" }}
                          ></i>
        </Button>
        <YearPicker />
      </div>
      <div className="grid-container-overview">
        <div className="ytd-grid-item one">
          <TotalSytemSales
            type="year"
            storeId={storeId}
            title={
              storeId
                ? `Total System Sales (Store:${storeId})`
                : "Total System Sales"
            }
            child={true}
            handleFullView={() => handleClick("financial/total-system-sales")}
          />
        </div>
        <div className="ytd-grid-item  two">
          <SalesByLineofBusiness
            type="year"
            storeId={storeId}
            title={
              storeId
                ? `Total System Sales By Line of Business (Store:${storeId})`
                : "Total System Sales By Line of Business"
            }
            child={true}
            handleFullView={() =>
              handleClick("financial/sales-line-of-business")
            }
          />
        </div>
        {storeId && (
          <div className="ytd-grid-item">
            <YTDStoreDigitalSales
              storeId={storeId}
              title={`Digital Sales (Store:${storeId})`}
            />
          </div>
        )}
        {storeId && (
          <div className="ytd-grid-item">
            <HourlySalesByStore store={storeId} skeleton={true} />
          </div>
        )}
        {/* {!storeId && (
          <div className="ytd-grid-item">
            <SalesByRegion
              type="year"
              storeId={storeId}
              title={
                storeId
                  ? `Total System Sales By Region (Store:${storeId})`
                  : "Total System Sales By Region"
              }
              child={true}
              handleFullView={() =>
                handleClick("financial/ytd-sales-by-region")
              }
            />
          </div>
        )} */}
        {!storeId && (
          <div className="ytd-grid-item">
            <DigitalSalesStoreWise
              child={true}
              handleFullView={() => handleClick("financial/digital-3pd")}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default YTDSales;
