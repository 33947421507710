import React from "react";
import { useDispatch } from "react-redux";
import { Button, Card, Row } from "antd";
import { Link } from "react-router-dom";
import { Typography } from "antd";
import { updateMenuSelection } from "../../redux/dashboard/dashboardSlice";
const { Title } = Typography;

const FranchiseSalesDashboard = () => {
  const dispatch = useDispatch();

  return (
    <Card
      className="dashboard-antd-card"
      title={
        <>
          <Button
            type="primary"
            className="btn-back"
            onClick={() => dispatch(updateMenuSelection("home"))}
          >
            <i className="fa fa-arrow-left" style={{ fontSize: "16px" }}></i>
          </Button>
          <span>{"Franchise Sales Dashboard"}</span>
        </>
      }
    >
      <div className="parent-flex-container mt-5">
        <Card
          className="report_Card"
          headStyle={{
            color: "#044A2A !important",
            width: "100%",
            fontSize: "14px !important",
          }}
          title={
            <Title
              style={{ color: "#044A2A !important", fontSize: "16px" }}
              level={5}
            >
              Franchise Sales
            </Title>
          }
          hoverable={true}
          bordered={true}
        >
          <Row type="flex" style={{ color: "#aaaaaa" }}>
            <Link to="/franchise-sales/fc-lease-details">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>FC Lease Details
                  </div>
                </div>
              </div>
            </Link>
          </Row>
          <Row type="flex" style={{ color: "#aaaaaa" }}>
            <Link to="">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>Open Leads
                  </div>
                </div>
              </div>
            </Link>
          </Row>
          <Row type="flex" style={{ color: "#aaaaaa" }}>
            <Link to="">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>Transfers
                  </div>
                </div>
              </div>
            </Link>
          </Row>
          <Row type="flex" style={{ color: "#aaaaaa" }}>
            <Link to="">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>Open Contracts
                  </div>
                </div>
              </div>
            </Link>
          </Row>
        </Card>
      </div>
    </Card>
  );
};

export default FranchiseSalesDashboard;
