import moment from "moment";
import { useSelector } from "react-redux";
import { NumberFormat } from "../../../utils";

const SummaryLoyaltyReport = (props) => {
  const { dataItem, selectedView,activeUsersData=null } = props;
  const {
    selectedYear,
    selectedQuarter,
    selectedMonth,
    selectedWeek,
    selectedDay,
  } = useSelector((state) => state?.customSelection);
  let All=false
  if(selectedView === "Yearly" || (selectedView === "Monthly" && selectedMonth === 0) ||
  (selectedView === "Quarterly" && selectedQuarter === 0) ||
  (selectedView === "Weekly" && selectedWeek === 0)){
    All=true
  }
  return (
    <>
      <table className="summary-table">
        <thead>
          {selectedView === "Yearly" && (
            <tr>
              <th>Year</th>
              <th>:</th>
              <th>{selectedYear}</th>
            </tr>
          )}
          {selectedView === "Quarterly" && (
            <tr>
              <th>Quarter</th>
              <th>:</th>
              <th>{selectedQuarter===0?`${selectedYear} - All Quarters`:`${selectedYear} - Q${selectedQuarter}`}</th>
            </tr>
          )}
          {selectedView === "Monthly" && (
            <tr>
              <th>Month</th>
              <th>:</th>
              <th>{selectedMonth===0 ?`${selectedYear}- All Months`:`${selectedYear}-${moment(selectedMonth, "M").format(
                "MMMM"
              )}`}</th>
            </tr>
          )}
            {selectedView === "Weekly" && selectedWeek !== -1 && (
            <tr>
              <th>Week</th>
              <th>:</th>
              <th>{selectedWeek===0 ? `${selectedYear} - All Weeks`:`${selectedYear} - Week ${selectedWeek}`}</th>
            </tr>
          )}
          {selectedView === "Weekly" && selectedWeek === -1 && (
            <tr>
              <th>Date</th>
              <th>:</th>
              <th>{`${moment().add(-1,'days').format("MM/DD")}/${selectedYear}`}</th>
            </tr>
          )}
          {selectedView === "Daily" && (
            <tr>
              <th>Date</th>
              <th>:</th>
              <th>{selectedDay}</th>
            </tr>
          )}
          <tr>
            <th>Guest Count</th>
            <th>:</th>
            <th>{NumberFormat(dataItem?.GuestCount)}</th>
          </tr>
          <tr>
            <th>Loyalty Visits</th>
            <th>:</th>
            <th>{NumberFormat(dataItem?.loyaltyVisits)}</th>
          </tr>
          {All && (<>
            <tr>
            <th>Active Users</th>
            <th>:</th>
            <th>{NumberFormat(activeUsersData?.ActiveUsers)}</th>
            </tr>
            <tr>
            <th>Downloads (Android)</th>
            <th>:</th>
            <th>{NumberFormat(activeUsersData?.Android)}</th>
            </tr>
            <tr>
            <th>Downloads (IOS)</th>
            <th>:</th>
            <th>{NumberFormat(activeUsersData?.IOS)}</th>
            </tr>
          </>
          )}
        </thead>
      </table>
    </>
  );
};

export default SummaryLoyaltyReport;
