import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import DashboardLayout from "./layouts/DashboardLayout";
import EmbeddedLayout from "./layouts/EmbeddedLayout";

function App() {
  return (
    <div className="">
      <Routes>
        <Route path="/app/*" element={<EmbeddedLayout />} />
        <Route path="/*" element={<DashboardLayout />}></Route>
      </Routes>
    </div>
  );
}

export default App;
