import React, { useState } from "react";
import { Button, Card, Radio, Segmented } from "antd";
import { useNavigate } from "react-router-dom";
import SalesByLineofBusiness from "./SalesByLineofBusiness";
const CummulativeSalesLineofBusiness = () => {
  const navigate = useNavigate();
  const [mode, setMode] = useState("Year");
  return (
    <>
      <Card
        title={
          <>
           <Button
              type="primary"
              className="btn-back"
              onClick={() => navigate("/financial")}
            >
              <i className="fa fa-arrow-left" style={{ fontSize: "16px" }}></i>
            </Button>
            <span>{"Total System Sales By Line of Business"}</span>
          </>
        }
      >
        <>
          <div className="ranking-btn-group pb-2">
            <Segmented
              options={["Year","Quarter","Month","Day"]}
              value={mode}
              onChange={(e) => setMode(e)}
            />
          </div>
          {mode === "Year" && (
            <SalesByLineofBusiness
              type="year"
              skeleton={true}
              title={"Current Year Total System Sales By Line of Business"}
            />
          )}
          {mode === "Quarter" && (
            <SalesByLineofBusiness
              type="quarter"
              skeleton={true}
              title={"Current Quarter Total System Sales By Line of Business"}
            />
          )}
          {mode === "Month" && (
            <SalesByLineofBusiness
              type="month"
              skeleton={true}
              title={"Current Month Total System Sales By Line of Business"}
            />
          )}
          {mode === "Week" && (
            <SalesByLineofBusiness
              type="week"
              skeleton={true}
              title={"Current Week Total System Sales By Line of Business"}
            />
          )}
          {mode === "Day" && (
            <SalesByLineofBusiness
              type="day"
              skeleton={true}
              title={"Current Day Total System Sales By Line of Business"}
            />
          )}
        </>
      </Card>
    </>
  );
};

export default CummulativeSalesLineofBusiness;
