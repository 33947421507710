import { Button, Card, Segmented } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import useMainSystemSales from "./MainSystemSales.hook";
import ConsolidatedSystemSales from "./SalesReports/ConsolidatedSystemSales.view";
import YearPicker from "../../YearPicker";

const MainSystemSales = () => {
  const navigate = useNavigate();
  const { selectedView, handleSegmentSelection, width } = useMainSystemSales();
  return (
    <Card
      title={
        <>
          <div className="d-flex justify-content-between">
            <div>
              <Button
                type="primary"
                className="btn-back"
                onClick={() => navigate("/financial")}
              >
                <i
                  className="fa fa-arrow-left"
                  style={{ fontSize: "16px" }}
                ></i>
              </Button>
              <span>{"Total System Sales"}</span>
            </div>
            <div>
              {selectedView !=="Daily" &&  <YearPicker />}
            </div>
          </div>
        </>
      }
    >
      {" "}
      <>
        <div className="show-monthly-selection">
          <Segmented
            options={["Yearly", "Quarterly", "Monthly", "Weekly"]}
            value={selectedView}
            onChange={(e) => handleSegmentSelection(e)}
          />
        </div>
        <div className="">
        <ConsolidatedSystemSales selectedView={selectedView} width={width} />
        </div>
      </>
    </Card>
  );
};

export default MainSystemSales;
