import FinancialDashboard from "../components/FinancialDashboard";
import GuestCount from "../components/FinancialDashboard/Guest/GuestCount";
import YTDGuestCountByRegion from "../components/FinancialDashboard/Guest/Yearly/YTDGuestCountByRegion";
import GuestsRank from "../components/FinancialDashboard/Ranking/GuestsRank";
import SalesRank from "../components/FinancialDashboard/Ranking/SalesRank";
import FCRankingReport from "../components/FinancialDashboard/Reconciliation/FCRankingReport";
import FCRollup from "../components/FinancialDashboard/Reconciliation/FCRollup";
import RegionRollup from "../components/FinancialDashboard/Reconciliation/RegionRollup";
import DayMonthRankingReport from "../components/FinancialDashboard/Sales/DayAndMonthSales/DayMonthRankingReport";
import DayMonthSalesByLineofBusiness from "../components/FinancialDashboard/Sales/DayAndMonthSales/DayMonthSalesByLineofBusiness";
import DayMonthSalesByRegion from "../components/FinancialDashboard/Sales/DayAndMonthSales/DayMonthSalesByRegion";
import TotalDayMonthSystemSales from "../components/FinancialDashboard/Sales/DayAndMonthSales/TotalDayMonthSystemSales";
import Day from "../components/FinancialDashboard/Sales/DaySales";
import Month from "../components/FinancialDashboard/Sales/MonthSales";
import Quarter from "../components/FinancialDashboard/Sales/QuarterSales";
import RankingReport from "../components/FinancialDashboard/Sales/RankingReport";
import SalesByLineofBusiness from "../components/FinancialDashboard/Sales/SalesByLineofBusiness";
import SalesByRegion from "../components/FinancialDashboard/Sales/SalesByRegion";
import TotalSytemSales from "../components/FinancialDashboard/Sales/TotalSytemSales";
import Week from "../components/FinancialDashboard/Sales/WeekSales";
import DigitalSalesStoreWise from "../components/FinancialDashboard/Sales/YTDSales/DigitalSalesStoreWise";
import YTDSales from "../components/FinancialDashboard/Sales/YTDSales";
import CumulativeGuestReport from "../components/FinancialDashboard/Guest/CumulativeGuestReport";
import CummulativeRanking from "../components/FinancialDashboard/CummulativeRanking";
import CummulativeSalesLineofBusiness from "../components/FinancialDashboard/Sales/CummulativeSalesLineofBusiness";
import MainSystemSales from "../components/FinancialDashboard/TotalSystemSales/MainSystemSales.view";

const FinancialRoutes = [
  {
    path: "/financial",
    name: "FinancialDashboard",
    key: "FinancialDashboard",
    component: <FinancialDashboard />,
  },
  {
    path: "/financial/ytd",
    name: "YTDSales",
    key: "YTDSales",
    component: <YTDSales />,
  },
  {
    path: "/financial/ytd/:storeId",
    name: "YTDSales",
    key: "YTDSales",
    component: <YTDSales />,
  },
  {
    path: "/financial/total-system-sales",
    name: "TotalSytemSales",
    key: "TotalSytemSales",
    component: <MainSystemSales />,
  },
  {
    path: "/financial/ytd-sales",
    name: "YearTotalSytemSales",
    key: "YearTotalSytemSales",
    component: <TotalSytemSales type="year" title={"Total System Sales"} />,
  },
  {
    path: "/financial/sales-line-of-business",
    name: "SalesByLineofBusiness",
    key: "SalesByLineofBusiness",
    component: <CummulativeSalesLineofBusiness />,
  },
  {
    path: "/financial/ytd-sales-by-region",
    name: "YearSalesByRegion",
    key: "YearSalesByRegion",
    component: (
      <SalesByRegion type="year" title={"Total System Sales By Region"} />
    ),
  },
  {
    path: "/financial/digital-3pd",
    name: "DigitalSales",
    key: "DigitalSales",
    component: <DigitalSalesStoreWise />,
  },
  {
    path: "/financial/month",
    name: "Month",
    key: "Month",
    component: <Month />,
  },
  {
    path: "/financial/month-sales",
    name: "MonthTotalSytemSales",
    key: "MonthTotalSytemSales",
    component: (
      <TotalSytemSales
        type="month"
        title={"Current Month Total System Sales"}
      />
    ),
  },
  {
    path: "/financial/month-sales-line-of-business",
    name: "MonthSalesByLineofBusiness",
    key: "MonthSalesByLineofBusiness",
    component: (
      <SalesByLineofBusiness
        type="month"
        title={"Month Total System Sales By Line of Business"}
      />
    ),
  },
  {
    path: "/financial/month-sales-by-region",
    name: "MonthSalesByRegion",
    key: "MonthSalesByRegion",
    component: (
      <SalesByRegion
        type="month"
        title={"Current Month Total System Sales By Region"}
      />
    ),
  },
  {
    path: "/financial/week",
    name: "Week",
    key: "Week",
    component: <Week />,
  },
  {
    path: "/financial/week-sales",
    name: "WeekTotalSytemSales",
    key: "WeekTotalSytemSales",
    component: (
      <TotalSytemSales type="week" title={"Current Week Total System Sales"} />
    ),
  },
  {
    path: "/financial/week-sales-line-of-business",
    name: "WeekSalesByLineofBusiness",
    key: "WeekSalesByLineofBusiness",
    component: (
      <SalesByLineofBusiness
        type="week"
        title={"Week Total System Sales By Line of Business"}
      />
    ),
  },
  {
    path: "/financial/week-sales-by-region",
    name: "WeekSalesByRegion",
    key: "WeekSalesByRegion",
    component: (
      <SalesByRegion
        type="week"
        title={"Current Week Total System Sales By Region"}
      />
    ),
  },
  {
    path: "/financial/day",
    name: "Day",
    key: "Day",
    component: <Day />,
  },
  {
    path: "/financial/day-sales",
    name: "DayTotalSytemSales",
    key: "DayTotalSytemSales",
    component: (
      <TotalSytemSales type="day" title={"Current Day Total System Sales"} />
    ),
  },
  {
    path: "/financial/day-sales-line-of-business",
    name: "DaySalesByLineofBusiness",
    key: "DaySalesByLineofBusiness",
    component: (
      <SalesByLineofBusiness
        type="day"
        title={"Current Day Total System Sales By Line of Business"}
      />
    ),
  },
  {
    path: "/financial/day-sales-by-region",
    name: "DaySalesByRegion",
    key: "DaySalesByRegion",
    component: (
      <SalesByRegion
        type="day"
        title={"Current Day Total System Sales By Region"}
      />
    ),
  },
  {
    path: "/financial/cumulative-guest",
    name: "YTDGuestCount",
    key: "YTDGuestCount",
    component: <CumulativeGuestReport />,
  },
  {
    path: "/financial/ytd-guest",
    name: "YTDGuestCount",
    key: "YTDGuestCount",
    component: <GuestCount type="year" title={"Yearly Total Guest Count"} />,
  },
  {
    path: "/financial/ytd-guest-region",
    name: "YTDGuestCountByRegion",
    key: "YTDGuestCountByRegion",
    component: <YTDGuestCountByRegion />,
  },
  {
    path: "/financial/month-guest",
    name: "MonthGuestCount",
    key: "MonthGuestCount",
    component: <GuestCount type="month" title={"Month Total Guest Count"} />,
  },
  {
    path: "/financial/week-guest",
    name: "WeekGuestCount",
    key: "WeekGuestCount",
    component: <GuestCount type="week" title={"Week Total Guest Count"} />,
  },
  {
    path: "/financial/day-guest",
    name: "DayGuestCount",
    key: "DayGuestCount",
    component: <GuestCount type="day" title={"Day Total Guest Count"} />,
  },
  {
    path: "/financial/guests-rank",
    name: "GuestRankingReport",
    key: "GuestRankingReport",
    component: <GuestsRank />,
  },
  {
    path: "/financial/sales-rank",
    name: "SalesRankingReport",
    key: "SalesRankingReport",
    component: <SalesRank />,
  },
  {
    path: "/financial/rank-report",
    name: "RankReport",
    key: "RankReport",
    component: <CummulativeRanking />,
  },
  {
    path: "/financial/year-rank-report",
    name: "YearRankReport",
    key: "YearRankReport",
    component: <RankingReport type="year" title={"YTD Ranking Report"} />,
  },
  {
    path: "/financial/month-rank-report",
    name: "MonthRankReport",
    key: "MonthRankReport",
    component: (
      <RankingReport type="month" title={"Month Total Ranking Report"} />
    ),
  },
  {
    path: "/financial/week-rank-report",
    name: "WeekRankReport",
    key: "WeekRankReport",
    component: (
      <RankingReport type="week" title={"Week Total Ranking Report"} />
    ),
  },
  {
    path: "/financial/day-rank-report",
    name: "DayRankReport",
    key: "DayRankReport",
    component: <RankingReport type="day" title={"Day Total Ranking Report"} />,
  },
  {
    path: "/financial/fc-ranking",
    name: "FCRanking",
    key: "FCRanking",
    component: <FCRankingReport />,
  },
  {
    path: "/financial/fc-rollup",
    name: "FCRollup",
    key: "FCRollup",
    component: <FCRollup />,
  },
  {
    path: "/financial/region-rollup",
    name: "RegionRollup",
    key: "RegionRollup",
    component: <RegionRollup />,
  },

  {
    path: "/financial/day-month-sales",
    name: "DayMonthTotalSytemSales",
    key: "DayMonthTotalSytemSales",
    component: <TotalDayMonthSystemSales />,
  },
  {
    path: "/financial/day-month-sales-line-of-business",
    name: "DayMonthSalesByLineofBusiness",
    key: "DayMonthSalesByLineofBusiness",
    component: <DayMonthSalesByLineofBusiness />,
  },
  {
    path: "/financial/day-month-sales-by-region",
    name: "DayMonthSalesByRegion",
    key: "DayMonthSalesByRegion",
    component: <DayMonthSalesByRegion />,
  },
  {
    path: "/financial/day-month-rank-report",
    name: "DayMonthRankingReport",
    key: "DayMonthRankingReport",
    component: <DayMonthRankingReport />,
  },
  {
    path: "/financial/quarter",
    name: "Quarter",
    key: "Quarter",
    component: <Quarter />,
  },
  {
    path: "/financial/quarter-sales",
    name: "QuarterTotalSytemSales",
    key: "QuarterTotalSytemSales",
    component: (
      <TotalSytemSales
        type="quarter"
        title={"Current Quarter Total System Sales"}
      />
    ),
  },
  {
    path: "/financial/quarter-sales-line-of-business",
    name: "QuarterSalesByLineofBusiness",
    key: "QuarterSalesByLineofBusiness",
    component: (
      <SalesByLineofBusiness
        type="quarter"
        title={"Current Quarter Total System Sales By Line of Business"}
      />
    ),
  },
  {
    path: "/financial/quarter-sales-by-region",
    name: "QuarterSalesByRegion",
    key: "QuarterSalesByRegion",
    component: (
      <SalesByRegion
        type="quarter"
        title={"Current Quarter Total System Sales By Region"}
      />
    ),
  },
  {
    path: "/financial/quarter-rank-report",
    name: "QuarterRankReport",
    key: "QuarterRankReport",
    component: (
      <RankingReport
        type="quarter"
        title={"Current Quarter Total Ranking Report"}
      />
    ),
  },
];

export default FinancialRoutes;
