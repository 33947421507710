import axios from "axios";

export class CommonServiceUtils {
  postData = (url, req = null) =>
    new Promise((resolve, reject) => {
      axios
        .post(url, req)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err?.message);
        });
    });

  getData = (url, req = null) =>
    new Promise((resolve, reject) => {
      axios
        .get(url, req)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });

  patchData = (url, req = null) =>
    new Promise((resolve, reject) => {
      axios
        .patch(url, req)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });

  deleteData = (url) =>
    new Promise((resolve, reject) => {
      axios
        .delete(url)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });

  apiUrlCreator = (endPoint) => {
    return `${process.env.REACT_APP_API_URL}/api/${endPoint}`;
  };
}
