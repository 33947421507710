import React from "react";
import { Card, Row, Col } from "antd";
import { DashboardLoader } from "../utils/Loading";
import { useDispatch, useSelector } from "react-redux";
import { updateMenuSelection } from "../redux/dashboard/dashboardSlice";
import {
  checkFCAccess,
  checkFinancialAccess,
  checkMarketingAccess,
  checkOperationAccess,
} from "../utils";

const Dashboard = () => {
  const data = useSelector((state) => state);
  const dispatch = useDispatch();
  const handleClick = (name) => {
    dispatch(updateMenuSelection(name));
    // navigate(`/${name}`);
  };
  const { accessToken } = useSelector((state) => state?.token);
  const profileData = useSelector((state) => state?.profile);
  if (!accessToken || !profileData) {
    return (
      <>
        {" "}
        <div className="site-card-wrapper">
          <div className="main-dashboard-container">
            <DashboardLoader />
          </div>
        </div>
      </>
    );
  }
  return (
    <div className="site-card-wrapper">
      <div className="main-dashboard-container-header">
        <Row type="flex" align="middle">
          <Col>
            <div
              className="welcome_msg"
              style={{
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {`Welcome back ${data?.profile ? data?.profile?.given_name : ""}`}
            </div>
            <div className="quote_msg">
              <b className="quote_linear">Let's get started with a dashboard</b>
            </div>
          </Col>
        </Row>
      </div>

      <div className="main-dashboard-container">
        {(profileData?.isAdmin ||
          checkFinancialAccess(profileData) ||
          checkFCAccess(profileData)) && (
          <Card
            className="main-dashboard-item"
            bordered={false}
            onClick={() => handleClick("financial")}
          >
            <div className="main-dashboard-child-item">
              <img
                alt=""
                src="./images/dashboard/finance.svg"
                width="48px"
                height="48px"
              />{" "}
              Financial
            </div>
          </Card>
        )}
        {(profileData?.isAdmin ||
          checkMarketingAccess(profileData) ||
          checkFCAccess(profileData)) && (
          <Card
            className="main-dashboard-item"
            bordered={false}
            onClick={() => handleClick("marketing")}
          >
            <div className="main-dashboard-child-item">
              <img
                alt=""
                src="./images/dashboard/marketing.svg"
                width="48px"
                height="48px"
              />{" "}
              Marketing
            </div>
          </Card>
        )}
        {(profileData?.isAdmin ||
          checkOperationAccess(profileData) ||
          checkFCAccess(profileData)) && (
          <Card
            className="main-dashboard-item"
            bordered={false}
            onClick={() => handleClick("operational")}
          >
            <div className="main-dashboard-child-item">
              <img
                alt=""
                src="./images/dashboard/operations.svg"
                width="48px"
                height="48px"
              />{" "}
              Operations
            </div>
          </Card>
        )}
        <Card
          className="main-dashboard-item"
          bordered={false}
          onClick={() => handleClick("franchise-sales")}
        >
          <div className="main-dashboard-child-item">
            <img
              alt=""
              src="./images/dashboard/franchiseSale.svg"
              width="48px"
              height="48px"
            />{" "}
            Franchise Sales
          </div>
        </Card>
        {profileData?.isAdmin && (
          <Card
            className="main-dashboard-item"
            bordered={false}
            onClick={() => handleClick("development")}
          >
            <div className="main-dashboard-child-item">
              <img
                alt=""
                src="./images/dashboard/developmentConstruction.svg"
                width="48px"
                height="48px"
              />{" "}
              {`Development & 
              Construction`}
            </div>
          </Card>
        )}
        <Card
          className="main-dashboard-item"
          bordered={false}
          onClick={() => handleClick("supply-chain")}
        >
          <div className="main-dashboard-child-item">
            <img
              alt=""
              src="./images/dashboard/supplyChain.svg"
              width="48px"
              height="48px"
            />{" "}
            Supply Chain & Research and Development
          </div>
        </Card>
        <Card
          className="main-dashboard-item"
          bordered={false}
          onClick={() => handleClick("legal")}
        >
          <div className="main-dashboard-child-item">
            <img
              alt=""
              src="./images/dashboard/Legal.svg"
              width="48px"
              height="48px"
            />{" "}
            Legal
          </div>
        </Card>
        <Card
          className="main-dashboard-item"
          bordered={false}
          onClick={() => handleClick("hr")}
        >
          <div className="main-dashboard-child-item">
            <img
              alt=""
              src="./images/dashboard/humanResource.svg"
              width="48px"
              height="48px"
            />{" "}
            Human Resource
          </div>
        </Card>
        {profileData?.isAdmin && (
          <Card
            className="main-dashboard-item"
            bordered={false}
            onClick={() => handleClick("ceo")}
          >
            <div className="main-dashboard-child-item">
              <img
                alt=""
                src="./images/dashboard/ceo_image.png"
                width="48px"
                height="48px"
                // style={{borderRadius:'50%',borderColor:'#D410423B'}}
              />{" "}
              CEO
            </div>
          </Card>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
