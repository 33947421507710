import React from "react";
import MenuSidebar from "../components/MenuSidebar";

const DashboardLayout = () => {
  return (
    <div className="App">
      <MenuSidebar />
    </div>
  );
};

export default DashboardLayout;
