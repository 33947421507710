import {
  CurrencyFormat,
  NumberFormat,
  generateRedeemFullName,
  generateRedeemptionFullName,
  getYearName,
  getYearNameOnly,
} from ".";
import { AllBarDataLegends, BarLegends, PieLegends } from "./legend";
import {
  SvgChg,
  SvgCurrentYear,
  SvgMixes,
  SvgPreviousYear,
  SvgTransaction,
  SvgYieldMixes,
} from "./svgpath";

export const CustomTooltipWithChg = ({
  active,
  payload,
  roundOff = false,
  selectedYear = null,
}) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        {payload?.map((i) => {
          let svg = <></>;
          let displayName = "";
          if (i?.dataKey === "currentYear") {
            svg = SvgCurrentYear();
            displayName = getYearName("currentYear", selectedYear);
          } else if (i?.dataKey === "priorYear") {
            svg = SvgPreviousYear();
            displayName = getYearName("priorYear", selectedYear);
          } else if (i?.dataKey === "chg") {
            svg = SvgChg();
            displayName = "Chg";
          }
          return (
            <>
              <p className="label">
                {svg}
                {displayName} :{" "}
                {i?.dataKey === "chg"
                  ? `${i?.value} %`
                  : `${CurrencyFormat(i?.value, roundOff)}`}
              </p>
            </>
          );
        })}
      </div>
    );
  }

  return null;
};

export const CustomTooltipWithoutChg = ({
  active,
  payload,
  roundOff = false,
  selectedYear = null,
}) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        {payload?.map((i) => {
          let svg = <></>;
          let displayName = "";
          if (i?.dataKey === "currentYear") {
            svg = SvgCurrentYear();
            displayName = getYearName("currentYear", selectedYear);
          } else if (i?.dataKey === "priorYear") {
            svg = SvgPreviousYear();
            displayName = getYearName("priorYear", selectedYear);
          } else if (i?.dataKey === "Chg") {
            svg = SvgChg();
          }
          return (
            <>
              <p className="label">
                {svg}
                {displayName} : {`${CurrencyFormat(i?.value, roundOff)}`}
              </p>
            </>
          );
        })}
      </div>
    );
  }

  return null;
};
export const CustomTooltipWeekView = ({
  active,
  payload,
  roundOff = false,
  selectedYear = null,
}) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        {payload?.map((i) => {
          let svg = <></>;
          let displayName = "";
          if (i?.dataKey === "currentYear") {
            svg = SvgCurrentYear();
            displayName = `${i?.payload?.displayName} ${getYearName(
              "currentYear",
              selectedYear
            )}`;
          } else if (i?.dataKey === "priorYear") {
            svg = SvgPreviousYear();
            displayName = `${i?.payload?.displayName} ${getYearName(
              "priorYear",
              selectedYear
            )}`;
          }
          return (
            <>
              <p className="label">
                {svg}
                {displayName} : {`${CurrencyFormat(i?.value, roundOff)}`}
              </p>
            </>
          );
        })}
      </div>
    );
  }

  return null;
};

export const GuestTooltipWithChg = ({
  active,
  payload,
  selectedYear = null,
}) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        {payload?.map((i) => {
          let svg = <></>;
          let displayName = "";
          if (i?.dataKey === "currentYear") {
            svg = SvgCurrentYear();
            displayName = getYearNameOnly("currentYear", selectedYear);
          } else if (i?.dataKey === "priorYear") {
            svg = SvgPreviousYear();
            displayName = getYearNameOnly("priorYear", selectedYear);
          } else if (i?.dataKey === "chg") {
            svg = SvgChg();
            displayName = "Chg";
          }
          return (
            <>
              <p className="label">
                {svg}
                {displayName} :{" "}
                {i?.dataKey === "chg" ? `${i?.value} %` : i?.value}
              </p>
            </>
          );
        })}
      </div>
    );
  }

  return null;
};

export const CustomTooltipForSales = ({
  active,
  payload,
  label,
  roundOff = false,
}) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        {payload?.map((i) => {
          let svg = <></>;
          if (i?.payload?.name === "currentYear") {
            svg = SvgCurrentYear();
          } else if (i?.payload?.name === "priorYear") {
            svg = SvgPreviousYear();
          }
          return (
            <>
              <p className="label">
                {svg}
                {i?.payload?.displayName} : {CurrencyFormat(i?.value, roundOff)}
              </p>
            </>
          );
        })}
      </div>
    );
  }
  return null;
};
export const CustomTooltipForYearGuest = ({
  active,
  payload,
  label,
  selectedYear = null,
}) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        {payload?.map((i) => {
          let svg = <></>;
          if (i?.payload?.name === "currentYear") {
            svg = SvgCurrentYear();
          } else if (i?.payload?.name === "priorYear") {
            svg = SvgPreviousYear();
          }
          return (
            <>
              <p className="label">
                {svg}
                {i?.payload?.displayName} : {i?.value}
              </p>
            </>
          );
        })}
      </div>
    );
  }
  return null;
};
export const CustomTooltipForMixes = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        {payload?.map((i) => {
          return (
            <>
              <p className="label">
                {SvgMixes()}
                {i?.payload?.ItemName} : {i?.payload?.YTDMix} %
              </p>
            </>
          );
        })}
      </div>
    );
  }
  return null;
};
export const CustomTooltipForYieldsMixes = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        {payload?.map((i) => {
          return (
            <>
              <p className="label">
                {SvgYieldMixes()}
                {i?.payload?.ItemName} : {i?.payload?.YTDMix} %
              </p>
            </>
          );
        })}
      </div>
    );
  }
  return null;
};

export const CustomTooltipWithFCRollupSales = ({
  active,
  payload,
  roundOff = false,
  selectedYear = null,
}) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <b>FC: {payload[0]?.payload?.FC}</b>
        {payload?.map((i) => {
          let svg = <></>;
          let displayName = "";
          if (i?.dataKey === "currentYear") {
            svg = SvgCurrentYear();
            displayName = getYearName("currentYear", selectedYear);
          } else if (i?.dataKey === "priorYear") {
            svg = SvgPreviousYear();
            displayName = getYearName("priorYear", selectedYear);
          } else if (i?.dataKey === "stores") {
            svg = SvgChg();
            displayName = "Stores";
          }
          return (
            <>
              <p className="label">
                {svg}
                {displayName} :{" "}
                {i?.dataKey === "stores"
                  ? `${i?.value}`
                  : `${CurrencyFormat(i?.value, roundOff)}`}
              </p>
            </>
          );
        })}
      </div>
    );
  }

  return null;
};
export const CustomTooltipWithFCRollupGuests = ({
  active,
  payload,
  selectedYear = null,
}) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <b>FC: {payload[0]?.payload?.FC}</b>
        {payload?.map((i) => {
          let svg = <></>;
          let displayName = "";
          if (i?.dataKey === "currentYear") {
            svg = SvgCurrentYear();
            displayName = getYearNameOnly("currentYear", selectedYear);
          } else if (i?.dataKey === "priorYear") {
            svg = SvgPreviousYear();
            displayName = getYearNameOnly("priorYear", selectedYear);
          } else if (i?.dataKey === "stores") {
            svg = SvgChg();
            displayName = "Stores";
          }
          return (
            <>
              <p className="label">
                {svg}
                {displayName} : {i?.value}
              </p>
            </>
          );
        })}
      </div>
    );
  }

  return null;
};

export const CustomTooltipWithRegionRollupSales = ({
  active,
  payload,
  roundOff = false,
  selectedYear = null,
}) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <b>Region Name: {payload[0]?.payload?.Region}</b>
        {payload?.map((i) => {
          let svg = <></>;
          let displayName = "";
          if (i?.dataKey === "currentYear") {
            svg = SvgCurrentYear();
            displayName = getYearName("currentYear", selectedYear);
          } else if (i?.dataKey === "priorYear") {
            svg = SvgPreviousYear();
            displayName = getYearName("priorYear", selectedYear);
          } else if (i?.dataKey === "stores") {
            svg = SvgChg();
            displayName = "Stores";
          }
          return (
            <>
              <p className="label">
                {svg}
                {displayName} :{" "}
                {i?.dataKey === "stores"
                  ? `${i?.value}`
                  : `${CurrencyFormat(i?.value, roundOff)}`}
              </p>
            </>
          );
        })}
      </div>
    );
  }

  return null;
};
export const CustomTooltipWithRegionRollupGuests = ({
  active,
  payload,
  selectedYear = null,
}) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <b>Region Name: {payload[0]?.payload?.Region}</b>
        {payload?.map((i) => {
          let svg = <></>;
          let displayName = "";
          if (i?.dataKey === "currentYear") {
            svg = SvgCurrentYear();
            displayName = getYearNameOnly("currentYear", selectedYear);
          } else if (i?.dataKey === "priorYear") {
            svg = SvgPreviousYear();
            displayName = getYearNameOnly("priorYear", selectedYear);
          } else if (i?.dataKey === "stores") {
            svg = SvgChg();
            displayName = "Stores";
          }
          return (
            <>
              <p className="label">
                {svg}
                {displayName} : {i?.value}
              </p>
            </>
          );
        })}
      </div>
    );
  }

  return null;
};

export const CustomTooltipForDigitalSales = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        {payload?.map((i) => {
          const svg = SvgCurrentYear();
          return (
            <>
              <p className="label">
                {svg}
                {i?.payload?.name} : {i?.value}
              </p>
            </>
          );
        })}
      </div>
    );
  }
  return null;
};

export const CustomTooltipForStoreTrackingSales = ({
  active,
  payload,
  label,
  roundOff = false,
}) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        {payload?.map((i) => {
          const svg = SvgCurrentYear();

          return (
            <>
              <p className="label">
                {svg}
                {i?.payload?.Year} : {CurrencyFormat(i?.value, roundOff)}
              </p>
            </>
          );
        })}
      </div>
    );
  }
  return null;
};
export const CustomTooltipForStoreTrackingHourlySales = ({
  active,
  payload,
  label,
  roundOff = false,
}) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        {payload?.map((i) => {
          const svg = SvgCurrentYear();

          return (
            <>
              <p className="label">
                {svg}
                {i?.payload?.time} : {CurrencyFormat(i?.value, roundOff)}
              </p>
            </>
          );
        })}
      </div>
    );
  }
  return null;
};

export const CustomTooltipLoyaltyReport = ({
  active,
  payload,
  selectedYear = null,
  all = false,
  selectedView = "",
}) => {
  let showHeading = false;
  let heading = "";
  if (all) {
    const headingName = payload[0]?.payload?.name;
    switch (selectedView) {
      case "Quarterly":
        heading = headingName;
        break;
      case "Monthly":
        heading = `${headingName}`;
        break;
      case "Weekly":
        heading = `Week - ${headingName}`;
        break;
      default:
        heading = "";
        break;
    }
    showHeading = true;
  }
  if (active && payload && payload.length) {
    return (
      <>
        <div className="custom-tooltip">
          {showHeading && <h6>{heading}</h6>}
          {payload?.map((i) => {
            if (i?.chartType === "prior") {
              return (
                <>
                  <p className="label">
                    {SvgTransaction(PieLegends["Loyalty Visits"])}
                    {generateRedeemptionFullName(
                      "Prior Visits",
                      selectedYear
                    )} : {NumberFormat(i?.payload["Prior Visits"])}
                  </p>
                  <p className="label">
                    {SvgTransaction(PieLegends["Guest Count"])}
                    {generateRedeemptionFullName("Prior GC", selectedYear)} :{" "}
                    {NumberFormat(i?.payload["Prior GC"])}
                  </p>
                </>
              );
            } else {
              return (
                <>
                  <p className="label">
                    {SvgTransaction(PieLegends["Loyalty Visits"])}
                    {generateRedeemptionFullName(
                      "Current Visits",
                      selectedYear
                    )} : {NumberFormat(i?.payload["Current Visits"])}
                  </p>
                  <p className="label">
                    {SvgTransaction(PieLegends["Guest Count"])}
                    {generateRedeemptionFullName("Current GC", selectedYear)} :{" "}
                    {NumberFormat(i?.payload["Current GC"])}
                  </p>
                </>
              );
            }
          })}
        </div>
      </>
    );
  }

  return null;
};
export const CustomTooltipRedeemReport = ({
  active,
  payload,
  selectedYear = null,
  name,
  all = false,
  keyName = "",
  selectedView = "",
}) => {
  if (active && payload && payload.length) {
    let showHeading = false;
    let heading = "";
    if (all) {
      const headingName = payload[0]?.payload?.name;
      switch (selectedView) {
        case "Quarterly":
          heading = headingName;
          break;
        case "Monthly":
          heading = `${headingName}`;
          break;
        case "Weekly":
          heading = `Week - ${headingName}`;
          break;
        default:
          heading = "";
          break;
      }
      showHeading = true;
    }
    return (
      <div className="custom-tooltip">
        {showHeading && <h6>{heading}</h6>}
        {payload?.map((i) => {
          let svg;
          if (i?.dataKey === name) {
            svg = SvgTransaction(BarLegends["Redeemptions"]);
          } else {
            svg = SvgTransaction(BarLegends["Guest Count"]);
          }
          let displayName = i?.dataKey;

          if (all) {
            svg = SvgTransaction(AllBarDataLegends[i?.dataKey]);
            displayName = generateRedeemptionFullName(i?.dataKey, selectedYear);
            if (
              i.dataKey === "Current Redeemptions" ||
              i.dataKey === "Prior Redeemptions"
            ) {
              displayName = generateRedeemptionFullName(
                i?.dataKey,
                selectedYear,
                keyName
              );
            }
          }

          return (
            <>
              <p className="label">
                {svg}
                {displayName} : {NumberFormat(i?.value)}
              </p>
            </>
          );
        })}
      </div>
    );
  }

  return null;
};
export const CustomTooltipRedeemStackedReport = ({
  active,
  payload,
  selectedYear = null,
  name,
  all = false,
  keyName = "",
  selectedView = "",
}) => {
  console.log(payload);
  if (active && payload && payload.length) {
    let showHeading = false;
    let heading = "";
    if (all) {
      const headingName = payload[0]?.payload?.name;
      switch (selectedView) {
        case "Quarterly":
          heading = headingName;
          break;
        case "Monthly":
          heading = `${headingName}`;
          break;
        case "Weekly":
          heading = `Week - ${headingName}`;
          break;
        default:
          heading = "";
          break;
      }
      showHeading = true;
    }
    return (
      <div className="custom-tooltip">
        {showHeading && <h6>{heading}</h6>}
        {payload?.map((i) => {
          if (i?.chartType === "prior") {
            return (
              <>
                <p className="label">
                  {SvgTransaction(AllBarDataLegends["PriorBirthday"])}
                  {generateRedeemFullName("PriorBirthday", selectedYear)} :{" "}
                  {NumberFormat(i?.payload?.PriorBirthday)}
                </p>
                <p className="label">
                  {SvgTransaction(AllBarDataLegends["PriorCoupon"])}
                  {generateRedeemFullName("PriorCoupon", selectedYear)} :{" "}
                  {NumberFormat(i?.payload?.PriorCoupon)}
                </p>
                <p className="label">
                  {SvgTransaction(AllBarDataLegends["PriorCustomer"])}
                  {generateRedeemFullName("PriorCustomer", selectedYear)} :{" "}
                  {NumberFormat(i?.payload?.PriorCustomer)}
                </p>
                <p className="label">
                  {SvgTransaction(AllBarDataLegends["PriorEarned"])}
                  {generateRedeemFullName("PriorEarned", selectedYear)} :{" "}
                  {NumberFormat(i?.payload?.PriorEarned)}
                </p>
              </>
            );
          } else {
            return (
              <>
                <p className="label">
                  {SvgTransaction(AllBarDataLegends["CurrentBirthday"])}
                  {generateRedeemFullName(
                    "CurrentBirthday",
                    selectedYear
                  )} : {NumberFormat(i?.payload?.CurrentBirthday)}
                </p>
                <p className="label">
                  {SvgTransaction(AllBarDataLegends["CurrentCoupon"])}
                  {generateRedeemFullName("CurrentCoupon", selectedYear)} :{" "}
                  {NumberFormat(i?.payload?.CurrentCoupon)}
                </p>
                <p className="label">
                  {SvgTransaction(AllBarDataLegends["CurrentCustomer"])}
                  {generateRedeemFullName(
                    "CurrentCustomer",
                    selectedYear
                  )} : {NumberFormat(i?.payload?.CurrentCustomer)}
                </p>
                <p className="label">
                  {SvgTransaction(AllBarDataLegends["CurrentEarned"])}
                  {generateRedeemFullName("CurrentEarned", selectedYear)} :{" "}
                  {NumberFormat(i?.payload?.CurrentEarned)}
                </p>
              </>
            );
          }
        })}
      </div>
    );
  }

  return null;
};

export const PieChartToolTip = ({ payload, active }) => {
  if (active && payload) {
    const svg = SvgTransaction(PieLegends[payload[0]?.name]);
    return (
      <div className="custom-tooltip">
        <p className="label">
          {svg}
          {payload[0]?.name} : {NumberFormat(payload[0]?.value)}
        </p>
      </div>
    );
  }

  return null;
};
