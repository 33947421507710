import { Button, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { FinancialService } from "../../../../services/financial/index.service";
import {
  checkFCAccess,
  chunkArray,
  CurrencyFormat,
  generateDownLoadPath,
  getYearName,
  NumberFormat,
  ShowArrow,
  YAxisNumberFormat,
} from "../../../../utils";
import { Legends } from "../../../../utils/legend";
import { DashboardLoader } from "../../../../utils/Loading";
import {
  SvgChg,
  SvgCurrentYear,
  SvgPreviousYear,
} from "../../../../utils/svgpath";
import { CustomTooltipWeekView } from "../../../../utils/Tooltip";
import SummarySalesTable from "../SummarySalesTable";


const WeekWiseSales = (props) => {
  const service = new FinancialService();
   const { selectedYear, roundOff } = useSelector((state) => state?.customSelection);
  const profile = useSelector((state) => state?.profile) || null;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [maxValue, setMaxValue] = useState(0);
  const [maindata, setMainData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const breakPointWidth = 767;
  const [excelLoading, setExcelLoading] = useState(false);

  useEffect(() => {
    fetchApi();
  }, [
    props?.excludeLessThanYearStore,
    selectedYear,
    roundOff,
    props?.excludeInactiveStore,
  ]);

  const handleExportExcel = async () => {
    setExcelLoading(true);
    let data = null;
    let payload = {
      excludeLessThanYearStore: props?.excludeLessThanYearStore,
      excludeInactiveStore: props?.excludeInactiveStore,
      selectedYear,
      roundOff,
    };
    if (checkFCAccess(profile)) {
      payload = { ...payload, intFSM: profile?.intFSM };
    }
    data = await service?.getCurrentYtdWeekViewExport(payload);
    const url = generateDownLoadPath(data?.path, data?.fileName);

    setExcelLoading(false);
    window.open(url, "_blank");
  };
  const fetchApi = async () => {
    let payload = {
      excludeLessThanYearStore: props?.excludeLessThanYearStore,
      excludeInactiveStore: props?.excludeInactiveStore,
      selectedYear,
      roundOff,
    };
    if (checkFCAccess(profile)) {
      payload = { ...payload, intFSM: profile?.intFSM };
    }
    setLoading(true);
    const res = await service?.fetchYtdWeeklyWiseTotalSales(payload);
    const result = res?.WeekWiseSales?.map((i) => {
      const displayCurrentYearName = getYearName("currentYear", selectedYear);
      const displayPriorYearName = getYearName("priorYear", selectedYear);
      return {
        ...i,
        displayName: `${i?.Week}`,
        [displayCurrentYearName]: i?.currentYear,
        [displayPriorYearName]: i?.priorYear,
      };
    });
    setMainData(res);
    setData(result || []);
    const currentYearMax = Math.max(...result.map((o) => o.currentYear));
    const priorYearMax = Math.max(...result.map((o) => o.priorYear));
    setMaxValue(Math.max(currentYearMax, priorYearMax) + 100);
    const test = chunkArray(result, 4);
    setTableData(test);
    setLoading(false);
  };
  return (
    <>
      {loading ? (
        <DashboardLoader />
      ) : (
        <>
          <Spin spinning={excelLoading}>
            {data?.length > 0 ? (
              <>
                <div className="total-system-sales">
                  {/* <div className="d-flex justify-content-end p-2">
                    <img
                      src="/DownLoadExcel.svg"
                      alt="DownLoadExcel"
                      className="DownLoadExcel"
                      onClick={() => handleExportExcel()}
                    />
                  </div> */}
                  <div className="show-monthly-selection pb-3">
                    <div className="summary-table-container">
                      <SummarySalesTable
                        selectedType={"year"}
                        dataItem={maindata?.ItemData}
                        roundOff={roundOff}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-end"></div>
                  <ResponsiveContainer
                    width="100%"
                    aspect={props?.width < breakPointWidth ? 2 : 4}
                  >
                    <BarChart
                      data={data}
                      margin={{ top: 5, right: 20, bottom: 5, left: 25 }}
                    >
                      <XAxis dataKey="Week" />
                      <YAxis
                        label={{
                          // value: "Amt ($)",
                          angle: 360,
                          position: "insideBottomRight",
                          offset: 10,
                        }}
                        // width={85}
                        domain={[0, (dataMax) => parseInt(maxValue).toFixed(2)]}
                        allowDataOverflow={true}
                        tickFormatter={YAxisNumberFormat}

                      />
                      <Tooltip
                        content={
                          <CustomTooltipWeekView
                            roundOff={roundOff}
                            selectedYear={selectedYear}
                          />
                        }
                        labelStyle={{ color: "darkblue" }}
                      />
                      <Bar
                        dataKey="currentYear"
                        fill={Legends["currentYear"]}
                        barSize={4}
                      />
                      <Bar
                        dataKey="priorYear"
                        fill={Legends["priorYear"]}
                        barSize={4}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                  <div className="week-table-container">
                    {maindata?.WeekWiseSalesChunks?.map((j, index) => {
                      return (
                        <>
                          <div className="table-container legend-table d-flex flex-column p-4">
                            <h6>{`Quarter ${index + 1}`}</h6>
                            <table key={props?.width}>
                              <thead>
                                <tr>
                                  <th>Week</th>
                                  <th>
                                    {" "}
                                    {SvgCurrentYear()}
                                    {getYearName("currentYear", selectedYear)}
                                    {ShowArrow(maindata?.ItemData?.currentArrow)}
                                  </th>
                                  <th>
                                    {" "}
                                    {SvgPreviousYear()}
                                    {getYearName("priorYear", selectedYear)}
                                    {ShowArrow(maindata?.ItemData?.priorArrow)}
                                  </th>
                                  <th>
                                    {" "}
                                    {SvgChg()}
                                    Chg %
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {j?.map((i) => {
                                  return (
                                    <tr>
                                      <td>{i?.Week}</td>
                                      <td
                                        style={{
                                          color:
                                            i?.currentYear < i?.priorYear
                                              ? `red`
                                              : `inherit`,
                                        }}
                                      >
                                        {CurrencyFormat(i?.currentYear, roundOff)}
                                      </td>
                                      <td
                                        style={{
                                          color:
                                            i?.priorYear < i?.currentYear
                                              ? `red`
                                              : `inherit`,
                                        }}
                                      >
                                        {CurrencyFormat(i?.priorYear, roundOff)}
                                      </td>
                                      <td>{`${i?.["chg"]} %`}</td>
                                    </tr>
                                  );
                                })}
                                <tr>
                                  <td>{"Total"}</td>
                                  <td
                                    style={{
                                      color:
                                        maindata?.WeekWiseSalesChunksTotal[index]
                                          ?.currentYear <
                                        maindata?.WeekWiseSalesChunksTotal[index]
                                          ?.priorYear
                                          ? `red`
                                          : `inherit`,
                                    }}
                                  >
                                    {CurrencyFormat(
                                      maindata?.WeekWiseSalesChunksTotal[index]
                                        ?.currentYear,
                                      roundOff
                                    )}
                                  </td>
                                  <td
                                    style={{
                                      color:
                                        maindata?.WeekWiseSalesChunksTotal[index]
                                          ?.priorYear <
                                        maindata?.WeekWiseSalesChunksTotal[index]
                                          ?.currentYear
                                          ? `red`
                                          : `inherit`,
                                    }}
                                  >
                                    {CurrencyFormat(
                                      maindata?.WeekWiseSalesChunksTotal[index]
                                        ?.priorYear,
                                      roundOff
                                    )}
                                  </td>
                                  <td>{`${maindata?.WeekWiseSalesChunksTotal[index]?.chg} %`}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="norecords-available">No records available</div>
              </>
            )}
          </Spin>
        </>
      )}
    </>
  );
};

export default WeekWiseSales;
