import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { checkIsAdmin } from "../../utils";
const initialState = null

const apiUrlCreator = (endPoint) => {
  return `${process.env.REACT_APP_API_URL}/api/${endPoint}`;
};

const getUserInfo = async (req) => {
  const reqPayload = {
    // vchUserName:`cholden`,//FC
    // vchUserName:`jharned`,//accounting
    // vchUserName:`pjurta`,//opeartions
    // vchUserName:`mweintraub`,//marketing
    vchUserName: req?.preferred_username,
  };
  const response = await axios.post(apiUrlCreator("login"), reqPayload);
  const obj = {
    ...req,
    ...response?.data?.result?.userData,
    isAdmin: checkIsAdmin(response?.data?.result?.userData),
    intFSM: response?.data?.result?.userData?.intContactID,
  };
  return obj;
};
export const getUserDetails = createAsyncThunk(
  "settings/getUserDetails",
  async (request, thunkAPI) => {
    try {
      return await getUserInfo(request);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const profileSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    reset: (state) => initialState,
    receiveProfileData: (state, action) => {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserDetails.pending, (state) => {})
      .addCase(getUserDetails.fulfilled, (state, action) => {
        return action.payload;
      })
      .addCase(getUserDetails.rejected, (state, action) => {});
  },
});

export const { reset } = profileSlice.actions;
export default profileSlice.reducer;
