import { Button, Card, Skeleton, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { FinancialService } from "../../../../services/financial/index.service";
import { CurrencyFormat, YAxisNumberFormat, checkFCAccess, getYearName } from "../../../../utils";
import { Legends } from "../../../../utils/legend";
import { DashboardLoader } from "../../../../utils/Loading";
import { SvgCurrentYear, SvgPreviousYear } from "../../../../utils/svgpath";
import { CustomTooltipWithoutChg } from "../../../../utils/Tooltip";

const MonthlyWiseSalesForRegion = (props) => {
  const { showMonthly, title } = props;
  const profile = useSelector((state) => state?.profile) || null;
  const service = new FinancialService();
  const [loading, setLoading] = useState(true);
  const [maxValue, setMaxValue] = useState(0);
  const [data, setData] = useState([]);
  const [excludeLessThanYearStore, setExcludeLessThanYearStore] =
    useState(false);
  const breakPointWidth = 767;

  useEffect(() => {
    fetchApi();
  }, [excludeLessThanYearStore]);

  const fetchApi = async () => {
    setLoading(true);
    let payload = { excludeLessThanYearStore };
    const req = showMonthly;
    if(checkFCAccess(profile)){
      payload={...payload,intFSM:profile?.intFSM}
    }
    const res = await service?.fetchYtdMonthlyWiseRegionSales(req, payload);
    const result = res?.map((i) => {
      const displayCurrentYearName = getYearName("currentYear");
      const displayPriorYearName = getYearName("priorYear");
      return {
        ...i,
        [displayCurrentYearName]: i?.currentYear,
        [displayPriorYearName]: i?.priorYear,
      };
    });
    setData(result || []);
    const currentYearMax = Math.max(...result.map((o) => o.currentYear));
    const priorYearMax = Math.max(...result.map((o) => o.priorYear));
    setMaxValue(Math.max(currentYearMax, priorYearMax) + 100);
    setLoading(false);
  };

  const handleToggle = (val) => {
    setExcludeLessThanYearStore(val);
  };

  return (
    <>
      <Card
        title={
          <>
            <Button
              type="primary"
              className="btn-back"
              onClick={() => props?.handleMonthlyView("")}
            >
              <i className="fa fa-arrow-left" style={{ fontSize: "16px" }}></i>
            </Button>
            <span>{`${title} (${showMonthly})`}</span>
          </>
        }
      >
        {loading ? (
          <DashboardLoader />
        ) : (
          <>
            {data?.length > 0 ? (
              <>
                <div className="total-system-sales">
                  {!props?.child && (
                    <div className="ranking-btn-group pb-2">
                      <b className="comp-sales">
                        {`Comp Sales (Excluding New Stores & Transfer Stores < 1 year) : `}
                        <Switch
                          checked={excludeLessThanYearStore}
                          onChange={handleToggle}
                          disabled={loading}
                        />
                      </b>
                    </div>
                  )}
                  <ResponsiveContainer
                    width="100%"
                    aspect={props?.width < breakPointWidth ? 2 : 4}
                  >
                    <BarChart
                      data={data}
                      margin={{ top: 5, right: 20, bottom: 5, left: 20 }}
                    >
                      <XAxis dataKey="month" />
                      <YAxis
                        label={{
                          // value: "Amt ($)",
                          angle: 360,
                          position: "insideBottomRight",
                          offset: 10,
                        }}
                        // width={120}
                        tickFormatter={YAxisNumberFormat}
                        domain={[0, (dataMax) => parseFloat(maxValue).toFixed(2)]}
                        allowDataOverflow={true}
                      />
                      <Tooltip
                        content={<CustomTooltipWithoutChg />}
                        labelStyle={{ color: "darkblue" }}
                      />
                      <Bar
                        dataKey="currentYear"
                        fill={Legends["currentYear"]}
                        barSize={props?.width < breakPointWidth ? 20 : 30}
                      />
                      <Bar
                        dataKey="priorYear"
                        fill={Legends["priorYear"]}
                        barSize={props?.width < breakPointWidth ? 20 : 30}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                  {props?.width < 991 ? (
                    <>
                      <div className="table-container">
                        <table key={props?.width}>
                          <thead>
                            <tr>
                              <th>Month</th>
                              <th>
                                {" "}
                                {SvgCurrentYear()}
                                {getYearName("currentYear")}
                              </th>
                              <th>
                                {" "}
                                {SvgPreviousYear()}
                                {getYearName("priorYear")}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.map((i) => {
                              return (
                                <tr>
                                  <td>{i?.month}</td>
                                  <td>{CurrencyFormat(i?.currentYear)}</td>
                                  <td>{CurrencyFormat(i?.priorYear)}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="table-container">
                        <table>
                          <thead>
                            <tr>
                              <th></th>
                              {data.map((i) => {
                                return (
                                  <>
                                    <td>{i?.month}</td>
                                  </>
                                );
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {SvgCurrentYear()}
                                {getYearName("currentYear")}
                              </td>
                              {data.map((i) => {
                                return (
                                  <>
                                    <td>{CurrencyFormat(i?.currentYear)}</td>
                                  </>
                                );
                              })}
                            </tr>
                            <tr>
                              <td>
                                {SvgPreviousYear()}
                                {getYearName("priorYear")}
                              </td>
                              {data.map((i) => {
                                return (
                                  <>
                                    <td>{CurrencyFormat(i?.priorYear)}</td>
                                  </>
                                );
                              })}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="norecords-available">No records available</div>
              </>
            )}
          </>
        )}
      </Card>
    </>
  );
};

export default MonthlyWiseSalesForRegion;
