import React, { useEffect, useState } from "react";
import { Cell, Pie, PieChart, Sector, Tooltip } from "recharts";
import { BREAKPOINTWIDTH } from "../../../../utils";
import { PieLegends } from "../../../../utils/legend";
import { PieChartToolTip } from "../../../../utils/Tooltip";

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = (props, mode = null, platform) => {
  const {
    cx,
    cy,
    midAngle,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    percent,
    value,
    name,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 8) * cos;
  const sy = cy + (outerRadius + 8) * sin;
  const mx = cx + (outerRadius + 20) * cos;
  const my = cy + (outerRadius + 20) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 11;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";
  const textLabel = name.split(" ");
  if(percent > 0){
    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >{`${textLabel[0]}`}</text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#333"
        >
          {`${textLabel[1]}`}
        </text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 20}
          y={ey}
          dy={36}
          textAnchor={textAnchor}
          fill="#999"
        >
          {/* {mode === null || mode === "In Percentage"
            ? `(${(percent * 100).toFixed(2)}%)`
            : `(${value} ${
                mode === "percentage"
                  ? "%"
                  : mode === "products"
                  ? "products"
                  : "stores"
              })`} */}
              {`(${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  }
};

const RedeemptionPieChart = (props) => {
  const { width, data } = props;
  const breakPointWidth = BREAKPOINTWIDTH;
  if (data?.length > 0) {
    return (
      <PieChart
        width={width < breakPointWidth ? 350 : 480}
        height={width < breakPointWidth ? 250 : 300}
      >
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          minAngle={width < breakPointWidth ? 40 : 30}
          outerRadius={width < breakPointWidth ? 40 : 80}
          labelLine={false}
          label={(props) => renderCustomizedLabel(props)}
        >
          {data?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={PieLegends[entry?.name]} />
          ))}
        </Pie>
        <Tooltip
        content={<PieChartToolTip />}
        labelStyle={{ color: "darkblue" }}
      />
      </PieChart>
    );
  } else {
    return (
      <div
        style={{

          width: width < breakPointWidth ? "350px" : "480px",
          height: width < breakPointWidth ? "250px" : "280px",
          background: "#FAFAFA",
        }}
        className="d-flex justify-content-center align-items-center text-muted"
      >
        {"No Data to display"}
      </div>
    );
  }
};

export default RedeemptionPieChart;
