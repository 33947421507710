import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { OperationalService } from "../../services/operational/index.service";

const useFeedBackDetails = () => {
  const { store = null } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);
  const service = new OperationalService();
  useEffect(() => {
    fetchFeedbackDetails();
  }, []);
  const handlePage = (pageNum) => {
    setPage(pageNum);
  };
  const fetchFeedbackDetails = async () => {
    const req = {
      ritasId: store,
    };
    setLoading(true);
    const res = await service.fetchStoreFeedbacks(req);
    setLoading(false);
    setData(res || []);
  };
  const columns = [
    {
      title: "Sr.No",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => (page - 1) * paginationSize + index + 1,
    },
    {
      title: "FeedBack By",
      dataIndex: "feedbackBy",
      key: "feedbackBy",
    },
    {
      title: "FeedBack Group",
      dataIndex: "feedbackGroup",
      key: "feedbackGroup",
      sorter: (a, b) => a?.feedbackGroup?.localeCompare(b?.feedbackGroup),
    },
    {
      title: "FeedBack Type",
      dataIndex: "feedbackType",
      key: "feedbackType",
      sorter: (a, b) => a?.feedbackType?.localeCompare(b?.feedbackType),
    },
    {
      title: "Details",
      dataIndex: "vchDetails",
      key: "vchDetails",
    },
    {
      title: "Received On",
      dataIndex: "dateReceived",
      key: "dateReceived",
      sorter: (a, b) =>
      moment(a.dateReceived).unix() -
      moment(b.dateReceived).unix(),
    },
    {
      title: "Source",
      dataIndex: "feedbackSource",
      key: "feedbackSource",
      sorter: (a, b) => a?.feedbackSource?.localeCompare(b?.feedbackSource),
    },
  ];

  return { data, loading, store, handlePage, page, columns };
};

export default useFeedBackDetails;
