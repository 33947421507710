import React, { useEffect, useState } from "react";
import { Button, Card, Segmented } from "antd";
import {
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Line,
  ComposedChart,
  ReferenceLine,
} from "recharts";
import { GuestTooltipWithChg } from "../../../../utils/Tooltip";
import {
  SvgChg,
  SvgCurrentYear,
  SvgPreviousYear,
} from "../../../../utils/svgpath";
import MonthlyWiseGuestsForRegion from "./MonthlyWiseGuestsForRegion";
import { FinancialService } from "../../../../services/financial/index.service";
import { getYearNameOnly, ShowArrow } from "../../../../utils";
import { Legends } from "../../../../utils/legend";
import { useNavigate } from "react-router-dom";
import { DashboardLoader } from "../../../../utils/Loading";

const YTDGuestCountByRegion = (props) => {
  const navigate = useNavigate();
  const service = new FinancialService();

  const [showMonthly, setshowMonthly] = useState("");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [maxValue, setMaxValue] = useState(0);
  const [maxChgValue, setMaxChgValue] = useState(0);
  const [minChgValue, setMinChgValue] = useState(0);
  const [width, setWindowWidth] = useState(0);
  const [selectedView, setSelectedView] = useState("Year");
  const breakPointWidth = 767;
  useEffect(() => {
    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };

  const handleMonthlyView = (name) => {
    setshowMonthly(name);
  };

  const handleSegmentSelection = (e) => {
    setSelectedView(e);
  };

  useEffect(() => {
    fetchApi();
  }, [selectedView]);

  const fetchApi = async () => {
    setLoading(true);
    let data = [];
    switch (selectedView) {
      case "Year":
        data = await service?.fetchYtdTotalGuestCountRegion();
        break;
      case "Month":
        data = await service?.fetchMonthTotalGuestCountRegion();
        break;
      case "Week":
        data = await service?.fetchWeekTotalGuestCountRegion();
        break;
      case "Day":
        data = await service?.fetchDayTotalGuestCountRegion();
        break;
      default:
        break;
    }

    const result = data?.SalesByRegion?.map((i) => {
      const displayCurrentYearName = getYearNameOnly("currentYear");
      const displayPriorYearName = getYearNameOnly("priorYear");
      return {
        ...i,
        [displayCurrentYearName]: i?.currentYear,
        [displayPriorYearName]: i?.priorYear,
      };
    });
    setData(result || []);
    setMainData(data);
    const removeTotal=result?.filter((i) => i?.Region !== "Total")
    const currentYearMax = Math.max(...removeTotal.map((o) => o.currentYear || 0));
    const priorYearMax = Math.max(...removeTotal.map((o) => o.priorYear || 0));
    setMaxValue(Math.max(currentYearMax, priorYearMax) + 100);
    setMaxChgValue(Math.max(...removeTotal.map((o) => o?.chg || 0)));
    setMinChgValue(Math.min(...removeTotal.map((o) => o?.chg || 0)));
    setLoading(false);
  };

  if (showMonthly !== "") {
    return (
      <>
        <MonthlyWiseGuestsForRegion
          showMonthly={showMonthly}
          width={width}
          handleMonthlyView={handleMonthlyView}
          ItemData={mainData?.ItemData}
        />
      </>
    );
  }

  const DefaultSummaryComponent = () => {
    return (
      <>
        {" "}
        {width < breakPointWidth ? (
          <>
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th>Region</th>
                    <th>
                      {SvgCurrentYear()}
                      {getYearNameOnly("currentYear")}
                      {ShowArrow(mainData?.ItemData?.currentArrow)}
                    </th>
                    <th>
                      {SvgPreviousYear()}
                      {getYearNameOnly("priorYear")}
                      {ShowArrow(mainData?.ItemData?.priorArrow)}
                    </th>
                    <th>
                      {SvgChg()}
                      Chg %
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((i) => {
                    return (
                      <tr>
                        <td>
                          {/* {!props?.child && i?.Region !== "Total"  ? (
                        <>
                          <span
                            onClick={() =>
                              handleMonthlyView(i?.Region)
                            }
                            className={"table-td btn btn-link"}
                          >
                            {i?.Region}{" "}
                            <i
                              className="fa fa-external-link"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </>
                      ) : (
                        <>{i?.Region}</>
                      )} */}
                          {i?.Region}
                        </td>
                        <td>{i?.currentYear}</td>
                        <td>{i?.priorYear}</td>
                        <td>{`${i?.["chg"]} %`}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <>
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    {data.map((i) => {
                      return (
                        <>
                          <td>
                            {/* {!props?.child &&
                        i?.Region !== "Total" ? (
                          <>
                            <span
                              onClick={() =>
                                handleMonthlyView(i?.Region)
                              }
                              className={"table-td btn btn-link"}
                            >
                              {i?.Region}{" "}
                              <i
                                className="fa fa-external-link"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </>
                        ) : (
                          <>{i?.Region}</>
                        )} */}
                            {i?.Region}
                          </td>
                        </>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {SvgCurrentYear()}
                      {getYearNameOnly("currentYear")}
                      {ShowArrow(mainData?.ItemData?.currentArrow)}
                    </td>
                    {data.map((i) => {
                      return (
                        <>
                          <td>{i?.currentYear}</td>
                        </>
                      );
                    })}
                  </tr>
                  <tr>
                    <td>
                      {SvgPreviousYear()}
                      {getYearNameOnly("priorYear")}
                      {ShowArrow(mainData?.ItemData?.priorArrow)}
                    </td>
                    {data.map((i) => {
                      return (
                        <>
                          <td>{i?.priorYear}</td>
                        </>
                      );
                    })}
                  </tr>
                  <tr>
                    <td>
                      {SvgChg()}
                      Chg %
                    </td>
                    {data.map((i) => {
                      return (
                        <>
                          <td>{`${i?.["chg"]} %`}</td>
                        </>
                      );
                    })}
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}
      </>
    );
  };

  const DefaultComponent = () => {
    return (
      <>
        <div className="total-system-sales">
          <ResponsiveContainer
            width="100%"
            aspect={width < breakPointWidth ? 2 : 4}
          >
            <ComposedChart
              data={data.filter((i) => i?.Region !== "Total")}
              width={400}
              margin={{
                top: 0,
                right: 20,
                left: 20,
                bottom: 0,
              }}
              height={300}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="Region" />
              <YAxis
                label={{
                  value: "Guest(s)",
                  angle: 360,
                  position: "insideBottomRight",
                  offset: 10,
                }}
                // width={100}
                domain={[0, (dataMax) => parseInt(maxValue).toFixed(2)]}
                allowDataOverflow={true}
              />
              <Tooltip
                content={<GuestTooltipWithChg />}
                labelStyle={{ color: "darkblue" }}
              />
              {/* <Legend verticalAlign="top" height={36} /> */}
              <Bar
                dataKey="currentYear"
                fill={Legends["currentYear"]}
                barSize={width < breakPointWidth ? 20 : 30}
              />
              <Bar
                dataKey="priorYear"
                fill={Legends["priorYear"]}
                barSize={width < breakPointWidth ? 20 : 30}
              />
              <Line
                dot={true}
                strokeWidth={2}
                dataKey="chg"
                stroke="#FFC000"
                yAxisId="right"
                name="chg"
              />
              {minChgValue < 0 && (
                <ReferenceLine
                  y={0}
                  stroke="green"
                  strokeDasharray="6 6"
                  yAxisId="right"
                  label={"0%"}
                />
              )}
              <YAxis
                tickLine={false}
                type={"number"}
                domain={[
                  (dataMin) => (minChgValue > 0 ? 0 : minChgValue).toFixed(2),
                  (dataMax) => (maxChgValue + 10).toFixed(2),
                ]}
                yAxisId="right"
                orientation="right"
                axisLine={{ stroke: "#f5f5f5" }}
                label={{
                  value: "Chg (%)",
                  angle: 360,
                  position: "insideBottomRight",
                  offset: 10,
                }}
                allowDataOverflow={true}
              />
            </ComposedChart>
          </ResponsiveContainer>
          {DefaultSummaryComponent()}
        </div>
      </>
    );
  };

  return (
    <>
      <>
        <Card
          title={
            <>
              {!props?.child && (
                <Button
                  type="primary"
                  className="btn-back"
                  onClick={() =>
                    showMonthly !== ""
                      ? setshowMonthly("")
                      : navigate("/financial")
                  }
                >
                  <i
                    className="fa fa-arrow-left"
                    style={{ fontSize: "16px" }}
                  ></i>
                </Button>
              )}
              <span>{`Total Guest Count By Region`}</span>
            </>
          }
        >
          <div className="table-div">
            <Segmented
              options={["Year", "Month", "Week", "Day"]}
              value={selectedView}
              disabled={loading}
              onChange={(e) => handleSegmentSelection(e)}
            />
          </div>
          {loading ? (
            <DashboardLoader />
          ) : (
            <>
              {data?.length > 0  && data.filter((i) => i?.Region !== "Total")?.length > 0? (
                <>
                  <DefaultComponent />
                  {/* <DefaultSummaryComponent /> */}
                </>
              ) : (
                <>
                  <div className="norecords-available">
                    No records available
                  </div>
                </>
              )}
            </>
          )}
        </Card>
      </>
    </>
  );
};

export default YTDGuestCountByRegion;
