import React, { useEffect, useState } from "react";
import { CoolnetService } from "../../../services/coolnet/index.service";
import moment from "moment";

const useStoreSummary = (props) => {

  const service = new CoolnetService();
  const [loading, setLoading] = useState(true);
  const [priorLoading, setPriorLoading] = useState(true);
  const [currentData, setCurrentData] = useState([]);
  const [priorData, setPriorData] = useState([]);
  const selectedYear=moment().year()
  useEffect(() => {
    fetchCurrentApi();
    fetchPriorApi();
  }, []);

  const fetchCurrentApi = async () => {
    let payload = {
      selectedYear,
      ritasId: props?.storeId
    };
    setLoading(true);
    const res = await service?.fetchStoreSummaryData(payload);
    setCurrentData(res?.summaryData);
    setLoading(false);
  };
  const fetchPriorApi = async () => {
    let payload = {
      selectedYear,
      ritasId: props?.storeId
    };
    setPriorLoading(true);
    const res = await service?.fetchPriorStoreSummaryData(payload);
    setPriorData(res?.summaryData);
    setPriorLoading(false);
  };
  return { loading, priorData,currentData,priorLoading };
};

export default useStoreSummary;
