import { Card, Spin } from "antd";
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  BREAKPOINTWIDTH,
  NumberFormat,
  ShowArrow,
  getXaxisName,
  getYearNameOnly,
} from "../../../../utils";
import {
  AllBarDataLegends,
  BarLegends,
  PieLegends,
} from "../../../../utils/legend";
import {
  CustomTooltipRedeemReport,
  CustomTooltipRedeemStackedReport,
} from "../../../../utils/Tooltip";
import { SvgTransaction } from "../../../../utils/svgpath";
import { DashboardLoader } from "../../../../utils/Loading";
import SummaryRedeemRewardsReport from "../SummaryRedeemRewardsReport";
import moment from "moment";
import useRedeemption from "./Redeemption.hook";
import RedeemptionPieChart from "./RedeemptionPieChart";
import QuarterPicker from "../../../QuarterPicker";
import MonthPicker from "../../../MonthPicker";
import WeekPicker from "../../../WeekPicker";
import RedeemptionTable from "./RedeemptionTable";
const Redeemption = (props) => {
  const breakPointWidth = BREAKPOINTWIDTH;
  const { width, selectedView } = props;
  const {
    loading,
    data,
    maxValue,
    selectedYear,
    maindata,
    customSelection,
    selectedMonth,
    selectedQuarter,
    selectedWeek,
    tableData,
    AllTableData,
  } = useRedeemption(props);
  let selctedViewValue = selectedView;
  let selectedDayValue = customSelection?.selectedDay;
  if (selectedView === "Weekly" && selectedWeek === -1) {
    selctedViewValue = "Daily";
    selectedDayValue = `${moment()
      .add(-1, "days")
      .format("MM/DD")}/${selectedYear}`;
  }
  let barSize = 35;
  if (selectedView === "Quarterly") {
    barSize = 30;
  } else if (selectedView === "Monthly") {
    barSize = 17;
  } else if (selectedView === "Weekly") {
    barSize = 5;
  }
  return (
    <Card>
      {loading ? (
        <DashboardLoader />
      ) : (
        <div className="total-system-sales">
          {/* <div className="table-div d-flex justify-content-center">
            <h6>Redeemptions</h6>
          </div> */}
          <div className={selectedView === "Yearly" ? "" : "tab"}>
            {selectedView === "Quarterly" && (
              <QuarterPicker disabled={loading} />
            )}
            {selectedView === "Monthly" && <MonthPicker disabled={loading} />}
            {selectedView === "Weekly" && <WeekPicker disabled={loading} />}
          </div>
          <div className="show-monthly-selection pb-3">
            <div className="summary-table-container">
              <SummaryRedeemRewardsReport
                dataItem={maindata?.ItemData}
                selectedView={selectedView}
                name={"Birthday Redeemptions"}
                value={maindata?.ItemData?.BirthdayRedeemptions}
              />
            </div>
          </div>
          {(selectedView === "Monthly" && selectedMonth === 0) ||
          (selectedView === "Quarterly" && selectedQuarter === 0) ||
          (selectedView === "Weekly" && selectedWeek === 0) ? (
            <>
              {" "}
              <ResponsiveContainer
                width="100%"
                aspect={width < breakPointWidth ? 2 : 4}
                // height={"350px"}
              >
                <BarChart
                  data={data}
                  margin={{ top: 5, right: 20, bottom: 5, left: 25 }}
                >
                  <XAxis dataKey="name" />
                  <YAxis
                    label={{
                      value: "Transactions",
                      angle: 360,
                      position: "insideBottomRight",
                      offset: 10,
                    }}
                    domain={[0, (dataMax) => parseInt(maxValue).toFixed(2)]}
                    allowDataOverflow={true}
                    tickFormatter={NumberFormat}
                  />
                  <Tooltip
                    content={
                      <CustomTooltipRedeemStackedReport
                        selectedYear={selectedYear}
                        all={true}
                        selectedView={selectedView}
                      />
                    }
                    shared={false}
                    labelStyle={{ color: "darkblue" }}
                  />
                  <Bar
                    dataKey="CurrentBirthday"
                    stackId="a"
                    barSize={barSize}
                    fill="#FFBB28"
                    chartType="current"
                  />
                  <Bar
                    dataKey="PriorBirthday"
                    stackId="b"
                    barSize={barSize}
                    fill="#FFBB28"
                    chartType="prior"
                  />
                  <Bar
                    dataKey="CurrentCoupon"
                    stackId="a"
                    barSize={barSize}
                    fill="#0088FE"
                    chartType="current"
                  />
                  <Bar
                    dataKey="PriorCoupon"
                    stackId="b"
                    barSize={barSize}
                    fill="#0088FE"
                    chartType="prior"
                  />
                  <Bar
                    dataKey="CurrentEarned"
                    stackId="a"
                    chartType="current"
                    barSize={barSize}
                    fill="#00C49F"
                  />
                  <Bar
                    dataKey="PriorEarned"
                    stackId="b"
                    barSize={barSize}
                    chartType="prior"
                    fill="#00C49F"
                  />
                  <Bar
                    dataKey="CurrentCustomer"
                    stackId="a"
                    barSize={barSize}
                    fill="#FF8042"
                    chartType="current"
                  />
                  <Bar
                    dataKey="PriorCustomer"
                    stackId="b"
                    chartType="prior"
                    barSize={barSize}
                    fill="#FF8042"
                  />
                </BarChart>
              </ResponsiveContainer>
            </>
          ) : (
            <>
              {" "}
              <div className="fc-items">
                <Spin spinning={loading}>
                  <div className="count-item">
                    <RedeemptionPieChart
                      width={width}
                      data={maindata?.currentYear}
                    />
                    <h6 className="d-flex justify-content-center">
                      {" "}
                      {getXaxisName("currentYear", selctedViewValue, {
                        ...customSelection,
                        selectedDay: selectedDayValue,
                      })}
                    </h6>
                  </div>
                </Spin>
                <Spin spinning={loading}>
                  <div className="percentage-item">
                    <RedeemptionPieChart
                      width={width}
                      data={maindata?.priorYear}
                    />
                    <h6 className="d-flex justify-content-center">
                      {" "}
                      {getXaxisName("priorYear", selctedViewValue, {
                        ...customSelection,
                        selectedDay: selectedDayValue,
                      })}
                    </h6>
                  </div>
                </Spin>
              </div>
            </>
          )}
          {(selectedView === "Monthly" && selectedMonth === 0) ||
          (selectedView === "Quarterly" && selectedQuarter === 0) ||
          (selectedView === "Weekly" && selectedWeek === 0) ? (
            <>
           <RedeemptionTable selectedView={selectedView} selectedYear={selectedYear} AllTableData={AllTableData} />
            </>
          ) : (
            <>
              <div className="table-container legend-table">
                <table>
                  <thead>
                    <tr>
                      <th>Rewards</th>
                      <th>{getYearNameOnly("currentYear", selectedYear)}</th>
                      <th>{getYearNameOnly("priorYear", selectedYear)}</th>
                      <th>{"Chg%"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {SvgTransaction(PieLegends["Birthday Redeemptions"])}
                        {"Birthday Redeemptions"}
                        {parseFloat(tableData["chg"]["Birthday Redeemptions"]) >
                        0
                          ? ShowArrow("up")
                          : ShowArrow("down")}
                      </td>
                      <td>
                        {NumberFormat(
                          tableData["currentYear"]["Birthday Redeemptions"]
                        )}
                      </td>
                      <td>
                        {NumberFormat(
                          tableData["priorYear"]["Birthday Redeemptions"]
                        )}
                      </td>
                      <td>{tableData["chg"]["Birthday Redeemptions"]}</td>
                    </tr>
                    <tr>
                      <td>
                        {SvgTransaction(PieLegends["Coupon Redeemptions"])}
                        {"Coupon Redeemptions"}
                        {parseFloat(tableData["chg"]["Coupon Redeemptions"]) > 0
                          ? ShowArrow("up")
                          : ShowArrow("down")}
                      </td>
                      <td>
                        {NumberFormat(
                          tableData["currentYear"]["Coupon Redeemptions"]
                        )}
                      </td>
                      <td>
                        {NumberFormat(
                          tableData["priorYear"]["Coupon Redeemptions"]
                        )}
                      </td>
                      <td>{tableData["chg"]["Coupon Redeemptions"]}</td>
                    </tr>
                    <tr>
                      <td>
                        {SvgTransaction(PieLegends["Earned Redeemptions"])}
                        {"Earned Redeemptions"}
                        {parseFloat(tableData["chg"]["Earned Redeemptions"]) > 0
                          ? ShowArrow("up")
                          : ShowArrow("down")}
                      </td>
                      <td>
                        {NumberFormat(
                          tableData["currentYear"]["Earned Redeemptions"]
                        )}
                      </td>
                      <td>
                        {NumberFormat(
                          tableData["priorYear"]["Earned Redeemptions"]
                        )}
                      </td>
                      <td>{tableData["chg"]["Earned Redeemptions"]}</td>
                    </tr>
                    <tr>
                      <td>
                        {SvgTransaction(PieLegends["Customer Appeasement"])}
                        {"Customer Appeasement"}
                        {parseFloat(tableData["chg"]["Customer Appeasement"]) >
                        0
                          ? ShowArrow("up")
                          : ShowArrow("down")}
                      </td>
                      <td>
                        {NumberFormat(
                          tableData["currentYear"]["Customer Appeasement"]
                        )}
                      </td>
                      <td>
                        {NumberFormat(
                          tableData["priorYear"]["Customer Appeasement"]
                        )}
                      </td>
                      <td>{tableData["chg"]["Customer Appeasement"]}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      )}
    </Card>
  );
};

export default Redeemption;
