import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { parse } from "query-string";
import jwtDecode from "jwt-decode";
import { DashboardLoader } from "../../utils/Loading";
import { getUserDetails } from "../../redux/profile/profileSlice";
import {
  receiveAccessToken,
  receiveIdToken,
} from "../../redux/token/tokenSlice";
import { checkUserAccess } from "../../utils";
import { authLogout } from "../../api/oidcApi";
const Auth = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const data = useSelector((state) => state);
  const receiveProfile = (data) => {
    dispatch(getUserDetails(data));
  };

  const logout = () => {
    authLogout({
      id_token_hint: data?.token?.accessToken,
      state: data?.token?.state,
    });
  };
  const receiveTokens = (accessToken, idToken) => {
    dispatch(receiveAccessToken(accessToken));
    dispatch(receiveIdToken(idToken));
  };
  const { profile } = useSelector((state) => state);

  useEffect(() => {
    if (isEmpty(profile)) {
      const hash = location.hash;
      const response = parse(hash);
      if (response.error) {
        navigate("/403");
        // return <></>;
      } else {
        // console.log(response);
        receiveTokens(response.access_token, response.id_token);
        receiveProfile(jwtDecode(response.id_token));
      }
      // return <DashboardLoader />;
    } else {
      const redirectTo=localStorage.getItem('path')
      if(checkUserAccess(data?.profile)){
        navigate(redirectTo);
      }else{
        logout()
        navigate("/403");
      }
      // return <></>;
    }

  }, [profile])
  

  return <DashboardLoader />
};

export default Auth;
