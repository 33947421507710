import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { CoolnetService } from "../../../services/coolnet/index.service";
import { getYearName } from "../../../utils";

const useWeekSales = (props) => {
  const { selectedYear } = useSelector(
    (state) => state?.customSelection
  );
  const service = new CoolnetService();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [maxValue, setMaxValue] = useState(0);
  const [maindata, setMainData] = useState(null);

  useEffect(() => {
    fetchApi();
  }, [selectedYear]);

  const fetchApi = async () => {
    let payload = {
      selectedYear,
      ritasId:props?.storeId
    };
    setLoading(true);
    const res = await service?.fetchYtdWeekSales(payload);
    const result = res?.WeekWiseSales?.map((i) => {
      const displayCurrentYearName = getYearName("currentYear", selectedYear);
      const displayPriorYearName = getYearName("priorYear", selectedYear);
      return {
        ...i,
        displayName: `${i?.Week}`,
        [displayCurrentYearName]: i?.currentYear,
        [displayPriorYearName]: i?.priorYear,
      };
    });
    setMainData(res);
    setData(result || []);
    const currentYearMax = Math.max(...result.map((o) => o.currentYear));
    const priorYearMax = Math.max(...result.map((o) => o.priorYear));
    setMaxValue(Math.max(currentYearMax, priorYearMax) + 100);
    setLoading(false);
  };
  return {  maindata, maxValue, loading, selectedYear, data };
};

export default useWeekSales;
