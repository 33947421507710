import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { FinancialService } from "../../../../services/financial/index.service";
import {
  checkFCAccess,
  generateDownLoadPath,
  getYearName,
} from "../../../../utils";
import { updateRoundOffSelection } from "../../../../redux/dashboard/customSelectionSlice";

const useConsolidatedSystemSales = (props) => {
  const service = new FinancialService();
  const dispatch = useDispatch();

  const { selectedView } = props;
  const {
    selectedYear,
    selectedQuarter,
    selectedMonth,
    selectedWeek,
    selectedDay,
    roundOff,
  } = useSelector((state) => state?.customSelection);
  const [excelLoading, setExcelLoading] = useState(false);
  const customSelection = useSelector((state) => state?.customSelection);
  const profile = useSelector((state) => state?.profile) || null;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [maxValue, setMaxValue] = useState(0);
  const [maindata, setMainData] = useState(null);
  const [barData, setBarData] = useState([]);
  const [excludeLessThanYearStore, setExcludeLessThanYearStore] =
    useState(false);
  const [selectedCompleteYear, setSelectedCompleteYear] = useState(false);
  const [excludeInactiveStore, setExcludeInactiveStore] = useState(true);
  useEffect(() => {
    fetchApi();
  }, [
    selectedYear,
    selectedView,
    selectedMonth,
    selectedQuarter,
    selectedWeek,
    selectedDay,
    excludeLessThanYearStore,
    roundOff,
    excludeInactiveStore,
    selectedCompleteYear,
  ]);

  const handleToggle = (val) => {
    setExcludeLessThanYearStore(val);
  };
  const handleRoundOffChange = () => {
    dispatch(updateRoundOffSelection(!roundOff));
  };
  const handleCompletedYearToggle = () => {
    setSelectedCompleteYear(!selectedCompleteYear);
  };

  const fetchApi = async () => {
    setLoading(true);
    let payload = { excludeLessThanYearStore, roundOff, excludeInactiveStore };
    if (checkFCAccess(profile)) {
      payload = { ...payload, intFSM: profile?.intFSM };
    }

    let AllValue = false;
    let data = [];
    switch (selectedView) {
      case "Yearly":
        payload = {
          ...payload,
          selectedYear,
          showCompleteYear: selectedCompleteYear,
        };
        data = await service?.fetchYtdTotalSales(payload);
        break;
      case "Quarterly":
        payload = {
          ...payload,
          selectedYear,
          selectedQuarter,
        };
        if (selectedQuarter === 0) {
          AllValue = true;
        }else{
          data = await service?.fetchQuarterTotalSales(payload);
        }
        break;
      case "Monthly":
        payload = {
          ...payload,
          selectedYear,
          selectedMonth,
        };
        if (selectedMonth === 0) {
          AllValue = true;
        } else {
          data = await service?.fetchMonthTotalSales(payload);
        }
        break;
      case "Weekly":
        if (selectedWeek === -1) {
          payload = {
            ...payload,
            selectedYear,
          };
          data = await service?.fetchDayTotalSales(payload);
        } else {
          payload = {
            ...payload,
            selectedYear,
            selectedWeek,
          };
          if (selectedWeek === 0) {
            AllValue = true;
          } else {
            data = await service?.fetchWeekTotalSales(payload);
          }
        }
        break;
      default:
        break;
    }

    if (AllValue) {
    } else {
      setData(data);
      const rec = data?.TotalSytemSales?.map((i) => {
        let yearVal = getYearName(i?.name,selectedYear);
        // if (selectedView === "Yearly") {
        //   yearVal = getYearName(i?.name, selectedYear);
        // }
        let displayName = yearVal;
        return { ...i, displayName: displayName };
      });
      setBarData(rec || []);
      const maxValue = Math.max(...rec.map((o) => o.Amount));
      setMaxValue(maxValue + 100);
    }
    setLoading(false);
  };
  const handleExportExcel = async () => {
    setExcelLoading(true);
    let data = null;
    let payload = { excludeLessThanYearStore, excludeInactiveStore };
    if (checkFCAccess(profile)) {
      payload = { ...payload, intFSM: profile?.intFSM };
    }
    switch (selectedView) {
      case "Yearly":
        const req = { ...payload, selectedYear };
        data = await service?.getCurrentYtdExport(req);
        break;
      case "Quarterly":
        payload = { ...payload, selectedYear, selectedQuarter };
        if (selectedQuarter === 0) {
          data = await service?.getCurrentYtdQuarterViewExport(payload);
        } else {
          data = await service?.getCurrentQuarterExport(payload);
        }
        break;
      case "Monthly":
        payload = { ...payload, selectedYear, selectedMonth };
        if (selectedMonth === 0) {
          data = await service?.getCurrentYtdMonthViewExport(payload);
        } else {
          data = await service?.getCurrentMonthExport(payload);
        }
        break;
      case "Weekly":
        payload = { ...payload, selectedYear, selectedWeek };
        if (selectedWeek === -1) {
          payload = {
            ...payload,
            selectedYear,
          };
          data = await service?.getCurrentDayExport(payload);
        } else {
          payload = { ...payload, selectedYear, selectedWeek };
          if (selectedWeek === 0) {
            data = await service?.getCurrentYtdWeekViewExport(payload);
          } else {
            data = await service?.getCurrentWeekExport(payload);
          }
        }
        break;
      default:
        break;
    }
    // const url = `${process.env.REACT_APP_API_URL}/${test?.path}${test?.fileName}`
    const url = generateDownLoadPath(data?.path, data?.fileName);

    setExcelLoading(false);
    window.open(url, "_blank");
  };

  return {
    loading,
    maxValue,
    maindata,
    selectedYear,
    data,
    customSelection,
    selectedMonth,
    selectedQuarter,
    selectedWeek,
    barData,
    roundOff,
    excludeLessThanYearStore,
    handleExportExcel,
    handleToggle,
    handleRoundOffChange,
    handleCompletedYearToggle,
    selectedCompleteYear,
    excludeInactiveStore,
    excelLoading,
  };
};

export default useConsolidatedSystemSales;
