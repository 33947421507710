import { stringify } from "query-string";

// Static oidc params for a single provider
const authority = process.env.REACT_APP_ONELOGIN_AUTHORITY;
const client_id = process.env.REACT_APP_ONELOGIN_CLIENT_ID;
const redirect_uri = `${process.env.REACT_APP_URL}/auth`;
const response_type = "id_token token";
const scope = "openid profile";
const logout = true;
export const beginAuth = ({ state, nonce }) => {
  // Generate authentication URL
  const params = stringify({
    client_id,
    redirect_uri,
    response_type,
    scope,
    state,
    nonce,
  });
  const authUrl = `${authority}/auth?${params}`;
  // Attempt login by navigating to authUrl
  window.location.assign(authUrl);
};
export const authLogout = ({ id_token_hint, state }) => {
  // Generate authentication URL
  const params = stringify({
    // client_id,
    id_token_hint,
    // post_logout_redirect_uri,
    logout,
  });
  const logoutUrl = `${authority}/logout?${params}`;

  // Attempt Logout by navigating to logoutUrl
  window.location.assign(logoutUrl);
};
