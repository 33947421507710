import moment from "moment";
import { useSelector } from "react-redux";
import { NumberFormat } from "../../../utils";

const SummaryRedeemRewardsReport = (props) => {
  const { dataItem, name, value, selectedView } = props;
  const {
    selectedYear,
    selectedQuarter,
    selectedMonth,
    selectedWeek,
    selectedDay,
  } = useSelector((state) => state?.customSelection);
  return (
    <>
      <table className="summary-table">
        <thead>
          {selectedView === "Yearly" && (
            <tr>
              <th>Year</th>
              <th>:</th>
              <th>{selectedYear}</th>
            </tr>
          )}
          {selectedView === "Quarterly" && (
            <tr>
              <th>Quarter</th>
              <th>:</th>
              <th>
                {selectedQuarter === 0
                  ? `${selectedYear} - All Quarters`
                  : `${selectedYear} - Q${selectedQuarter}`}
              </th>
            </tr>
          )}
          {selectedView === "Monthly" && (
            <tr>
              <th>Month</th>
              <th>:</th>
              <th>
                {selectedMonth === 0
                  ? `${selectedYear}- All Months`
                  : `${selectedYear}-${moment(selectedMonth, "M").format(
                      "MMMM"
                    )}`}
              </th>
            </tr>
          )}
          {selectedView === "Weekly" && selectedWeek !== -1 && (
            <tr>
              <th>Week</th>
              <th>:</th>
              <th>
                {selectedWeek === 0
                  ? `${selectedYear} - All Weeks`
                  : `${selectedYear} - Week ${selectedWeek}`}
              </th>
            </tr>
          )}
          {selectedView === "Weekly" && selectedWeek === -1 && (
            <tr>
              <th>Date</th>
              <th>:</th>
              <th>{`${moment()
                .add(-1, "days")
                .format("MM/DD")}/${selectedYear}`}</th>
            </tr>
          )}
          {selectedView === "Daily" && (
            <tr>
              <th>Date</th>
              <th>:</th>
              <th>{selectedDay}</th>
            </tr>
          )}
          <tr>
            <th>Birthday Redeemptions</th>
            <th>:</th>
            <th>{NumberFormat(dataItem["Birthday Redeemptions"])}</th>
          </tr>
          <tr>
            <th>Coupon Redeemptions</th>
            <th>:</th>
            <th>{NumberFormat(dataItem["Coupon Redeemptions"])}</th>
          </tr>
          <tr>
            <th>Earned Redeemptions</th>
            <th>:</th>
            <th>{NumberFormat(dataItem["Earned Redeemptions"])}</th>
          </tr>
          <tr>
            <th>Customer Appeasement</th>
            <th>:</th>
            <th>{NumberFormat(dataItem["Customer Appeasement"])}</th>
          </tr>
        </thead>
      </table>
    </>
  );
};

export default SummaryRedeemRewardsReport;
