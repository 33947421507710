import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateQuarterSelection } from "../redux/dashboard/customSelectionSlice";
import moment from "moment";

const QuarterPicker = (props) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state);

  const handleChange = (value) => {
    dispatch(updateQuarterSelection(value));
  };
  const QuarterOptions = [
    {
      value: 0,
      label: `All Quarters`,
    },
    {
      value: moment().quarter(),
      label: `Current Quarter`,
    },
    {
      value: 1,
      label: `${data?.customSelection?.selectedYear}-Q1`,
    },
    {
      value: 2,
      label: `${data?.customSelection?.selectedYear}-Q2`,
    },
    {
      value: 3,
      label: `${data?.customSelection?.selectedYear}-Q3`,
    },
    {
      value: 4,
      label: `${data?.customSelection?.selectedYear}-Q4`,
    },
  ];
  const onSearch = (value) => {
    console.log('search:', value);
  };

  return (
    <div className="year-picker">
      <Select
      showSearch
        value={data?.customSelection?.selectedQuarter}
        style={{
          width: 140,
        }}
        onSearch={onSearch}
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        disabled={props?.disabled}
        onChange={handleChange}
        options={QuarterOptions}
      />
    </div>
  );
};

export default QuarterPicker;
