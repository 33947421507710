import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  selectedYear: moment().year(),
  roundOff: false,
  selectedQuarter: 0,
  selectedMonth: 0,
  selectedWeek: 0,
  // selectedQuarter: moment().quarter(),
  // selectedMonth: moment().month()+1,
  // selectedWeek: moment().week(),
  selectedDay:moment().add(-1,'day').format('L')
};

export const customSelectionSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    resetCustomSelection: (state) => initialState,
    updateYearSelection: (stateVal, action) => {
      stateVal.selectedYear = action.payload;
      return stateVal;
    },
    updateQuarterSelection: (stateVal, action) => {
      stateVal.selectedQuarter = action.payload;
      return stateVal;
    },
    updateMonthSelection: (stateVal, action) => {
      stateVal.selectedMonth = action.payload;
      return stateVal;
    },
    updateWeekSelection: (stateVal, action) => {
      stateVal.selectedWeek = action.payload;
      return stateVal;
    },
    updateDaySelection: (stateVal, action) => {
      stateVal.selectedDay = action.payload;
      return stateVal;
    },
    updateRoundOffSelection: (stateVal, action) => {
      stateVal.roundOff = action.payload;
      return stateVal;
    },
  },
});

export const {
  updateRoundOffSelection,
  updateDaySelection,
  updateYearSelection,
  updateQuarterSelection,
  updateMonthSelection,
  updateWeekSelection,
  resetCustomSelection
} = customSelectionSlice.actions;
export default customSelectionSlice.reducer;
