import React, { useEffect, useState } from "react";
import { Button, Card, Segmented, Spin, Switch } from "antd";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { FinancialService } from "../../../services/financial/index.service";
import {
  checkFCAccess,
  CurrencyFormat,
  generateDownLoadPath,
  getYearName,
  ShowArrow,
  YAxisNumberFormat,
} from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { CustomTooltipForSales } from "../../../utils/Tooltip";
import { Legends } from "../../../utils/legend";
import { SvgCurrentYear, SvgPreviousYear } from "../../../utils/svgpath";
import MonthlyWiseSales from "./YTDSales/MonthlyWiseSales";
import SummarySalesTable from "./SummarySalesTable";
import WeekWiseSales from "./YTDSales/WeekWiseSales";
import { useNavigate } from "react-router-dom";
import { DashboardLoader } from "../../../utils/Loading";
import YearPicker from "../../YearPicker";
import { updateRoundOffSelection } from "../../../redux/dashboard/customSelectionSlice";

const TotalSytemSales = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedYear, roundOff } = useSelector(
    (state) => state?.customSelection
  );
  const profile = useSelector((state) => state?.profile) || null;
  const { type, title } = props;
  const service = new FinancialService();
  const [excelLoading, setExcelLoading] = useState(false);
  const [maxValue, setMaxValue] = useState(0);
  const [isChildComponent, setIsChildComponent] = useState(false);
  const [selectedView, setSelectedView] = useState("Year");
  const [selectedCompleteYear, setSelectedCompleteYear] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [barData, setBarData] = useState([]);
  const [excludeLessThanYearStore, setExcludeLessThanYearStore] =
    useState(false);
  const [excludeInactiveStore, setExcludeInactiveStore] = useState(true);
  const [width, setWindowWidth] = useState(0);
  const breakPointWidth = 767;
  useEffect(() => {
    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };
  useEffect(() => {
    fetchApi();
  }, [
    excludeLessThanYearStore,
    selectedYear,
    roundOff,
    excludeInactiveStore,
    selectedCompleteYear,
  ]);

  const fetchApi = async () => {
    setLoading(true);
    let payload = { excludeLessThanYearStore, roundOff, excludeInactiveStore };
    if (checkFCAccess(profile)) {
      payload = { ...payload, intFSM: profile?.intFSM };
    }
    if (props?.storeId) {
      payload = { ...payload, ritasId: props?.storeId };
    }
    let data = [];
    switch (type) {
      case "year":
        const req = { ...payload, selectedYear, showCompleteYear:selectedCompleteYear };
        data = await service?.fetchYtdTotalSales(req);
        break;
      case "quarter":
        data = await service?.fetchQuarterTotalSales(payload);
        break;
      case "month":
        data = await service?.fetchMonthTotalSales(payload);
        break;
      case "week":
        data = await service?.fetchWeekTotalSales(payload);
        break;
      case "day":
        data = await service?.fetchDayTotalSales(payload);
        break;
      default:
        break;
    }
    setData(data);
    const rec = data?.TotalSytemSales?.map((i) => {
      let yearVal = getYearName(i?.name);
      if (type === "year") {
        yearVal = getYearName(i?.name, selectedYear);
      }
      let displayName = yearVal;
      if (type === "week") {
        displayName = `Week ${data?.ItemData?.Week} ${yearVal}`;
      }
      return { ...i, displayName: displayName };
    });
    setBarData(rec || []);
    const maxValue = Math.max(...rec.map((o) => o.Amount));
    setMaxValue(maxValue + 100);
    setLoading(false);
  };

  const handleExportExcel = async () => {
    setExcelLoading(true);
    let data = null;
    let payload = { excludeLessThanYearStore, excludeInactiveStore };
    if (checkFCAccess(profile)) {
      payload = { ...payload, intFSM: profile?.intFSM };
    }
    switch (type) {
      case "year":
        const req = { ...payload, selectedYear };
        data = await service?.getCurrentYtdExport(req);
        break;
      case "quarter":
        data = await service?.getCurrentQuarterExport(payload);
        break;
      case "month":
        data = await service?.getCurrentMonthExport(payload);
        break;
      case "week":
        data = await service?.getCurrentWeekExport(payload);
        break;
      case "day":
        data = await service?.getCurrentDayExport(payload);
        break;
      default:
        break;
    }
    // const url = `${process.env.REACT_APP_API_URL}/${test?.path}${test?.fileName}`
    const url = generateDownLoadPath(data?.path, data?.fileName);

    setExcelLoading(false);
    window.open(url, "_blank");
  };

  const handleToggle = (val) => {
    setExcludeLessThanYearStore(val);
  };

  const handleInactiveStoreToggle = (val) => {
    setExcludeInactiveStore(val);
  };

  const handleSegmentSelection = (e) => {
    if (e === "Year") {
      setIsChildComponent(false);
    } else {
      setIsChildComponent(true);
    }
    setSelectedView(e);
  };

  const DefaultComponent = (propData) => {
    return (
      <div className="total-system-sales">
        {!props?.child && (
          <>
            <div className="d-flex justify-content-end p-2">
              {selectedView !== "Month" &&
                selectedView !== "Week" &&
                !propData?.skeleton && (
                  <img
                    src="/DownLoadExcel.svg"
                    alt="DownLoadExcel"
                    className="DownLoadExcel"
                    onClick={() => handleExportExcel()}
                  />
                )}
            </div>
            {type === "year" && selectedView === "Year" && (
              <div className=" d-flex justify-content-center px-1">
                <label className="px-1">Enable Complete Year Comparison</label>
                <Switch
                  title="Enable Complete Year Comparison"
                  defaultChecked
                  checked={selectedCompleteYear}
                  onChange={handleCompletedYearToggle}
                />
              </div>
            )}
            <div className="show-monthly-selection pb-3">
              <div className="summary-table-container">
                <SummarySalesTable
                  selectedType={type}
                  dataItem={data?.ItemData}
                  roundOff={roundOff}
                />
              </div>
            </div>
          </>
        )}
        <ResponsiveContainer
          width="100%"
          aspect={width < breakPointWidth ? 2 : 4}
        >
          <BarChart
            data={barData}
            margin={{ top: 5, right: 20, bottom: 5, left: 20 }}
          >
            <XAxis dataKey="displayName" />
            <YAxis
              dataKey={"Amount"}
              label={{
                // value: "Amt ($)",
                angle: 360,
                position: "insideBottomRight",
                offset: 10,
              }}
              tickFormatter={YAxisNumberFormat}
              domain={[0, (dataMax) => parseFloat(maxValue).toFixed(2)]}
              // mirror={true}
              // type="number"
              // domain={[
              //   0,
              //   (dataMax) => parseInt(dataMax * 1.25).toFixed(2),
              // ]}
              // width={120}
              allowDataOverflow={true}
            />
            <Tooltip
              content={<CustomTooltipForSales />}
              labelStyle={{ color: "darkblue" }}
            />
            <Bar
              dataKey="Amount"
              fill="#0088FE"
              barSize={width < breakPointWidth ? 20 : 30}
            >
              {barData.map((entry, index) => {
                const color = Legends[entry?.name];
                return <Cell key={`cell-${index}`} fill={color} />;
              })}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        {DefaultSummaryComponent()}
      </div>
    );
  };
  const DefaultSummaryComponent = () => {
    return (
      <>
        {width < breakPointWidth ? (
          <>
            <div className="table-container legend-table">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>{"Amount"}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {SvgCurrentYear()}
                      {barData?.[0]?.["displayName"]}
                      {ShowArrow(data?.ItemData?.currentArrow)}
                    </td>
                    <td>
                      {CurrencyFormat(barData?.[0]?.["Amount"], roundOff)}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {SvgPreviousYear()}
                      {barData?.[1]?.["displayName"]}
                      {ShowArrow(data?.ItemData?.priorArrow)}
                    </td>
                    <td>
                      {CurrencyFormat(barData?.[1]?.["Amount"], roundOff)}
                    </td>
                  </tr>
                  <tr>
                    <td>{"% Chg"}</td>
                    <td>{`${data?.chg} %`}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <>
            <div className="table-container legend-table">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      {SvgCurrentYear()}
                      {barData?.[0]?.["displayName"]}
                      {ShowArrow(data?.ItemData?.currentArrow)}
                    </th>
                    <th>
                      {SvgPreviousYear()}
                      {barData?.[1]?.["displayName"]}
                      {ShowArrow(data?.ItemData?.priorArrow)}
                    </th>
                    <th>% Chg</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Amount</td>
                    <td>
                      {CurrencyFormat(barData?.[0]?.["Amount"], roundOff)}
                    </td>
                    <td>
                      {CurrencyFormat(barData?.[1]?.["Amount"], roundOff)}
                    </td>
                    <td>{`${data?.chg} %`}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}
      </>
    );
  };
  const handleRoundOffChange = () => {
    dispatch(updateRoundOffSelection(!roundOff));
  };
  const handleCompletedYearToggle = () => {
    setSelectedCompleteYear(!selectedCompleteYear);
  };

  const SkeletonComponent = (propData) => {
    return (
      <>
        <div className="table-div d-flex flex-column align-items-start">
          <div className="total-system-sales-header">
            {props?.storeId && <span>{title}</span>}
            {!props?.storeId && !props?.child && (
              <div>
                <b className="comp-sales">
                  {`Comp Sales (Excluding New Stores & Transfer Stores < 1 year) : `}
                  <Switch
                    checked={excludeLessThanYearStore}
                    onChange={handleToggle}
                    disabled={loading}
                  />
                </b>
              </div>
            )}
            <div className="d-flex justify-self-end align-items-center">
              {!props?.child && (
                <>
                  <div className="px-1">
                    <label className="px-1">Enable Round Off</label>
                    <Switch
                      title="RoundOff"
                      defaultChecked
                      checked={roundOff}
                      onChange={handleRoundOffChange}
                    />
                  </div>
                  {type === "year" && <YearPicker />}
                </>
              )}
              {propData?.skeleton && type !== "year" && (
                <img
                  src="/DownLoadExcel.svg"
                  alt="DownLoadExcel"
                  className="DownLoadExcel"
                  onClick={() => handleExportExcel()}
                />
              )}
              {propData?.skeleton &&
                type === "year" &&
                selectedView === "Year" && (
                  <img
                    src="/DownLoadExcel.svg"
                    alt="DownLoadExcel"
                    className="DownLoadExcel"
                    onClick={() => handleExportExcel()}
                  />
                )}
            </div>
          </div>
          {/* <div className="ranking-btn-group pb-2">
                  <b className="comp-sales">
                    {`Exclude Inactive Stores : `}
                    <Switch
                      checked={excludeInactiveStore}
                      onChange={handleInactiveStoreToggle}
                      disabled={loading}
                    />
                  </b>
                </div> */}
          {!props?.child && type === "year" && (
            <>
              <div className="show-monthly-selection pb-2">
                <Segmented
                  options={["Year", "Month", "Week"]}
                  value={selectedView}
                  disabled={loading}
                  onChange={(e) => handleSegmentSelection(e)}
                />
              </div>
            </>
          )}
        </div>
        {isChildComponent ? (
          <>
            {selectedView === "Month" && (
              <MonthlyWiseSales
                title={title}
                width={width}
                excludeLessThanYearStore={excludeLessThanYearStore}
                ItemData={data?.ItemData}
                excludeInactiveStore={excludeInactiveStore}
              />
            )}
            {selectedView === "Week" && (
              <WeekWiseSales
                title={title}
                width={width}
                excludeLessThanYearStore={excludeLessThanYearStore}
                excludeInactiveStore={excludeInactiveStore}
              />
            )}
          </>
        ) : (
          <>
            {loading ? (
              <DashboardLoader />
            ) : (
              <>
                {barData?.length > 0 ? (
                  <>
                    <DefaultComponent skeleton={propData?.skeleton} />
                    {/* <DefaultSummaryComponent /> */}
                  </>
                ) : (
                  <>
                    <div className="norecords-available">
                      No records available
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </>
    );
  };
  if (!props?.skeleton) {
    return (
      <>
        {!props?.storeId ? (
          <Spin spinning={excelLoading}>
            <Card
              title={
                <>
                  <div className="d-flex justify-content-between">
                    <div>
                      {!props?.child && (
                        <Button
                          type="primary"
                          className="btn-back"
                          onClick={() => navigate("/financial")}
                        >
                          <i
                            className="fa fa-arrow-left"
                            style={{ fontSize: "16px" }}
                          ></i>
                        </Button>
                      )}
                      {!props?.storeId && (
                        <span className="card-heading-overview-item">
                          {title}
                        </span>
                      )}
                      {props?.child && !props?.storeId && (
                        <i
                          className="fa fa-external-link"
                          style={{
                            cursor: "pointer",
                            paddingLeft: "0.5rem",
                            fontSize: "14px",
                          }}
                          aria-hidden="true"
                          onClick={() => props?.handleFullView()}
                        ></i>
                      )}
                    </div>
                    {props?.child && (
                      <div>
                        <b className="comp-sales">
                          {`Excluding New Stores & Transfer Stores < 1 year : `}
                          <Switch
                            checked={excludeLessThanYearStore}
                            onChange={handleToggle}
                            disabled={loading}
                          />
                        </b>
                      </div>
                    )}
                  </div>
                </>
              }
            >
              <SkeletonComponent skeleton={false} />
            </Card>
          </Spin>
        ) : (
          <Card>
            <SkeletonComponent skeleton={false} />
          </Card>
        )}
      </>
    );
  } else {
    return (
      <>
        <Spin spinning={excelLoading}>
          <SkeletonComponent skeleton={true} />
        </Spin>
      </>
    );
  }
};

export default TotalSytemSales;
