export const SvgCurrentYear = () => {
  return (
    <>
      <svg
        className="recharts-surface"
        width="14"
        height="14"
        viewBox="0 0 32 32"
        version="1.1"
        style={{
          display: "inlineBlock",
          verticalAlign: "middle",
          marginRight: "4px",
        }}
      >
        <path
          stroke="none"
          fill="#0088FE"
          d="M0,4h32v24h-32z"
          className="recharts-legend-icon"
        ></path>
      </svg>
    </>
  );
};
export const SvgMixes = () => {
  return (
    <>
      <svg
        className="recharts-surface"
        width="14"
        height="14"
        viewBox="0 0 32 32"
        version="1.1"
        style={{
          display: "inlineBlock",
          verticalAlign: "middle",
          marginRight: "4px",
        }}
      >
        <path
          stroke="none"
          fill="#107C41"
          d="M0,4h32v24h-32z"
          className="recharts-legend-icon"
        ></path>
      </svg>
    </>
  );
};
export const SvgYieldMixes = () => {
  return (
    <>
      <svg
        className="recharts-surface"
        width="14"
        height="14"
        viewBox="0 0 32 32"
        version="1.1"
        style={{
          display: "inlineBlock",
          verticalAlign: "middle",
          marginRight: "4px",
        }}
      >
        <path
          stroke="none"
          fill="#007BF7"
          d="M0,4h32v24h-32z"
          className="recharts-legend-icon"
        ></path>
      </svg>
    </>
  );
};
export const SvgPreviousYear = () => {
  return (
    <>
      <svg
        className="recharts-surface"
        width="14"
        height="14"
        viewBox="0 0 32 32"
        version="1.1"
        style={{
          display: "inlineBlock",
          verticalAlign: "middle",
          marginRight: "4px",
        }}
      >
        <path
          stroke="none"
          fill="#D9D9D9"
          d="M0,4h32v24h-32z"
          className="recharts-legend-icon"
        ></path>
      </svg>
    </>
  );
};
export const SvgChg = () => {
  return (
    <>
      <svg
        className="recharts-surface"
        width="14"
        height="14"
        viewBox="0 0 32 32"
        version="1.1"
        sstyle={{
          display: "inlineBlock",
          verticalAlign: "middle",
          marginRight: "4px",
        }}
      >
        <path
          stroke="#FFC000"
          fill="none"
          d="M0,16h10.666666666666666
            A5.333333333333333,5.333333333333333,0,1,1,21.333333333333332,16
            H32M21.333333333333332,16
            A5.333333333333333,5.333333333333333,0,1,1,10.666666666666666,16"
          className="recharts-legend-icon"
          strokeWidth="4"
        ></path>
      </svg>
    </>
  );
};

export const SvgTransaction = (color) => {
  return (
    <>
      <svg
        className="recharts-surface"
        width="14"
        height="14"
        viewBox="0 0 32 32"
        version="1.1"
        style={{
          display: "inlineBlock",
          verticalAlign: "middle",
          marginRight: "4px",
        }}
      >
        <path
          stroke="none"
          fill={color}
          d="M0,4h32v24h-32z"
          className="recharts-legend-icon"
        ></path>
      </svg>
    </>
  );
};