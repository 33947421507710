import { DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateDaySelection } from "../redux/dashboard/customSelectionSlice";
import moment from "moment";
const DayPicker = (props) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state);
  const onChange = (date, dateString) => {
    dispatch(updateDaySelection(moment(date).format('L')));
  };
  const dateFormatList = 'MM/DD/YYYY'
//   const disableDate=`${data?.customSelection?.selectedYear-1}-12-31`
  const disableDate=`2020-12-31`
  console.log(disableDate)
  return (
    <div className="year-picker">
      <DatePicker onChange={onChange}   size="middle"    disabled={props?.disabled} value={moment(moment(data?.customSelection?.selectedDay), dateFormatList)} format={dateFormatList} 
    //   disabledDate={d => !d || d.isAfter(moment(moment(), dateFormatList))}  
      disabledDate={d => !d || d.isAfter(moment(moment(), dateFormatList)) || d.isSameOrBefore(moment(moment(disableDate), dateFormatList)) }  
      />
    </div>
  );
};

export default DayPicker;
