import React from "react";
import { Button, Card, Row, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateMenuSelection } from "../../redux/dashboard/dashboardSlice";
const { Title } = Typography;

const FinancialDashboard = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state?.profile) || null;

  return (
    <Card
      className="dashboard-antd-card"
      title={
        <>
          <Button
            type="primary"
            className="btn-back"
            onClick={() => dispatch(updateMenuSelection("home"))}
          >
            <i className="fa fa-arrow-left" style={{ fontSize: "16px" }}></i>
          </Button>
          <span>{"Financial Dashboard"}</span>
        </>
      }
    >
      <div className="parent-flex-container pt-3">
        <Card
          className="report_Card"
          headStyle={{ width: "100%" }}
          title={
            <Title
              style={{ color: "#044A2A !important", fontSize: "16px" }}
              level={5}
            >
              Sales and GC
            </Title>
          }
          hoverable={true}
          bordered={true}
        >
          <Row type="flex" style={{ color: "#aaaaaa" }}>
            <Link to="/financial/ytd">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>Overview
                  </div>
                </div>
              </div>
            </Link>
          </Row>
          <Row type="flex" style={{ color: "#aaaaaa" }}>
            <Link to="/financial/total-system-sales">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>Total System Sales
                  </div>
                </div>
              </div>
            </Link>
          </Row>
          <Row type="flex" style={{ color: "#aaaaaa" }}>
            <Link to="/financial/sales-line-of-business">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>Total System Sales
                    by Line of Business
                  </div>
                </div>
              </div>
            </Link>
          </Row>
          {/* <Row type="flex" style={{ color: "#aaaaaa" }}>
            <Link to="/financial/ytd-sales-by-region">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>YTD Total System Sales
                    by Region
                  </div>
                </div>
              </div>
            </Link>
          </Row> */}
          <Row type="flex" style={{ color: "#aaaaaa" }}>
            <Link to="/financial/digital-3pd">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>Digital Sales - 3PD
                  </div>
                </div>
              </div>
            </Link>
          </Row>
          <Row type="flex" style={{ color: "#aaaaaa" }}>
            <Link to="/financial/rank-report">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>Ranking Report
                  </div>
                </div>
              </div>
            </Link>
          </Row>
          <Row type="flex" style={{ color: "#aaaaaa" }}>
            <Link to="/financial/cumulative-guest">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>Guest Count
                  </div>
                </div>
              </div>
            </Link>
          </Row>
        </Card>
        {/* <Card
          className="report_Card"
          headStyle={{ width: "100%" }}
          title={
            <Title
              style={{ color: "#044A2A !important", fontSize: "16px" }}
              level={5}
            >
              Current Quarter Sales
            </Title>
          }
          hoverable={true}
          bordered={true}
        >
          <Row type="flex" style={{ color: "#aaaaaa" }}>
            <Link to="/financial/quarter">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>Overview
                  </div>
                </div>
              </div>
            </Link>{" "}
          </Row>
          <Row type="flex" style={{ color: "#aaaaaa" }}>
            <Link to="/financial/quarter-sales">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>Current Quarter Total
                    System Sales
                  </div>
                </div>
              </div>
            </Link>{" "}
          </Row>
          <Row type="flex" style={{ color: "#aaaaaa" }}>
            <Link to="/financial/quarter-sales-line-of-business">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>Current Quarter Total
                    System Sales by Line of Business
                  </div>
                </div>
              </div>
            </Link>{" "}
          </Row>
          <Row type="flex" style={{ color: "#aaaaaa" }}>
            <Link to="/financial/quarter-sales-by-region">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>Current Quarter Total
                    System Sales by Region
                  </div>
                </div>
              </div>
            </Link>{" "}
          </Row>
          <Row type="flex" style={{ color: "#aaaaaa" }}>
            <Link to="/financial/quarter-rank-report">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>Current Quarter Total
                    Ranking Report
                  </div>
                </div>
              </div>
            </Link>
          </Row>
        </Card> */}
        <Card
          className="report_Card"
          headStyle={{ width: "100%" }}
          title={
            <Title
              style={{ color: "#044A2A !important", fontSize: "16px" }}
              level={5}
            >
              Current Week Sales
            </Title>
          }
          hoverable={true}
          bordered={true}
        >
          <Row type="flex" style={{ color: "#aaaaaa" }}>
            <Link to="/financial/week">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>Overview
                  </div>
                </div>
              </div>
            </Link>{" "}
          </Row>
          <Row type="flex" style={{ color: "#aaaaaa" }}>
            <Link to="/financial/week-sales">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>Current Week Total
                    System Sales
                  </div>
                </div>
              </div>
            </Link>
          </Row>
          <Row type="flex" style={{ color: "#aaaaaa" }}>
            <Link to="/financial/week-sales-line-of-business">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>Current Week Total
                    System Sales by Line of Business
                  </div>
                </div>
              </div>
            </Link>
          </Row>
          {/* <Row type="flex" style={{ color: "#aaaaaa" }}>
            <Link to="/financial/week-sales-by-region">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>Current Week Total
                    System Sales by Region
                  </div>
                </div>
              </div>
            </Link>
          </Row> */}
          <Row type="flex" style={{ color: "#aaaaaa" }}>
            <Link to="/financial/week-rank-report">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>Week Total Ranking
                    Report
                  </div>
                </div>
              </div>
            </Link>
          </Row>
        </Card>
        {/* <Card
          className="report_Card"
          headStyle={{ width: "100%" }}
          title={
            <Title
              style={{
                color: "#044A2A !important",
                fontSize: "16px",
              }}
              level={5}
            >
              Day and Month Sales
            </Title>
          }
          hoverable={true}
          bordered={true}
        >
          <Row type="flex" style={{ color: "#aaaaaa" }}>
            <Link to="/financial/day-month-sales">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>Total System Sales
                  </div>
                </div>
              </div>
            </Link>{" "}
          </Row>
          <Row type="flex" style={{ color: "#aaaaaa" }}>
            <Link to="/financial/day-month-sales-line-of-business">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>Total System Sales by
                    Line of Business
                  </div>
                </div>
              </div>
            </Link>{" "}
          </Row>
          <Row type="flex" style={{ color: "#aaaaaa" }}>
            <Link to="/financial/day-month-sales-by-region">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>Total System Sales by
                    Region
                  </div>
                </div>
              </div>
            </Link>{" "}
          </Row>
          <Row type="flex" style={{ color: "#aaaaaa" }}>
            <Link to="/financial/day-month-rank-report">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>Total Ranking Report
                  </div>
                </div>
              </div>
            </Link>
          </Row>
        </Card> */}
        {/* <Card
          className="report_Card"
          headStyle={{ width: "100%" }}
          title={
            <Title
              style={{ color: "#044A2A !important", fontSize: "16px" }}
              level={5}
            >
              Guest Count
            </Title>
          }
          hoverable={true}
          bordered={true}
        >
          <Row type="flex" style={{ color: "#aaaaaa" }}>
            <Link to="/financial/cumulative-guest">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>Guest Count
                  </div>
                </div>
              </div>
            </Link>
          </Row>
          <Row type="flex" style={{ color: "#aaaaaa" }}>
            <Link to="/financial/ytd-guest">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>YTD Total Guest Count
                  </div>
                </div>
              </div>
            </Link>{" "}
          </Row>
           <Row type="flex" style={{ color: "#aaaaaa" }}>
            <Link to="/financial/ytd-guest-region">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>Total Guest Count By
                    Region
                  </div>
                </div>
              </div>
            </Link>{" "}
          </Row>
          <Row type="flex" style={{ color: "#aaaaaa" }}>
            <Link to="/financial/month-guest">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>Monthly Total Guest
                    Count
                  </div>
                </div>
              </div>
            </Link>{" "}
          </Row>
          <Row type="flex" style={{ color: "#aaaaaa" }}>
            <Link to="/financial/week-guest">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>Week Total Guest Count
                  </div>
                </div>
              </div>
            </Link>{" "}
          </Row>
          <Row type="flex" style={{ color: "#aaaaaa" }}>
            <Link to="/financial/day-guest">
              <div className="report-content-text">
                <div className="report-text-wrap">
                  <div className="report-sub-heading">
                    <span className="fas fa-poll"></span>Day Total Guest Count
                  </div>
                </div>
              </div>
            </Link>
          </Row>
        </Card> */}
        {profile?.isAdmin && (
          <>
            <Card
              className="report_Card"
              headStyle={{ width: "100%" }}
              title={
                <Title
                  style={{ color: "#044A2A !important", fontSize: "16px" }}
                  level={5}
                >
                  Reconciliation
                </Title>
              }
              hoverable={true}
              bordered={true}
            >
              <Row type="flex" style={{ color: "#aaaaaa" }}>
                <Link to="/financial/fc-rollup">
                  <div className="report-content-text">
                    <div className="report-text-wrap">
                      <div className="report-sub-heading">
                        <span className="fas fa-poll"></span>FC Rollup
                      </div>
                    </div>
                  </div>
                </Link>
              </Row>
              <Row type="flex" style={{ color: "#aaaaaa" }}>
                <Link to="/financial/fc-ranking">
                  <div className="report-content-text">
                    <div className="report-text-wrap">
                      <div className="report-sub-heading">
                        <span className="fas fa-poll"></span>FC Ranking
                      </div>
                    </div>
                  </div>
                </Link>
              </Row>
              <Row type="flex" style={{ color: "#aaaaaa" }}>
                <Link to="/financial/region-rollup">
                  <div className="report-content-text">
                    <div className="report-text-wrap">
                      <div className="report-sub-heading">
                        <span className="fas fa-poll"></span>Region Rollup
                      </div>
                    </div>
                  </div>
                </Link>
              </Row>
            </Card>
          </>
        )}
      </div>
      <div style={{ marginBottom: "15px" }}></div>
    </Card>
  );
};

export default FinancialDashboard;
