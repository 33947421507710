import React, { useEffect, useState } from "react";
import { Button, Card, Segmented, Spin, Switch, Table } from "antd";
import { useNavigate } from "react-router-dom";
import { generateDownLoadPath } from "../../utils";
import { OperationalService } from "../../services/operational/index.service";
import { useDispatch, useSelector } from "react-redux";
import YearPicker from "../YearPicker";
import { updateRoundOffSelection } from "../../redux/dashboard/customSelectionSlice";

const YTDMixesSales = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedYear, roundOff } = useSelector((state) => state?.customSelection);
  const service = new OperationalService();
  const [loading, setLoading] = useState(true);
  const [yearValue, setyearValue] = useState(selectedYear);
  const [excelLoading, setExcelLoading] = useState(false);

  const salesColumns = [
    {
      title: "Rank",
      dataIndex: "Ranking",
      key: "Ranking",
    },
    {
      title: "Item #",
      dataIndex: "ItemCode",
      key: "ItemCode",
    },
    {
      title: "Name",
      dataIndex: "ItemName",
      key: "ItemName",
    },
    {
      title: "Cases Purchased",
      dataIndex: "sum",
      key: "sum",
      render: (data) => {
        return data;
      },
    },
    {
      title: "Yield per Batch",
      dataIndex: "YieldPerBatch",
      key: "YieldPerBatch",
      render: (data) => {
        return data;
      },
    },
    {
      title: "Batches per Case",
      dataIndex: "BatchPerCase",
      key: "BatchPerCase",
      render: (data) => {
        return data;
      },
    },
    {
      title: "Ounces",
      dataIndex: "Ounces",
      key: "Ounces",
      render: (data) => {
        return data;
      },
    },
    {
      title: "YTD % of Mix Total",
      dataIndex: "YTDMix",
      key: "YTDMix",
      render: (data) => {
        return `${data}%`;
      },
    },
    {
      title: "January",
      dataIndex: "January",
      key: "January",
      render: (data) => {
        return data;
      },
    },
    {
      title: "February",
      dataIndex: "February",
      key: "February",
      render: (data) => {
        return data;
      },
    },
    {
      title: "March",
      dataIndex: "March",
      key: "March",
      render: (data) => {
        return data;
      },
    },
    {
      title: "April",
      dataIndex: "April",
      key: "April",
      render: (data) => {
        return data;
      },
    },
    {
      title: "May",
      dataIndex: "May",
      key: "May",
      render: (data) => {
        return data;
      },
    },
    {
      title: "June",
      dataIndex: "June",
      key: "June",
      render: (data) => {
        return data;
      },
    },
    {
      title: "July",
      dataIndex: "July",
      key: "July",
      render: (data) => {
        return data;
      },
    },
    {
      title: "August",
      dataIndex: "August",
      key: "August",
      render: (data) => {
        return data;
      },
    },
    {
      title: "September",
      dataIndex: "September",
      key: "September",
      render: (data) => {
        return data;
      },
    },
    {
      title: "October",
      dataIndex: "October",
      key: "October",
      render: (data) => {
        return data;
      },
    },
    {
      title: "November",
      dataIndex: "November",
      key: "November",
      render: (data) => {
        return data;
      },
    },
    {
      title: "December",
      dataIndex: "December",
      key: "December",
      render: (data) => {
        return data;
      },
    },
  ];
  const [salesData, setSalesData] = useState([]);
  useEffect(() => {
    fetchApi();
  }, [yearValue, roundOff]);

  useEffect(() => {
    setyearValue(selectedYear);
  }, [selectedYear]);

  const handleRoundOffChange = () => {
    dispatch(updateRoundOffSelection(!roundOff));
  };
  const handleExportExcel = async () => {
    setExcelLoading(true);
    let payload = {
      year: yearValue,
      roundOff,
    };

    const data = await service?.fetchYTDCaseMixesExcel(payload);
    const url = generateDownLoadPath(data?.path, data?.fileName);
    setExcelLoading(false);
    window.open(url, "_blank");
  };

  const fetchApi = async () => {
    setLoading(true);
    let payload = {
      year: yearValue,
      roundOff,
    };

    const res = await service?.fetchYtdCaseMixes(payload);
    const caseMixes = res?.caseMixes;
    let result = [];
    if (caseMixes) {
      result = Object.keys(caseMixes).map((key, index) => {
        return { ...caseMixes[key], Ranking: index + 1 };
      });
    }
    setSalesData(result);
    setLoading(false);
  };
  let columns = salesColumns;
  const SkeletonComponent = () => {
    return (
      <>
          <div className="table-div">
            <div className=" mb-1 d-flex justify-content-between align-items-center" style={{width:'100%'}}>
            <Segmented
                options={[selectedYear, selectedYear - 1]}
                value={yearValue}
                onChange={(e) => setyearValue(e)}
              />
              <div className="px-1">
                <label className="px-1">Enable Round Off</label>
                <Switch
                  title="RoundOff"
                  defaultChecked
                  checked={roundOff}
                  onChange={handleRoundOffChange}
                  />
              </div>
      
            </div>
          </div>
        <div className="report-grid">
          <div
            className="rank-grid-item"
          >
            {salesData?.length > 0 && (<>
                <div className="d-flex justify-content-end py-2">
                <img
                  src="/DownLoadExcel.svg"
                  alt="DownLoadExcel"
                  className="DownLoadExcel"
                  onClick={() => handleExportExcel()}
                />
              </div>
            </>)}
            <Table
              dataSource={salesData}
              columns={columns}
              pagination={{
                pageSize: "15",
                showQuickJumper: false,
                showSizeChanger: false,
              }}
              loading={loading}
              bordered
              onChange={(e) => console.log(e)}
              scrollToFirstRowOnChange={true}
              size="middle"
            />
          </div>
        </div>
      </>
    );
  };

  if (!props?.skeleton) {
    return (
      <>
        <Spin spinning={excelLoading}>
          <Card
            title={
              <>
                {" "}
                <div className="d-flex justify-content-between">
                  <div>
                    <Button
                      type="primary"
                      className="btn-back"
                      onClick={() => navigate("/supply-chain")}
                    >
                      <i
                        className="fa fa-arrow-left"
                        style={{ fontSize: "16px" }}
                      ></i>
                    </Button>
                    <span>{`YTD Mixes Sales`}</span>
                  </div>
                  <div className="d-flex justify-content-end align-items-center px-2">
                    <YearPicker />
                  </div>
                </div>
              </>
            }
          >
            <SkeletonComponent />
          </Card>
        </Spin>
      </>
    );
  } else {
    return <SkeletonComponent />;
  }
};

export default YTDMixesSales;
