import React, { useEffect, useState } from "react";
import { Button, Card, DatePicker, Spin, Table } from "antd";
import { useNavigate } from "react-router-dom";
import { generateDownLoadPath } from "../../utils";
import { OperationalService } from "../../services/operational/index.service";
import moment from "moment";

const YTDMixesSales = (props) => {
  const navigate = useNavigate();
  const service = new OperationalService();
  const [loading, setLoading] = useState(true);
  const [datePickerVal, setDatePickerVal] = useState(moment());
  const [excelLoading, setExcelLoading] = useState(false);

  const salesColumns = [
    {
      title: "Rank",
      dataIndex: "Ranking",
      key: "Ranking",
    },
    {
      title: "Item #",
      dataIndex: "ItemCode",
      key: "ItemCode",
    },
    {
      title: "Name",
      dataIndex: "ItemName",
      key: "ItemName",
    },
    {
      title: "Description",
      dataIndex: "ItemDesc",
      key: "ItemDesc",
    },
    {
      title: "Cases Purchased",
      dataIndex: "sum",
      key: "sum",
      render: (data) => {
        return data;
      },
    },
    {
      title: "Yield per Batch",
      dataIndex: "YieldPerBatch",
      key: "YieldPerBatch",
      render: (data) => {
        return data;
      },
    },
    {
      title: "Batches per Case",
      dataIndex: "BatchPerCase",
      key: "BatchPerCase",
      render: (data) => {
        return data;
      },
    },
    {
      title: "Ounces",
      dataIndex: "Ounces",
      key: "Ounces",
      render: (data) => {
        return data;
      },
    },
    {
      title: "YTD % of Mix Total",
      dataIndex: "YTDMix",
      key: "YTDMix",
      render: (data) => {
        return `${data}%`;
      },
    },
  ];
  const [salesData, setSalesData] = useState([]);
  useEffect(() => {
    fetchApi();
  }, [datePickerVal]);

  const handleExportExcel = async () => {
    setExcelLoading(true);
    const date = datePickerVal ? moment(datePickerVal) : datePickerVal;
    const payload = {
      year: date.year(),
      month: date.month() + 1,
    };
    const data = await service?.fetchMonthCaseMixesExcel(payload);
    const url = generateDownLoadPath(data?.path, data?.fileName);
    setExcelLoading(false);
    window.open(url, "_blank");
  };

  const onChange = (dateval, dateString) => {
    const date = dateval ? moment(dateval) : dateval;
    setDatePickerVal(date);
  };

  const fetchApi = async () => {
    const date = datePickerVal ? moment(datePickerVal) : datePickerVal;
    if (datePickerVal) {
      const payload = {
        year: date.year(),
        month: date.month() + 1,
      };
      setLoading(true);
      const res = await service?.fetchMonthCaseMixes(payload);
      const caseMixes = res?.caseMixes;
      let result = [];
      if (caseMixes) {
        result = Object.keys(caseMixes).map((key, index) => {
          return { ...caseMixes[key], Ranking: index + 1 };
        });
      }
      setSalesData(result || []);
      setLoading(false);
    } else {
      setSalesData([]);
    }
  };
  let columns = salesColumns;
  const SkeletonComponent = () => {
    return (
      <>
        <div className="report-grid">
          <div className="rank-grid-item">
            <div className="d-flex justify-content-end my-2">
              <div className="show-monthly-selection">
                <div className="summary-table-container">
                  <DatePicker
                    onChange={onChange}
                    picker="month"
                    value={datePickerVal}
                    defaultValue={moment()}
                  />
                </div>
              </div>
            {salesData?.length > 0 && (<>
                <div className="d-flex justify-content-end">
                <img
                  src="/DownLoadExcel.svg"
                  alt="DownLoadExcel"
                  className="DownLoadExcel"
                  onClick={() => handleExportExcel()}
                />
              </div>
            </>)}
            </div>
            <Table
              dataSource={salesData}
              columns={columns}
              pagination={{ pageSize: "20" }}
              loading={loading}
              bordered
              onChange={(e) => console.log(e)}
              scrollToFirstRowOnChange={true}
              size="middle"
            />
          </div>
        </div>
      </>
    );
  };

  if (!props?.skeleton) {
    return (
      <>
        <Spin spinning={excelLoading}>
          <Card
            title={
              <>
                <Button
                  type="primary"
                  className="btn-back"
                  onClick={() => navigate("/supply-chain")}
                >
                  <i
                    className="fa fa-arrow-left"
                    style={{ fontSize: "16px" }}
                  ></i>
                </Button>
                <span>{`Month View Mixes Sales`}</span>
              </>
            }
          >
            <>
              <SkeletonComponent />
            </>
          </Card>
        </Spin>
      </>
    );
  } else {
    return <SkeletonComponent />;
  }
};

export default YTDMixesSales;
