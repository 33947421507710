import React from "react";
import {
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Line,
  ComposedChart,
  ReferenceLine,
} from "recharts";
import {
  BREAKPOINTWIDTH,
  CurrencyFormat,
  getYearName,
  NumberFormat,
  ShowArrow,
  YAxisNumberFormat,
} from "../../../utils";
import { Legends } from "../../../utils/legend";
import { DashboardLoader } from "../../../utils/Loading";
import {
  SvgChg,
  SvgCurrentYear,
  SvgPreviousYear,
} from "../../../utils/svgpath";
import { CustomTooltipWithChg } from "../../../utils/Tooltip";
import YearPicker from "../../YearPicker";

import useLineOfBusinessSales from "./LineOfBusinessSales.hook";

const LineOfBusinessSales = (props) => {
  const {
    loading,
    mainData,
    maxChgValue,
    maxValue,
    selectedYear,
    data,
    minChgValue,
    roundOff = false,
  } = useLineOfBusinessSales(props);
  const breakPointWidth = BREAKPOINTWIDTH;
  const { width } = props;
  return (
    <>
      {loading ? (
        <DashboardLoader />
      ) : (
        <>
          <div className="d-flex justify-content-end pb-2"> <YearPicker /></div>
          {data?.length > 0 ? (
            <>
              <div className="total-system-sales">
                <ResponsiveContainer
                  width="100%"
                  aspect={width < breakPointWidth ? 2 : 4}
                >
                  <ComposedChart
                    data={data.filter((i) => i?.name !== "Total")}
                    width={400}
                    margin={{
                      top: 25,
                      right: 20,
                      left: 20,
                      bottom: 0,
                    }}
                    height={500}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis
                      label={{
                        // value: "Amt ($)",
                        angle: 360,
                        position: "insideBottomRight",
                        offset: 10,
                      }}
                      tickFormatter={YAxisNumberFormat}
                      allowDataOverflow={true}
                      domain={[0, (dataMax) => parseFloat(maxValue).toFixed(2)]}
                    />
                    <Tooltip
                      content={
                        <CustomTooltipWithChg
                          roundOff={roundOff}
                          selectedYear={selectedYear}
                        />
                      }
                      labelStyle={{ color: "darkblue" }}
                    />
                    {/* <Legend verticalAlign="top" height={36} /> */}
                    <Bar
                      dataKey="currentYear"
                      fill={Legends["currentYear"]}
                      barSize={width < breakPointWidth ? 15 : 30}
                    />
                    <Bar
                      dataKey="priorYear"
                      fill={Legends["priorYear"]}
                      barSize={width < breakPointWidth ? 15 : 30}
                    />
                    <Line
                      dot={true}
                      strokeWidth={2}
                      dataKey="chg"
                      stroke="#FFC000"
                      yAxisId="right"
                      name="Chg"
                    />
                    {minChgValue < 0 && (
                      <ReferenceLine
                        y={0}
                        stroke="green"
                        strokeDasharray="6 6"
                        yAxisId="right"
                        label={"0%"}
                      />
                    )}
                    <YAxis
                      tickLine={false}
                      type={"number"}
                      domain={[
                        (dataMin) =>
                          (minChgValue > 0 ? 0 : minChgValue).toFixed(2),
                        (dataMax) => (maxChgValue + 10).toFixed(2),
                      ]}
                      yAxisId="right"
                      orientation="right"
                      axisLine={{ stroke: "#f5f5f5" }}
                      label={{
                        value: "Chg (%)",
                        angle: 360,
                        position: "insideBottomRight",
                        offset: 10,
                      }}
                      allowDataOverflow={true}
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              {width < breakPointWidth ? (
                <>
                  <div className="table-container legend-table">
                    <table>
                      <thead>
                        <tr>
                          <th>{"Line of Business"}</th>
                          <th>
                            {" "}
                            {SvgCurrentYear()}
                            {getYearName("currentYear", selectedYear)}
                            {ShowArrow(mainData?.ItemData?.currentArrow)}
                          </th>
                          <th>
                            {" "}
                            {SvgPreviousYear()}
                            {getYearName("priorYear", selectedYear)}
                            {ShowArrow(mainData?.ItemData?.priorArrow)}
                          </th>
                          <th>
                            {" "}
                            {SvgChg()}
                            Chg %
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((i) => {
                          return (
                            <>
                              <tr>
                                <td>{i?.name}</td>
                                <td>
                                  {CurrencyFormat(i?.currentYear, roundOff)}
                                </td>
                                <td>
                                  {CurrencyFormat(i?.priorYear, roundOff)}
                                </td>
                                <td>{`${i?.["chg"]} %`}</td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <>
                  <div className="table-container legend-table">
                    <table>
                      <thead>
                        <tr>
                          <th></th>
                          {data.map((i) => {
                            return (
                              <>
                                <th>{i?.name}</th>
                              </>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {SvgCurrentYear()}
                            {getYearName("currentYear", selectedYear)}
                            {ShowArrow(mainData?.ItemData?.currentArrow)}
                          </td>
                          {data.map((i) => {
                            return (
                              <>
                                <td>
                                  {CurrencyFormat(i?.currentYear, roundOff)}
                                </td>
                              </>
                            );
                          })}
                        </tr>
                        <tr>
                          <td>
                            {SvgPreviousYear()}
                            {getYearName("priorYear", selectedYear)}
                            {ShowArrow(mainData?.ItemData?.priorArrow)}
                          </td>
                          {data.map((i) => {
                            return (
                              <>
                                <td>
                                  {CurrencyFormat(i?.priorYear, roundOff)}
                                </td>
                              </>
                            );
                          })}
                        </tr>
                        <tr>
                          <td>
                            {SvgChg()}
                            Chg %
                          </td>
                          {data.map((i) => {
                            return (
                              <>
                                <td>{`${i?.["chg"]} %`}</td>
                              </>
                            );
                          })}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </>
              )}
              </div>
            </>
          ) : (
            <>
              <div className="norecords-available">No records available</div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default LineOfBusinessSales;
