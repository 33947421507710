import React, { useEffect, useState } from "react";
import { Button, Card, InputNumber, Select, Table, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { OperationalService } from "../../services/operational/index.service";
import { useSelector } from "react-redux";
import { checkFCAccess } from "../../utils";

const StoresList = (props) => {
  const navigate = useNavigate();
  const profile = useSelector((state) => state?.profile) || null;
  const service = new OperationalService();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const [ritasIdValue, setRitasIdValue] = useState(null);
  const [searchTextState, setSearchTextState] = useState("");
  const onChange = (value) => {
    setValue(value);
    setRitasIdValue(null);
    setSearchTextState("");
    handleSearch(value);
  };
  const [page, setPage] = useState(1);
  const onSearch = (value) => {
    // console.log("search:", value);
  };
  const columns = [
    {
      title: "Store #",
      dataIndex: "RitasID",
      key: "RitasID",
      sorter: (a, b) => a.RitasID - b.RitasID,
    },
    {
      title: "Store Name",
      dataIndex: "StoreName",
      key: "StoreName",
    },
    {
      title: "FC Name",
      dataIndex: "FSMName",
      key: "FSMName",
    },
    {
      title: "State",
      dataIndex: "State",
      key: "State",
      sorter: (a, b) => a?.State?.localeCompare(b?.State),
    },
    {
      title: "Region",
      dataIndex: "Region",
      key: "Region",
      sorter: (a, b) => a?.Region?.localeCompare(b?.Region),
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      render: (data, propData) => {
        return (
          <td
            className="store-link"
            onClick={() =>
              navigate(`/${props?.from}/store-track-report/${propData?.RitasID}`)
            }
          >
            {"View Details"}
          </td>
        );
      },
    },
  ];

  useEffect(() => {
    if (checkFCAccess(profile)) {
      handleSearch();
    } else {
      fetchFranchises();
      fetchApi();
    }
  }, []);

  const fetchFranchises = async () => {
    const res = await service?.fetchCloverFC();
    setOptions(res?.FCNames || []);
  };

  const fetchApi = async (payload, searchState = false) => {
    setLoading(true);
    const res = await service?.fetchStoresList(payload);
    let result = res?.summaryData;
    if (searchState) {
      result = result.filter((i) =>
        i?.State.toLowerCase().includes(searchTextState.toLowerCase())
      );
    }
    setData(result);
    setLoading(false);
  };

  const handleClear = () => {
    setRitasIdValue(null);
    setValue("");
    setSearchTextState("");
    let payload = {};
    if (checkFCAccess(profile)) {
      payload = { ...payload, intFSM: profile?.intFSM };
    }
    fetchApi(payload);
  };
  const handleStoreSearch = () => {
    let payload = {
      ritasId: ritasIdValue ? ritasIdValue : undefined,
      intFSM: value ? value : undefined,
    };
    if (checkFCAccess(profile)) {
      payload = { ...payload, intFSM: profile?.intFSM };
    }
    fetchApi(payload);
  };

  const handleSearch = (intFSMValue = null) => {
    if (intFSMValue) {
      let payload = {
        intFSM: intFSMValue,
      };
      fetchApi(payload);
    } else {
      let payload = {
        ritasId: ritasIdValue ? ritasIdValue : undefined,
        intFSM: value ? value : undefined,
      };
      if (checkFCAccess(profile)) {
        payload = { ...payload, intFSM: profile?.intFSM };
      }
      if (searchTextState && searchTextState !== "") {
        if (ritasIdValue || value) {
          fetchApi(payload, true);
        } else {
          const filteredData = data.filter((i) =>
            i?.State.toLowerCase().includes(searchTextState.toLowerCase())
          );
          setData(filteredData);
        }
      } else {
        fetchApi(payload);
      }
    }
  };

  const handleChange = (val) => {
    setRitasIdValue(val);
  };

  const handleFilterWithState = (e) => {
    setSearchTextState(e);
  };
  return (
    <>
      <Card
        title={
          <>
            <Button
              type="primary"
              className="btn-back"
              onClick={() => navigate(`/${props?.from}`)}
            >
              <i className="fa fa-arrow-left" style={{ fontSize: "16px" }}></i>
            </Button>
            <span>{`Stores List`}</span>
          </>
        }
      >
        <div className="report-grid">
          <div className="rank-grid-item">
            <div className="table-div">
              <div className="ranking-btn-group"></div>
              <div className="stores-list-grid-header">
                <InputNumber
                  name="RitasId"
                  type={"number"}
                  style={{ width: "auto" }}
                  id="RitasId"
                  placeholder="Store Number"
                  onChange={handleChange}
                  value={ritasIdValue}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      handleSearch();
                    }
                  }}
                />
                <Input
                  name="StateName"
                  autoComplete="off"
                  style={{ width: "auto" }}
                  placeholder="State Name"
                  onChange={(e) => handleFilterWithState(e.target.value)}
                  value={searchTextState}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      handleStoreSearch();
                    }
                  }}
                />
                {profile?.isAdmin && (
                  <>
                    <Select
                      showSearch
                      placeholder="Select a FC"
                      style={{ width: "auto" }}
                      optionFilterProp="children"
                      value={value || { value: 0, label: "Please Select FC" }}
                      onChange={onChange}
                      onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={options}
                    />
                  </>
                )}
                <div>
                  <Button
                    onClick={() => handleClear()}
                    style={{ marginRight: "8px" }}
                  >
                    <i className="fas fa-eraser"></i>
                  </Button>
                  <Button onClick={() => handleSearch()}>
                    <i className="fas fa-search"></i>
                  </Button>
                </div>
              </div>
            </div>
            <Table
              dataSource={data}
              pagination={{
                pageSize: "10",
                current: page,
                showQuickJumper: false,
                showSizeChanger: false,
                onChange(page, pageSize) {
                  setPage(page);
                },
              }}
              columns={columns}
              loading={loading}
              bordered
              scrollToFirstRowOnChange={true}
              size="middle"
            />
          </div>
        </div>
      </Card>
    </>
  );
};

export default StoresList;
