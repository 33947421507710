import MarketingDashboard from "../components/MarketingDashboard";
import YTDTransactionsByShop from "../components/MarketingDashboard/YTDTransactionsByShop";
import RedeemRewardsReport from "../components/MarketingDashboard/RedeemRewardsReport/RedeemRewardsReport";
import MainLoyaltyReport from "../components/MarketingDashboard/LoyaltyReport/MainLoyaltyReport.view";
import YTDSales from "../components/FinancialDashboard/Sales/YTDSales";
import StoresList from "../components/OperationalDashboard/StoresList";
import SalesSectionByStore from "../components/OperationalDashboard/SalesSectionByStore";
import CummulativeRanking from "../components/FinancialDashboard/CummulativeRanking";
const MarketingRoutes = [
  {
    path: "/marketing",
    name: "MarketingDashboard",
    key: "MarketingDashboard",
    component: <MarketingDashboard />,
  },
  {
    path: "/marketing/ytd/:storeId",
    name: "YTDSales",
    key: "YTDSales",
    component: <YTDSales from={"marketing"} />,
  },
  {
    path: "/marketing/loyalty-incentive-promotions",
    name: "YTDTransactionsByShop",
    key: "YTDTransactionsByShop",
    component: <YTDTransactionsByShop />,
  },
  {
    path: "/marketing/store-track-report/:store",
    name: "SalesSectionByStore",
    key: "SalesSectionByStore",
    component: <SalesSectionByStore from={`marketing`} />,
  },
  {
    path: "/marketing/store-track-report",
    name: "SalesSectionByStore",
    key: "SalesSectionByStore",
    component: <SalesSectionByStore from={`marketing`} />,
  },
  {
    path: "/marketing/stores-list-report",
    name: "MarketingStoresList",
    key: "MarketingStoresList",
    component: <StoresList from={`marketing`} />,
  },
  {
    path: "/marketing/loyalty-report",
    name: "LoyaltyReport",
    key: "LoyaltyReport",
    component: <MainLoyaltyReport />,
  },

  {
    path: "/marketing/redeem-rewards-report",
    name: "RedeemRewardsReport",
    key: "RedeemRewardsReport",
    component: <RedeemRewardsReport />,
  },
  {
    path: "/marketing/rank-report",
    name: "MarketingCummulativeRanking",
    key: "MarketingCummulativeRanking",
    component: <CummulativeRanking />,
  },
];

export default MarketingRoutes;
