import React from "react";
import { Spin } from "antd";
import useStoreSummary from "./StoreSummary.hook";
import { CurrencyFormat } from "../../../utils";

const StoreSummary = (props) => {
  const { loading, priorData, currentData, priorLoading } =
    useStoreSummary(props);

  return (
    <div className="store-summary-prior-current">
      <Spin spinning={priorLoading}>
        <div className="d-flex justify-content-center align-items-center">
          <div className="summary  ">
            <table className="tbl">
              <tr>
                <td className="ident" colspan="3" align="center">
                  FINANCIAL REVIEW
                  <br />
                  Week Number:&nbsp;{priorData?.SRSDate}
                </td>
              </tr>
              <tr>
                <td className="ident" colspan="3">
                  {`${priorData?.city}, ${priorData?.state} ${props?.storeId}`}
                </td>
              </tr>
              <tr>
                <td className="category">Current Sales</td>
                <td className="category">% &Delta;</td>
                <td className="category">Ranking</td>
              </tr>
              <tr>
                <td className={`data`}>
                  {CurrencyFormat(priorData?.CurrentSales)}
                </td>
                <td
                  className={`data ${
                    priorData?.CurrentSalesPer > 0 ? "color-green" : "color-red"
                  }`}
                >
                  {priorData?.CurrentSalesPer?.toFixed(2) || 0}%
                </td>
                <td className={`data`}>{priorData?.SalesRank}</td>
              </tr>
              <tr>
                <td className="category">Guest Count</td>
                <td className="category">% &Delta;</td>
                <td className="category">Ranking</td>
              </tr>
              <tr>
                <td className={`data`}>{priorData?.Transactions || 0}</td>
                <td
                  className={`data ${
                    priorData?.TransactionsPer > 0 ? "color-green" : "color-red"
                  }`}
                >
                  {priorData?.TransactionsPer?.toFixed(2) || 0}%
                </td>
                <td className={`data`}>{priorData?.GCRank}</td>
              </tr>
              <tr>
                <td className="category">Check Average</td>
                <td className="category">% &Delta;</td>
                <td className="category">Ranking</td>
              </tr>
              <tr>
                <td className={`data`}>
                  {CurrencyFormat(priorData?.CheckAverage)}
                </td>
                <td
                  className={`data ${
                    priorData?.CheckAveragePer > 0 ? "color-green" : "color-red"
                  }`}
                >
                  {priorData?.CheckAveragePer?.toFixed(2) || 0}%
                </td>
                <td className={`data`}>{priorData?.AvgRank}</td>
              </tr>
              <tr>
                <td className="category">Current Catering Sales</td>
                <td className="category">% &Delta;</td>
                <td className="category">Ranking</td>
              </tr>
              <tr>
                <td className={`data`}>
                  {CurrencyFormat(priorData?.CurrentCateringSales)}
                </td>
                <td
                  className={`data ${
                    priorData?.CurrentCateringSalesPer > 0
                      ? "color-green"
                      : "color-red"
                  }`}
                >
                  {priorData?.CurrentCateringSalesPer?.toFixed(2) || 0}%
                </td>
                <td className={`data`}>{priorData?.CateringRank}</td>
              </tr>
              <tr>
                <td className="category">Current Digital Sales</td>
                <td className="category">% &Delta;</td>
                <td className="category">Ranking</td>
              </tr>
              <tr>
                <td className={`data`}>
                  {CurrencyFormat(priorData?.CurrentDigitalSales)}
                </td>
                <td
                  className={`data ${
                    priorData?.CheckDigitalPer > 0 ? "color-green" : "color-red"
                  }`}
                >
                  {priorData?.CheckDigitalPer || 0}%
                </td>
                <td className={`data`}>{priorData?.DigitalRank}</td>
              </tr>
              <tr>
                <td className="category">Current Mobile Sales</td>
                <td className="category">% &Delta;</td>
                <td className="category">Ranking</td>
              </tr>
              <tr>
                <td className={`data`}>
                  {CurrencyFormat(priorData?.CurrentMobileSales)}
                </td>
                <td
                  className={`data ${
                    priorData?.CheckMobilePer > 0 ? "color-green" : "color-red"
                  }`}
                >
                  {priorData?.CheckMobilePer || 0}%
                </td>
                <td className={`data`}>{priorData?.MobileRank}</td>
              </tr>
              <tr>
                <td className="category">YTD Sales</td>
                <td className="category">% &Delta;</td>
                <td className="category">Ranking</td>
              </tr>
              <tr>
                <td className={`data`}>
                  {CurrencyFormat(priorData?.YTDSales)}
                </td>
                <td
                  className={`data ${
                    priorData?.YTDSalesPer > 0 ? "color-green" : "color-red"
                  }`}
                >
                  {priorData?.YTDSalesPer?.toFixed(2) || 0}%
                </td>
                <td className={`data`}>{priorData?.YTDTotalSalesRank}</td>
              </tr>
              <tr>
                <td className="category">YTD Guest Count</td>
                <td className="category">% &Delta;</td>
                <td className="category">Ranking</td>
              </tr>
              <tr>
                <td className={`data`}>{priorData?.YTDTransactions || 0}</td>
                <td
                  className={`data ${
                    priorData?.YTDTransactionsPer > 0
                      ? "color-green"
                      : "color-red"
                  }`}
                >
                  {priorData?.YTDTransactionsPer?.toFixed(2) || 0}%
                </td>
                <td className={`data`}>{priorData?.YTDTotalGCRank}</td>
              </tr>
              <tr>
                <td className="category">YTD Check Average</td>
                <td className="category">% &Delta;</td>
                <td className="category">Ranking</td>
              </tr>
              <tr>
                <td className={`data`}>
                  {CurrencyFormat(priorData?.YTDCheckAverage)}
                </td>
                <td
                  className={`data ${
                    priorData?.YTDCheckAveragePer > 0
                      ? "color-green"
                      : "color-red"
                  }`}
                >
                  {priorData?.YTDCheckAveragePer?.toFixed(2) || 0}%
                </td>
                <td className={`data`}>{priorData?.YTDTotalAvgRank}</td>
              </tr>
              <tr>
                <td className="category">YTD Catering Sales</td>
                <td className="category">% &Delta;</td>
                <td className="category">Ranking</td>
              </tr>
              <tr>
                <td className={`data`}>
                  {CurrencyFormat(priorData?.YTDCateringSales)}
                </td>
                <td
                  className={`data ${
                    priorData?.YTDCateringSalesPer > 0
                      ? "color-green"
                      : "color-red"
                  }`}
                >
                  {priorData?.YTDCateringSalesPer?.toFixed(2) || 0}%
                </td>
                <td className={`data`}>{priorData?.YTDTotalCateringRank}</td>
              </tr>
              <tr>
                <td className="category">YTD Digital Sales</td>
                <td className="category">% &Delta;</td>
                <td className="category">Ranking</td>
              </tr>
              <tr>
                <td className={`data`}>
                  {CurrencyFormat(priorData?.YTDDigitalSales)}
                </td>
                <td
                  className={`data ${
                    priorData?.YTDDigitalPer > 0 ? "color-green" : "color-red"
                  }`}
                >
                  {priorData?.YTDDigitalPer || 0}%
                </td>
                <td className={`data`}>{priorData?.YTDTotalDigitalRank}</td>
              </tr>
              <tr>
                <td className="category">YTD Mobile Sales</td>
                <td className="category">% &Delta;</td>
                <td className="category">Ranking</td>
              </tr>
              <tr>
                <td className={`data`}>
                  {CurrencyFormat(priorData?.YTDMobileSales)}
                </td>
                <td
                  className={`data ${
                    priorData?.YTDMobilePer > 0 ? "color-green" : "color-red"
                  }`}
                >
                  {priorData?.YTDMobilePer || 0}%
                </td>
                <td className={`data`}>{priorData?.YTDTotalMobileRank}</td>
              </tr>
            </table>
          </div>
        </div>
      </Spin>
      <Spin spinning={loading}>
        <div className="d-flex justify-content-center align-items-center">
          <div className="summary  ">
            <table className="tbl">
              <tr>
                <td className="ident" colspan="3" align="center">
                  FINANCIAL REVIEW
                  <br />
                  Week Number:&nbsp;{currentData?.SRSDate}
                </td>
              </tr>
              <tr>
                <td className="ident" colspan="3">
                  {`${currentData?.city}, ${currentData?.state} ${props?.storeId}`}
                </td>
              </tr>
              <tr>
                <td className="category">Current Sales</td>
                <td className="category">% &Delta;</td>
                <td className="category">Ranking</td>
              </tr>
              <tr>
                <td className={`data`}>
                  {CurrencyFormat(currentData?.CurrentSales)}
                </td>
                <td
                  className={`data ${
                    currentData?.CurrentSalesPer > 0
                      ? "color-green"
                      : "color-red"
                  }`}
                >
                  {currentData?.CurrentSalesPer?.toFixed(2) || 0}%
                </td>
                <td className={`data`}>{currentData?.SalesRank}</td>
              </tr>
              <tr>
                <td className="category">Guest Count</td>
                <td className="category">% &Delta;</td>
                <td className="category">Ranking</td>
              </tr>
              <tr>
                <td className={`data`}>{currentData?.Transactions || 0}</td>
                <td
                  className={`data ${
                    currentData?.TransactionsPer > 0
                      ? "color-green"
                      : "color-red"
                  }`}
                >
                  {currentData?.TransactionsPer?.toFixed(2) || 0}%
                </td>
                <td className={`data`}>{currentData?.GCRank}</td>
              </tr>
              <tr>
                <td className="category">Check Average</td>
                <td className="category">% &Delta;</td>
                <td className="category">Ranking</td>
              </tr>
              <tr>
                <td className={`data`}>
                  {CurrencyFormat(currentData?.CheckAverage)}
                </td>
                <td
                  className={`data ${
                    currentData?.CheckAveragePer > 0
                      ? "color-green"
                      : "color-red"
                  }`}
                >
                  {currentData?.CheckAveragePer?.toFixed(2) || 0}%
                </td>
                <td className={`data`}>{currentData?.AvgRank}</td>
              </tr>
              <tr>
                <td className="category">Current Catering Sales</td>
                <td className="category">% &Delta;</td>
                <td className="category">Ranking</td>
              </tr>
              <tr>
                <td className={`data`}>
                  {CurrencyFormat(currentData?.CurrentCateringSales)}
                </td>
                <td
                  className={`data ${
                    currentData?.CurrentCateringSalesPer > 0
                      ? "color-green"
                      : "color-red"
                  }`}
                >
                  {currentData?.CurrentCateringSalesPer?.toFixed(2) || 0}%
                </td>
                <td className={`data`}>{currentData?.CateringRank}</td>
              </tr>
              <tr>
                <td className="category">Current Digital Sales</td>
                <td className="category">% &Delta;</td>
                <td className="category">Ranking</td>
              </tr>
              <tr>
                <td className={`data`}>
                  {CurrencyFormat(currentData?.CurrentDigitalSales)}
                </td>
                <td
                  className={`data ${
                    currentData?.CheckDigitalPer > 0
                      ? "color-green"
                      : "color-red"
                  }`}
                >
                  {currentData?.CheckDigitalPer || 0}%
                </td>
                <td className={`data`}>{currentData?.DigitalRank}</td>
              </tr>
              <tr>
                <td className="category">Current Mobile Sales</td>
                <td className="category">% &Delta;</td>
                <td className="category">Ranking</td>
              </tr>
              <tr>
                <td className={`data`}>
                  {CurrencyFormat(currentData?.CurrentMobileSales)}
                </td>
                <td
                  className={`data ${
                    currentData?.CheckMobilePer > 0
                      ? "color-green"
                      : "color-red"
                  }`}
                >
                  {currentData?.CheckMobilePer || 0}%
                </td>
                <td className={`data`}>{currentData?.MobileRank}</td>
              </tr>
              <tr>
                <td className="category">
                  <a
                    className=""
                    onClick={() => props?.handleSegmentSelection("Year")}
                  >
                    YTD Sales
                  </a>
                </td>
                <td className="category">% &Delta;</td>
                <td className="category">Ranking</td>
              </tr>
              <tr>
                <td className={`data`}>
                  {CurrencyFormat(currentData?.YTDSales)}
                </td>
                <td
                  className={`data ${
                    parseFloat(currentData?.YTDSalesPer) > 0
                      ? "color-green"
                      : "color-red"
                  }`}
                >
                  {parseFloat(currentData?.YTDSalesPer)?.toFixed(2) || 0}%
                </td>
                <td className={`data`}>{currentData?.YTDTotalSalesRank}</td>
              </tr>
              <tr>
                <td className="category">YTD Guest Count</td>
                <td className="category">% &Delta;</td>
                <td className="category">Ranking</td>
              </tr>
              <tr>
                <td className={`data`}>{currentData?.YTDTransactions || 0}</td>
                <td
                  className={`data ${
                    currentData?.YTDTransactionsPer > 0
                      ? "color-green"
                      : "color-red"
                  }`}
                >
                  {currentData?.YTDTransactionsPer?.toFixed(2) || 0}%
                </td>
                <td className={`data`}>{currentData?.YTDTotalGCRank}</td>
              </tr>
              <tr>
                <td className="category">YTD Check Average</td>
                <td className="category">% &Delta;</td>
                <td className="category">Ranking</td>
              </tr>
              <tr>
                <td className={`data`}>
                  {CurrencyFormat(currentData?.YTDCheckAverage)}
                </td>
                <td
                  className={`data ${
                    currentData?.YTDCheckAveragePer > 0
                      ? "color-green"
                      : "color-red"
                  }`}
                >
                  {currentData?.YTDCheckAveragePer?.toFixed(2) || 0}%
                </td>
                <td className={`data`}>{currentData?.YTDTotalAvgRank}</td>
              </tr>
              <tr>
                <td className="category">
                  <a
                    className=""
                    onClick={() =>
                      props?.handleSegmentSelection("Line of Business")
                    }
                  >
                    YTD Catering Sales
                  </a>
                </td>

                <td className="category">% &Delta;</td>
                <td className="category">Ranking</td>
              </tr>
              <tr>
                <td className={`data`}>
                  {CurrencyFormat(currentData?.YTDCateringSales)}
                </td>
                <td
                  className={`data ${
                    parseFloat(currentData?.YTDCateringSalesPer) > 0
                      ? "color-green"
                      : "color-red"
                  }`}
                >
                  {parseFloat(currentData?.YTDCateringSalesPer)?.toFixed(2) ||
                    0}
                  %
                </td>
                <td className={`data`}>{currentData?.YTDTotalCateringRank}</td>
              </tr>
              <tr>
                <td className="category">
                  {" "}
                  <a
                    className=""
                    onClick={() => props?.handleSegmentSelection("Digital")}
                  >
                    YTD Digital Sales
                  </a>
                </td>

                <td className="category">% &Delta;</td>
                <td className="category">Ranking</td>
              </tr>
              <tr>
                <td className={`data`}>
                  {CurrencyFormat(currentData?.YTDDigitalSales)}
                </td>
                <td
                  className={`data ${
                    currentData?.YTDDigitalPer > 0 ? "color-green" : "color-red"
                  }`}
                >
                  {currentData?.YTDDigitalPer || 0}%
                </td>
                <td className={`data`}>{currentData?.YTDTotalDigitalRank}</td>
              </tr>
              <tr>
                <td className="category">
                  <a
                    className=""
                    onClick={() =>
                      props?.handleSegmentSelection("Line of Business")
                    }
                  >
                    YTD Mobile Sales
                  </a>
                </td>
                <td className="category">% &Delta;</td>
                <td className="category">Ranking</td>
              </tr>
              <tr>
                <td className={`data`}>
                  {CurrencyFormat(currentData?.YTDMobileSales)}
                </td>
                <td
                  className={`data ${
                    currentData?.YTDMobilePer > 0 ? "color-green" : "color-red"
                  }`}
                >
                  {currentData?.YTDMobilePer || 0}%
                </td>
                <td className={`data`}>{currentData?.YTDTotalMobileRank}</td>
              </tr>
            </table>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default StoreSummary;
